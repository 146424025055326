import { ds } from "js/core/models/dataService";
import { _ } from "js/vendor";
import { controls } from "js/editor/ui";

import { ElementOptionsMenu, ElementSelection } from "../BaseElementEditor";
import { CollectionElementSelection } from "../CollectionElementEditor";
import { TextElementSelection } from "./textEditor";

const CalendarSelection = ElementSelection.extend({
    renderControls: function() {
        this.addControl({
            type: controls.DATE_PICKER,
            label: "Date",
            property: "date"
        });

        // this.addControl({
        //     type: controls.MULTI_TOGGLE,
        //     property: "calendarType",
        //     options: [{
        //         value: "month", label: "Month"
        //     }, {
        //         value: "week", label: "Week"
        //     }]
        // });
    },

});

const CalendarOptionsMenu = ElementOptionsMenu.extend({
    renderControls: function() {
        this.addControl({
            type: controls.TOGGLE,
            label: "Show Title",
            property: "showTitle"
        });

        this.addControl({
            type: controls.TOGGLE,
            label: "Show Weekends",
            property: "showWeekends",
        });

        // this.addControl({
        //     type: controls.TOGGLE,
        //     label: "Start Week On Monday",
        //     property: "startOnMonday",
        // });
    }
});

const CalendarCellSelection = CollectionElementSelection.extend({
    showSelectionBox: true,

    getOffset: function() {
        return 0;
    },

    renderControls: function() {
        // this.createEmphasizeWidget();

        this.addControl({
            type: controls.COLOR_PALETTE_PICKER,
            property: "cellColor",
            includeWhite: true,
        });

        this.addControl({
            type: controls.BUTTON,
            label: "Add Text",
            enabled: this.element.showTask == false,
            callback: () => {
                this.element.showTaskPlaceholder = true;
                this.element.canvas.updateCanvasModel(false).then(() => {
                    ds.selection.element = this.element.task;
                });
            }
        });
    }
});

const CalendarTaskTextSelection = TextElementSelection.extend({
    cleanUp: function() {
        if (!this.element.model.task || this.element.model.task == "" || this.element.model.task.text == "") {
            this.element.parentElement.showTaskPlaceholder = false;
            _.defer(() => {
                this.element.canvas.refreshCanvasAutoRevert({ transition: true }).then(() => {
                    TextElementSelection.prototype.cleanUp.apply(this, arguments);
                });
            });
        } else {
            TextElementSelection.prototype.cleanUp.apply(this, arguments);
        }
    }
});

export const editors = {
    CalendarSelection,
    CalendarOptionsMenu,
    CalendarCellSelection,
    CalendarTaskTextSelection
};
