import * as geom from "js/core/utilities/geom";
import { app } from "js/namespaces";
import { $, _ } from "js/vendor";
import { AnchorType } from "js/core/utilities/geom";
import { ds } from "js/core/models/dataService";
import { ConnectorType } from "common/constants";
import { Convert } from "js/core/utilities/geom";

import { renderNodeConnectorWidget, renderNodeElementControls } from "./NodeEditor";
import { ContentItemSelection, renderContentItemControls } from "./ContentItemEditor";
import { CollectionElementSelection } from "../CollectionElementEditor";

import { AnnotationLayer } from "../../elements/elements/AnnotationLayer";
import { ContentItem } from "../../elements/base/ContentItem";

const AnnotationLayerSelection = CollectionElementSelection.extend({});

const AnnotationItemSelection = ContentItemSelection.extend({

    showDragDropTarget: false,

    getOffset() {
        return 20;
    },

    transitionOnDelete() {
        return false;
    },

    renderControls() {
        if (this.element instanceof ContentItem) {
            renderContentItemControls(this, { allowFrames: true, canResize: true });
            if (this.element.showResizeHandle) {
                this.renderResizeHandle();
            }
        } else {
            renderNodeElementControls(this);
            this.renderResizeHandle();
        }

        if (this.model.hideNodeConnectorWidget !== true) {
            renderNodeConnectorWidget(this, event => createAnnotationConnector(this, event));
        }
    },

    _layout() {
        ContentItemSelection.prototype._layout.apply(this, arguments);

        let selectionBounds = this.getElementSelectionBounds();

        for (let connector of this.element.connectorsFromNode) {
            if (connector.model.target == null && connector.model.targetPoint != null) {
                let endPt = new geom.Point(connector.model.targetPoint.x, connector.model.targetPoint.y).offset(connector.parentElement.canvasBounds.position).multiply(app.canvasScale).offset(-selectionBounds.left, -selectionBounds.top);
                this.$el.find("#widget-" + connector.id).left(endPt.x - 5).top(endPt.y - 5);
            }
        }
    },

    createConnectorPoint: function(connector) {
        let $el = this.$el.addEl($.div("connector-dragger control"));
        $el.attr("id", "widget-" + connector.id);

        $el.on("mousedown", event => {
            event.stopPropagation();
            app.isDraggingItem = true;

            app.mainView.editorView.selectionLayer.hideWidgets();

            $("body").on("mousemove.drag", event => {
                event.stopPropagation();
                connector.model.targetPoint = Convert.ScreenToElementCoordinates(this.element.parentElement.canvas, this.element.parentElement, event.pageX, event.pageY);
                this.element.refreshElement();
            });
            $("body").on("mouseup.drag", event => {
                event.stopPropagation();
                app.isDraggingItem = false;
                $("body").off(".drag");
                this.element.canvas.updateCanvasModel(false)
                    .then(() => {
                        this.layout();
                        app.mainView.editorView.selectionLayer.showWidgets();
                        this.$el.find(".anchor-widget").remove();
                    });
            });
        });
    },

    onDrag: function(event, position, dragProps) {
        if (!this.canvas.layouter.isGenerating) {
            const registrationPoint = this.element.registrationPoint;

            const containerElement = this.element.parentElement;

            const dragPosition = position.elementPosition.offset(registrationPoint);
            this.element.dragWidgetPosition = position.elementPosition;
            this.element.model.x = Math.clamp(dragPosition.x / containerElement.canvasBounds.width, 0, 1);
            this.element.model.y = Math.clamp(dragPosition.y / containerElement.canvasBounds.height, 0, 1);

            // If the element has snap options - remove them after it's been moved
            if (this.element.model.snapOptions) {
                delete this.element.model.snapOptions;
            }

            if (containerElement.allowDragDropElements) {
                this.calcDropTarget(dragProps, position);
            }

            this.element.findClosestOfType(AnnotationLayer).refreshElement();
        }
    },

});

function createAnnotationConnector(view, event) {
    event.stopPropagation();

    view.selectionLayer.hideWidgets($(".anchor-widget"));
    app.isDraggingItem = true;

    let element = view.element;
    let containerElement = view.element.parentElement;

    element.canvas.lockSlideForCollaborators(60); // 1 minute

    // create a new connector model that will point to the mouse
    let connectorModel = {
        source: element.id,
        startAnchor: AnchorType.FREE,
        endAnchor: AnchorType.FREE,
        target: null,
        targetPoint: Convert.ScreenToElementCoordinates(containerElement.canvas, containerElement, event.pageX, event.pageY),
        endDecoration: "circle",
        connectorType: ConnectorType.STRAIGHT
    };
    containerElement.connectors.model.items.push(connectorModel);

    containerElement.canvas.refreshCanvas({ suppressRefreshCanvasEvent: true }).then(() => {
        let startDragPt = new geom.Point(event.pageX, event.pageY);

        $("body").on("mousemove.addNode", event => {
            view.selectionLayer.hideWidgets($(".anchor-widget"));
            connectorModel.targetPoint = Convert.ScreenToElementCoordinates(containerElement.canvas, containerElement, event.pageX, event.pageY);
            containerElement.refreshElement();
        });

        $("body").on("mouseup.addNode", event => {
            if (new geom.Point(event.pageX, event.pageY).distance(startDragPt) > 20) {
                element.canvas.updateCanvasModel(false).then(() => {
                    // select new connector after mouseup
                    ds.selection.element = _.last(containerElement.connectors.itemElements);
                    view.selectionLayer.showWidgets();
                    app.isDraggingItem = false;
                    element.canvas.unlockSlideForCollaborators();
                });
            } else {
                // we didnt drag far enough to create a new node so cancel the operation and delete the connectorModel
                containerElement.connectors.model.items.remove(connectorModel);
                element.canvas.refreshCanvas();

                view.selectionLayer.showWidgets();
                app.isDraggingItem = false;
                element.canvas.unlockSlideForCollaborators();
            }

            $("body").off(".addNode");
        });
    });
}

export const editors = {
    AnnotationLayerSelection,
    AnnotationItemSelection,
    // AnnotationItemRollover
};
