import React from "reactn";

import * as geom from "js/core/utilities/geom";
import { FormatType, HorizontalAlignType } from "common/constants";
import { formatter } from "js/core/utilities/formatter";
import { DegreesToRadians } from "js/core/utilities/geom";
import { SVGGroup } from "js/core/utilities/svgHelpers";
import { Path, Shape } from "js/core/utilities/shapes";

import { CollectionElement, CollectionItemElement } from "../base/CollectionElement";
import { TextElement } from "../base/TextElement";
import { SVGElement, SVGPathElement } from "../base/SVGElement";

class RadialBarChart extends CollectionElement {
    getChildItemType() {
        return RadialBarChartItem;
    }

    get maxItemCount() {
        return 8;
    }

    get maxValue() {
        return this.model.maxValue || 100;
    }

    get format() {
        return this.model.format || FormatType.PERCENT;
    }

    get formatOptions() {
        return this.model.formatOptions || formatter.getDefaultFormatOptions();
    }

    get startPosition() {
        return this.model.startPosition || "top";
    }

    get showGrid() {
        return this.model.showGrid || false;
    }

    get trackStyle() {
        return this.model.trackStyle || false;
    }

    get defaultItemData() {
        return {
            value: 50
        };
    }

    formatValue(value) {
        let displayValue = value;
        if (this.format == FormatType.PERCENT) {
            displayValue = value / 100;
        } else {
            displayValue = value;
        }

        return formatter.formatValue(displayValue, this.format, this.formatOptions);
    }

    get barGap() {
        return 10;
    }

    _build() {
        this.buildItems();

        if (this.showGrid) {
            this.grid = this.addElement("grid", () => RadialBarChartGrid);
        }
    }

    _calcProps(props, options) {
        let { size } = props;
        let maxBarWidth = 30;

        let outerRadius = Math.min(size.width, size.height) / 2;
        let innerRadius = Math.max(outerRadius - (this.itemCount * (maxBarWidth + this.barGap)) + this.barGap, 50);

        let barWidth = Math.min(maxBarWidth, (outerRadius - innerRadius - this.barGap * (this.itemCount - 1)) / this.itemCount);
        if (barWidth < 12) {
            props.isFit = false;
        }
        this.barWidth = barWidth;

        for (let item of this.itemElements) {
            let itemProps = item.calcProps(size);
            itemProps.bounds = new geom.Rect(size.width / 2 - itemProps.size.width / 2, size.height / 2 - itemProps.size.height / 2, itemProps.size);
        }

        if (this.grid) {
            const gridProps = this.grid.calcProps(size, { itemCount: this.itemCount, startPosition: this.startPosition, barWidth, barGap: this.barGap });
            gridProps.layer = this.itemElements.length;
        }

        return { size, barWidth: barWidth, barGap: this.barGap };
    }
}

class RadialBarChartGrid extends SVGElement {
    renderSVG(props, styles) {
        const { options: { itemCount, startPosition, barWidth, barGap }, bounds } = props;

        let gridChildren = [];

        let outerRadius = Math.min(bounds.width, bounds.height) / 2;
        let innerRadius = outerRadius - (itemCount * (barWidth + barGap)) + barGap;
        let cx = bounds.width / 2;
        let cy = bounds.height / 2;

        let startAngle, endAngle;
        if (startPosition == "top") {
            startAngle = 0;
            endAngle = 270;
        } else {
            startAngle = 180;
            endAngle = 450;
        }

        for (let angle = startAngle; angle <= endAngle; angle += 45) {
            let a = DegreesToRadians(angle - 90);
            let start = new geom.Point(cx + innerRadius * Math.cos(a), cy + innerRadius * Math.sin(a));
            let end = new geom.Point(cx + outerRadius * Math.cos(a), cy + outerRadius * Math.sin(a));

            gridChildren.push(<line key={gridChildren.length} x1={start.x} y1={start.y} x2={end.x} y2={end.y} style={styles} />);
        }

        return (
            <SVGGroup ref={this.ref} key={this.id}>
                {gridChildren}
            </SVGGroup>
        );
    }
}

class RadialBarChartItem extends CollectionItemElement {
    get barWidth() {
        return this.parentElement.barWidth;
    }

    get barGap() {
        return this.parentElement.barGap;
    }

    get trackStyle() {
        return this.parentElement.trackStyle || "none";
    }

    get currentValue() {
        if (this.isAnimating) {
            return this.model.value * this.animationState.value;
        }

        return this.model.value;
    }

    get selectionPadding() {
        return 0;
    }

    get valueLabelText() {
        return this.parentElement.formatValue(this.currentValue);
    }

    getSelectionPath(scale) {
        let center = new geom.Point(this.radius, this.radius).multiply(scale);
        return Shape.drawArc2((this.radius + this.barWidth / 2 + 5) * scale, (this.radius - this.barWidth / 2 - 5) * scale, this.startAngle, this.endAngle, center);
    }

    _build() {
        // Maxing our value to the parent's maxValue, i.e. after the maxValue was reduced
        if (this.model.value > this.parentElement.maxValue) {
            this.model.value = this.parentElement.maxValue;
        }

        if (this.trackStyle != "none") {
            this.track = this.addElement("track", () => SVGPathElement);
            this.track.layer = -1;
        }

        this.radialBar = this.addElement("radialBar", () => SVGPathElement, {
            styleOptions: {
                shading: true
            }
        });

        this.label = this.addElement("label", () => RadialBarChartItemLabel, {
            autoWidth: true,
            autoHeight: true,
        });

        if (this.parentElement.format != FormatType.NONE) {
            this.value = this.addElement("value", () => RadialBarChartItemValue, {
                model: { value: this.valueLabelText },
                autoWidth: true,
                autoHeight: true,
                canEdit: false,
            });
        }
    }

    _calcProps(props, options) {
        let { size } = props;

        let percent = Math.clamp(this.currentValue / this.parentElement.maxValue, 0, 1);

        let startAngle, endAngle, trackEndAngle;

        if (this.parentElement.startPosition == "top") {
            startAngle = 270;
            endAngle = 270 * percent + 270;
            trackEndAngle = 180;
        } else {
            startAngle = 90;
            endAngle = 270 * percent + 90;
            trackEndAngle = 0;
        }

        let maxRadius = Math.min(size.width, size.height) / 2;
        let r = maxRadius - this.barWidth / 2;
        r -= (this.barWidth + this.barGap) * this.itemIndex;

        this.radius = r;
        this.startAngle = startAngle;
        this.endAngle = endAngle;

        let center = new geom.Point(r, r);

        let arcStartPoint = geom.Point.PointFromAngle(r, startAngle, center);
        let arcEndPoint = geom.Point.PointFromAngle(r, endAngle, center);

        let radialBarProps = this.radialBar.createProps({
            path: Shape.drawArc2(r + this.barWidth / 2, r - this.barWidth / 2, startAngle, endAngle, center).toPathData()
        });

        if (this.trackStyle != "none") {
            let trackProps = this.track.createProps({});

            switch (this.parentElement.trackStyle) {
                case "line":
                    let trackStartOffset = this.value == null ? 0 : 40;
                    let trackStartPoint = arcEndPoint.rotateByArcLength(trackStartOffset, r, center);
                    let trackEndPoint = geom.Point.PointFromAngle(r, trackEndAngle, center);

                    let trackStartAngle = center.angleToPoint(trackStartPoint);
                    let trackArcLength;
                    if (trackStartAngle % 360 > trackEndAngle) {
                        trackArcLength = (360 - trackStartAngle) + trackEndAngle;
                    } else {
                        trackArcLength = trackEndAngle - trackStartAngle;
                    }

                    if (trackArcLength > 270) {
                        break;
                    }

                    let trackLinePath = new Path();
                    trackLinePath.moveTo(trackStartPoint);

                    while (trackArcLength > 0) {
                        let arcLength = Math.min(trackArcLength, 90);
                        trackStartAngle += arcLength;
                        let point = geom.Point.PointFromAngle(r, trackStartAngle % 360, center);

                        trackLinePath.arc(r, r, 0, 0, 1, point.x, point.y);
                        trackArcLength -= arcLength;
                    }

                    this.track.updateStyles(this.styles.trackLine);
                    trackProps.path = trackLinePath.toPathData();
                    break;
                case "bar":
                default:
                    this.track.updateStyles(this.styles.trackBar);
                    trackProps.path = Shape.drawArc2(r + this.barWidth / 2, r - this.barWidth / 2, startAngle, startAngle + 270, center).toPathData();
                    break;
            }
        }

        if (this.parentElement.startPosition == "top") {
            this.styles.label.textAlign = HorizontalAlignType.RIGHT;
        } else {
            this.styles.label.textAlign = HorizontalAlignType.LEFT;
        }
        let labelProps = this.label.calcProps(new geom.Size(size.width / 2, 100));
        let labelPosition;
        if (this.parentElement.startPosition == "top") {
            labelPosition = arcStartPoint.offset(-labelProps.size.width, -labelProps.size.height / 2);
        } else {
            labelPosition = arcStartPoint.offset(0, -labelProps.size.height / 2);
        }
        labelProps.bounds = new geom.Rect(labelPosition, labelProps.size);

        if (this.value) {
            if (this.isAnimating) {
                this.value.updateText(this.valueLabelText);
            }

            let valuePt = arcEndPoint.rotateByArcLength(20, r, center);
            let valueProps = this.value.calcProps(new geom.Size(200, 200));
            valueProps.bounds = new geom.Rect(valuePt.offset(-valueProps.size.width / 2, -valueProps.size.height / 2), valueProps.size);
        }

        if (this.shadeColors && (this.model.color == null || this.model.color == "auto")) {
            this.calcShadedColors(this.radialBar);
        }

        return { size: new geom.Size(r * 2, r * 2) };
    }

    containsPoint(pt) {
        let center = new geom.Point(this.selectionBounds.centerH, this.selectionBounds.centerV);
        let distance = center.distance(pt);
        return (distance >= this.radius - this.barWidth / 2 && distance <= this.radius + this.barWidth / 2);
    }

    get animationElementName() {
        return `Bar #${this.itemIndex + 1}`;
    }

    _getAnimations() {
        return [{
            name: "Grow in",
            easing: "easeOutQuad",
            defaultDuration: 1800,
            overlapWithSameAnimationMultiplier: 0.1,
            prepare: () => {
                this.animationState.fadeInProgress = 0;
                this.animationState.value = 0;
            },
            onBeforeAnimationFrame: progress => {
                this.animationState.fadeInProgress = Math.min(1, progress * 3);
                this.animationState.value = progress;
                return this;
            }
        }];
    }
}

class RadialBarChartItemLabel extends TextElement {
    get requireParentSelection() {
        return false;
    }
}

class RadialBarChartItemValue extends TextElement {
    get requireParentSelection() {
        return false;
    }
}

export const elements = {
    RadialBarChart
};
