import { _ } from "js/vendor";
import * as geom from "js/core/utilities/geom";
import { VerticalAlignType } from "common/constants";
import { Shape } from "js/core/utilities/shapes";

import { BaseElement } from "../base/BaseElement";
import { CollectionElement, CollectionItemElement } from "../base/CollectionElement";
import { TextElement } from "../base/TextElement";
import { TextGroup } from "../base/TextGroup";
import { SVGPolygonElement } from "../base/SVGElement";

class ProcessDiagram extends CollectionElement {
    _calcProps(props, options) {
        let { size } = props;
        return this.getLayouter(props, this.itemElements, size)
            .calcHorizontalLayout()
            .alignVertically().size;
    }
}

class ProcessDiagramBoxes extends ProcessDiagram {
    get maxItemCount() {
        return 8;
    }

    getChildItemType() {
        return ProcessDiagramBoxItem;
    }

    _calcProps(props, options) {
        let { size } = props;

        let layouter = this.getLayouter(props, this.itemElements, size);
        layouter.calcRowLayout();
        layouter.alignVertically("middle");

        return { size: layouter.size };
    }
}

class ProcessDiagramBoxItem extends CollectionItemElement {
    _build() {
        this.index = this.addElement("index", () => TextElement, {
            model: {
                index: this.itemIndex + 1
            },
            canEdit: false,
            isTabbable: false
        });
        this.text = this.addElement("text", () => TextGroup, {
            autoHeight: true,
        });
    }

    _calcProps(props, options) {
        let { size } = props;

        let layouter = this.getLayouter(props, [this.index, this.text], size);
        layouter.calcHorizontalBlockLayout({
            verticalAlign: layouter.VerticalBlockAlignType.TOP
        });

        return { size: layouter.size };
    }
}

class ProcessDiagramChevrons extends ProcessDiagram {
    getChildItemType() {
        return ProcessDiagramChevronItem;
    }

    get textLayout() {
        return this.model.textLayout || "alternate";
    }

    _calcProps(props, options) {
        let { size } = props;

        let layouter = this.getLayouter(props, this.itemElements, size);
        layouter.calcRowLayout({
            itemOptions: {
                textLayout: this.textLayout
            }
        });
        if (layouter.totalRows > 1 || (this.textLayout === "above" || this.textLayout === "below")) {
            layouter.alignVertically(VerticalAlignType.MIDDLE);
        }

        return { size: layouter.size };
    }
}

class ProcessDiagramChevronItem extends CollectionItemElement {
    get showBody() {
        // if (this.body && ds.selection.element == this.body) return true;
        if (this.showBodyPlaceholder) return true;
        if (this.options.showBody === true) return true;
        if (this.model.body && !_.isEmpty(this.model.body.text)) return true;
        return false;
    }

    get showBodyPlaceholder() {
        return this._showBodyPlaceholder == undefined ? false : this._showBodyPlaceholder;
    }

    set showBodyPlaceholder(value) {
        this._showBodyPlaceholder = value;
    }

    get selectionBounds() {
        return this.shapeContainer.bounds.inflate(this.selectionPadding).offset(this.canvasBounds.left, this.canvasBounds.top);
    }

    get selectionPadding() {
        return { left: 0, right: 0, top: 20, bottom: 20 };
    }

    _build() {
        this.shapeContainer = this.addElement("shapeContainer", () => ProcessDiagramChevronShape);
        if (this.showBody) {
            this.body = this.addElement("body", () => ProcessDiagramDescription, {
                autoHeight: true
            });
        }
    }

    _calcProps(props, options) {
        let { size } = props;

        let shapeTop = 0;
        let shapeHeight = this.styles.shapeContainer.height;
        let totalHeight = shapeHeight;

        let textLayout = options.textLayout;
        this.totalRows = options.totalRows;
        if (options.totalRows > 1) {
            textLayout = options.rowIndex === 0 ? "above" : "below";
            this.verticalRowAlign = options.rowIndex === 0 ? "bottom" : "top";
        } else {
            if (options.textLayout === "above") {
                this.verticalRowAlign = "bottom";
            } else {
                this.verticalRowAlign = "middle";
            }
        }

        switch (textLayout) {
            case "below":
                shapeTop = 0;
                break;
            case "above":
                shapeTop = 0;
                break;
            case "alternate":
            default:
                shapeTop = size.height / 2 - shapeHeight / 2;
                break;
        }

        if (this.showBody) {
            let bodyProps;
            let availableBodyWidth = size.width - this.shapeContainer.styles.shape.chevronOffset * 2;
            switch (textLayout) {
                case "above":
                    bodyProps = this.body.calcProps(new geom.Size(availableBodyWidth, size.height - shapeHeight));
                    bodyProps.bounds = new geom.Rect(size.width / 2 - bodyProps.size.width / 2, 0, bodyProps.size);
                    totalHeight += bodyProps.size.height;
                    shapeTop = bodyProps.size.height;
                    break;
                case "below":
                    bodyProps = this.body.calcProps(new geom.Size(availableBodyWidth, size.height - shapeHeight));
                    shapeTop = 0;
                    bodyProps.bounds = new geom.Rect(size.width / 2 - bodyProps.size.width / 2, shapeHeight + this.styles.body.shapeGap, bodyProps.size);
                    totalHeight += bodyProps.size.height;
                    break;
                case "alternate":
                default:
                    if (this.itemIndex % 2 == 0) {
                        this.body.styles.marginTop = 0;
                        this.body.styles.marginBottom = this.body.styles.shapeGap;
                    } else {
                        this.body.styles.marginBottom = 0;
                        this.body.styles.marginTop = this.body.styles.shapeGap;
                    }
                    bodyProps = this.body.calcProps(new geom.Size(availableBodyWidth, (size.height - shapeHeight) / 2));

                    shapeTop = size.height / 2 - shapeHeight / 2;
                    if (this.itemIndex % 2 == 0) {
                        bodyProps.bounds = new geom.Rect(size.width / 2 - bodyProps.size.width / 2, shapeTop - bodyProps.size.height, bodyProps.size);
                    } else {
                        bodyProps.bounds = new geom.Rect(size.width / 2 - bodyProps.size.width / 2, shapeTop + shapeHeight, bodyProps.size);
                    }
                    totalHeight += bodyProps.size.height * 2; // * 2 to account for above/below description
                    break;
            }
        }

        if (textLayout == "above" && this.parentElement.itemElements.filter(item => item.showBody).length) {
            shapeTop += this.styles.body.shapeGap;
            totalHeight += this.styles.body.shapeGap;
        }

        let shapeProps = this.shapeContainer.calcProps(new geom.Size(size.width, shapeHeight));
        shapeProps.bounds = new geom.Rect(0, shapeTop, shapeProps.size);

        this.contentBounds = this.shapeContainer.bounds;
        if (this.showBody) {
            this.contentBounds = this.contentBounds.union(this.body.bounds);
        }

        return { size: new geom.Size(size.width, totalHeight) };
    }
}

class ProcessDiagramDescription extends TextElement {
    get requireParentSelection() {
        return false;
    }
}

class ProcessDiagramChevronShape extends BaseElement {
    get canRollover() {
        return false;
    }

    _build() {
        this.shape = this.addElement("shape", () => SVGPolygonElement);
        this.title = this.addElement("title", () => TextElement, {
            autoHeight: true
        });
    }

    _loadStyles(styles) {
        if (this.parentElement.itemIndex == 0) {
            styles.paddingLeft = 20;
        }
    }

    _calcProps(props, options) {
        let { size } = props;

        let shapeBounds = new geom.Rect(0, 0, size.width, size.height).inflate(this.styles.padding).zeroOffset();
        let shapeProps = this.shape.calcProps(size);
        shapeProps.path = Shape.drawChevron(shapeBounds, this.styles.shape.chevronOffset, this.parentElement.itemIndex === 0).toPolygonData();

        let titleProps = this.title.calcProps(size);
        titleProps.bounds = new geom.Rect(0, size.height / 2 - titleProps.size.height / 2, titleProps.size);

        return { size };
    }

    getBackgroundColor(forElement) {
        if (forElement === this.title) {
            return this.getShapeFillColor(this.shape);
        } else {
            return super.getBackgroundColor(forElement);
        }
    }
}

export const elements = {
    ProcessDiagramBoxes,
    ProcessDiagramChevrons,
};
