import styled from "styled-components";

import { ds } from "js/core/models/dataService";
import { $, _, SVG } from "js/vendor";
import { app } from "js/namespaces";
import { controls } from "js/editor/ui";
import * as geom from "js/core/utilities/geom";
import { Backbone } from "js/vendor";
import { Key } from "js/core/utilities/keys";
import { AssetType, CanvasEventType, FormatType } from "common/constants";
import { formatter } from "js/core/utilities/formatter";
import { parseCSV } from "js/core/utilities/utilities";
import { renderReactDialog } from "js/react/renderReactRoot";
import React, { Component } from "react";
import { PopupMenu } from "js/react/views/Editor/PopupMenu";
import { ColorPalette } from "js/react/views/Editor/Components/ColorPalette";
import { FlexBox } from "js/react/components/LayoutGrid";
import { getStaticUrl } from "js/config";
import { Gap20 } from "js/react/components/Gap";
import { themeColors } from "js/react/sharedStyles";
import { ShowWarningDialog, ShowDialogAsync } from "js/react/components/Dialogs/BaseDialog";
import BadFitDialog from "js/react/components/Dialogs/BadFitDialog";
import DataLockedDialog from "js/react/components/Dialogs/DataLockedDialog";
import { FormatOptionsMenu } from "../FormatOptionsMenu";
import { ElementUI, ElementSelection } from "../BaseElementEditor";
import { clipboardWrite, ClipboardType, clipboardRead } from "js/core/utilities/clipboard";

const TableFrameSelection = ElementSelection.extend({
    showSelectionBox: false,

    showDataSourceMenu: function() {
        return true;
    },

    getOffset: function() {
        if (this.element.isRootElement()) {
            return "canvas";
        } else {
            return 53;
        }
    },

    renderControls: function() {
        this.addControl({
            type: controls.POPUP_BUTTON,
            label: "AutoFit",
            items: [{
                value: "columns", label: "Columns", icon: "view_week"
            }, {
                value: "rows", label: "Rows", icon: "table_rows"
            }],
            callback: value => {
                (async () => {
                    if (this.element.table.overlay?.editingCell) {
                        await this.element.table.overlay.stopEditingCell();
                    }

                    this.element.table.overlay && this.element.table.overlay.clearSelection();

                    await this.element.table.calcAutoFit(value);

                    if (this.element.isOnAuthoringCanvas) {
                        // Adjusting the container
                        const authoringElementContainer = this.element.parentElement;
                        if (value == "columns") {
                            authoringElementContainer.model.width *= this.element.model.tableWidth;
                        } else {
                            authoringElementContainer.model.height *= this.element.model.tableHeight;
                        }
                        authoringElementContainer.canvas.updateCanvasModel(false);
                    }
                })();
            }
        });

        this.addControl({
            type: controls.POPUP_BUTTON,
            label: "Styles",
            menuContents: closeMenu => {
                let $menu = $.div("menu-container");

                let $icons = $menu.addEl($.div("icon-toggle-menu"));

                $icons.append(controls.createIconToggle(this, {
                    icon: "/images/ui/tables/table_border.svg",
                    label: "Border",
                    model: this.element.model,
                    property: "showBorder"
                }));

                $icons.append(controls.createIconToggle(this, {
                    label: "Column Lines",
                    icon: "/images/ui/tables/table_column.svg",
                    model: this.element.model,
                    property: "showColGridLines"
                }));

                $icons.append(controls.createIconToggle(this, {
                    label: "Row Lines",
                    icon: "/images/ui/tables/table_row.svg",
                    model: this.element.model,
                    property: "showRowGridLines"
                }));

                $icons.append(controls.createIconToggle(this, {
                    label: "Top Left Cell",
                    icon: "/images/ui/tables/table_a1cell.svg",
                    model: this.element.model,
                    property: "showTopLeftCell"
                }));

                $icons.append(controls.createIconToggle(this, {
                    icon: "/images/ui/tables/table_headerrow.svg",
                    label: "Header Row",
                    model: this.element.model,
                    selected: this.element.table.getRowStyle(0) == "headerRow",
                    callback: val => {
                        if (val) {
                            this.element.table.getRow(0).style = "headerRow";
                        } else {
                            this.element.table.getRow(0).style = "defaultRow";
                        }
                        this.element.canvas.updateCanvasModel(false);
                    }
                }));

                $icons.append(controls.createIconToggle(this, {
                    icon: "/images/ui/tables/table_headercol.svg",
                    label: "Header Col",
                    selected: this.element.table.getColumnStyle(0) == "headerCol",
                    callback: val => {
                        if (val) {
                            this.element.table.getColumn(0).style = "headerCol";
                        } else {
                            this.element.table.getColumn(0).style = "defaultCol";
                        }
                        this.element.canvas.updateCanvasModel(false);
                    }
                }));

                $icons.append(controls.createIconToggle(this, {
                    icon: "/images/ui/tables/table_banded.svg",
                    label: "Banded Rows",
                    model: this.element.model,
                    property: "alternateRows"
                }));

                $menu.append($.hr());

                $menu.append(controls.createColorPalettePicker(this, {
                    label: "Table Background",
                    model: this.element.model,
                    property: "tableBackgroundColor",
                    showBackgroundColors: true
                }));

                return $menu;
            }
        });
    },

});

const TableSelection = ElementSelection.extend({
    showSelectionBox: false,

    selectedCols: [],
    selectedRows: [],
    selectedCells: [],

    // --------------------------------------------------------------------------------------------
    // region INITIALIZATION
    // --------------------------------------------------------------------------------------------
    setup: function() {
        this.$el.addClass("table_editor");

        let getDataForTextClipboard = () => {
            let data = "";
            for (let row = _.minBy(this.selectedCells, cell => cell.row).row; row <= _.maxBy(this.selectedCells, cell => cell.row).row; row++) {
                let rowData = "";
                for (let col = _.minBy(this.selectedCells, cell => cell.col).col; col <= _.maxBy(this.selectedCells, cell => cell.col).col; col++) {
                    let cell = _.find(this.selectedCells, { col, row });
                    if (cell) {
                        rowData += `"${cell.model.cellText ? cell.model.cellText.text : ""}",`;
                    } else {
                        rowData += `"",`;
                    }
                }
                data += rowData + "\n";
            }
            return data;
        };

        $(document).on("copy.table", event => {
            if (this.editingCell) return;
            if (this.selectedCells.length) {
                clipboardWrite({
                    [ClipboardType.CELLS]: this.selectedCells.map(cell => cell.model),
                    [ClipboardType.TEXT]: getDataForTextClipboard(),
                });
                event.preventDefault();
            }
        });
        $(document).on("cut.table", event => {
            if (this.editingCell) return;
            if (this.selectedCells.length) {
                clipboardWrite({
                    [ClipboardType.CELLS]: this.selectedCells.map(cell => cell.model),
                    [ClipboardType.TEXT]: getDataForTextClipboard(),
                });
                event.preventDefault();

                for (let cell of this.selectedCells) {
                    if (cell.model.cellText) {
                        cell.model.cellText.text = "";
                    }
                    if (cell.model.format == "icon") {
                        cell.model.cellText = { text: "" };
                        cell.model.content_type = null;
                        cell.model.content_value = null;
                        cell.model.format = "text";
                    }
                }
                this.element.canvas.updateCanvasModel(false);
            }
        });
        $(document).on("paste.table", event => {
            if (this.editingCell) return;
            event.preventDefault();
            this.handlePasteTable(event.originalEvent);
        });

        $(document).on("click.table", event => {
            let $cellWidget = $(event.currentTarget.elementFromPoint(event.clientX, event.clientY));
            if ($cellWidget.hasClass("table_cell_widget")) {
                if (this.editingCell == $cellWidget.data("cell")) {
                    return;
                }
                this.clearSelection();
                this.selectCell($cellWidget.data("cell"), true, false);
            }
        });

        $(document).on("keydown.table", event => {
            if (this.editingCell) return;

            if (event.which == 16 || event.which == 17 || event.which == 91) return;

            switch (event.which) {
                case Key.KEY_V:
                    if (event.metaKey || event.ctrlKey) {
                        return;
                    }
                    break;
                case Key.KEY_Z:
                    if (event.metaKey || event.ctrlKey) {
                        return; // let undo command-z pass through
                    }

                case Key.KEY_A:
                    if (event.metaKey || event.ctrlKey) {
                        this.selectedCells = this.element.cells.slice(0);
                        this.renderSelectedCells();
                    }
                    break;

                case Key.KEY_B:
                    if (event.metaKey || event.ctrlKey) {
                        let bold = !this.selectedCells[0].model.bold;
                        for (let cell of this.selectedCells) {
                            cell.model.bold = bold;
                        }
                        this.element.refreshElement();
                        this.element.canvas.saveCanvasModel();
                    }
                    break;
                case Key.KEY_I:
                    if (event.metaKey || event.ctrlKey) {
                        let italic = !this.selectedCells[0].model.italic;
                        for (let cell of this.selectedCells) {
                            cell.model.italic = italic;
                        }
                        this.element.refreshElement();
                        this.element.canvas.saveCanvasModel();
                    }
                    break;

                case Key.LEFT_META:
                case Key.RIGHT_META:
                case Key.SHIFT:
                case Key.CTRL:
                    return;

                case Key.LEFT_ARROW:
                    this.moveCellSelection("left", event.shiftKey);
                    break;
                case Key.RIGHT_ARROW:
                    this.moveCellSelection("right", event.shiftKey);
                    break;
                case Key.TAB:
                    event.preventDefault();
                    if (event.shiftKey) {
                        this.moveCellSelection("left", false);
                    } else {
                        this.moveCellSelection("right", false);
                    }
                    break;
                case Key.UP_ARROW:
                    this.moveCellSelection("up", event.shiftKey);
                    break;
                case Key.DOWN_ARROW:
                    this.moveCellSelection("down", event.shiftKey);
                    break;

                case Key.DELETE:
                case Key.BACKSPACE:
                    if (!this.editingCell) {
                        if (this.selectedCols.length) {
                            for (let col of this.selectedCols) {
                                this.deleteColumn(col);
                            }
                            this.clearSelection();
                        } else if (this.selectedRows.length) {
                            for (let row of this.selectedRows) {
                                this.deleteRow(row);
                            }
                            this.clearSelection();
                        } else if (this.selectedCells.length) {
                            for (let cell of this.selectedCells) {
                                if (cell.model.cellText) {
                                    cell.model.cellText.text = "";
                                }
                                if (cell.model.format == "icon") {
                                    cell.model.content_type = null;
                                    cell.model.content_value = null;
                                    cell.model.format = "text";
                                }
                            }
                        }
                        this.element.canvas.updateCanvasModel(false).then(() => {
                            this.refresh();
                        });
                    }
                    break;
            }

            event.stopPropagation();
        });

        $(document).on("keypress.table", event => {
            if (event.metaKey || event.altKey) {
                return;
            }
            if (!this.editingCell && this.focusedCell) {
                let initialValue;
                if (event.which == 13) {
                    initialValue == "";
                } else {
                    initialValue = String.fromCharCode(event.which);
                }
                this.startEditingCell(this.focusedCell, initialValue);
            }
        });
    },

    getTableSelectionLayerBounds: function() {
        return this.canvasToSelectionCoordinates(this.element.calculatedLayout.tableBounds.offset(this.element.offsetBounds.position));
    },

    renderControls: function() {
        this.$el.find(".table_cell_selection_container").remove();
        let $selectionContainer = this.$el.addEl($.div("table_cell_selection_container"));
        this.cellSelectionSVG = SVG($selectionContainer[0]);

        this.renderRowOrColWidgets("col");
        this.renderRowOrColWidgets("row");

        this.renderCellWidgets();

        this.renderSelectedCells();
        this.renderSelectedCols();
        this.renderSelectedRows();

        this.$el.off(".table");

        this.$el.on("click.table", event => {
            event.stopPropagation();
        });

        if (!this.element.hasDataSourceLink()) {
            // Add column button
            this.$addColWidget = this.$el.addEl($.div("ui_widget add_col_button"));
            this.$addColWidget.append($.div("add_item_button button"));
            this.$addColWidget.on("click", event => {
                this.insertColumn(this.element.model.cols.length);

                this.element.canvas.updateCanvasModel(false).then(() => {
                    this.refresh();
                }).catch(err => {
                    ShowDialogAsync(BadFitDialog, {
                        title: "Sorry, we aren't able to fit any more columns in your table",
                    });
                });
            });

            // Add row button
            this.$addRowWidget = this.$el.addEl($.div("ui_widget add_row_button"));
            this.$addRowWidget.append($.div("add_item_button button"));
            this.$addRowWidget.on("click", event => {
                this.insertRow(this.element.model.rows.length);
                this.element.canvas.updateCanvasModel(false).then(() => {
                    this.refresh();
                }).catch(err => {
                    ShowDialogAsync(BadFitDialog, {
                        title: "Sorry, we aren't able to fit any more rows in your table",
                    });
                });
            });
        }

        if (!this.element.parentElement.isOnAuthoringCanvas) {
            // add table resizer
            this.$tableWidthResizer = this.$el.addEl($.div("ui_widget table_width_resizer"));
            this.$tableWidthResizer.append($.div("tray_resizer"));

            this.$tableWidthResizer.on("mousedown", event => {
                $(".col_widget, .row_widget, .add_col_button, .add_row_button, .table_height_resizer").hide();

                this.clearSelection();
                this.element.isResizingTable = true;
                this.element.canvas.trigger(CanvasEventType.REFRESH);

                $(document).on("mousemove.resizer", event => {
                    let value = ((event.pageX - this.element.screenBounds.centerH - 20) * 2) / this.element.canvas.getScale() / this.element.parentElement.calculatedProps.bounds.width;
                    value = Math.clamp(value, 0.1, 1);
                    this.element.model.tableWidth = value;
                    this.element.refreshElement();
                });

                $(document).on("mouseup.resizer", event => {
                    $(document).off(".resizer");
                    this.element.isResizingTable = false;
                    this.element.canvas.updateCanvasModel(false).then(() => {
                        $(".col_widget, .row_widget, .add_col_button, .add_row_button, .table_height_resizer").show();
                        this._layout();
                    });
                });
            });

            this.$tableHeightResizer = this.$el.addEl($.div("ui_widget table_height_resizer"));
            this.$tableHeightResizer.append($.div("tray_resizer"));

            let tableHeight;
            let resizableRows;

            this.$tableHeightResizer.on("mousedown", event => {
                $(".col_widget, .row_widget, .add_col_button, .add_row_button, .table_width_resizer").hide();

                tableHeight = _.sumBy(this.element.model.rows, "size") + _.filter(this.element.model.rows, "break").length * this.element.styles.rowBreak;

                resizableRows = _.filter(this.element.model.rows, row => {
                    return row.style != "headerRow" && row.style != "cleanRow";
                });

                this.clearSelection();
                this.element.isResizingTable = true;
                this.element.canvas.trigger(CanvasEventType.REFRESH);

                $(document).on("mousemove.resizer", event => {
                    let value = ((event.pageY - this.element.screenBounds.centerV - 20) * 2) / this.element.canvas.getScale() / this.element.parentElement.calculatedProps.bounds.height;
                    value = Math.clamp(value, 0.1, 1);
                    this.element.model.tableHeight = value;
                    this.element.refreshElement();
                });

                $(document).on("mouseup.resizer", event => {
                    $(document).off(".resizer");
                    this.element.isResizingTable = false;
                    this.element.canvas.updateCanvasModel(false).then(() => {
                        $(".col_widget, .row_widget, .add_col_button, .add_row_button, .table_width_resizer").show();
                        this._layout();
                    });
                });
            });
        }
    },

    _layout: function() {
        this.layoutColAndRowWidgets();
        this.renderSelectedCells();

        let tableBounds = this.element.screenBounds;

        this.$addRowWidget && this.$addRowWidget.setBounds(new geom.Rect(-40, tableBounds.height + 10, 30, 30));
        this.$addColWidget && this.$addColWidget.setBounds(new geom.Rect(tableBounds.width + 10, -36, 30, 30));

        this.$tableHeightResizer && this.$tableHeightResizer.setBounds(new geom.Rect(tableBounds.width / 2 - 15, tableBounds.height + 10, 30, 30));
        this.$tableWidthResizer && this.$tableWidthResizer.setBounds(new geom.Rect(tableBounds.width + 10, tableBounds.height / 2 - 15, 30, 30));
    },

    refresh() {
        const selectedElement = ds.selection.element;
        ds.selection.element = null;
        this.$el.empty();
        this.render();
        this.layout();
        ds.selection.element = selectedElement;
    },

    clearSelection() {
        this.clearSelectedCells();
        this.selectedCells = [];
        this.selectedCols = [];
        this.selectedRows = [];
        this.renderSelectedCols();
        this.renderSelectedRows();
    },

    // --------------------------------------------------------------------------------------------
    // endregion
    // --------------------------------------------------------------------------------------------

    // --------------------------------------------------------------------------------------------
    // region TABLE CELLS
    // --------------------------------------------------------------------------------------------

    selectCell: async function(cell, toggleSelection, addRangeToSelection) {
        if (!cell || cell.model.isHidden) return;

        if (cell == this.editingCell) return;

        await this.stopEditingCell();
        this.clearSelectedCols();
        this.clearSelectedRows();

        if (toggleSelection) {
            if (this.selectedCells.contains(cell)) {
                this.selectedCells.remove(cell);
            } else {
                this.selectedCells.push(cell);
            }
        } else if (addRangeToSelection && this.selectedCells.length) {
            if (!this.startCell) {
                this.startCell == cell;
            }

            this.endCell = cell;

            let startCol = this.startCell.col;
            let startRow = this.startCell.row;
            let endCol = this.endCell.col;
            let endRow = this.endCell.row;

            this.selectedCells = [];
            for (let col = Math.min(startCol, endCol); col <= Math.max(startCol, endCol); col++) {
                for (let row = Math.min(startRow, endRow); row <= Math.max(startRow, endRow); row++) {
                    this.selectedCells.push(this.element.getCell(col, row));
                }
            }
        } else {
            this.selectedCells = [cell];
            this.focusedCell = cell;
            this.startCell = cell;
            this.endCell = cell;
        }

        this.renderSelectedCells();
    },

    unselectCell: function(cell) {
        if (this.selectedCells.contains(cell)) {
            this.selectedCells.remove(cell);
        }
        if (cell == this.focusedCell) {
            this.focusedCell = null;
        }
        this.refresh();
    },

    clearSelectedCells: function() {
        this.selectedCells = [];
        this.focusedCell = null;
        this.cellSelectionSVG.clear();
        this.cellControlBar && this.cellControlBar.remove();
    },

    moveCellSelection: function(direction, addToSelection) {
        if (!this.endCell) return;

        let row, col;
        switch (direction) {
            case "left":
                if (addToSelection && this.endCell.col == 0) return;

                col = this.endCell.col - 1;
                row = this.endCell.row;

                if (col < 0) {
                    col = this.element.model.cols.length - 1;
                    row--;
                    if (row < 0) {
                        row = this.element.model.rows.length - 1;
                    }
                }
                this.selectCell(this.element.getCell(col, row), false, addToSelection);
                break;
            case "right":
                event.preventDefault();

                if (addToSelection && this.endCell.col == this.element.model.cols.length - 1) return;

                col = this.endCell.col + 1;
                row = this.endCell.row;
                if (col > this.element.model.cols.length - 1) {
                    col = 0;
                    row++;
                    if (row > this.element.model.rows.length - 1) {
                        row = 0;
                    }
                }
                this.selectCell(this.element.getCell(col, row), false, addToSelection);
                break;
            case "up":
                if (addToSelection && this.endCell.row == 0) return;

                col = this.endCell.col;
                row = this.endCell.row - 1;
                if (row < 0) {
                    row = this.element.model.rows.length - 1;
                    col--;
                    if (col < 0) {
                        col = this.element.model.cols.length - 1;
                    }
                }
                this.selectCell(this.element.getCell(col, row), false, addToSelection);
                break;
            case "down":
                if (addToSelection && this.endCell.row == this.element.model.rows.length - 1) return;

                col = this.endCell.col;
                row = this.endCell.row + 1;
                if (row > this.element.model.rows.length - 1) {
                    row = 0;
                    col++;
                    if (col > this.element.model.cols.length - 1) {
                        col = 0;
                    }
                }
                this.selectCell(this.element.getCell(col, row), false, addToSelection);
                break;
        }
    },

    getCellAtPoint: function(x, y) {
        return this.element.cells.find(cell => cell.bounds.multiply(app.currentCanvas.getScale()).contains(x, y));
    },

    renderCellWidgets: function() {
        let $cellSelection = this.$el.addEl($.div("cellSelection"));
        $cellSelection.css({
            position: "absolute",
            pointerEvents: "auto",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
        });

        $cellSelection.on("mousedown", event => {
            if (this.mouseDownOnColOrRow) return;

            let cell = this.getCellAtPoint(event.offsetX, event.offsetY);
            if (!cell) return;
            if (cell.model.isHidden) return;

            if (this.focusedCell && this.focusedCell == cell) {
                if (event.buttons == 1) {
                    // just select the focused cell
                    if (this.selectedCells.length > 1) {
                        this.selectCell(this.focusedCell, false, false);
                    }
                    // start editing the cell
                    if (cell.model.format != "icon") {
                        this.startEditingCell(cell);
                        return;
                    }
                }
            } else {
                event.stopPropagation();
                this.selectCell(cell, event.ctrlKey || event.metaKey, event.shiftKey);
            }

            $cellSelection.on("mousemove.cellDragSelection", event => {
                if (event.metaKey || event.shiftKey) return;
                let cell = this.getCellAtPoint(event.offsetX, event.offsetY);
                if (!cell) return;
                if (cell.model.isHidden) return;
                if (cell.model.width > 1 || cell.model.height > 1) return;

                this.selectCell(cell, false, true);
            });

            $(document).on("mouseup.cellDragSelection", event => {
                $cellSelection.off(".cellDragSelection");
            });
        });
    },

    startEditingCell: function(cell, chr) {
        if (this.element.hasDataSourceLink()) {
            ShowDialogAsync(DataLockedDialog, { element: this.element.parentElement, elementEditor: this });
            return;
        }

        if (this.editingCell == cell && this.cellEditor) {
            // return focus to cell input
            _.defer(() => {
                this.cellEditor.setFocus();
            });
            return;
        }

        this.selectCell(cell);

        let createEditor = () => {
            this.cellEditor = new TableCellEditor({
                tableEditor: this,
                cell: cell,
                initialValue: chr
            });
            this.$el.append(this.cellEditor.render().$el);

            let cellBounds = cell.bounds.multiply(this.canvasScale);
            this.cellEditor.position(cellBounds);

            this.editingCell = cell;

            this.cellControlBar && this.cellControlBar.remove();
        };

        if (this.canvas.layouter.isGenerating) {
            _.defer(() => createEditor);
        } else {
            createEditor();
        }
    },

    stopEditingCell: async function() {
        if (this.cellEditor) {
            await this.cellEditor.commit();
            this.cellEditor = null;
        }
        this.editingCell = null;
    },

    renderSelectedCells: function() {
        this.cellSelectionSVG.clear();

        if (this.selectedCells.length == 0) return;

        let selectionBounds = this.selectedCells[0].bounds.multiply(this.canvasScale);

        for (let cell of this.selectedCells) {
            if (cell.model.isHidden) continue;

            let col = cell.col;
            let row = cell.row;

            let cellBounds = cell.bounds.multiply(this.canvasScale);

            if (!this.selectedCells.contains(this.element.getCell(col - 1, row))) {
                this.cellSelectionSVG.line(cellBounds.left + 5, cellBounds.top + 5, cellBounds.left + 5, cellBounds.bottom + 5);
            }
            if (!this.selectedCells.contains(this.element.getCell(col + 1, row))) {
                this.cellSelectionSVG.line(cellBounds.right + 5, cellBounds.top + 5, cellBounds.right + 5, cellBounds.bottom + 5);
            } {
                if (!this.selectedCells.contains(this.element.getCell(col, row - 1))) this.cellSelectionSVG.line(cellBounds.left + 5, cellBounds.top + 5, cellBounds.right + 5, cellBounds.top + 5);
            }
            if (!this.selectedCells.contains(this.element.getCell(col, row + 1))) {
                this.cellSelectionSVG.line(cellBounds.left + 5, cellBounds.bottom + 5, cellBounds.right + 5, cellBounds.bottom + 5);
            }

            selectionBounds = selectionBounds.union(cellBounds);
        }

        let cellSelectionElement = {
            get selectionBounds() {
                return selectionBounds.multiply(1 / app.canvasScale);
            },
            isRootElement: () => false,
            canvas: this.element.canvas,
            cells: this.selectedCells
        };

        $(".table-cell-control-bar").remove();

        this.cellControlBar = new TableCellSelection({
            element: cellSelectionElement,
            editor: this,
        });

        this.$el.addEl(this.cellControlBar.render().$el);

        this.cellControlBar.layout();
    },

    // --------------------------------------------------------------------------------------------
    // endregion
    // --------------------------------------------------------------------------------------------

    selectCol: function(colIndex, toggleSelection, addRange) {
        let colModel = _.find(this.element.model.cols, { index: colIndex });

        this.stopEditingCell();
        if (this.selectedRows.length) {
            this.clearSelectedRows();
        }
        if (toggleSelection) {
            if (this.selectedCols.contains(colModel)) {
                this.selectedCols.remove(colModel);
            } else {
                this.selectedCols.push(colModel);
                this.focusedCol = colModel;
            }
        } else if (addRange && this.focusedCol) {
            this.selectedCols = [];
            for (let colIndex = Math.min(colModel.index, this.focusedCol.index); colIndex <= Math.max(colModel.index, this.focusedCol.index); colIndex++) {
                let selectedCol = _.find(this.element.model.cols, { index: colIndex });
                if (selectedCol && !this.selectedCols.contains(selectedCol)) {
                    this.selectedCols.push(selectedCol);
                }
            }
        } else {
            this.clearSelectedCells();
            this.selectedCols = [colModel];
            this.focusedCol = colModel;
        }

        for (let selectedCol of this.selectedCols) {
            for (let cell of this.element.cells.filter(cell => cell.col == selectedCol.index)) {
                this.selectedCells.push(cell);
            }
        }

        this.renderSelectedCells();
        this.renderSelectedCols();
    },

    selectRow: function(rowIndex, toggleSelection, addRange) {
        let rowModel = _.find(this.element.model.rows, { index: rowIndex });

        this.stopEditingCell();
        if (this.selectedCols.length) {
            this.clearSelectedCols();
        }
        if (toggleSelection) {
            if (this.selectedRows.contains(rowModel)) {
                this.selectedRows.remove(rowModel);
            } else {
                this.selectedRows.push(rowModel);
                this.focusedRow = rowModel;
            }
        } else if (addRange && this.focusedRow) {
            this.selectedRows = [];
            for (let rowIndex = Math.min(rowModel.index, this.focusedRow.index); rowIndex <= Math.max(rowModel.index, this.focusedRow.index); rowIndex++) {
                let selectedRow = _.find(this.element.model.rows, { index: rowIndex });
                if (selectedRow && !this.selectedRows.contains(selectedRow)) {
                    this.selectedRows.push(selectedRow);
                }
            }
        } else {
            this.clearSelectedCells();
            this.selectedRows = [rowModel];
            this.focusedRow = rowModel;
        }

        for (let selectedRow of this.selectedRows) {
            for (let cell of this.element.cells.filter(cell => cell.row == selectedRow.index)) {
                this.selectedCells.push(cell);
            }
        }

        this.renderSelectedCells();
        this.renderSelectedRows();
    },

    clearSelectedCols: function() {
        this.selectedCols = [];
        this.renderSelectedCols();
    },

    clearSelectedRows: function() {
        this.selectedRows = [];
        this.renderSelectedRows();
    },

    getColSelectionBounds: function(col) {
        return this.element.calculatedProps.colBounds[col].multiply(this.element.canvas.getScale());
    },

    getRowSelectionBounds: function(row) {
        return this.element.calculatedProps.rowBounds[row].multiply(this.element.canvas.getScale());
    },

    renderRowOrColWidgets: function(type) {
        this.$el.find(`.${type}_widget`).remove();

        let models, select, selectedItems;
        if (type == "col") {
            models = this.element.model.cols;
            select = _.bind(this.selectCol, this);
            selectedItems = () => this.selectedCols;
        } else {
            models = this.element.model.rows;
            select = _.bind(this.selectRow, this);
            selectedItems = () => this.selectedRows;
        }

        for (let model of models) {
            let index = model.index;

            let $widget = $.div("widget").attr(`data-${type}`, index).addClass(`${type}_widget control`);
            this.$el.prepend($widget); // reverse order so that resizer isn't overlapped by next widget

            let bounds;
            let size;
            if (type == "col") {
                if (index > 0) {
                    $widget.css({ borderLeft: "none" });
                }
                let colBounds = this.getColSelectionBounds(index);
                bounds = new geom.Rect(colBounds.left, colBounds.top - 40, colBounds.width + 1, 30);
                size = colBounds.width;
            } else {
                if (index > 0) {
                    $widget.css({ borderTop: "none" });
                }
                let rowBounds = this.getRowSelectionBounds(index);
                bounds = new geom.Rect(rowBounds.left - 50, rowBounds.top, 40, rowBounds.height + 1);
                size = rowBounds.height;
            }

            $widget.setBounds(bounds);

            $widget.on("mousedown", event => {
                event.stopPropagation();
                // select row on mousedown normally, unless it's currently selected and then require a mouseup. this is so dragging rows doesn't deselect
                if (selectedItems().contains(model)) return;
                if (this.isResizing) return;

                // select the column
                select(index, event.metaKey, event.shiftKey);

                this.mouseDownOnColOrRow = true;

                $(document).on("mouseup.widget", event => {
                    $(document).off(".widget");
                    this.mouseDownOnColOrRow = false;
                });
            });

            // $widget.on("mouseup", event => {
            //     this.mouseDownOnColOrRow = false;
            // });

            $widget.on("mouseover", event => {
                // if this is a click and drag, add the moused over column to the selection
                if (this.mouseDownOnColOrRow && event.buttons == 1 && !this.isDraggingColOrRow && !this.isResizing) {
                    select(index, false, true);
                }
            });

            let isLastColOrRow;
            if (type == "col") {
                $widget.addEl($.label(String.fromCharCode(65 + index)));
                isLastColOrRow = index == this.element.totalCols - 1;
            } else {
                $widget.addEl($.label(index + 1));
                isLastColOrRow = index == this.element.totalRows - 1;
            }

            let setResizer = ($resizer, reverse) => {
                $resizer.on("mousedown", event => {
                    event.stopPropagation();  // prevent the colWidget from getting a mousedown event and selecting a column

                    if (this.isDraggingColOrRow) {
                        event.preventDefault();
                        return;
                    }

                    ds.selection.element = this.element; // blur any editing cell
                    this.stopEditingCell();
                    this.clearSelectedCells();
                    this.clearSelectedCols();
                    this.clearSelectedRows();

                    this.isResizing = true;

                    this.element.isResizingTable = true;
                    this.element.canvas.trigger(CanvasEventType.REFRESH);

                    let otherItem;
                    let itemSelectionBounds;
                    let otherItemSelectionBounds;
                    let constrainRange;

                    if (type == "col") {
                        itemSelectionBounds = this.getColSelectionBounds(model.index);
                        otherItem = this.element.getColumn(model.index + 1);
                        otherItemSelectionBounds = this.getColSelectionBounds(otherItem.index);
                        constrainRange = [itemSelectionBounds.left + this.element.MIN_COL_WIDTH * this.element.canvas.getScale(), otherItemSelectionBounds.right - this.element.MIN_COL_WIDTH * this.element.canvas.getScale()];
                    } else {
                        itemSelectionBounds = this.getRowSelectionBounds(model.index);
                        otherItem = this.element.getRow(model.index + 1);
                        otherItemSelectionBounds = this.getRowSelectionBounds(otherItem.index);

                        constrainRange = [itemSelectionBounds.top + this.element.MIN_ROW_HEIGHT * this.element.canvas.getScale(), otherItemSelectionBounds.bottom - this.element.MIN_ROW_HEIGHT * this.element.canvas.getScale()];
                    }

                    $(document).on("mousemove.resize", event => {
                        let mousePosition = new geom.Point(event.pageX - this.$el.offset().left, event.pageY - this.$el.offset().top);

                        if (type == "col") {
                            mousePosition.x = Math.clamp(mousePosition.x, constrainRange[0], constrainRange[1]);

                            let leftSize = (mousePosition.x - itemSelectionBounds.left) / this.element.canvas.getScale();
                            model.size = leftSize / this.element.calculatedProps.baseColWidth;

                            if (otherItem) {
                                let rightSize = (otherItemSelectionBounds.right - mousePosition.x) / this.element.canvas.getScale();
                                otherItem.size = rightSize / this.element.calculatedProps.baseColWidth;
                            }
                        } else {
                            mousePosition.y = Math.clamp(mousePosition.y, constrainRange[0], constrainRange[1]);

                            let topSize = (mousePosition.y - itemSelectionBounds.top) / this.element.canvas.getScale();
                            model.size = topSize / this.element.calculatedProps.baseRowHeight;

                            let bottomSize = (otherItemSelectionBounds.bottom - mousePosition.y) / this.element.canvas.getScale();
                            otherItem.size = bottomSize / this.element.calculatedProps.baseRowHeight;
                        }

                        this.element.refreshElement(false, false);
                    });
                    $(document).on("mouseup.resize", event => {
                        $(document).off(".resize");

                        this.isResizing = false;
                        this.element.isResizingTable = false;
                        _.defer(() => {
                            this.element.canvas.saveCanvasModel();
                        });
                    });
                });
            };

            if (!isLastColOrRow) {
                setResizer($widget.addEl($.div("resizer")), false);
            }
        }
    },

    layoutColAndRowWidgets: function() {
        for (let colModel of this.element.model.cols) {
            let $colWidget = this.$el.find(`[data-col=${colModel.index}]`);
            let colBounds = this.getColSelectionBounds(colModel.index);
            $colWidget.setBounds(new geom.Rect(colBounds.left, colBounds.top - 40, colBounds.width + 1, 30));
        }

        for (let rowModel of this.element.model.rows) {
            let $rowWidget = this.$el.find(`[data-row=${rowModel.index}]`);
            let rowBounds = this.getRowSelectionBounds(rowModel.index);
            $rowWidget.setBounds(new geom.Rect(rowBounds.left - 40, rowBounds.top, 30, rowBounds.height + 1));
        }

        $(".resizer").removeAttr("style"); // resets any resizers which have their position set from draggable
    },

    renderSelectedCols: function() {
        $(".col_widget").removeClass("selected");
        for (let selectedCol of this.selectedCols) {
            $(`.col_widget[data-col=${selectedCol.index}]`).addClass("selected");
        }

        $(".col_widget").off(".selectedCol");
        $(document).off(".selectedCol");

        this.$colWidget && this.$colWidget.remove();

        // we only support drag for a contiguous selection of columns
        if (this.selectedCols.length && _.minBy(this.selectedCols, col => col.index).index + this.selectedCols.length - 1 == _.maxBy(this.selectedCols, col => col.index).index) {
            let tableBounds = this.element.bounds.multiply(app.canvasScale);

            let colBounds = this.getColSelectionBounds(_.minBy(this.selectedCols, col => col.index).index);
            let left = colBounds.left;
            let width = _.sumBy(this.selectedCols, col => {
                return this.getColSelectionBounds(col.index).width;
            });

            for (let selectedCol of this.selectedCols) {
                let $colWidget = $(`.col_widget[data-col=${selectedCol.index}]`);

                $colWidget.on("mousedown.selectedCol", event => {
                    // start column dragging
                    event.stopPropagation();

                    this.isDraggingColOrRow = true;

                    // create the dragHelper
                    let $dragHelper = this.$el.addEl($.div("table_drag_helper"));
                    $dragHelper.top(0).left(left).width(width).height(tableBounds.height);

                    // get the drag offset for the mouse
                    let dragMouseOffsetX = event.pageX - this.getSelectionLayerScreenBounds().left - left;

                    let dropIndex;

                    let clickX = event.pageX;
                    let isDrag = false;

                    // listen for mousemove to position dragHelper
                    $(document).on("mousemove.selectedCol", event => {
                        if (!isDrag && Math.abs(event.pageX - clickX) < 5) {
                            return;
                        }
                        isDrag = true;

                        let mouseX = event.pageX - this.getSelectionLayerScreenBounds().left;

                        let dragLeft = mouseX - dragMouseOffsetX;
                        let dragRight = dragLeft + width;

                        $dragHelper.left(dragLeft);

                        dropIndex = null;
                        let dropRowLeft = 0;

                        // are we left of the first col?
                        let firstColBounds = this.getColSelectionBounds(0);
                        if (dragRight < firstColBounds.right) {
                            dropIndex = 0;
                            dropRowLeft = firstColBounds.left;
                        }
                        // are we right of the last col?
                        let lastColBounds = this.getColSelectionBounds(this.element.model.cols.length - 1);
                        if (dragLeft > lastColBounds.left) {
                            dropIndex = this.element.model.cols.length;
                            dropRowLeft = lastColBounds.right;
                        }

                        // are we over an inner col?
                        if (dropIndex == null) {
                            for (let col of this.element.model.cols) {
                                let colBounds = this.getColSelectionBounds(col.index);

                                if (dragLeft < colBounds.left && dragRight > colBounds.left) {
                                    dropIndex = col.index;
                                    dropRowLeft = colBounds.left;
                                }
                            }
                        }

                        // draw the drop indicator bar
                        let colBounds = this.getColSelectionBounds(0);
                        this.$el.find(".drag_target_indicator").remove();
                        let $dropTargetIndicator = this.$el.addEl($.div("drag_target_indicator"));
                        $dropTargetIndicator.setBounds(new geom.Rect(dropRowLeft - 1, colBounds.top, 3, colBounds.height));
                    });

                    // listen for mouseup to drop selected columns
                    $(document).one("mouseup.selectedCol", event => {
                        event.stopPropagation();

                        $(document).off(".selectedCol");
                        this.isDraggingColOrRow = false;

                        // remove the dragHelper and dragTargetIndicator
                        $dragHelper.remove();
                        this.$el.find(".drag_target_indicator").remove();

                        if (!isDrag) {
                            this.selectCol(selectedCol.index);
                            return;
                        }

                        if (dropIndex == null) return;

                        // drop the selected columns into place
                        let columns = this.element.model.cols;

                        // temp give each column it's cells
                        for (let col of columns) {
                            col.cells = this.element.getCellsInColumn(col.index);
                        }

                        if (dropIndex > _.max(this.selectedCols, "index").index) {
                            dropIndex = dropIndex - this.selectedCols.length;
                        }
                        // reorder the columns array to move the selected column into their dropped location
                        for (let selectedCol of this.selectedCols) {
                            columns.splice(columns.indexOf(selectedCol), 1);
                        }

                        for (let selectedCol of this.selectedCols) {
                            columns.insert(selectedCol, dropIndex);
                            dropIndex++;
                        }

                        // reset the col property on all the columns and their cells
                        for (let i = 0; i < columns.length; i++) {
                            columns[i].index = i;
                            for (let cell of columns[i].cells) {
                                cell.model.col = i;
                            }
                            columns[i].cells = null;
                        }

                        this.element.canvas.updateCanvasModel(false).then(() => {
                            this.refresh();
                        });
                    });
                });
            }

            this.$colWidget = this.$el.addEl(controls.createPopupButton(this, {
                className: "col-menu",
                triggerOn: "click",
                items: () => this.getColumnMenu(),
                callback: action => {
                    this.handleColumnAction(action);
                }
            }));
            this.$colWidget.left(left + width - 25).top(colBounds.top - 35);
        }
    },

    renderSelectedRows: function() {
        $(".row_widget").removeClass("selected");
        for (let selectedRow of this.selectedRows) {
            $(`.row_widget[data-row=${selectedRow.index}]`).addClass("selected");
        }

        $(".col_widget").off(".selectedRow");
        $(document).off(".selectedRow");

        this.$rowWidget && this.$rowWidget.remove();

        // we only support drag for a contiguous selection of rowumns
        if (this.selectedRows.length && _.minBy(this.selectedRows, row => row.index).index + this.selectedRows.length - 1 == _.maxBy(this.selectedRows, row => row.index).index) {
            let tableBounds = this.element.bounds.multiply(app.canvasScale);

            let rowBounds = this.getRowSelectionBounds(_.minBy(this.selectedRows, row => row.index).index);
            let top = rowBounds.top;
            let height = _.sumBy(this.selectedRows, row => {
                return this.getRowSelectionBounds(row.index).height;
            });
            for (let selectedRow of this.selectedRows) {
                let $rowWidget = $(`.row_widget[data-row=${selectedRow.index}]`);

                $rowWidget.on("mousedown.selectedRow", event => {
                    // start rpw dragging
                    event.stopImmediatePropagation();

                    this.isDraggingColOrRow = true;

                    // create the dragHelper
                    let $dragHelper = this.$el.addEl($.div("table_drag_helper"));
                    $dragHelper.top(top).left(0).width(tableBounds.width).height(height);

                    // get the drag offset for the mouse
                    let dragMouseOffsetY = this.screenToSelectionCoordinates(new geom.Point(event.pageX, event.pageY)).y - top;

                    let dropIndex;

                    let clickY = event.pageY;
                    let isDrag = false;

                    // listen for mousemove to position dragHelper
                    $(document).on("mousemove.selectedRow", event => {
                        if (!isDrag && Math.abs(event.pageY - clickY) < 5) {
                            return;
                        }
                        isDrag = true;

                        let mouseY = this.screenToSelectionCoordinates(new geom.Point(event.pageX, event.pageY)).y;

                        let dragTop = mouseY - dragMouseOffsetY;
                        let dragBottom = dragTop + height;

                        $dragHelper.top(dragTop);

                        dropIndex = null;
                        let dropRowTop = 0;

                        // are we above the first row?
                        let firstRowBounds = this.getRowSelectionBounds(0);
                        if (dragBottom < firstRowBounds.bottom) {
                            dropIndex = 0;
                            dropRowTop = firstRowBounds.top;
                        }
                        // are we below the last row?
                        let lastRowBounds = this.getRowSelectionBounds(this.element.model.rows.length - 1);
                        if (dragTop > lastRowBounds.top) {
                            dropIndex = this.element.model.rows.length;
                            dropRowTop = lastRowBounds.bottom;
                        }

                        // are we over an inner row?
                        if (dropIndex == null) {
                            for (let row of this.element.model.rows) {
                                let rowBounds = this.getRowSelectionBounds(row.index);

                                if (dragTop < rowBounds.top && dragBottom > rowBounds.top) {
                                    dropIndex = row.index;
                                    dropRowTop = rowBounds.top;
                                }
                            }
                        }

                        // draw the drop indicator bar
                        let rowBounds = this.getRowSelectionBounds(0);
                        this.$el.find(".drag_target_indicator").remove();
                        let $dropTargetIndicator = this.$el.addEl($.div("drag_target_indicator"));
                        $dropTargetIndicator.setBounds(new geom.Rect(rowBounds.left, dropRowTop - 1, rowBounds.width, 3));
                    });

                    // listen for mouseup to drop selected rowumns
                    $(document).one("mouseup.selectedRow", event => {
                        event.stopPropagation();

                        $(document).off(".selectedRow");
                        this.isDraggingColOrRow = false;

                        // remove the dragHelper and dragTargetIndicator
                        $dragHelper.remove();
                        this.$el.find(".drag_target_indicator").remove();

                        if (!isDrag) {
                            return;
                        }

                        if (dropIndex == null) return;
                        // drop the selected rowumns into place
                        let rows = this.element.model.rows;

                        // temp give each rowumn it's cells
                        for (let row of rows) {
                            row.cells = this.element.getCellsInRow(row.index);
                        }

                        if (dropIndex > _.max(this.selectedRows, "index").index) {
                            dropIndex = dropIndex - this.selectedRows.length;
                        }
                        for (let selectedRow of this.selectedRows) {
                            rows.splice(rows.indexOf(selectedRow), 1);
                        }

                        for (let selectedRow of this.selectedRows) {
                            rows.insert(selectedRow, dropIndex);
                            dropIndex++;
                        }

                        // reset the row property on all the rows and their cells
                        for (let i = 0; i < rows.length; i++) {
                            rows[i].index = i;
                            for (let cell of rows[i].cells) {
                                cell.model.row = i;
                            }
                            rows[i].cells = null;
                        }
                        this.element.canvas.updateCanvasModel(false).then(() => {
                            this.refresh();
                        });
                    });
                });
            }

            this.$rowWidget = this.$el.addEl(controls.createPopupButton(this, {
                className: "row-menu",
                triggerOn: "click",
                items: () => this.getRowMenu(),
                callback: action => {
                    this.handleRowAction(action);
                }
            }));
            this.$rowWidget.left(rowBounds.left - 10).top(rowBounds.top).height(height + 1);
        }
    },

    // --------------------------------------------------------------------------------------------
    // region MENUS
    // --------------------------------------------------------------------------------------------

    getColumnMenu: function() {
        let menu = [];

        let isHeaderColSelected = this.element.model.showHeaderCol && _.some(this.selectedCols, { index: 0 });
        menu.push({
            type: "menu",
            label: "Column Style",
            menu: {
                items: [
                    { value: "defaultCol", label: "Default" },
                    { value: "headerCol", label: "Header" },
                    { value: "cleanCol", label: "Clean Header" },
                    { value: "summaryCol", label: "Summary" },
                    { value: "emphasizedCol", label: "Emphasized" }
                ],
                value: this.selectedCols[0].style,
                callback: style => {
                    for (let col of this.selectedCols) {
                        col.style = style;
                    }
                    this.element.model.showHeaderCol = (style === "headerCol" || style === "cleanCol") && _.some(this.selectedCols, { index: 0 });
                    this.element.refreshElement();
                    _.defer(() => {
                        this.element.canvas.saveCanvasModel();
                    });
                }
            }
        });
        menu.push({ type: "divider" });

        if (this.selectedCols.length == 1) {
            // menu.push({
            //     type: "control",
            //     view: controls.createToggle(this, {
            //         label: "Lock Width",
            //         model: this.selectedCols[0],
            //         value: this.selectedCols[0].locked,
            //         callback: locked => {
            //             this.selectedCols[0].locked = locked;
            //             if (locked) {
            //                 this.selectedCols[0].size *= this.element.calculatedProps.baseColWidth;
            //             } else {
            //                 this.selectedCols[0].size /= this.element.calculatedProps.baseColWidth;
            //             }
            //             this.element.refreshElement();
            //             _.defer(() => {
            //                 this.element.canvas.saveCanvasModel();
            //             });
            //         },
            //     })
            // });
            menu.push({
                type: "control",
                view: () => controls.createToggle(this, {
                    label: "Break After",
                    model: this.selectedCols[0],
                    property: "break",
                    transitionModel: false,
                    enabled: this.selectedCols[0].index < this.model.cols.length - 1,
                })
            });

            menu.push({ type: "divider" });
            menu.push({ value: "autofit", label: "Fit to Contents" });
            menu.push({ type: "divider" });
            menu.push({ value: "insert_col_after", label: "Add Column After", enabled: !this.element.hasDataSourceLink() });
            menu.push({ value: "insert_col_before", label: "Add Column Before", enabled: !this.element.hasDataSourceLink() });
            menu.push({ type: "divider" });
        }

        menu.push({ value: "delete_column", label: "Delete Column".pluralize(this.selectedCols.length > 1), enabled: !this.element.hasDataSourceLink() });
        return menu;
    },

    getRowMenu() {
        let menu = [];

        let isHeaderRowSelected = this.element.model.showHeaderRow && _.some(this.selectedRows, { index: 0 });
        menu.push({
            type: "menu",
            label: "Row Style",
            menu: {
                items: [
                    { value: "defaultRow", label: "Default" },
                    { value: "headerRow", label: "Header" },
                    { value: "cleanRow", label: "Clean Header" },
                    { value: "summaryRow", label: "Summary" },
                ],
                value: this.selectedRows[0].style,
                callback: style => {
                    for (let row of this.selectedRows) {
                        row.style = style;
                    }
                    this.element.refreshElement();
                    _.defer(() => {
                        this.element.canvas.saveCanvasModel();
                    });
                }
            }
        });
        menu.push({ type: "divider" });
        if (this.selectedRows.length == 1) {
            menu.push({
                type: "control",
                view: () => controls.createToggle(this, {
                    label: "Break After",
                    model: this.selectedRows[0],
                    property: "break",
                    transitionModel: false,
                    enabled: this.selectedRows[0].index < this.model.rows.length - 1,
                })
            });
            menu.push({ type: "divider" });
            menu.push({ value: "autofit", label: "Fit to Contents" });
            menu.push({ type: "divider" });
            menu.push({ value: "insert_row_after", label: "Add Row After", enabled: !this.element.hasDataSourceLink() });
            menu.push({ value: "insert_row_before", label: "Add Row Before", enabled: !this.element.hasDataSourceLink() });
            menu.push({ type: "divider" });
        }
        menu.push({ value: "delete_row", label: "Delete Row".pluralize(this.selectedRows.length > 1), enabled: !this.element.hasDataSourceLink() });
        return menu;
    },

    insertColumn(index) {
        if (this.element.model.cols.length >= this.element.MAX_COLS) {
            ShowWarningDialog({
                title: "Can't add another column",
                message: `Tables are limited to ${this.element.MAX_COLS} columns`,
            });
            return;
        }

        this.stopEditingCell();

        let colSize = this.element.model.cols[index - 1].size || (this.element.MIN_COL_WIDTH * 2);

        // shift right cols
        for (let column of _.filter(this.element.model.cols, c => c.index >= index)) {
            column.index++;
        }
        // shift right cells
        for (let cell of _.filter(this.element.model.cells, cell => cell.col >= index)) {
            cell.col++;
        }

        // create the col
        this.element.model.cols.insert({
            index: index,
            break: false,
            style: "defaultCol",
            size: colSize
        }, index);

        // create cells for col
        for (let row = 0; row < this.element.model.rows.length; row++) {
            if (index > 0) {
                let cellToLeft = this.element.getCell(index - 1, row);
                this.element.model.cells.push({
                    col: index,
                    row: row,
                    format: cellToLeft.format == FormatType.ICON ? FormatType.TEXT : cellToLeft.format,
                    formatOptions: cellToLeft.format == FormatType.ICON ? null : cellToLeft.formatOptions,
                });
            } else {
                this.element.model.cells.push({
                    col: index,
                    row: row,
                    format: FormatType.TEXT
                });
            }
        }
        this.element.generateCells();

        let tableWidth = this.element.model.tableWidth;
        let actualTableWidth = tableWidth * this.element.calculatedProps.allowedSize.width;
        if (actualTableWidth < this.element.calculatedProps.allowedSize.width) {
            this.element.model.tableWidth = Math.min(1, (actualTableWidth + this.element.MIN_COL_WIDTH) / this.element.calculatedProps.allowedSize.width);
        }
    },

    deleteCell(cell) {
        this.element.model.cells.remove(cell);
        if (this.element.elements[cell.id]) {
            delete this.element.elements[cell.id];
        }
    },

    deleteColumn(col) {
        if (this.element.model.cols.length == 1) return;

        this.stopEditingCell();
        this.element.model.cols.remove(col);
        for (let cell of _.filter(this.element.model.cells, { col: col.index })) {
            this.deleteCell(cell);
        }
        // shift right cols
        for (let column of _.filter(this.element.model.cols, c => c.index > col.index)) {
            column.index--;
        }
        // shift right cells
        for (let cell of _.filter(this.element.model.cells, cell => cell.col > col.index)) {
            cell.col--;
        }
        this.element.generateCells();
    },

    insertRow(index) {
        if (this.element.model.rows.length >= this.element.MAX_ROWS) {
            ShowWarningDialog({
                title: "Can't add another row",
                message: `Tables are limited to ${this.element.MAX_ROWS} rows`,
            });
            return;
        }

        this.stopEditingCell();

        // shift lower rows
        for (let row of _.filter(this.element.model.rows, r => r.index >= index)) {
            row.index++;
        }
        // shift lower cells
        for (let cell of _.filter(this.element.model.cells, cell => cell.row >= index)) {
            cell.row++;
        }
        // create the row
        this.element.model.rows.insert({
            index: index,
            break: false,
            style: "defaultRow",
            size: this.element.MIN_ROW_HEIGHT
        }, index);

        // create cells for row
        for (let col = 0; col < this.element.model.cols.length; col++) {
            if (index > 0) {
                let cellAbove = this.element.getCell(col, index - 1);
                this.element.model.cells.push({
                    col: col,
                    row: index,
                    format: cellAbove.format,
                    formatOptions: cellAbove.formatOptions,
                    content_type: cellAbove.format == "icon" ? cellAbove.model.content_type : null,
                    content_value: cellAbove.format == "icon" ? cellAbove.model.content_value : null
                });
            } else {
                this.element.model.cells.push({
                    col: col,
                    row: index,
                    format: FormatType.TEXT
                });
            }
        }

        let tableHeight = this.element.model.tableHeight;
        let actualTableHeight = tableHeight * this.element.calculatedProps.allowedSize.height;
        if (actualTableHeight < this.element.calculatedProps.allowedSize.height) {
            this.element.model.tableHeight = Math.min(1, (actualTableHeight + this.element.MIN_ROW_HEIGHT) / this.element.calculatedProps.allowedSize.height);
        }

        this.element.generateCells();
    },

    deleteRow(row) {
        if (this.element.model.rows.length == 1) return;

        this.stopEditingCell();
        this.element.model.rows.remove(row);
        for (let cell of _.filter(this.element.model.cells, { row: row.index })) {
            this.deleteCell(cell);
        }
        // shift lower rows
        for (let r of _.filter(this.element.model.rows, r => r.index > row.index)) {
            r.index--;
        }
        //shift lower cells
        for (let cell of _.filter(this.element.model.cells, cell => cell.row > row.index)) {
            cell.row--;
        }
        this.element.generateCells();
    },

    handleColumnAction: function(action) {
        switch (action) {
            case "autofit":
                for (let col of this.selectedCols) {
                    this.element.calcAutoFit("columns", col.index);
                }
                this.clearSelection();
                break;
            case "insert_col_after":
                this.insertColumn(this.selectedCols[0].index + 1);
                this.clearSelection();
                this.element.canvas.updateCanvasModel(false).then(() => {
                    this.refresh();
                }).catch(err => {
                    ShowDialogAsync(BadFitDialog, {
                        title: "Sorry, we aren't able to fit any more columns in your table",
                    });
                });
                break;
            case "insert_col_before":
                this.insertColumn(this.selectedCols[0].index);
                this.clearSelection();
                this.element.canvas.updateCanvasModel(false).then(() => {
                    this.refresh();
                }).catch(err => {
                    ShowDialogAsync(BadFitDialog, {
                        title: "Sorry, we aren't able to fit any more columns in your table",
                    });
                });
                break;
            case "delete_column":
                for (let col of this.selectedCols) {
                    this.deleteColumn(col);
                }
                this.clearSelection();
                this.element.canvas.updateCanvasModel(false).then(() => {
                    this.refresh();
                });
                break;
        }
    },

    handleRowAction: function(action) {
        switch (action) {
            case "autofit":
                for (let row of this.selectedRows) {
                    this.element.calcAutoFit("rows", row.index);
                }
                this.clearSelection();
                break;
            case "insert_break":
                this.selectedRows[0].break = !this.selectedRows[0].break;
                this.element.canvas.updateCanvasModel(false).then(() => {
                    this.refresh();
                }).catch(err => {
                    ShowDialogAsync(BadFitDialog, {
                        title: "Sorry, we aren't able to fit any more breaks in your table",
                    });
                });
                break;
            case "insert_row_after":
                this.insertRow(this.selectedRows[0].index + 1);
                this.clearSelection();
                this.element.canvas.updateCanvasModel(false).then(() => {
                    this.refresh();
                }).catch(err => {
                    ShowDialogAsync(BadFitDialog, {
                        title: "Sorry, we aren't able to fit any more rows in your table",
                    });
                });
                break;
            case "insert_row_before":
                this.insertRow(this.selectedRows[0].index);
                this.clearSelection();
                this.element.canvas.updateCanvasModel(false).then(() => {
                    this.refresh();
                }).catch(err => {
                    ShowDialogAsync(BadFitDialog, {
                        title: "Sorry, we aren't able to fit any more rows in your table",
                    });
                });
                break;
            case "delete_row":
                for (let row of this.selectedRows) {
                    this.deleteRow(row);
                }
                this.clearSelection();
                this.element.canvas.updateCanvasModel(false).then(() => {
                    this.refresh();
                });
                break;
        }
    },

    // --------------------------------------------------------------------------------------------
    // endregion
    // --------------------------------------------------------------------------------------------

    // handles a tab key while editing text because the table isn't selected
    handleTabKey: function(event) {
        event.stopPropagation();
        if (event.shiftKey) {
            this.moveCellSelection("left", false);
        } else {
            this.moveCellSelection("right", false);
        }
    },

    cleanUp: function() {
        $(document).off(".table");
        // Let the selection layer do its stuff before
        _.defer(() => this.stopEditingCell());
    },

    handlePasteTable: async function(event) {
        event.stopPropagation();

        let startCol = 0;
        let startRow = 0;
        if (this.selectedCells.length) {
            startRow = this.selectedCells[0].row;
            startCol = this.selectedCells[0].col;
        } else {
            this.element.model.cols = [];
            this.element.model.rows = [];
            this.element.model.cells = [];
        }

        this.clearSelection();

        let cells = await clipboardRead([ClipboardType.CELLS], event);
        if (cells) {
            try {
                let offsetRow = _.minBy(cells, cell => cell.row).row;
                let offsetCol = _.minBy(cells, cell => cell.col).col;

                for (let row = _.minBy(cells, cell => cell.row).row; row <= _.maxBy(cells, cell => cell.row).row; row++) {
                    for (let col = _.minBy(cells, cell => cell.col).col; col <= _.maxBy(cells, cell => cell.col).col; col++) {
                        let cell = this.element.getCell(startCol + col - offsetCol, startRow + row - offsetRow);
                        // TODO deal with merged cells
                        // TODO undo on paste cells
                        if (cell) {
                            cell.model = Object.assign(cell.model, _.omit(_.find(cells, { col, row }), ["id", "row", "col"]), { width: 1, height: 1 });
                            this.selectedCells.push(cell);
                        }
                    }
                }
                this.renderSelectedCells();
                this.element.canvas.updateCanvasModel(false);
            } catch (err) {
                ShowWarningDialog({
                    title: "An error occurred while pasting"
                });
            }
            return;
        }

        let text = await clipboardRead([ClipboardType.TEXT], event);
        if (text) {
            // pasting on windows adds an extra return in the clipboard data
            if (window.navigator.platform.indexOf("Win") > -1 && (text.charCodeAt(text.length - 1) == 13 || text.charCodeAt(text.length - 1) == 10)) {
                text = text.substring(0, text.length - 1);
            }

            const processedData = parseCSV(text, String.fromCharCode(9));
            try {
                if (this.element.hasDataSourceLink()) {
                    this.element.model.dataSourceLink = null;
                    this.element.removeDataSource();
                }

                this.element.updateTableData(processedData, startRow, startCol);
            } catch (err) {
                ShowWarningDialog({
                    title: "Unable to parse clipboard",
                    message: "Sorry, we weren't able to parse the data in your clipboard into something we could use to populate a table. Try copying a range of cells from Microsoft Excel, Google Sheets, or similar spreadsheet application.",
                });
            }
            return;
        }
    },
});

const TableCellEditor = Backbone.View.extend({
    className: "table-cell-editor",

    initialize: function(options) {
        this.tableEditor = options.tableEditor;
        this.cell = options.cell;
        this.initialValue = options.initialValue || (this.cell.model.cellText ? this.cell.model.cellText.text : "");
    },

    render: function() {
        this.$input = this.$el.addEl($.textarea("").addClass("table-cell-input"));
        this.$input.val(this.initialValue);

        this.$input.on("keydown", event => {
            switch (event.which) {
                case Key.TAB:
                    event.stopPropagation();
                    this.tableEditor.moveCellSelection("right");
                    break;
                case Key.RIGHT_ARROW:
                    if (this.$input[0].selectionStart == this.$input.val().length) {
                        event.stopPropagation();
                        this.tableEditor.moveCellSelection("right");
                    }
                    break;
                case Key.DOWN_ARROW:
                    if (this.$input[0].selectionStart == this.$input.val().length) {
                        event.stopPropagation();
                        this.tableEditor.moveCellSelection("down");
                    }
                    break;
                case Key.UP_ARROW:
                    if (this.$input[0].selectionStart == 0) {
                        event.stopPropagation();
                        this.tableEditor.moveCellSelection("up");
                    }
                    break;
                case Key.LEFT_ARROW:
                    if (this.$input[0].selectionStart == 0) {
                        event.stopPropagation();
                        this.tableEditor.moveCellSelection("left");
                    }
                    break;
                case Key.BACKSPACE:
                case Key.DELETE:
                    _.defer(() => {
                        this.layout(this.$input.val().substring(0, this.$input.val().length - 1));
                    });
                    break;
                case Key.ESCAPE:
                    event.preventDefault();
                    this.$input.val(this.cell.model.cellText ? this.cell.model.cellText.text : "");
                    this.commit();
                    break;
                case Key.ENTER:
                    if (!event.shiftKey) {
                        event.preventDefault();
                        this.commit();
                    }
                    break;
            }
        });

        this.$input.on("keypress", event => {
            // if (event.which == Key.ENTER && !event.shiftKey) {
            //     event.preventDefault();
            // this.tableEditor.selectCell(this.cell);
            // this.commit();
            // return;
            // }
            this.layout(this.$input.val() + String.fromCharCode(event.which));
        });

        this.$el.opacity(0);

        _.defer(() => {
            this.layout(this.$input.val());
            this.setFocus();
        });

        return this;
    },

    setFocus: function() {
        this.$input.focus();
        if (typeof this.$input[0].selectionStart == "number") {
            this.$input[0].selectionStart = this.$input[0].selectionEnd = this.$input[0].value.length;
        } else if (typeof this.$input[0].createTextRange != "undefined") {
            var range = this.$input[0].createTextRange();
            range.collapse(false);
            range.select();
        }
    },

    position: function(bounds) {
        this.cellBounds = bounds.deflate(1);
        this.$el.setBounds(this.cellBounds);
        this.layout();
    },

    layout: function(value) {
        let width = this.cellBounds.width - 20;

        this.$input.opacity(0);

        let $sizer = this.$el.addEl($.textarea().val(value)).addClass("sizer");
        $sizer.width(width);
        $sizer.height(0);

        while ($sizer[0].scrollHeight > 30 && width < 300) {
            width += 5;
            $sizer.width(width);
        }

        let height = $sizer[0].scrollHeight;

        let editorHeight = Math.max(this.cellBounds.height, height + 4);

        this.$input.top(editorHeight / 2 - height / 2).left(10).width(width);
        this.$input.height(height);

        this.$el.width(width);
        this.$el.height(editorHeight);

        this.$el.top(this.cellBounds.centerV - editorHeight / 2);
        this.$el.left(this.cellBounds.centerH - width / 2 - 10);

        $sizer.remove();

        this.tableEditor.cellControlBar.$el.hide();

        this.$input.opacity(1);
        this.$el.opacity(1);
    },

    commit: async function() {
        if (!this.cell.model.cellText) {
            this.cell.model.cellText = {};
        }

        let value = this.$input.val().replace(/\n/g, String.fromCodePoint(13));

        if (!this.cell.model.cellText.text || this.cell.model.cellText.text != value) {
            let detectedFormat = formatter.detectFormatFromString(value);

            if (!(detectedFormat.format == FormatType.NUMBER && formatter.getFormatType(this.cell.model.format) == "numeric") || !this.cell.model.formatOptions) {
                // change the format to the detected format unless the cell is already formatted as a numeric format type and the detected format is number
                this.cell.model.format = detectedFormat.format;
                this.cell.model.formatOptions = detectedFormat.formatOptions;
            }

            // turn on decimals if detected in the format
            if ((detectedFormat.format == FormatType.NUMBER || detectedFormat.format == FormatType.CURRENCY || detectedFormat.format == FormatType.PERCENT) && detectedFormat.formatOptions.decimal > 0) {
                this.cell.model.formatOptions.decimal = detectedFormat.formatOptions.decimal;
            }

            this.cell.model.cellText.text = value;

            // if this cell previously had an icon, we need to delete it's iconCell element
            if (this.tableEditor.element.elements[this.cell.model.id]) {
                this.tableEditor.element.removeElement(this.tableEditor.element.elements[this.cell.model.id]);
            }

            this.tableEditor.element.refreshElement();
            _.defer(() => {
                this.tableEditor.element.canvas.saveCanvasModel();
            });
        }
        this.tableEditor.editingCell = null;
        this.tableEditor.cellControlBar.$el.show();

        this.remove();
    }

});

const TableCellSelection = ElementUI.extend({
    showSelectionBox: false,
    captureMouseEvents: false,

    getOffset: function() {
        return 10;
    },

    setup: function(options) {
        this.editor = options.editor;
        this.cells = this.element.cells;
        this.showEmphasize = options.showEmphasize;
    },

    renderControls: function() {
        this.$el.addClass("table-cell-control-bar");

        let canMergeCells = false;
        if (this.cells.length > 1) {
            let isContiguous = true;
            for (let col = _.minBy(this.cells, cell => cell.col).col; col <= _.maxBy(this.cells, cell => cell.col).col; col++) {
                for (let row = _.minBy(this.cells, cell => cell.row).row; row <= _.maxBy(this.cells, cell => cell.row).row; row++) {
                    if (!_.find(this.cells, { col, row })) {
                        isContiguous = false;
                        break;
                    }
                }
            }

            if (isContiguous) {
                canMergeCells = true;
            }
        }

        if (canMergeCells) {
            this.addControl({
                type: controls.BUTTON,
                label: "Merge Cells",
                enabled: !this.editor.element.hasDataSourceLink(),
                callback: () => {
                    let startCol = _.minBy(this.cells, cell => cell.col).col;
                    let startRow = _.minBy(this.cells, cell => cell.row).row;
                    let endCol = _.maxBy(this.cells, cell => cell.col).col;
                    let endRow = _.maxBy(this.cells, cell => cell.row).row;

                    let cellValue = "";

                    for (let col = startCol; col <= endCol; col++) {
                        for (let row = startRow; row <= endRow; row++) {
                            let cell = _.find(this.cells, { col, row });
                            cellValue += cell.value;
                            if (col == startCol && row == startRow) {
                                cell.model.width = endCol - startCol + 1;
                                cell.model.height = endRow - startRow + 1;
                                cell.model.isHidden = false;
                            } else {
                                cell.model.isHidden = true;
                            }
                        }
                    }

                    let mergedCell = this.editor.element.getCell(startCol, startRow);
                    mergedCell.model.cellText = {
                        text: cellValue
                    };

                    this.editor.element.refreshElement();
                    _.defer(() => {
                        this.editor.element.canvas.saveCanvasModel();
                    });
                    this.editor.selectCell(mergedCell);
                }
            });
        } else if (this.cells.length == 1 && (this.cells[0].width > 1 || this.cells[0].height > 1)) {
            this.addControl({
                type: controls.BUTTON,
                label: "Unmerge",
                callback: () => {
                    let startCell = this.cells[0];
                    let startCol = startCell.col;
                    let endCol = startCell.col + startCell.width;
                    let startRow = startCell.row;
                    let endRow = startCell.row + startCell.height;

                    for (let col = startCol; col < endCol; col++) {
                        for (let row = startRow; row < endRow; row++) {
                            let cell = this.editor.element.getCell(col, row);
                            if (cell) {
                                cell.model.isHidden = false;
                                cell.model.width = cell.model.height = 1;
                            }
                        }
                    }
                    this.editor.element.refreshElement();
                    _.defer(() => {
                        this.editor.element.canvas.saveCanvasModel();
                    });
                }
            });
        }

        this.cells.forEach(cell => {
            if (!cell.format) {
                cell.model.format = FormatType.TEXT;
            }
        });

        let label;
        if (_.uniqBy(this.cells, "format").length == 1) {
            label = this.cells[0].format;
        } else {
            label = "Mixed Formats";
        }

        let formats = _.uniq(_.map(this.cells, "format"));

        let currentFormat;
        if (formats.length == 1) {
            currentFormat = formats[0];
        } else {
            currentFormat = "Multiple";
        }

        let formatOptions = {};
        for (let options of _.map(this.cells, cell => cell.formatOptions)) {
            options = Object.assign(formatter.getDefaultFormatOptions(), options);
            for (let key of Object.keys(options)) {
                if (formatOptions.hasOwnProperty(key) && formatOptions[key] != options[key]) {
                    formatOptions[key] = null;
                } else {
                    formatOptions[key] = options[key];
                }
            }
        }

        this.addControl({
            type: controls.POPUP_BUTTON,
            label: label,
            id: "formatMenu",
            menuContents: closeMenu => {
                let $menu = new FormatOptionsMenu({
                    format: currentFormat,
                    formatOptions: formatOptions,
                    closeMenu,
                    callback: data => {
                        for (let cell of this.cells) {
                            // Remove video specific fields in case we're switching content_type
                            delete cell.model.content_url;
                            delete cell.model.assetProps;

                            cell.model = _.merge(
                                cell.model,
                                data,
                            );
                        }

                        this.editor.element.canvas.updateCanvasModel();
                    },
                    showTableFormatOptions: true,
                    allowedFormats: [FormatType.TEXT, FormatType.NUMBER, FormatType.CURRENCY, FormatType.PERCENT, FormatType.DATE, FormatType.ICON]
                }).render().$el;

                return $menu;
            }
        });

        let $colorControl = this.$controlBar.addEl($.div("control color_picker"));
        let $chit = $colorControl.addEl($.div("color_picker_chit"));

        let setChitColor = () => {
            if (_.uniqBy(this.cells, "cellColor").length == 1 && this.cells[0].cellStyle != "none") {
                $chit.css("backgroundColor", this.element.canvas.getTheme().palette.getColor(this.cells[0].model.cellColor).toRgbString());
            } else {
                $chit.css("backgroundColor", "none");
            }
        };
        setChitColor();

        $colorControl.on("mousedown", () => {
            renderReactDialog(TableCellStyleMenu, {
                target: $colorControl,
                element: this.element,
                onSetStyle: style => {
                    this.editor.element.markStylesAsDirty();
                    for (let cell of this.cells) {
                        cell.model.cellStyle = style;
                        if (!cell.model.cellColor) {
                            cell.model.cellColor = "theme";
                        }
                    }
                    setChitColor();

                    if (this.cells.some(cell => cell.format == "icon")) {
                        this.editor.element.canvas.updateCanvasModel();
                    } else {
                        this.editor.element.refreshElement();
                        _.defer(() => {
                            this.editor.element.canvas.saveCanvasModel();
                        });
                    }
                },
                onSetColor: color => {
                    this.editor.element.markStylesAsDirty();
                    for (let cell of this.cells) {
                        cell.model.cellColor = color;
                        if (!cell.model.cellStyle || cell.model.cellStyle == "none") {
                            cell.model.cellStyle = "fill";
                        }
                    }
                    setChitColor();
                    if (this.cells.some(cell => cell.format == "icon")) {
                        this.editor.element.canvas.updateCanvasModel();
                    } else {
                        this.editor.element.refreshElement();
                        _.defer(() => {
                            this.editor.element.canvas.saveCanvasModel();
                        });
                    }
                }
            });
        });

        if (currentFormat != "icon") {
            let $formatControls = $.div("control format_control_bar");

            let $formatBold = $formatControls.addEl($.div("format_toggle").append($.icon("format_bold")));
            $formatBold.toggleClass("toggled", this.cells[0].bold == true);
            $formatBold.on("click", () => {
                let bold = !this.cells[0].bold;
                for (let cell of this.cells) {
                    cell.model.bold = bold;
                }
                this.editor.element.refreshElement();
                _.defer(() => {
                    this.editor.element.canvas.saveCanvasModel();
                });
            });

            let $formatItalic = $formatControls.addEl($.div("format_toggle").append($.icon("format_italic")));
            $formatItalic.toggleClass("toggled", this.cells[0].italic == true);
            $formatItalic.on("click", () => {
                let italic = !this.cells[0].italic;
                for (let cell of this.cells) {
                    cell.model.italic = italic;
                }
                this.editor.element.refreshElement();
                _.defer(() => {
                    this.editor.element.canvas.saveCanvasModel();
                });
            });

            let $formatStrikeThrough = $formatControls.addEl($.div("format_toggle").append($.icon("format_strikethrough")));
            $formatStrikeThrough.toggleClass("toggled", this.cells[0].strikeThrough == true);
            $formatStrikeThrough.on("click", () => {
                let strike = !this.cells[0].strikeThrough;
                for (let cell of this.cells) {
                    cell.model.strikeThrough = strike;
                }
                this.editor.element.refreshElement();
                _.defer(() => {
                    this.editor.element.canvas.saveCanvasModel();
                });
            });

            this.$controlBar.append($formatControls);

            this.addControl({
                type: controls.NUMERIC,
                min: 6,
                max: 99,
                step: 1,
                value: this.cells[0].fontSize,
                triggerEvent: "blur",
                callback: value => {
                    for (let cell of this.cells) {
                        cell.model.fontSize = value;
                    }
                    this.editor.element.refreshElement();
                    _.defer(() => {
                        this.editor.element.canvas.saveCanvasModel();
                    });
                }
            });
        }

        // if (currentFormat == "icon") {
        //     let iconSize;
        //     if (_.uniqBy(this.cells, cell => cell.model.iconSize).length == 1) {
        //         iconSize = this.cells[0].model.iconSize;
        //     } else {
        //         iconSize = "--";
        //     }
        //
        //     this.addControl({
        //         type: controls.NUMERIC,
        //         value: iconSize,
        //         triggerEvent: "blur",
        //         callback: value => {
        //             for (let cell of this.cells) {
        //                 cell.model.iconSize = value;
        //             }
        //             this.editor.element.refreshElement();
        //             _.defer(() => {
        //                 this.editor.element.canvas.saveCanvasModel();
        //             });
        //         }
        //     });
        // }
    }
});

const div = styled.div`
  display: flex;
  flex-direction: column;
  width: 80px;
  margin-right: 0px;
  cursor: pointer;
  align-items: center;
  padding-bottom: 6px;
  label {
    font-size: 10px;
    margin: 0px !important;
  }
  &:hover{
    background: ${themeColors.hoverBlue};
  }
  &.selected {
    background: ${themeColors.lightBlue};
  } 
`;
const StyleOption = div;

class TableCellStyleMenu extends Component {
    handleChangeStyle = style => {
        this.props.onSetStyle(style);
    }

    handleChangeColor = color => {
        this.props.onSetColor(color);
    }

    render() {
        return (
            <PopupMenu {...this.props} className="style-popup-menu beautiful-ui">
                <FlexBox>
                    <StyleOption onClick={() => this.handleChangeStyle("none")}>
                        <img src={getStaticUrl("/images/elements/table/table-cell-none.svg")} />
                        <label>None</label>
                    </StyleOption>
                    <StyleOption onClick={() => this.handleChangeStyle("fill")}>
                        <img src={getStaticUrl("/images/elements/table/table-cell-fill.svg")} />
                        <label>Fill</label>
                    </StyleOption>
                    <StyleOption onClick={() => this.handleChangeStyle("stroke")}>
                        <img src={getStaticUrl("/images/elements/table/table-cell-stroke.svg")} />
                        <label>Stroke</label>
                    </StyleOption>
                    <StyleOption onClick={() => this.handleChangeStyle("text")}>
                        <img src={getStaticUrl("/images/elements/table/table-cell-color.svg")} />
                        <label>Text</label>
                    </StyleOption>
                    <StyleOption onClick={() => this.handleChangeStyle("flag")}>
                        <img src={getStaticUrl("/images/elements/table/table-cell-flag.svg")} />
                        <label>Flag</label>
                    </StyleOption>
                </FlexBox>
                <Gap20 />
                <ColorPalette
                    onSelect={this.handleChangeColor}
                    showAccentColors
                    showLightDark
                    showPositiveNegative
                />
            </PopupMenu>
        );
    }
}

export const editors = {
    TableFrameSelection,
    TableSelection,
    TableCellEditor,
    TableCellSelection,
};
