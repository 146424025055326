import { Backbone, $, SVG } from "js/vendor";
import { app } from "js/namespaces";
import { controls } from "js/editor/ui";
import { ds } from "js/core/models/dataService";
import moment from "moment/moment";
import { getStaticUrl } from "js/config";
import { AssetType, FormatType, HorizontalAlignType, CellChangeStyle } from "common/constants";
import { formatter } from "js/core/utilities/formatter";
import { ACCEPTED_DATE_FORMATS } from "common/constants";
import { ShowDialog } from "js/react/components/Dialogs/BaseDialog";
import { AddAssetsContainer } from "js/react/views/AddAssets";
import getLogger from "js/core/logger";

const logger = getLogger();

const FormatOptionsMenu = Backbone.View.extend({

    className: "format-menu menu-container",

    initialize: function(options) {
        this.container = options.container;
        this.element = options.element;
        this.allowedFormats = options.allowedFormats;
        this.closeMenu = options.closeMenu;
        this.onLayoutMenu = options.onLayoutMenu;

        if (this.element) {
            this.format = this.element.format;
            this.formatOptions = this.element.formatOptions || formatter.getDefaultFormatOptions();
        } else {
            this.currentFormat = options.format;
            this.formatOptions = options.formatOptions;
        }

        this.callback = options.callback;

        this.showTableFormatOptions = options.showTableFormatOptions;
    },

    render: function() {
        this.format = this.currentFormat || this.element.format;

        let addFormat = value => {
            let $item = $.div("format-option");
            $item.append($.img(getStaticUrl(`/images/ui/formats/format_${value}.svg`)));

            if (value == FormatType.ICON) {
                $item.append($.label("Icon/Image"));
            } else {
                $item.append($.label(value));
            }

            $item.toggleClass("selected", this.format == value);

            $item.on("click", () => {
                this.format = value;

                if (this.element) {
                    this.element.model.format = value;
                    this.element.canvas.updateCanvasModel(false, true);
                } else if (this.callback) {
                    let savedFormatOptions = this.formatOptions;
                    for (let key of Object.keys(savedFormatOptions)) {
                        if (savedFormatOptions[key] == null) {
                            savedFormatOptions[key] = formatter.getDefaultFormatOptions()[key];
                        }
                    }

                    if (value == "icon") {
                        this.callback({ format: value, formatOptions: savedFormatOptions, content_type: AssetType.ICON, content_value: "check-yes" });
                    } else {
                        this.callback({ format: value, formatOptions: savedFormatOptions });
                    }
                }

                this.$el.find(".selected").removeClass("selected");
                $item.addClass("selected");

                this.renderFormatOptions();
                this.onLayoutMenu && this.onLayoutMenu();
            });

            return $item;
        };

        let $formats = this.$el.addEl($.div("format-icons"));
        for (let format of this.allowedFormats) {
            $formats.append(addFormat(format));
        }

        this.renderFormatOptions();

        return this;
    },

    saveFormatOptions: function(formatOptions) {
        let savedFormatOptions = this.formatOptions;
        for (let key of Object.keys(savedFormatOptions)) {
            if (savedFormatOptions[key] == null) {
                savedFormatOptions[key] = formatter.getDefaultFormatOptions()[key];
            }
        }
        if (this.element) {
            this.element.model.formatOptions = savedFormatOptions;
            this.element.canvas.updateCanvasModel(false, true);
        } else if (this.callback) {
            this.callback({ format: this.format, formatOptions: savedFormatOptions });
        }
    },

    renderFormatOptions: function() {
        const MIXED_LABEL = "Mixed";

        this.$el.find(".format_options").remove();

        let $formatOptions = this.$el.addEl($.div("format_options menu-container"));

        const addChangeInValueControls = $formatOptions => {
            $formatOptions.addEl($.hr());
            if (!this.formatOptions.accountingStyle) {
                $formatOptions.addEl(controls.createDropdownMenu(this, {
                    label: "Change In Value",
                    items: [
                        { value: CellChangeStyle.NONE, label: "None" },
                        { value: CellChangeStyle.PLUS_MINUS, label: "+/-" },
                        { value: CellChangeStyle.ARROWS, label: "Up/down arrows" }
                    ],
                    value: this.formatOptions.changeStyle || MIXED_LABEL,
                    callback: value => {
                        this.formatOptions.changeStyle = value;
                        this.saveFormatOptions(this.formatOptions);
                    }
                }).addClass("change-in-value__dropdown"));
            }

            if ((this.formatOptions.changeStyle && this.formatOptions.changeStyle != CellChangeStyle.NONE) || this.formatOptions.accountingStyle) {
                $formatOptions.addEl(controls.createCheckbox(this, {
                    label: "Color Code Change Values",
                    value: this.formatOptions.changeColor || "",
                    callback: value => {
                        this.formatOptions.changeColor = value;
                        this.saveFormatOptions(this.formatOptions);
                    }
                }).addClass("color-code__checkbox"));
            }
        };

        switch (this.format) {
            case FormatType.NONE:
            case FormatType.TEXT:

                if (this.showTableFormatOptions) {
                    $formatOptions.append($.hr());
                    let $alignmenu = $formatOptions.addEl($.div("text-alignment"));

                    $alignmenu.append($.label("Text Align"));

                    $alignmenu.addEl(controls.createButtonToggle(this, {
                        icon: "format_align_left",
                        value: this.formatOptions.textAlign == HorizontalAlignType.LEFT,
                        callback: value => {
                            this.formatOptions.textAlign = HorizontalAlignType.LEFT;
                            this.saveFormatOptions(this.formatOptions);
                        }
                    }));

                    $alignmenu.addEl(controls.createButtonToggle(this, {
                        icon: "format_align_center",
                        value: this.formatOptions.textAlign == HorizontalAlignType.CENTER,
                        callback: value => {
                            this.formatOptions.textAlign = HorizontalAlignType.CENTER;
                            this.saveFormatOptions(this.formatOptions);
                        }
                    }));

                    $alignmenu.addEl(controls.createButtonToggle(this, {
                        icon: "format_align_right",
                        value: this.formatOptions.textAlign == HorizontalAlignType.RIGHT,
                        callback: value => {
                            this.formatOptions.textAlign = HorizontalAlignType.RIGHT;
                            this.saveFormatOptions(this.formatOptions);
                        }
                    }));
                }
                break;
            case FormatType.NUMBER:
            case FormatType.CURRENCY:
                $formatOptions.append($.hr());

                if (this.format == FormatType.CURRENCY) {
                    $formatOptions.addEl(controls.createDropdownMenu(this, {
                        label: "Currency",
                        items: [
                            { value: "$", label: "$" },
                            { value: "€", label: "Euro (€)" },
                            { value: "£", label: "British Pound (£)" },
                            { value: "¥", label: "Yen / Yuan (¥)" },
                            { value: "₹", label: "Indian Rupee (₹)" },
                            { value: "₽", label: "Russian Ruble (₽)" },
                            { value: "kr", label: "Krone (kr)" },
                            { value: "MX$", label: "Mexican Peso (MX$)" },
                            { value: "R$", label: "Brazilian Real (R$)" },
                            { value: "₩", label: "South Korean Won (₩)" },
                            { value: "₪", label: "Israeli New Shekel (₪)" },
                            { value: "₫", label: "Vietnamese Dong (₫)" },
                        ],
                        value: this.formatOptions.currency || MIXED_LABEL,
                        callback: value => {
                            this.formatOptions.currency = value;
                            this.saveFormatOptions(this.formatOptions);
                        }
                    }));
                }

                $formatOptions.addEl(controls.createDropdownMenu(this, {
                    label: "Decimals",
                    items: [{
                        value: "auto", label: "Auto"
                    }, {
                        value: 0, label: "0"
                    }, {
                        value: 1, label: "0.0"
                    }, {
                        value: 2, label: "0.00"
                    }, {
                        value: 3, label: "0.000"
                    }, {
                        value: 4, label: "0.0000"
                    }],
                    value: this.formatOptions.decimal ?? "auto",
                    callback: value => {
                        this.formatOptions.decimal = value;
                        this.saveFormatOptions(this.formatOptions);
                    }
                }));

                $formatOptions.addEl(controls.createToggle(this, {
                    label: "Thousands Separator",
                    toggled: this.formatOptions.separator,
                    callback: value => {
                        this.formatOptions.separator = value;
                        this.saveFormatOptions(this.formatOptions);
                        this.renderFormatOptions();
                    }
                }));

                $formatOptions.addEl(controls.createDropdownMenu(this, {
                    label: "Large Numbers",
                    items: [{
                        value: false, label: this.formatOptions.separator ? "100,000" : "100000"
                    }, {
                        value: "lower", label: "100k"
                    }, {
                        value: "upper", label: "100K"
                    }],
                    value: this.formatOptions.abbreviated ?? false,
                    callback: value => {
                        this.formatOptions.abbreviated = value;
                        this.saveFormatOptions(this.formatOptions);
                    }
                }));

                if (this.showTableFormatOptions) {
                    $formatOptions.append($.hr());
                    $formatOptions.addEl(controls.createToggle(this, {
                        label: "Accounting Style",
                        toggled: this.formatOptions.accountingStyle,
                        callback: value => {
                            this.formatOptions.accountingStyle = value;
                            this.formatOptions.changeStyle = CellChangeStyle.NONE;
                            this.saveFormatOptions(this.formatOptions);
                            this.renderFormatOptions();
                        }
                    }));

                    addChangeInValueControls($formatOptions);
                }
                break;
            case FormatType.DATE:
                $formatOptions.append($.hr());

                let dateFormats = [];
                for (let format of ACCEPTED_DATE_FORMATS) {
                    dateFormats.push({ label: moment().format(format), value: format });
                }
                $formatOptions.addEl(controls.createDropdownMenu(this, {
                    label: "Format",
                    items: dateFormats,
                    value: moment().format(this.formatOptions.dateFormat),
                    callback: value => {
                        this.formatOptions.dateFormat = value;
                        this.saveFormatOptions(this.formatOptions);
                    }
                }));

                break;
            case FormatType.PERCENT:
                $formatOptions.append($.hr());
                $formatOptions.addEl(controls.createDropdownMenu(this, {
                    label: "Decimals",
                    items: [{
                        value: "auto", label: "Auto"
                    }, {
                        value: 0, label: "0"
                    }, {
                        value: 1, label: "0.0"
                    }, {
                        value: 2, label: "0.00"
                    }, {
                        value: 3, label: "0.000"
                    }, {
                        value: 4, label: "0.0000"
                    }],
                    value: this.formatOptions.decimal ?? "auto",
                    callback: value => {
                        this.formatOptions.decimal = value;
                        this.saveFormatOptions(this.formatOptions);
                    }
                }));
                if (this.showTableFormatOptions) {
                    addChangeInValueControls($formatOptions);
                }
                break;

            case FormatType.ICON:
                $formatOptions.append($.hr());
                let $icons = $formatOptions.addEl($.div("table-icons-menu"));
                renderIconsMenu($icons, this.callback, this.closeMenu);
                break;
        }
    }

});

const renderIconsMenu = ($icons, callback, closeMenu) => {
    let loadIcon = ($menu, iconId) => {
        let $item = $menu.addEl($.div("icon"));
        $item.data("icon", iconId);

        ds.assets.getAssetById("icon-" + app.currentTheme.get("iconStyle") + "-" + iconId, "icon").then(icon => {
            const url = icon.get("original");
            // return fetch(url).then(res => res.text()).then(data => {
            //     let r = / d="(.*?)"/m;
            //     let pathString = r.exec(data)[1];
            //     let svg = SVG($item[0]);
            //     svg.path(pathString);
            //     svg.viewbox(0, 0, 128, 128);
            // });
            return fetch(url).then(res => res.text()).then(svgData => {
                $item.append(svgData);
            });
        }).catch(err => {
            logger.error(err, "[renderIconsMenu] failed to load icon", { iconId });
        });

        $item.on("click", () => {
            if (callback) {
                callback({
                    content_type: AssetType.ICON,
                    content_value: iconId
                });
            }
        });
    };

    let $checkIcons = $icons.addEl($.div("preset-icons checks"));

    let iconStyle = app.currentTheme.get("iconStyle");

    loadIcon($checkIcons, "check-yes");
    loadIcon($checkIcons, "x");
    loadIcon($checkIcons, "thumbs-up");
    loadIcon($checkIcons, "thumbs-down");
    loadIcon($checkIcons, "star");
    loadIcon($checkIcons, "star-half");
    loadIcon($checkIcons, "star-none");

    let $harveyIcons = $icons.addEl($.div("preset-icons harvey"));
    loadIcon($harveyIcons, "full");
    loadIcon($harveyIcons, "quarters-three");
    loadIcon($harveyIcons, "contrast");
    loadIcon($harveyIcons, "quarter");
    loadIcon($harveyIcons, "none");

    let $choose = $icons.addEl(controls.createButton(this, {
        label: "Choose Icon, Logo, or Image...",
        icon: "add_a_photo",
        callback: () => {
            closeMenu && closeMenu();
            ShowDialog(AddAssetsContainer, {
                assetType: AssetType.ICON,
                workspaceId: ds.selection.presentation.getWorkspaceId(),
                callback: callback,
            });
        }
    }));
};

export { FormatOptionsMenu, renderIconsMenu };
