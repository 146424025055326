import { app } from "js/namespaces.js";
import { controls } from "js/editor/ui";
import * as geom from "js/core/utilities/geom";
import { _, $ } from "js/vendor";
import { ds } from "js/core/models/dataService";
import { getStaticUrl } from "js/config";
import { ShowDialog, ShowDialogAsync } from "js/react/components/Dialogs/BaseDialog";
import BadFitDialog from "js/react/components/Dialogs/BadFitDialog";
import { AddAssetsContainer } from "js/react/views/AddAssets";

import { HeadlinePositionType } from "../../elements/elements/Headline";
import { ElementRollover, ElementSelection } from "../BaseElementEditor";

const HeadlineSelection = ElementSelection.extend({
    showSelectionBox: false,

    renderControls: function() {
        if (this.element.showImages) {
            this.addControl({
                type: controls.POPUP_BUTTON,
                label: "Layout",
                icon: "photo_camera",
                menuClass: "icon-menu fivecol",
                showArrow: true,
                items: [{
                    value: "center",
                    label: "Background",
                    image: getStaticUrl("/images/ui/trays/headline_background.png")
                }, {
                    value: "left", label: "Left", image: getStaticUrl("/images/ui/trays/headline_left.png")
                }, {
                    value: "right", label: "Right", image: getStaticUrl("/images/ui/trays/headline_right.png")
                }, {
                    value: "top", label: "Top", image: getStaticUrl("/images/ui/trays/headline_top.png")
                }, {
                    value: "bottom", label: "Bottom", image: getStaticUrl("/images/ui/trays/headline_bottom.png")
                }],
                callback: position => {
                    this.element.markStylesAsDirty();
                    this.element.model.headlinePosition = position;
                    this.element.canvas.updateCanvasModel(true);
                }
            });
        } else {
            this.addControl({
                type: controls.BUTTON,
                label: "Add Image",
                icon: "add_a_photo",
                callback: () => {
                    ShowDialog(AddAssetsContainer, {
                        workspaceId: ds.selection.presentation.getWorkspaceId(),
                        callback: model => {
                            this.element.model.items = [model];
                            this.element.model.headlinePosition = "center";
                            this.element.markStylesAsDirty();
                            this.element.canvas.updateCanvasModel(false).catch(err => {
                                ShowDialogAsync(BadFitDialog, {
                                    title: "Sorry, the content on your slide won't fit with an image",
                                });
                            });
                        },
                    });
                }
            });
        }
    },

});

const HeadlineRollover = ElementRollover.extend({
    renderControls: function() {
        if (app.isEditingText !== true && this.element.showImages && this.element.headlinePosition != HeadlinePositionType.CENTER) {
            this.renderResizer();
        }
    },

    renderResizer: function() {
        let headline = this.element;
        let headlineLayout = headline.headlinePosition;

        let $resizer = this.$widgets.addEl($.div("ui_widget"));
        let $control = $resizer.addEl($.div("tray_resizer"));

        let axis, constrainDrag;
        let headlineHeight = headline.bounds.height;
        let headlineWidth = headline.bounds.width;

        const scale = app.currentCanvas.getScale();
        let maxDividerWidth = headline.dividerMax * headlineWidth;
        let minDividerWidth = headline.dividerMin * headlineWidth;
        let maxDividerHeight = headline.dividerMax * headlineHeight;
        let minDividerHeight = headline.dividerMin * headlineHeight;

        let dragHandleWidth = 11;
        let dragHandleOffset = 0;

        switch (headlineLayout) {
            case HeadlinePositionType.LEFT:
                axis = "x";
                dragHandleOffset = -15;
                $resizer.left(headline.screenBounds.width * headline.dividerSize - dragHandleWidth + dragHandleOffset).top(headline.screenBounds.height / 2 - dragHandleWidth);
                $control.addClass("horizontal");
                constrainDrag = new geom.Rect(minDividerWidth + (dragHandleWidth * 2 - dragHandleOffset) / scale, 0, maxDividerWidth - minDividerWidth, headline.canvas.CANVAS_HEIGHT);
                break;
            case HeadlinePositionType.RIGHT:
                axis = "x";
                dragHandleOffset = 15;
                $resizer.left(headline.screenBounds.width - headline.screenBounds.width * headline.dividerSize - dragHandleWidth + dragHandleOffset).top(headline.screenBounds.height / 2 - dragHandleWidth);
                $control.addClass("horizontal");
                constrainDrag = new geom.Rect(headlineWidth + headline.bounds.left - maxDividerWidth + dragHandleWidth / scale, 0, maxDividerWidth - minDividerWidth - dragHandleOffset, headline.canvas.CANVAS_HEIGHT);
                break;
            case HeadlinePositionType.TOP:
                axis = "y";
                dragHandleOffset = -15;
                $resizer.top(headline.screenBounds.height * headline.dividerSize - dragHandleWidth + dragHandleOffset).left(headline.screenBounds.width / 2 - dragHandleWidth);
                $control.addClass("vertical");
                constrainDrag = new geom.Rect(0, minDividerHeight + (dragHandleWidth * 2 - dragHandleOffset) / scale, headline.canvas.CANVAS_WIDTH, maxDividerHeight - minDividerHeight);
                break;
            case HeadlinePositionType.BOTTOM:
                axis = "y";
                dragHandleOffset = 15;
                $resizer.top(headline.screenBounds.height - headline.screenBounds.height * headline.dividerSize - dragHandleWidth + dragHandleOffset).left(headline.screenBounds.width / 2 - dragHandleOffset);
                $control.addClass("vertical");
                constrainDrag = new geom.Rect(0, headlineHeight + headline.bounds.top - maxDividerHeight + dragHandleWidth / scale, headline.canvasBounds.width, maxDividerHeight - minDividerHeight - dragHandleOffset);
                break;
        }

        let refresh = _.throttle(value => {
            if (this.maxValue && value < this.maxValue) {
                return;
            }

            headline.model.dividerSize = value;
            this.element.canvas.refreshCanvas().then(() => {
                this.lastValue = value;
            }).catch(() => {
                headline.model.dividerSize = this.lastValue;
                this.maxValue = this.lastValue;
            });
        }, 50);

        $resizer.makeDraggable({
            axis: axis,
            constrainDrag: constrainDrag,
            start: event => {
                app.mainView.editorView.selectionLayer.hideWidgets($resizer);
            },
            drag: (event, position) => {
                let dividerSize;
                switch (headlineLayout) {
                    case HeadlinePositionType.LEFT:
                        dividerSize = (position.canvasPosition.x - headline.bounds.left + (dragHandleWidth - dragHandleOffset) / scale) / headline.bounds.width;
                        break;
                    case HeadlinePositionType.RIGHT:
                        dividerSize = 1 - ((position.canvasPosition.x - headline.bounds.left + (dragHandleWidth - dragHandleOffset) / scale) / headline.bounds.width);
                        break;
                    case HeadlinePositionType.TOP:
                        dividerSize = (position.canvasPosition.y - headline.bounds.top + (dragHandleWidth - dragHandleOffset) / scale) / headline.bounds.height;
                        break;
                    case HeadlinePositionType.BOTTOM:
                        dividerSize = 1 - ((position.canvasPosition.y - headline.bounds.top + (dragHandleWidth - dragHandleOffset) / scale) / headline.bounds.height);
                        break;
                }
                refresh(dividerSize);
            },
            stop: event => {
                event.stopPropagation();
                this.element.canvas.updateCanvasModel(false).then(() => {
                    app.mainView.editorView.selectionLayer.showWidgets($resizer);
                });
            }
        });
    }

});

// function HeadlineAddContentMenu(element, menu) {
//     let setHeadlinePosition = (position, value) => {
//         if (value) {
//             if (!element.model.items || element.model.items.length == 0) {
//                 if (element.tempImageModel) {
//                     element.model.items = element.tempImageModel;
//                 } else {
//                     element.model.items = [{}];
//                 }
//             }
//
//             element.model.headlinePosition = position;
//             if (element.model.items.length == 1 && !element.model.items[0].content_value) {
//                 ShowDialog(AddAssetsContainer, {
//                     assetType: AssetType.IMAGE,
//                     workspaceId: ds.selection.presentation.getWorkspaceId(),
//                     callback: model => {
//                         element.model.items = [model];
//
//                         element.canvas.updateCanvasModel(true).then(() => {
//                             menu.render();
//                         });
//                     },
//                 });
//             } else {
//                 element.canvas.updateCanvasModel(true).then(() => {
//                     menu.render();
//                 });
//             }
//         } else {
//             element.tempImageModel = _.cloneDeep(element.model.items);
//             element.model.items = null;
//             element.canvas.updateCanvasModel(true).then(() => {
//                 menu.render();
//             });
//         }
//     };
//
//     return [{
//         title: "Headline Position",
//         options: [{
//             icon: "headline_left",
//             label: "Left",
//             enabled: true,
//             selected: element.showImages == true && element.headlinePosition == HeadlinePositionType.LEFT,
//             callback: value => setHeadlinePosition(HeadlinePositionType.LEFT, value)
//         }, {
//             icon: "headline_right",
//             label: "Right",
//             enabled: true,
//             selected: element.showImages == true && element.headlinePosition == HeadlinePositionType.RIGHT,
//             callback: value => setHeadlinePosition(HeadlinePositionType.RIGHT, value)
//         }, {
//             icon: "headline_top",
//             label: "Top",
//             enabled: true,
//             selected: element.showImages == true && element.headlinePosition == HeadlinePositionType.TOP,
//             callback: value => setHeadlinePosition(HeadlinePositionType.TOP, value)
//         }, {
//             icon: "headline_bottom",
//             label: "Bottom",
//             enabled: true,
//             selected: element.showImages == true && element.headlinePosition == HeadlinePositionType.BOTTOM,
//             callback: value => setHeadlinePosition(HeadlinePositionType.BOTTOM, value)
//         }, {
//             icon: "headline_background",
//             label: "Background",
//             enabled: true,
//             selected: element.showImages == true && element.headlinePosition == HeadlinePositionType.CENTER,
//             callback: value => setHeadlinePosition(HeadlinePositionType.CENTER, value)
//         }]
//     }];
// }

export const editors = {
    HeadlineSelection,
    HeadlineRollover,
    // HeadlineImagesSelection,
    // HeadlineAddContentMenu,
};
