import React from "react";
import { ds } from "js/core/models/dataService";
import { _ } from "js/vendor";
import { app } from "js/namespaces";
import * as geom from "js/core/utilities/geom";
import { SVGGroup } from "js/core/utilities/svgHelpers";
import { AuthoringBlockType, HorizontalAlignType, VerticalAlignType, AuthoringShapeDirection, AuthoringShapeType, AssetType } from "common/constants";
import { RadiansToDegrees } from "js/core/utilities/geom";
import { FlexBox } from "js/react/components/LayoutGrid";
import { polarToCartesian, Shape } from "js/core/utilities/shapes";
import { sanitizeHtml, sanitizeSvg } from "js/core/utilities/dompurify";

import { BaseElement } from "../../base/BaseElement";
import {
    AuthoringBlockContainer,
    calculateBlockContainerSize
} from "./AuthoringBlockContainer";
import { GetTextBlockStyle } from "./AuthoringBlocks/TextBlock";

export class AuthoringShapeElement extends BaseElement {
    static get schema() {
        return {
            fill: "background_accent",
            stroke: "none",
            strokeWidth: 1,
            strokeStyle: "solid",
            textAlign: HorizontalAlignType.CENTER,
            verticalAlign: VerticalAlignType.MIDDLE,
            blocks: [],
            blockGap: 0,
            textInset: 20,
            fitToText: false,
            opacity: 100,
            shape: "rect"
        };
    }

    get exportAsImage() {
        if (this.shape === AuthoringShapeType.RAW_SVG) {
            // Quick and dirty check for unsupported svg elements
            return this.renderedSvgHTML.includes("<textPath href=") || this.renderedSvgHTML.includes("<clipPath id=");
        }

        return false;
    }

    get isAuthoringElement() {
        return true;
    }

    get _canSelect() {
        return true;
    }

    get isTextBox() {
        return this.model.fill == "none" && (this.model.stroke == "none" || this.model.strokeWidth == 0);
    }

    get shape() {
        return this.model.shape;
    }

    get fitToContents() {
        return {
            height: !!this.model.fitToText && this.hasText,
            width: !!this.model.fitToText && this.hasText
        };
    }

    get restrictResize() {
        return {
            width: false,
            height: !!this.model.fitToText && this.hasText
        };
    }

    // preserve aspect ratio by default when resizing shape. can be overridden by holding down mod key while resizing
    // unless lockAspectRatio = true
    get preserveAspectRatio() {
        switch (this.model.shape) {
            case AuthoringShapeType.ELLIPSE:
            case AuthoringShapeType.STAR:
            case AuthoringShapeType.POLYGON:
                return true;
        }
    }

    // prevent use override of aspect ratio when resizing using mod key. only makes sense if preserveAspectRatio = true
    get lockAspectRatio() {
        switch (this.model.shape) {
            case AuthoringShapeType.STAR:
            case AuthoringShapeType.POLYGON:
                return true;
        }
    }

    get canChangeDirection() {
        switch (this.model.shape) {
            case AuthoringShapeType.ARROW:
            case AuthoringShapeType.CHEVRON:
            case AuthoringShapeType.CHEVRON_START:
                return true;
        }

        return false;
    }

    get canChangeFill() {
        const { shape, canChangeFill } = this.model;
        if (shape === AuthoringShapeType.RAW_SVG && !canChangeFill) {
            return false;
        }

        return true;
    }

    get canChangeStroke() {
        const { shape, canChangeStroke } = this.model;
        if (shape === AuthoringShapeType.RAW_SVG && !canChangeStroke) {
            return false;
        }

        return true;
    }

    get direction() {
        return _.defaultTo(this.model.direction, AuthoringShapeDirection.RIGHT);
    }

    get adj1() {
        switch (this.shape) {
            case AuthoringShapeType.RECT:
                return _.defaultTo(this.model.adj1, 0);
            case AuthoringShapeType.CHEVRON:
            case AuthoringShapeType.CHEVRON_START:
                switch (this.direction) {
                    case AuthoringShapeDirection.LEFT:
                    case AuthoringShapeDirection.RIGHT:
                        return Math.clamp(0, _.defaultTo(this.model.adj1, 0.25), this.bounds.width / this.bounds.height);
                    case AuthoringShapeDirection.UP:
                    case AuthoringShapeDirection.DOWN:
                        return Math.clamp(0, _.defaultTo(this.model.adj1, 0.25), this.bounds.height / this.bounds.width);
                }
            case AuthoringShapeType.STAR:
                return _.defaultTo(this.model.adj1, 0.2);
            case AuthoringShapeType.POLYGON:
                return _.defaultTo(this.model.adj1, 5);
            case AuthoringShapeType.CALLOUT_BUBBLE:
                return _.defaultTo(this.model.adj1, { x: 100, y: 100 });
            case AuthoringShapeType.ARROW:
                return _.defaultTo(this.model.adj1, 0.5);
            default:
                return 0;
        }
    }

    get adj2() {
        switch (this.shape) {
            case AuthoringShapeType.STAR:
                return _.defaultTo(this.model.adj2, 5);
            case AuthoringShapeType.CALLOUT_BUBBLE:
                return _.defaultTo(this.model.adj2, 30);
            case AuthoringShapeType.ARROW:
                switch (this.direction) {
                    case AuthoringShapeDirection.LEFT:
                    case AuthoringShapeDirection.RIGHT:
                        return Math.clamp(0, _.defaultTo(this.model.adj2, 0.5), this.bounds.width);
                    case AuthoringShapeDirection.UP:
                    case AuthoringShapeDirection.DOWN:
                        return Math.clamp(0, _.defaultTo(this.model.adj2, 0.5), this.bounds.height);
                }
            default:
                return 0;
        }
    }

    static getFontFamilies(model) {
        const fontFamilies = new Set();
        if (model.blocks) {
            model.blocks.forEach(blockModel => {
                if (blockModel.fontFamily) {
                    fontFamilies.add(blockModel.fontFamily);
                } else {
                    fontFamilies.add(GetTextBlockStyle(blockModel).fontFamily);
                }

                if (blockModel.html) {
                    const domparser = new DOMParser();
                    const document = domparser.parseFromString(blockModel.html, "text/html");
                    document.querySelectorAll("*").forEach(element => {
                        if (element.style) {
                            const fontFamily = element.style.getPropertyValue("font-family");
                            if (fontFamily) {
                                fontFamilies.add(fontFamily);
                            }
                        }
                    });
                }
            });
        }
        return fontFamilies;
    }

    static updateFontFamilies(model, fontsMap) {
        if (!model.blocks) {
            return;
        }

        model.blocks.forEach(blockModel => {
            if (blockModel.fontFamily) {
                const fontMap = fontsMap[blockModel.fontFamily];
                if (fontMap) {
                    blockModel.fontFamily = fontMap.fontId;
                    blockModel.fontWeight = fontMap.fontWeight;
                } else {
                    delete blockModel.fontFamily;
                }
            }

            if (blockModel.html) {
                const domparser = new DOMParser();
                const document = domparser.parseFromString(blockModel.html, "text/html");
                document.querySelectorAll("*").forEach(element => {
                    if (element.style) {
                        const fontFamily = element.style.getPropertyValue("font-family");
                        if (fontFamily) {
                            const fontMap = fontsMap[fontFamily];
                            if (fontMap) {
                                element.style.setProperty("font-family", fontMap.fontId);
                                element.style.setProperty("font-weight", fontMap.fontWeight);
                            } else {
                                element.style.removeProperty("font-family");
                            }
                        }
                    }
                });
                blockModel.html = sanitizeHtml(document.body.innerHTML);
            }
        });

        return model;
    }

    get usedFontIds() {
        const fontFamilies = AuthoringShapeElement.getFontFamilies(this.model);
        return Array.from(new Set([...super.usedFontIds, ...fontFamilies]));
    }

    get blockContainer() {
        return this.blockContainerRef.current;
    }

    getTextStyles() {
        const styles = {
            fontFamily: app.currentTheme.get("fontHeaderRegular"),
            fontSize: this.model.fontSize,
            color: this.model.color,
            textAlign: this.model.textAlign,
            letterSpacing: this.model.letterSpacing + "px",
            lineHeight: this.model.lineHeight ? (parseInt(this.model.lineHeight) + "%") : "normal",
            width: "100%",
            hyphens: "manual",
            overflowWrap: "normal"
        };

        if (this.backgroundColor.isDark()) {
            styles.color = "rgb(255, 255, 255)";
        } else {
            styles.color = "rgb(0, 0, 0)";
        }

        return styles;
    }

    get backgroundColor() {
        if (this.model.fill) {
            const styles = this.getShapeStyles();
            return styles.fill;
        } else {
            return this.canvas.getTheme().palette.getColor(this.canvas.getBackgroundColor());
        }
    }

    get textInset() {
        // Can only be set upon ppt import
        if (this.model.textPadding) {
            const { left, right, top, bottom } = this.model.textPadding;
            return Math.round(Math.max((left + right) / 2, (top + bottom) / 2));
        }

        return _.defaultTo(this.model.textInset, 30);
    }

    get textPadding() {
        if (this.model.textPadding) {
            return this.model.textPadding;
        }

        return { left: this.textInset, right: this.textInset, top: this.textInset, bottom: this.textInset };
    }

    get blockGap() {
        return _.defaultTo(this.model.blockGap, 30);
    }

    get fill() {
        return this.getShapeStyles().fill;
    }

    get stroke() {
        return this.getShapeStyles().stroke;
    }

    get hasText() {
        return this.model.blocks.length > 0;
    }

    getShapeStyles() {
        const styles = {
            fill: this.model.fill,
            stroke: this.model.stroke,
            strokeWidth: this.model.strokeWidth,
            opacity: _.defaultTo(this.model.opacity, 100)
        };

        if (styles.fill && !this.canvas.getTheme().palette.isRawColor(styles.fill)) {
            if (styles.fill === "auto") {
                styles.fill = this.getSlideColor().toRgbString();
            } else {
                styles.fill = this.canvas.getTheme().palette.getColor(styles.fill).toRgbString();
            }
        }

        if (styles.stroke && !this.canvas.getTheme().palette.isRawColor(styles.stroke)) {
            if (styles.stroke === "auto") {
                styles.stroke = this.getSlideColor().toRgbString();
            } else {
                styles.stroke = this.canvas.getTheme().palette.getColor(styles.stroke).toRgbString();
            }
        }

        switch (this.model.strokeStyle) {
            case "dashed":
                styles.strokeDasharray = `${this.model.strokeWidth * 6}px ${this.model.strokeWidth * 4}px`;
                break;
            case "dotted":
                styles.strokeDasharray = `${this.model.strokeWidth}px ${Math.max(this.model.strokeWidth, 4)}px`;
                break;
            default:
                styles.strokeDasharray = null;
        }

        return styles;
    }

    get isShapeVisible() {
        return this.model.fill != "none" || (this.model.stroke != "none" && this.model.strokeWidth > 0);
    }

    get canRefreshElement() {
        return true;
    }

    get isRawSvg() {
        return this.shape === AuthoringShapeType.RAW_SVG;
    }

    refreshElement(transition) {
        this.canvas.refreshElement(this, transition, true);
    }

    setupElement() {
        this.svgRef = React.createRef();
        this.blockContainerRef = React.createRef();
    }

    async _load() {
        if (this.isRawSvg) {
            if (this.svgHTML) {
                return;
            }

            const { svgAssetId, svgHTML } = this.model;

            if (svgHTML) {
                this.svgHTML = svgHTML;
                return;
            }

            const svgAsset = await ds.assets.getAssetById(svgAssetId, AssetType.SVG);
            this.svgHTML = await svgAsset.getSvg();
        }
    }

    _build() {
        for (const block of this.model.blocks) {
            if (!block.type) {
                block.type = AuthoringBlockType.TEXT;
            }
        }
    }

    _calcProps(props, options) {
        const { size } = props;

        const propsForCalc = {
            model: _.cloneDeep(this.model),
            size: size.toArray()
        };

        if (this.lastPropsForCalc && _.isEqual(this.lastPropsForCalc, propsForCalc)) {
            return {
                size: this.lastSize,
                blockScale: this.lastBlockScale,
                textPadding: this.lastTextPadding
            };
        }

        this.lastPropsForCalc = propsForCalc;

        if (this.model.fitToText && this.hasText) {
            const textPadding = { ...this.textPadding };
            const availableTextSize = size.deflate(textPadding);
            const textSize = calculateBlockContainerSize(this.model, availableTextSize.width, 1, this.canvas);

            this.lastSize = textSize.inflate(textPadding);
            this.lastBlockScale = 1;
            this.lastTextPadding = textPadding;
        } else {
            let innerSize;
            let blockScale = 1;
            const minScale = 0.1;
            let blocksSize;
            let textPadding;
            do {
                textPadding = { left: this.textPadding.left * blockScale, right: this.textPadding.right * blockScale, top: this.textPadding.top * blockScale, bottom: this.textPadding.bottom * blockScale };
                innerSize = size.deflate(textPadding);
                blocksSize = calculateBlockContainerSize(this.model, innerSize.width, blockScale, this.canvas);
                blockScale -= 0.05;
            } while (blockScale > minScale && (blocksSize.width > (innerSize.width + 1) || blocksSize.height > (innerSize.height + 1)));
            blockScale += 0.05;

            this.lastSize = size;
            this.lastBlockScale = blockScale;

            // Adjusting padding to make sure padding + block size < available size
            const horizontalScale = (textPadding.left + textPadding.right + blocksSize.width) / size.width;
            if (horizontalScale > 1) {
                textPadding.left /= horizontalScale;
                textPadding.right /= horizontalScale;
            }
            const verticalScale = (textPadding.top + textPadding.bottom + blocksSize.height) / size.height;
            if (verticalScale > 1) {
                textPadding.top /= verticalScale;
                textPadding.bottom /= verticalScale;
            }

            this.lastTextPadding = textPadding;
        }

        return {
            size: this.lastSize,
            blockScale: this.lastBlockScale,
            textPadding: this.lastTextPadding
        };
    }

    renderChildren(transition) {
        const { textPadding, blockScale } = this.calculatedProps;

        return [
            this.renderSVG(this.calculatedProps),
            <FlexBox
                key="block-container"
                fill
                verticalAlign={this.model.verticalAlign}
                style={{
                    position: "absolute",
                    paddingLeft: textPadding.left,
                    paddingRight: textPadding.right,
                    paddingTop: textPadding.top,
                    paddingBottom: textPadding.bottom
                }}
            >
                <AuthoringBlockContainer
                    ref={this.blockContainerRef}
                    model={this.model}
                    gap={this.blockGap}
                    scale={blockScale}
                    canvas={this.canvas}
                />
            </FlexBox>
        ];
    }

    renderSVG(props) {
        const { bounds } = props;

        return (
            <SVGGroup ref={this.svgRef} key={this.id}>
                {this.renderShape(this.shape, this.getShapeStyles(), bounds)}
            </SVGGroup>
        );
    }

    renderShape(shape, styles, bounds) {
        switch (shape) {
            case AuthoringShapeType.RECT:
                return (
                    <rect
                        x={bounds.left}
                        y={bounds.top}
                        width={bounds.width}
                        height={bounds.height}
                        rx={this.adj1}
                        style={styles}
                    />
                );

            case AuthoringShapeType.ELLIPSE:
                return (
                    <ellipse
                        rx={bounds.width / 2}
                        ry={bounds.height / 2}
                        cx={bounds.centerH}
                        cy={bounds.centerV}
                        style={styles}
                    />
                );

            case AuthoringShapeType.CHEVRON:
            case AuthoringShapeType.CHEVRON_START:
                let offset;
                switch (this.direction) {
                    case AuthoringShapeDirection.RIGHT:
                    case AuthoringShapeDirection.LEFT:
                        offset = this.adj1 * bounds.height;
                        break;
                    case AuthoringShapeDirection.UP:
                    case AuthoringShapeDirection.DOWN:
                        offset = this.adj1 * bounds.width;
                        break;
                }

                return (
                    <path
                        d={Shape.drawChevron(bounds, offset, shape === AuthoringShapeType.CHEVRON_START, this.direction).toPathData()}
                        style={styles}
                    />
                );
            case AuthoringShapeType.STAR:
                return (
                    <path
                        d={Shape.drawStar(bounds, this.adj1 * bounds.width, this.adj2)}
                        style={styles}
                    />
                );

            case AuthoringShapeType.POLYGON:
                return (
                    <path
                        d={Shape.drawPolygon(bounds, this.adj1).toPathData()}
                        style={styles}
                    />
                );

            case AuthoringShapeType.DIAMOND:
                return (
                    <path
                        d={Shape.drawDiamond(bounds).toPathData()}
                        style={styles}
                    />
                );

            case AuthoringShapeType.CALLOUT_BUBBLE:
                return (
                    <path
                        d={Shape.drawCalloutBubble(bounds, this.adj1, this.adj2).toPathData()}
                        style={styles}
                    />
                );

            case AuthoringShapeType.ARROW:
                let stemWidth;
                let arrowHeadLength;
                switch (this.direction) {
                    case AuthoringShapeDirection.RIGHT:
                    case AuthoringShapeDirection.LEFT:
                        stemWidth = this.adj1 * bounds.height;
                        arrowHeadLength = Math.min(this.adj2 * bounds.height, bounds.width);
                        break;
                    case AuthoringShapeDirection.UP:
                    case AuthoringShapeDirection.DOWN:
                        stemWidth = this.adj1 * bounds.width;
                        arrowHeadLength = Math.min(this.adj2 * bounds.width, bounds.height);
                        break;
                }

                return (
                    <path
                        d={Shape.drawArrow2(bounds, stemWidth, arrowHeadLength, this.direction).toPathData()}
                        style={styles}
                    />
                );

            case AuthoringShapeType.RAW_SVG:
                const { viewBox, strokeWidthCanvasScale } = this.model;

                // Props that affect how the svg is rendered
                const renderedSvgHTMLProps = {
                    fill: styles.fill,
                    stroke: styles.stroke,
                    strokeWidth: styles.strokeWidth,
                    strokeDasharray: styles.strokeDasharray
                };

                // Checking for cache
                if (!_.isEqual(this.renderedSvgHTMLProps, renderedSvgHTMLProps)) {
                    this.renderedSvgHTMLProps = renderedSvgHTMLProps;

                    if (styles.fill || styles.stroke) {
                        const svgNodeDocument = new DOMParser().parseFromString(
                            `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">${this.svgHTML}</svg>`,
                            "image/svg+xml"
                        );
                        const svgNode = svgNodeDocument.firstChild;

                        const setNodeStyles = node => {
                            if (styles.fill && node.getAttribute("fill") === "@fill") {
                                node.setAttribute("fill", styles.fill);
                            }
                            if (styles.stroke && node.getAttribute("stroke") === "@stroke") {
                                node.setAttribute("stroke", styles.stroke);

                                const scaledStrokeWidth = styles.strokeWidth * (strokeWidthCanvasScale ?? 1);
                                node.setAttribute("stroke-width", scaledStrokeWidth);

                                if (styles.strokeDasharray) {
                                    node.setAttribute("stroke-dasharray", styles.strokeDasharray);
                                }
                            }

                            Array.from(node.children)
                                .forEach(child => setNodeStyles(child));
                        };

                        setNodeStyles(svgNode);

                        this.renderedSvgHTML = sanitizeSvg(svgNode.innerHTML);
                    } else {
                        this.renderedSvgHTML = sanitizeSvg(this.svgHTML);
                    }
                }

                const aspectRatio = this.model.preserveAspectRatio ? "xMidYMid meet" : "none";

                return (
                    <SVGGroup key={this.id} ref={this.ref}>
                        <svg
                            width={"100%"}
                            height={"100%"}
                            style={{
                                overflow: "visible",
                                position: "absolute",
                                left: "0",
                                top: "0"
                            }}
                            preserveAspectRatio={aspectRatio}
                            viewBox={viewBox ? `${viewBox.x} ${viewBox.y} ${viewBox.width} ${viewBox.height}` : null}
                            dangerouslySetInnerHTML={{ __html: sanitizeSvg(this.renderedSvgHTML) }}
                        />
                    </SVGGroup>
                );
        }
    }

    containsPoint(pt, isSelected) {
        let svg = this.svgRef.current.ref.current;

        let svgPoint = svg.createSVGPoint();
        svgPoint.x = pt.x - this.canvasBounds.left;
        svgPoint.y = pt.y - this.canvasBounds.top;

        let shapes = svg.querySelectorAll("path, rect, circle");
        if (shapes.length == 1) {
            let shape = shapes[0];

            // check what's available for hit detection
            let [hasFill, hasStroke] = ["fill", "stroke"].map(prop =>
                (!!shape.getAttribute(prop) && shape.getAttribute(prop) !== "none") ||
                (!!shape.style[prop] && shape.style[prop] !== "none")
            );

            // if the shape is selected, clicking and dragging within it's bounds should return true
            // also, make it easier to select if the shape has no fill but has text
            if (isSelected || this.model.blocks?.length) {
                hasFill = true;
            }

            shape.setAttribute("transform", `rotate(45 ${shape.getBoundingClientRect().width / 2} ${shape.getBoundingClientRect().height / 2})`);

            // check for fills first, then strokes, but fall back to
            // fill for a completely transparent object
            let containsPoint = hasFill ? shape.isPointInFill(svgPoint)
                : hasStroke ? shape.isPointInStroke(svgPoint)
                    : shape.isPointInFill(svgPoint);

            shape.setAttribute("transform", "");
            return containsPoint;
        } else {
            return this.canvasBounds.contains(pt);
        }
    }

    getAdjustmentHandles() {
        if (!this.isShapeVisible) return;

        const bounds = this.calculatedProps.bounds;

        switch (this.shape) {
            case AuthoringShapeType.RECT:
                return [{
                    getPosition: () => {
                        return new geom.Point(this.adj1, 0).offset(10, 10);
                    },
                    setPosition: (x, y) => {
                        this.model.adj1 = Math.clamp(x - 10, 0, bounds.width / 2);
                    }
                }];
            case AuthoringShapeType.CHEVRON:
            case AuthoringShapeType.CHEVRON_START:
                switch (this.direction) {
                    case AuthoringShapeDirection.RIGHT:
                        return [{
                            getPosition: () => {
                                return new geom.Point((bounds.width - this.adj1 * bounds.height), 0);
                            },
                            setPosition: (x, y) => {
                                this.model.adj1 = Math.clamp(0, (bounds.width - x) / bounds.height, bounds.width / bounds.height);
                            }
                        }];
                    case AuthoringShapeDirection.LEFT:
                        return [{
                            getPosition: () => {
                                return new geom.Point(this.adj1 * bounds.height, 0);
                            },
                            setPosition: (x, y) => {
                                this.model.adj1 = Math.clamp(0, x / bounds.height, bounds.width / bounds.height);
                            }
                        }];
                    case AuthoringShapeDirection.UP:
                        return [{
                            getPosition: () => {
                                return new geom.Point(0, this.adj1 * bounds.width);
                            },
                            setPosition: (x, y) => {
                                this.model.adj1 = Math.clamp(0, y / bounds.width, bounds.height / bounds.width);
                            }
                        }];
                    case AuthoringShapeDirection.DOWN:
                        return [{
                            getPosition: () => {
                                return new geom.Point(0, bounds.height - this.adj1 * bounds.width);
                            },
                            setPosition: (x, y) => {
                                this.model.adj1 = Math.clamp(0, (bounds.height - y) / bounds.width, bounds.height / bounds.width);
                            }
                        }];
                }
            case AuthoringShapeType.STAR:
                return [{
                    getPosition: () => {
                        const centerX = bounds.width / 2;
                        const centerY = bounds.height / 2;
                        const outerRadius = bounds.size.square().width / 2;
                        const innerRadius = outerRadius - this.adj1 * bounds.width;
                        const points = this.adj2;
                        const degrees = 360 / (points * 2);

                        return polarToCartesian(centerX, centerY, innerRadius, degrees);
                    },
                    setPosition: (x, y) => {
                        const outerRadius = bounds.size.square().width / 2;
                        const radius = outerRadius - new geom.Line(this.calculatedProps.bounds.center, new geom.Point(x, y)).length;

                        this.model.adj1 = Math.clamp(radius, 0, bounds.height / 2) / bounds.width;
                    }
                }, {
                    getPosition: () => {
                        const centerX = bounds.width / 2;
                        const centerY = bounds.height / 2;
                        const outerRadius = bounds.size.square().width / 2;
                        const points = this.adj2;
                        const degrees = 360 / 17 * (points - 3);

                        return polarToCartesian(centerX, centerY, outerRadius, degrees);
                    },
                    setPosition: (x, y) => {
                        let angle = RadiansToDegrees(new geom.Line(bounds.center, new geom.Point(x, y)).angle) + 90;
                        if (angle < 0) {
                            angle = 360 + angle;
                        }
                        this.model.adj2 = Math.floor(angle / (360 / 17)) + 3;
                    }

                }];
            case AuthoringShapeType.POLYGON:
                return [{
                    getPosition: () => {
                        const centerX = bounds.width / 2;
                        const centerY = bounds.height / 2;
                        const outerRadius = bounds.size.square().width / 2;
                        const sides = this.adj1;
                        const degrees = 360 / 8 * (sides - 3);

                        return polarToCartesian(centerX, centerY, outerRadius, degrees);
                    },
                    setPosition: (x, y) => {
                        let angle = RadiansToDegrees(new geom.Line(bounds.center, new geom.Point(x, y)).angle) + 90;
                        if (angle < 0) {
                            angle = 360 + angle;
                        }
                        this.model.adj1 = Math.floor(angle / (360 / 8)) + 3;
                    }
                }];

            case AuthoringShapeType.CALLOUT_BUBBLE:
                return [{
                    getPosition: () => {
                        return this.adj1;
                    },
                    setPosition: (x, y) => {
                        this.model.adj1 = { x, y };
                    }
                }];

            case AuthoringShapeType.ARROW:
                switch (this.direction) {
                    case AuthoringShapeDirection.RIGHT:
                        return [{
                            getPosition: () => {
                                const x = bounds.width - this.adj2 * bounds.height;
                                const y = (bounds.height - this.adj1 * bounds.height) / 2;
                                return new geom.Point(x, y);
                            },
                            setPosition: (x, y) => {
                                this.model.adj2 = (bounds.width - Math.clamp(x, 0, bounds.width)) / bounds.height;
                                this.model.adj1 = Math.clamp(0, (bounds.height - 2 * y) / bounds.height, 1);
                            }
                        }];
                    case AuthoringShapeDirection.LEFT:
                        return [{
                            getPosition: () => {
                                const x = this.adj2 * bounds.height;
                                const y = (bounds.height - this.adj1 * bounds.height) / 2;
                                return new geom.Point(x, y);
                            },
                            setPosition: (x, y) => {
                                this.model.adj2 = Math.clamp(x, 0, bounds.width) / bounds.height;
                                this.model.adj1 = Math.clamp(0, (bounds.height - 2 * y) / bounds.height, 1);
                            }
                        }];
                    case AuthoringShapeDirection.UP:
                        return [{
                            getPosition: () => {
                                const x = (bounds.width - this.adj1 * bounds.width) / 2;
                                const y = this.adj2 * bounds.width;
                                return new geom.Point(x, y);
                            },
                            setPosition: (x, y) => {
                                this.model.adj1 = Math.clamp(0, (bounds.width - 2 * x) / bounds.width, 1);
                                this.model.adj2 = Math.clamp(y, 0, bounds.height) / bounds.width;
                            }
                        }];
                    case AuthoringShapeDirection.DOWN:
                        return [{
                            getPosition: () => {
                                const x = (bounds.width - this.adj1 * bounds.width) / 2;
                                const y = bounds.height - this.adj2 * bounds.width;
                                return new geom.Point(x, y);
                            },
                            setPosition: (x, y) => {
                                this.model.adj1 = Math.clamp(0, (bounds.width - 2 * x) / bounds.width, 1);
                                this.model.adj2 = (bounds.height - Math.clamp(y, 0, bounds.height)) / bounds.width;
                            }
                        }];
                }

            case AuthoringShapeType.PLUS:
                return [];
        }
    }
}
