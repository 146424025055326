import { _ } from "js/vendor";
import * as geom from "js/core/utilities/geom";

import { ElementLayouter } from "./ElementLayouter";
import { layoutHelper } from "./LayoutHelper";

export function CalcTextBoxGrid(count, size, styles, options = {}) {
    styles = _.defaults({}, styles, {
        hGap: 10,
        vGap: 10,
    });

    let grid = [],
        width,
        height;

    let bounds = new geom.Rect(0, 0, size);

    let hGap = styles.hGap;
    let vGap = styles.vGap;
    let flip = options.flipOrientation || false;

    switch (count) {
        case 1:
            grid.push(bounds.clone());
            break;
        case 2:
            if (flip) {
                height = (bounds.height - vGap) / 2;
                grid.push(new geom.Rect(bounds.left, bounds.top, bounds.width, height));
                grid.push(new geom.Rect(bounds.left, bounds.bottom - height, bounds.width, height));
            } else {
                width = (bounds.width - hGap) / 2;
                grid.push(new geom.Rect(bounds.left, bounds.top, width, bounds.height));
                grid.push(new geom.Rect(bounds.right - width, bounds.top, width, bounds.height));
            }
            break;
        case 3:
            if (flip) {
                height = (bounds.height - vGap * 2) / 3;
                grid.push(new geom.Rect(bounds.left, bounds.top, bounds.width, height));
                grid.push(new geom.Rect(bounds.left, bounds.top + height + vGap, bounds.width, height));
                grid.push(new geom.Rect(bounds.left, bounds.bottom - height, bounds.width, height));
            } else {
                width = (bounds.width - hGap * 2) / 3;
                grid.push(new geom.Rect(bounds.left, bounds.top, width, bounds.height));
                grid.push(new geom.Rect(bounds.left + width + hGap, bounds.top, width, bounds.height));
                grid.push(new geom.Rect(bounds.right - width, bounds.top, width, bounds.height));
            }
            break;
        case 4:
            width = (bounds.width - hGap) / 2;
            height = (bounds.height - vGap) / 2;
            grid.push(new geom.Rect(bounds.left, bounds.top, width, height));
            grid.push(new geom.Rect(bounds.centerH + hGap / 2, bounds.top, width, height));
            grid.push(new geom.Rect(bounds.left, bounds.centerV + vGap / 2, width, height));
            grid.push(new geom.Rect(bounds.centerH + hGap / 2, bounds.centerV + vGap / 2, width, height));
            break;
        case 5:
            if (flip) {
                width = (bounds.width - hGap) / 2;
                height = (bounds.height - vGap) / 2;
                grid.push(new geom.Rect(bounds.left, bounds.top, width, height));
                grid.push(new geom.Rect(bounds.left + width + hGap, bounds.top, width, height));
                width = (bounds.width - hGap * 2) / 3;
                grid.push(new geom.Rect(bounds.left, bounds.top + height + vGap, width, height));
                grid.push(new geom.Rect(bounds.left + width + hGap, bounds.top + height + vGap, width, height));
                grid.push(new geom.Rect(bounds.right - width, bounds.top + height + vGap, width, height));
            } else {
                width = (bounds.width - hGap * 2) / 3;
                height = (bounds.height - vGap) / 2;
                grid.push(new geom.Rect(bounds.left, bounds.top, width, height));
                grid.push(new geom.Rect(bounds.left + width + hGap, bounds.top, width, height));
                grid.push(new geom.Rect(bounds.right - width, bounds.top, width, height));
                width = (bounds.width - hGap) / 2;
                grid.push(new geom.Rect(bounds.left, bounds.centerV + vGap / 2, width, height));
                grid.push(new geom.Rect(bounds.centerH + hGap / 2, bounds.centerV + vGap / 2, width, height));
            }
            break;

        case 6:
        default:
            width = (bounds.width - hGap * 2) / 3;
            height = (bounds.height - vGap) / 2;
            grid.push(new geom.Rect(bounds.left, bounds.top, width, height));
            grid.push(new geom.Rect(bounds.left + width + hGap, bounds.top, width, height));
            grid.push(new geom.Rect(bounds.right - width, bounds.top, width, height));
            grid.push(new geom.Rect(bounds.left, bounds.top + height + vGap, width, height));
            grid.push(new geom.Rect(bounds.left + width + hGap, bounds.top + height + vGap, width, height));
            grid.push(new geom.Rect(bounds.right - width, bounds.top + height + vGap, width, height));
            break;
    }

    return grid;
}

ElementLayouter.prototype.calcTextBoxGridLayout = function(options = {}) {
    let styles = _.defaults({}, this.styles, {
        hGap: 10,
        vGap: 10,
        minItemWidth: 100,
        minItemHeight: 300
    });

    if (this.items.length > styles.maxItemCount) {
        this.isFit = false;
        this.size = this.containerSize;
        return this;
    }

    let grid = CalcTextBoxGrid(this.items.length, this.containerSize, styles, options);

    this.isFit = true;
    this.items.forEach((item, i) => {
        // check if each item fits
        if (i < grid.length) {
            item.calcProps(grid[i].size, { ...options.itemOptions, autoHeight: true });
        }
    });

    let itemHeight;
    if (this.items.length < 4) {
        itemHeight = layoutHelper.getMaxHeightOfItems(this.items);
    } else {
        itemHeight = grid[0].height;
    }

    this.items.forEach((item, i) => {
        let itemProps = item.calculatedProps;
        let cell = grid[i];
        let itemWidth = Math.min(cell.width, itemProps.size.width);
        if (itemWidth < styles.minItemWidth) {
            this.isFit = false;
        }
        if (itemProps.size.height !== itemHeight) {
            itemProps = item.calcProps(new geom.Size(itemProps.size.width, itemHeight), { ...options.itemOptions, autoHeight: false });
        }
        itemProps.bounds = new geom.Rect(cell.centerH - itemWidth / 2, cell.centerV - itemHeight / 2, itemWidth, itemHeight);
    });

    this.size = this.containerSize;

    return this;
};

