import { ds } from "js/core/models/dataService";
import { controls } from "js/editor/ui";
import { TextEditorEvent } from "common/constants";
import { _ } from "js/vendor";
import { ShowWarningDialog } from "js/react/components/Dialogs/BaseDialog";

import { TextElementSelection } from "./textEditor";
import { ElementRollover, ElementSelection } from "../BaseElementEditor";

const DisplayTextRollover = ElementRollover.extend({
    showSelectionBox: false,

    getOffset: function() {
        if (this.element.canShowDescription && !this.element.showDescription) {
            return 0;
        } else {
            return 0;
        }
    },
});

const DisplayTextSelection = ElementSelection.extend({
    showSelectionBox: false,

    getOffset() {
        return 10;
    },

    renderControls: function() {
        let displayText = this.element;

        let $bar;
        if (!displayText.showLabel || !displayText.showDescription) {
            $bar = this.renderAddComponentBar(this.element.textAlign, this.element.paddedBounds.left, displayText.showDescription ? 15 : 5);
        }

        if (!displayText.showLabel) {
            $bar.append(controls.createButton(this, {
                label: "Add Label",
                callback: () => {
                    displayText.showLabelPlaceholder = true;
                    displayText.canvas.refreshCanvas({ transition: false }).then(() => {
                        ds.selection.element = displayText.label;
                    }).catch(err => {
                        displayText.showLabelPlaceholder = false;
                        displayText.canvas.revertCanvasModel();
                        ShowWarningDialog({
                            title: "Sorry, we were unable to make this change",
                            message: err.message,
                        });
                    });
                }
            }));
        }

        if (!displayText.showDescription) {
            $bar.append(controls.createButton(this, {
                label: "Add SubHeader",
                callback: () => {
                    displayText.showDescriptionPlaceholder = true;
                    displayText.canvas.refreshCanvas({ transition: false }).then(() => {
                        ds.selection.element = displayText.description;
                    }).catch(err => {
                        displayText.showDescriptionPlaceholder = false;
                        displayText.canvas.revertCanvasModel();
                        ShowWarningDialog({
                            title: "Sorry, we were unable to make this change",
                            message: err.message,
                        });
                    });
                }
            }));
        }
    },

});

const DisplayTextHeadlineSelection = TextElementSelection.extend({
    getCursor: function() {
        return "text";
    },

    renderControls: function() {
        this.setupTextEditor();

        let displayText = this.element.parentElement;

        let elementBounds = this.element.innerBounds.multiply(this.element.canvas.getScale());

        if (this.element.singleLine) {
            let textGroup = this.element.parentElement;

            this.listenTo(this.textEditor, TextEditorEvent.ENTER_KEY, () => {
                if (textGroup.showDescription) {
                    ds.selection.element = textGroup.elements.description;
                } else {
                    textGroup.showDescriptionPlaceholder = true;
                    textGroup.canvas.refreshCanvasAutoRevert({ transition: false }).then(() => {
                        ds.selection.element = textGroup.elements.description;
                    }).catch(err => {
                        textGroup.showDescriptionPlaceholder = false;
                        textGroup.canvas.revertCanvasModel();
                        ShowWarningDialog({
                            title: "Sorry, we were unable to make this change",
                            message: err.message,
                        });
                    });
                }
            });
        }
    },

});

const DisplayTextStatisticSelection = DisplayTextHeadlineSelection.extend({
    getCursor: function() {
        return "text";
    },
});

const DisplayTextLabelSelection = TextElementSelection.extend({
    getCursor: function() {
        return "text";
    },

    renderControls: function() {
        TextElementSelection.prototype.renderControls.apply(this, arguments);

        this.createDeleteComponentWidget({
            action: () => {
                ds.selection.element = null;
                this.element.parentElement.showLabelPlaceholder = false;
                this.element.model.label = null;
                this.element.canvas.updateCanvasModel(true);
            }
        });
    },

    cleanUp: function() {
        if (this.element.model.label && _.isEmpty(this.element.model.label.text)) {
            this.element.parentElement.showLabelPlaceholder = false;
            // need to defer or the refreshCanvas will intefere with the selection layer rendering anything that was selected from the click
            // that blurred the empty textbox
            _.defer(() => {
                this.element.canvas.refreshCanvasAutoRevert({ transition: false });
            });
        }
        TextElementSelection.prototype.cleanUp.apply(this, arguments);
    }
});

const DisplayTextDescriptionSelection = TextElementSelection.extend({
    captureMouseEvents: true,

    getCursor: function() {
        return "text";
    },

    renderControls: function() {
        TextElementSelection.prototype.renderControls.apply(this, arguments);

        this.createDeleteComponentWidget({
            action: () => {
                ds.selection.element = null;
                this.element.parentElement.showDescriptionPlaceholder = false;
                this.element.model.description = null;
                this.element.canvas.updateCanvasModel(true);
            }
        });
    },

    cleanUp: function() {
        if (this.element.model.description && _.isEmpty(this.element.model.description.text)) {
            this.element.parentElement.showDescriptionPlaceholder = false;

            // need to defer or the refreshCanvas will intefere with the selection layer rendering anything that was selected from the click
            // that blurred the empty textbox
            _.defer(() => {
                this.element.canvas.refreshCanvasAutoRevert({ transition: false });
            });
        }
        TextElementSelection.prototype.cleanUp.apply(this, arguments);
    }
});

export { DisplayTextRollover, DisplayTextSelection };

export const editors = {
    DisplayTextRollover,
    DisplayTextSelection,
    DisplayTextHeadlineSelection,
    DisplayTextStatisticSelection,
    DisplayTextLabelSelection,
    DisplayTextDescriptionSelection,
};
