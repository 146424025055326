import { ds } from "js/core/models/dataService";
import { app } from "js/namespaces.js";
import { controls } from "js/editor/ui";
import { DEFAULT_ADDRESS } from "common/constants";
import { _ } from "js/vendor";
import { ShowWarningDialog } from "js/react/components/Dialogs/BaseDialog";

import { TextElementSelection } from "./textEditor";
import { ElementSelection } from "../BaseElementEditor";
import {
    CollectionElementSelection,
    CollectionItemElementSelection
} from "../CollectionElementEditor";

const ContactInfoContentSelection = ElementSelection.extend({
    renderControls: function() {
        this.addControl({
            type: controls.POPUP_BUTTON,
            label: "Region Type",
            property: "contentRegionType",
            items: [{ label: "Map", value: "map" }, { label: "Image", value: "image" }]
        });
    }
});

const ContactInfoSelection = CollectionElementSelection.extend({
    renderControls: function() {
        this.addControl({
            type: controls.POPUP_BUTTON,
            label: "Add Contact Info",
            icon: "info",
            id: "addContactInfo",
            items: [{ label: "Text", value: "text" }, { type: "divider" }, {
                label: "Address",
                value: "address"
            }, { label: "Phone", value: "phone" }, { label: "Website", value: "website" }, {
                label: "Email",
                value: "email"
            }, { label: "Fax", value: "fax" }],
            callback: function(type) {
                if (type == "address" && _.find(this.element.list.elements, item => item.model.type == "address")) {
                    ShowWarningDialog({
                        title: "Can't add another address",
                        message: "You can only have one address block",
                    });
                    return;
                }

                let item = this.element.list.addItem({
                    type: type,
                });

                this.element.canvas.updateCanvasModel(false).then(() => {
                    ds.selection.element = this.element.list.getChild(item.id).text;
                }).catch(err => {
                    ShowWarningDialog({
                        title: "Unable to add item",
                        message: err.message,
                    });
                });
            }
        });

        this.addControl({
            type: controls.POPUP_BUTTON,
            label: "Add Social Link",
            icon: "share",
            id: "addSocialLink",
            items: [
                { label: "Facebook", value: "facebook" },
                { label: "Twitter", value: "twitter" },
                { label: "LinkedIn", value: "linkedin" },
                { label: "Skype", value: "skype" },
                { label: "Instagram", value: "instagram" },
                { label: "Tumblr", value: "tumblr" },
                { label: "Pinterest", value: "pinterest" },
                { label: "WhatsApp", value: "whatsapp" }
            ],
            callback: function(type) {
                let item = this.element.list.addItem({
                    type: "social",
                    social_type: type
                });

                this.element.canvas.updateCanvasModel(false).then(() => {
                    ds.selection.element = this.element.list.getChild(item.id).text;
                }).catch(err => {
                    ShowWarningDialog({
                        title: "Unable to add item",
                        message: err.message,
                    });
                });
            }
        });
    }
});

const ContactItemHeaderSelection = CollectionItemElementSelection.extend({
    showSelectionBox: false,

    canDelete: function() {
        return false;
    }
});

const ContactItemAddressSelection = CollectionItemElementSelection.extend({
    showSelectionBox: false
});

const ContactItemAddressTextSelection = TextElementSelection.extend({
    cleanUp: function() {
        if (!this.element.getRootElement().map) {
            return TextElementSelection.prototype.cleanUp.call(this);
        }

        const map = this.element.getRootElement().map.map;

        app.isEditingText = false;
        if (this.textEditor) {
            this.element.removeRenderUIFunction(this.textEditor.renderTextSelection);
            this.textEditor.remove();
            this.textEditor = null;
        }

        _.defer(() => {
            ds.selection.element = map;
            _.defer(() => {
                map.refreshElement();
            });
        });
    }
});

const ContactItemTextSelection = CollectionItemElementSelection.extend({
    showSelectionBox: false
});

const ContactItemIconSelection = CollectionItemElementSelection.extend({
    showSelectionBox: false,

    getTitle: function() {
        if (this.element.model.type == "social") {
            return this.element.model.social_type;
        } else {
            return this.element.model.type;
        }
    }
});

function ContactInfoAddContentMenu(element, menu) {
    return [{
        title: "Street Map",
        options: [{
            icon: "contact_map_left",
            label: "Steet Map",
            selected: element.layout == "left_map",
            enabled: true,
            callback: value => {
                if (value) {
                    element.model.layout = "left_map";
                    element.model.map_address = element.model.map_address || { text: DEFAULT_ADDRESS };
                } else {
                    element.model.layout = "none";
                }
                element.canvas.updateCanvasModel(true).then(() => {
                    menu.render();
                }).catch(err => {
                    ShowWarningDialog({
                        title: "Sorry, we were unable to make this change",
                        message: err.message,
                    });
                });
            }
        }, {
            icon: "contact_map_right",
            label: "Steet Map",
            selected: element.layout == "right_map",
            enabled: true,
            callback: value => {
                if (value) {
                    element.model.layout = "right_map";
                    element.model.map_address = element.model.map_address || { text: DEFAULT_ADDRESS };
                } else {
                    element.model.layout = "none";
                }
                element.canvas.updateCanvasModel(true).then(() => {
                    menu.render();
                }).catch(err => {
                    ShowWarningDialog({
                        title: "Sorry, we were unable to make this change",
                        message: err.message,
                    });
                });
            }
        }]
    }, {
        title: "Picture",
        options: [{
            icon: "contact_image_left",
            label: "Left Picture",
            selected: element.layout == "left_picture",
            enabled: true,
            callback: value => {
                if (value) {
                    element.model.layout = "left_picture";
                } else {
                    element.model.layout = "none";
                }
                element.canvas.updateCanvasModel(true).then(() => {
                    menu.render();
                }).catch(err => {
                    ShowWarningDialog({
                        title: "Sorry, we were unable to make this change",
                        message: err.message,
                    });
                });
            }
        }, {
            icon: "contact_image_top",
            label: "Top Picture",
            selected: element.layout == "top_picture",
            enabled: true,
            callback: value => {
                if (value) {
                    element.model.layout = "top_picture";
                } else {
                    element.model.layout = "none";
                }
                element.canvas.updateCanvasModel(true).then(() => {
                    menu.render();
                }).catch(err => {
                    ShowWarningDialog({
                        title: "Sorry, we were unable to make this change",
                        message: err.message,
                    });
                });
            }
        }, {
            icon: "contact_image_right",
            label: "Right Picture",
            selected: element.layout == "right_picture",
            enabled: true,
            callback: value => {
                if (value) {
                    element.model.layout = "right_picture";
                } else {
                    element.model.layout = "none";
                }
                element.canvas.updateCanvasModel(true).then(() => {
                    menu.render();
                }).catch(err => {
                    ShowWarningDialog({
                        title: "Sorry, we were unable to make this change",
                        message: err.message,
                    });
                });
            }
        }]
    }];
}

export const editors = {
    ContactInfoContentSelection,
    ContactInfoSelection,
    ContactItemHeaderSelection,
    ContactItemAddressSelection,
    ContactItemAddressTextSelection,
    ContactItemTextSelection,
    ContactItemIconSelection,
    ContactInfoAddContentMenu
};
