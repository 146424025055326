import { controls } from "js/editor/ui";
import { $ } from "js/vendor";
import { WidgetPositionType } from "common/constants";
import { app } from "js/namespaces";

import { ContentItem } from "../../../elements/base/ContentItem";

import { ElementOptionsMenu } from "../../BaseElementEditor";
import { CollectionElementSelection } from "../../CollectionElementEditor";
import { ContentItemSelection, renderContentItemControls } from "..//ContentItemEditor";
import { renderNodeElementControls } from "../../elements/NodeEditor";

const XYGraphSelection = CollectionElementSelection.extend({
    renderControls: function() {
        this.createAddItemButton({ skipAddItemPopup: true });
    }
});

const XYGraphOptionsMenu = ElementOptionsMenu.extend({
    renderControls: function() {
        let selectedStyle;

        if (this.model.showAxis && !this.model.showQuadrants && this.model.axisPosition == "left") {
            selectedStyle = "axis";
        } else if (this.model.showAxis && this.model.showQuadrants) {
            selectedStyle = "axis-with-quadrants";
        } else if (this.model.showAxis && this.model.axisPosition == "center") {
            selectedStyle = "center-axis";
        } else {
            selectedStyle = "no-axis";
        }

        this.addControl({
            type: controls.DROPDOWN_MENU,
            label: "Style",
            value: selectedStyle,
            items: [{
                value: "axis", label: "Left Axis"
            }, {
                value: "axis-with-quadrants", label: "Left Axis with Quadrants"
            }, {
                value: "center-axis", label: "Center Axis"
            }, {
                value: "no-axis", label: "No Axis"
            }],
            callback: value => {
                switch (value) {
                    case "axis":
                        this.model.showAxis = true;
                        this.model.showQuadrants = false;
                        this.model.axisPosition = "left";
                        break;
                    case "axis-with-quadrants":
                        this.model.showAxis = true;
                        this.model.showQuadrants = true;
                        this.model.axisPosition = "left";
                        break;
                    case "center-axis":
                        this.model.showAxis = true;
                        this.model.showQuadrants = false;
                        this.model.axisPosition = "center";
                        break;
                    case "no-axis":
                        this.model.showAxis = false;
                        this.model.showQuadrants = false;
                        break;
                }
                this.element.canvas.updateCanvasModel(true);
                this.render();
            }
        });

        this.addControl({
            type: controls.CHECKBOX,
            label: "Show Axis Labels",
            property: "showAxisLabels",
            enabled: this.model.showAxis
        });

        this.addControl({
            type: controls.CHECKBOX,
            label: "Show Arrowheads",
            property: "showArrowHeads",
            enabled: this.model.showAxis
        });

        this.addControl({
            type: controls.CHECKBOX,
            label: "Show Quadrant Labels",
            property: "showQuadrantLabels",
            enabled: this.model.showQuadrants
        });

        this.addControl({
            type: controls.CHECKBOX,
            label: "Show Grid",
            property: "showGrid",
            callback: () => this.render()
        });

        this.addControl({
            type: controls.SLIDER,
            label: "Grid Size",
            property: "gridSize",
            min: 16,
            max: 50,
            step: 2,
            enabled: this.model.showGrid
        });
    },

});

const XYGraphItemSelection = ContentItemSelection.extend({

    canDrag() {
        return false;
    },

    renderControls() {
        if (this.element instanceof ContentItem) {
            renderContentItemControls(this);
            if (this.element.showResizeHandle) {
                this.renderResizeHandle();
            }
        } else {
            renderNodeElementControls(this);
            this.renderResizeHandle();
        }

        this.addControl({
            type: controls.POPUP_BUTTON,
            icon: "settings",
            showArrow: false,
            items: [{
                value: "bringToFront", label: "Bring to Front", icon: "flip_to_front"
            }, {
                value: "sendToBack", label: "Send to Back", icon: "flip_to_back"
            }],
            callback: action => {
                switch (action) {
                    case "bringToFront":
                        this.element.parentElement.itemCollection.remove(this.element.model);
                        this.element.parentElement.itemCollection.push(this.element.model);
                        break;
                    case "sendToBack":
                        this.element.parentElement.itemCollection.remove(this.element.model);
                        this.element.parentElement.itemCollection.insert(this.element.model, 0);
                        break;
                }
                this.element.canvas.updateCanvasModel(false);
            }
        });

        let $dragWidget = this.createDragWidget(this.element, {
            position: WidgetPositionType.DRAG_HANDLE
        });
        $dragWidget.makeDraggable({
            element: this.element,
            axis: this.getDragAxis(),
            dragDistance: 5,
            start: (event, axis) => {
                app.isDraggingItem = true;
                this.selectionLayer.hideWidgets($(".drag_button"));
            },
            drag: (event, position) => {
                if (!this.canvas.layouter.isGenerating) {
                    const registrationPoint = this.element.registrationPoint;

                    const containerElement = this.element.parentElement;

                    const dragPosition = position.elementPosition.offset(registrationPoint);
                    this.element.dragWidgetPosition = position.elementPosition;
                    this.element.model.x = Math.clamp(dragPosition.x / containerElement.canvasBounds.width, 0, 1);
                    this.element.model.y = Math.clamp(dragPosition.y / containerElement.canvasBounds.height, 0, 1);

                    // If the element has snap options - remove them after it's been moved
                    if (this.element.model.snapOptions) {
                        delete this.element.model.snapOptions;
                    }

                    this.element.getRootElement().refreshElement();
                }
            },
            stop: (event, position) => {
                app.isDraggingItem = false;
                this.selectionLayer.showWidgets();
                this.element.canvas.updateCanvasModel(false);
            },
        });
    },
});

export const editors = {
    XYGraphSelection,
    XYGraphItemSelection,
    XYGraphOptionsMenu
};
