import { CollectionElementSelection, CollectionItemElementSelection } from "../CollectionElementEditor";
import { controls } from "../../../../editor/ui";
import { ElementOptionsMenu } from "../BaseElementEditor";
import moment from "moment/moment";
import { $, _ } from "js/vendor";
import { app } from "js/namespaces";
import { AnchorType } from "js/core/utilities/geom";
import { ds } from "../../../../core/models/dataService";
import { Key } from "../../../../core/utilities/keys";
import { ShowDialogAsync } from "js/react/components/Dialogs/BaseDialog";
import BadFitDialog from "js/react/components/Dialogs/BadFitDialog";

const GanttChartSelection = CollectionElementSelection.extend({
    renderControls: function() {
        this.addControl({
            type: controls.BUTTON,
            label: "Add Task",
            icon: "add",
            enabled: this.element.tasks.maxItemCount ? (this.element.tasks.itemCount < this.element.tasks.maxItemCount) : true,
            callback: () => {
                this.element.tasks.addItem();

                return this.element.canvas.updateCanvasModel(false).catch(err => {
                    ShowDialogAsync(BadFitDialog, {
                        title: "Sorry, we aren't able to fit another task on this chart",
                    });
                });
            }
        });

        this.addControl({
            type: controls.BUTTON,
            label: "Add Milestone",
            icon: "chat",
            enabled: (this.element.milestones && this.element.milestones.maxItemCount) ? (this.element.milestones.itemCount < this.element.milestones.maxItemCount) : true,
            callback: () => {
                let day = Math.round(this.element.duration.asDays() * Math.random());
                this.element.model.milestones.push({
                    id: _.uniqueId() + new Date().getTime(),
                    date: this.element.startDate.clone().add(day, "days").toDate().getTime()
                });
                return this.element.canvas.updateCanvasModel(false).catch(err => {
                    ShowDialogAsync(BadFitDialog, {
                        title: "Sorry, we aren't able to fit another task on this chart",
                    });
                });
            }
        });
    },

    _handleKeyboardShortcut(event) {
        switch (event.which) {
            case Key.KEY_D:
                this.element.tasks.addItem();
                return this.element.canvas.updateCanvasModel(false).catch(err => {
                    ShowDialogAsync(BadFitDialog, {
                        title: "Sorry, we aren't able to fit another task on this chart",
                    });
                });
        }
    }
});

const GanttChartOptionsMenu = ElementOptionsMenu.extend({
    renderControls: function($container) {
        this.addControl({
            type: controls.DROPDOWN_MENU,
            label: "Scale",
            property: "axisScale",
            items: [{
                value: "days", label: "Days"
            }, {
                value: "weeks", label: "Weeks"
            }, {
                value: "months", label: "Months", enabled: this.element.relativeTimescale == false
            }, {
                value: "quarters", label: "Quarters", enabled: this.element.relativeTimescale == false
            }, {
                value: "years", label: "Years"
            }]
        });

        this.addControl({
            type: controls.TOGGLE,
            label: "Use Relative Time",
            value: this.element.relativeTimescale,
            callback: value => {
                ds.selection.element = null;
                this.element.model.relativeTimescale = value;
                if (this.element.model.axisScale == "months" || this.element.model.axisScale == "quarters") {
                    this.element.model.axisScale = "weeks";
                }
                this.element.canvas.updateCanvasModel(true);
            }
        });

        this.addControl({
            type: controls.TOGGLE,
            label: "Show Grid",
            property: "showGrid"
        });

        this.$el.append($.hr());

        this.addControl({
            type: controls.TOGGLE,
            label: "Show Task Image",
            property: "showImage"
        });

        this.addControl({
            type: controls.DROPDOWN_MENU,
            label: "Task Details",
            property: "showTaskDetail",
            items: [{
                value: "none", label: "None"
            }, {
                value: "duration", label: "Duration"
            }, {
                value: "dates", label: "Start/End Date"
            }]
        });
    }
});

const GanttChartMilestoneSelection = CollectionItemElementSelection.extend({

    showSelectionBox: false,

    canDrag() {
        return true;
    },

    getDragAxis() {
        return "x";
    },

    renderControls() {
        this.addControl({
            type: controls.DATE_PICKER,
            label: "Date",
            value: new Date(this.element.date.format("YYYY-MM-DDT00:00:00.000")),
            callback: value => {
                this.element.model.date = moment.utc(value).valueOf();
                this.element.canvas.updateCanvasModel(true);
            }
        });
        this.addControl({
            type: controls.BUTTON,
            icon: "flip",
            callback: () => {
                this.element.model.flipLabel = !this.element.model.flipLabel;
                this.element.canvas.updateCanvasModel(false);
            }
        });
    },

    onStartDrag: function(event, dragProps) {
        app.isDraggingItem = true;
        app.mainView.editorView.selectionLayer.hideWidgets($(".drag_button"));

        this.$dragTip = this.$el.addEl($.div("drag-tooltip"));
        this.ganttChart = this.element.getRootElement();
        let totalDays = moment.duration(this.ganttChart.endDate.diff(this.ganttChart.startDate)).asDays();
        this.dayWidth = this.ganttChart.bounds.width / totalDays;
    },

    onDrag: function(event, position, dragProps) {
        let mousePt = this.screenToElementCoordinates(position.screenPosition, this.ganttChart);

        if (this.element.flipLabel) {
            mousePt.x += this.element.bounds.width;
        }
        let day = Math.round(mousePt.x / this.dayWidth);
        day = Math.clamp(day, 0, this.ganttChart.duration.asDays());

        let date = this.ganttChart.startDate.clone().add(day, "days");
        this.element.model.date = date.toDate().getTime();

        this.$dragTip.text(`${date.format("MMM DD")}`);
        this.$dragTip.center(0, -20);

        this.element.refreshElement();
    },

});

const GanttChartTaskSelection = CollectionItemElementSelection.extend({

    getDragAxis() {
        return "detect";
    },

    renderControls: function() {
        let chartStartDate = this.element.getRootElement().startDate;

        if (this.element.getRootElement().relativeTimescale) {
            this.addControl({
                type: controls.POPUP_BUTTON,
                label: "Set Task",
                menuContents: closeMenu => {
                    let $menu = $.grid();

                    $menu.append(controls.createNumericStepper(this, {
                        label: "Start Day",
                        value: (this.element.itemCount > 1) ? Math.round(moment.duration(this.element.taskStartDate.diff(chartStartDate)).asDays()) + 1 : 1,
                        enabled: this.element.itemCount > 1,
                        min: 1,
                        max: 99999,
                        step: 1,
                        callback: value => {
                            let duration = Math.round(moment.duration(this.element.taskEndDate.diff(this.element.taskStartDate)).asDays());
                            let startDate = chartStartDate.clone().add(value - 1, "days");
                            this.element.model.startDate = startDate.startOf("day").toDate().getTime();
                            this.element.model.endDate = startDate.add(duration - 1, "days").endOf("day").toDate().getTime();
                            this.element.canvas.updateCanvasModel();
                        }
                    }));

                    $menu.append(controls.createNumericStepper(this, {
                        label: "Length",
                        value: Math.round(moment.duration(this.element.taskEndDate.diff(this.element.taskStartDate)).asDays()),
                        min: 1,
                        step: 1,
                        max: 9999,
                        callback: value => {
                            this.element.model.endDate = this.element.taskStartDate.clone().add(value - 1, "days").endOf("day").toDate().getTime();
                            this.element.canvas.updateCanvasModel();
                        }
                    }));

                    return $menu;
                }
            });
        } else {
            this.addControl({
                type: controls.POPUP_BUTTON,
                label: "Set Task",
                menuContents: closeMenu => {
                    let $menu = $.grid();
                    $menu.append(controls.createDatePicker(this, {
                        label: "Start",
                        value: new Date(this.element.taskStartDate.format("YYYY-MM-DDT00:00:00.000")),
                        callback: value => {
                            let startDate = moment.utc(value).startOf("day");
                            if (startDate.isAfter(this.element.taskEndDate)) {
                                this.element.model.endDate = startDate.clone().endOf("day").toDate().getTime();
                            }
                            this.element.model.startDate = startDate.toDate().getTime();
                            this.element.canvas.updateCanvasModel();
                            closeMenu();
                        }
                    }));

                    $menu.append(controls.createDatePicker(this, {
                        label: "End",
                        value: new Date(this.element.taskEndDate.format("YYYY-MM-DDT00:00:00.000")),
                        callback: value => {
                            let endDate = moment.utc(value).endOf("day");
                            if (endDate.isBefore(this.element.taskStartDate)) {
                                this.element.model.startDate = endDate.clone().startOf("day").toDate().getTime();
                            }
                            this.element.model.endDate = endDate.toDate().getTime();
                            this.element.canvas.updateCanvasModel();
                            closeMenu();
                        }
                    }));
                    return $menu;
                }
            });
        }
        this.addControl({
            type: controls.COLOR_PALETTE_PICKER,
            property: "color",
            includeAuto: true
        });

        this.renderWidget("start");
        this.renderWidget("end");

        this.$dragTip = this.$el.addEl($.div("drag-tooltip"));
        this.$dragTip.hide();
    },

    renderWidget(type) {
        let $widget = this.$el.addEl($.div("drag-widget horizontal control"));
        $widget.addClass(type);

        let ganttChart = this.element.getRootElement();
        let startDate = ganttChart.startDate;
        let endDate = ganttChart.endDate;

        let totalDays = moment.duration(endDate.diff(startDate)).asDays();
        let dayWidth = ganttChart.bounds.width / totalDays;

        $widget.makeDraggable({
            axis: "x",
            start: event => {
                this.selectionLayer.hideWidgets($widget);
            },
            drag: (event, position) => {
                let mousePt = this.screenToElementCoordinates(position.screenPosition, ganttChart);
                let day = Math.round(mousePt.x / dayWidth);

                let duration = Math.round(this.element.duration.asDays());

                switch (type) {
                    case "start":
                        let start = startDate.clone().add(day, "day");
                        start = moment.max(start, startDate);
                        start = moment.min(start, endDate);
                        this.element.model.startDate = start.toDate().getTime();
                        break;
                    case "end":
                        let end = startDate.clone().add(day - 1, "day").endOf("day");
                        end = moment.max(this.element.taskStartDate, end);
                        end = moment.min(end, endDate);
                        this.element.model.endDate = end.toDate().getTime();
                        break;
                }

                this.element.canvas.refreshCanvas().then(() => {
                    this.layout();

                    let taskStart = this.element.taskStartDate;
                    let taskEnd = this.element.taskEndDate;

                    if (ganttChart.relativeTimescale) {
                        this.$dragTip.text(`Days ${Math.round(moment.duration(taskStart.diff(startDate)).asDays() + 1)} to ${Math.round(moment.duration(taskEnd.diff(startDate)).asDays())} - ${duration} ${"day".pluralize(duration > 1)}`);
                    } else {
                        this.$dragTip.text(`${taskStart.format("MMM DD")} to ${taskEnd.format("MMM DD")} - ${duration} ${"day".pluralize(duration > 1)}`);
                    }
                    this.$dragTip.center($widget.left(), $widget.top() - 30).show();
                });
            },
            stop: (event, position) => {
                this.selectionLayer.showWidgets();
                this.$dragTip.hide();
                this.element.canvas.updateCanvasModel(true).then(() => {
                    ds.selection.element = null;
                    ds.selection.element = this.element;
                });
            }

        });
    },

    onStartDrag(event, dragProps, axis) {
        if (axis == "y") {
            CollectionItemElementSelection.prototype.onStartDrag.apply(this, arguments);
        } else {
            this.$dragTip = this.$el.addEl($.div("drag-tooltip"));
            this.$dragTip.hide();

            this.ganttChart = this.element.getRootElement();
            let totalDays = moment.duration(this.ganttChart.endDate.diff(this.ganttChart.startDate)).asDays();
            this.dayWidth = this.ganttChart.bounds.width / totalDays;

            // this.taskX = this.element.svg.getTransform().x;
            this.startDragY = event.pageY;
        }
    },

    onDrag(event, position, dragProps) {
        if (position.axis == "y") {
            CollectionItemElementSelection.prototype.onDrag.apply(this, arguments);
        } else {
            // drag horizontal

            let mousePt = this.screenToElementCoordinates(position.screenPosition, this.ganttChart);
            let day = Math.max(0, Math.round(mousePt.x / this.dayWidth));

            let taskStart = this.ganttChart.startDate.clone().add(day, "days").startOf("day");
            let duration = Math.round(this.element.duration.asDays());
            let taskEnd = taskStart.clone().add(duration - 1, "days").endOf("day");
            this.element.model.startDate = taskStart.toDate().getTime();
            this.element.model.endDate = taskEnd.toDate().getTime();

            if (this.ganttChart.relativeTimescale) {
                this.$dragTip.text(`Days ${Math.round(moment.duration(taskStart.diff(this.ganttChart.startDate)).asDays() + 1)} to ${Math.round(moment.duration(taskEnd.diff(this.ganttChart.startDate)).asDays())} - ${duration} ${"day".pluralize(duration > 1)}`);
            } else {
                this.$dragTip.text(`${taskStart.format("MMM DD")} to ${taskEnd.format("MMM DD")} - ${duration} ${"day".pluralize(duration > 1)}`);
            }
            this.$dragTip.center(0, -20).show();

            this.element.refreshElement();

            this.layout();
        }
    },

    _layout() {
        let selectionBounds = this.getElementSelectionBounds().zeroOffset();

        this.$el.find(".control.start").center(selectionBounds.getPoint(AnchorType.LEFT));
        this.$el.find(".control.end").center(selectionBounds.getPoint(AnchorType.RIGHT));
        this.$el.find(".control.move").center(selectionBounds.getPoint(AnchorType.TOP));
    }

});

export const editors = {
    GanttChartSelection,
    GanttChartOptionsMenu,
    GanttChartTaskSelection,
    GanttChartMilestoneSelection
};
