import Cookies from "js-cookie";

import firebase from "js/firebase";
import { openWindowAndWaitForItToBeClosed } from "js/core/utilities/selfClosingWindow";

export class SmartsheetAuthError extends Error { }
export class SmartsheetAuthFlowWasInterruptedError extends SmartsheetAuthError { }

interface SmartsheetAuthTokens {
    accessToken: string
}

/**
 * Grants our app offline access for the given scope, executes the redirect ouath flow in a separate window
 * and saves the tokens
 */
export async function grantOfflineAccess(): Promise<SmartsheetAuthTokens> {
    const userIdToken = await firebase.auth().currentUser.getIdToken();
    Cookies.set("user_id_token", userIdToken, { sameSite: "strict", expires: new Date(Date.now() + 60 * 1000) });

    const url = `${window.location.origin}/smartsheet/auth` +
        `?continueUrl=${encodeURIComponent("/close")}` +
        "&requestOfflineAccess=true";

    const lastSearchParams = await openWindowAndWaitForItToBeClosed(url);

    const error = lastSearchParams.get("smartsheet_auth_error");
    if (error) {
        throw new SmartsheetAuthError(error);
    }

    const accessToken = Cookies.get("smartsheet_access_token");
    Cookies.remove("smartsheet_access_token");

    if (!accessToken && !error) {
        throw new SmartsheetAuthFlowWasInterruptedError();
    }

    return { accessToken };
}
