import { ds } from "js/core/models/dataService";
import { $, _ } from "js/vendor";
import { app } from "js/namespaces.js";
import { controls, CreateColorChit } from "js/editor/ui";
import { HorizontalAlignType, NodeType, PositionType } from "common/constants";
import { getStaticUrl } from "js/config";

import { createResizer } from "../EditorComponents/Resizer";
import { CollectionItemElementSelection } from "../CollectionElementEditor";
import { ElementRollover } from "../BaseElementEditor";
import { CreateMediaMenu, CreateImageFrameMenu } from "./pictureEditor";
import { AddContentBlock, ContentBlockItemSelection } from "./ContentBlockEditor";

export const NodeElementRollover = ElementRollover.extend({

    captureMouseEvents: true,
    showSelectionBox: true,

    setup() {
    },

    getCursor() {
        return "default";
    },

    renderControls() {
    },

    _layout() {
        if (this.$connectorWidget) {
            let selectionBounds = this.getElementSelectionBounds();
            this.$connectorWidget.left(selectionBounds.zeroOffset().right - 9).top(selectionBounds.zeroOffset().bottom - 9);
        }
    }

});

export function renderNodeElementControls(view, showSlider = (view.element.nodeType == NodeType.FLEX_CIRCLE)) {
    let items = [];

    if (view.element.parentElement.getAllowedNodeTypes(view.element).length > 1) {
        for (let nodeType of view.element.parentElement.getAllowedNodeTypes(view.element)) {
            switch (nodeType) {
                case NodeType.TEXT:
                    items.push({
                        label: "Text",
                        value: NodeType.TEXT,
                        image: getStaticUrl("/images/ui/node_types/node_text.svg")
                    });
                    break;
                case NodeType.BOX:
                    items.push({
                        label: "Box",
                        value: NodeType.BOX,
                        image: getStaticUrl("/images/ui/node_types/node_box.svg")
                    });
                    break;
                case NodeType.CIRCLE:
                    items.push({
                        label: "Circle",
                        value: NodeType.CIRCLE,
                        image: getStaticUrl("/images/ui/node_types/node_circle.svg")
                    });
                    break;
                case NodeType.DIAMOND:
                    items.push({
                        label: "Diamond",
                        value: NodeType.DIAMOND,
                        image: getStaticUrl("/images/ui/node_types/node_diamond.svg")
                    });
                    break;
                case NodeType.CAPSULE:
                    items.push({
                        label: "Capsule",
                        value: NodeType.CAPSULE,
                        image: getStaticUrl("/images/ui/node_types/node_capsule.svg")
                    });
                    break;
                case NodeType.BULLET_TEXT:
                    items.push({
                        label: "Bullet",
                        value: NodeType.BULLET_TEXT,
                        image: getStaticUrl("/images/ui/node_types/node_bullet_text.svg")
                    });
                    break;
                case NodeType.CONTENT_AND_TEXT:
                    items.push({
                        label: "Content with Text",
                        value: NodeType.CONTENT_AND_TEXT,
                        image: getStaticUrl("/images/ui/node_types/node_content_and_text.svg")
                    });
                    break;
                case NodeType.NUMBERED_TEXT:
                    items.push({
                        label: "Numbered",
                        value: NodeType.NUMBERED_TEXT,
                        image: getStaticUrl("/images/ui/node_types/node_numbered_text.svg")
                    });
                    break;
                case NodeType.CONTENT:
                    items.push({
                        label: "Image/Icon",
                        value: NodeType.CONTENT,
                        image: getStaticUrl("/images/ui/node_types/node_content.svg")
                    });
                    break;
            }
        }

        view.addControl({
            id: "nodeType",
            type: controls.POPUP_BUTTON,
            icon: "apps",
            showArrow: false,
            menuClass: "icon-menu fivecol",
            items: items,
            callback: value => {
                if (!(view.element.nodeType.equalsAnyOf(NodeType.TEXT, NodeType.NUMBERED_TEXT, NodeType.CONTENT_AND_TEXT, NodeType.BULLET_TEXT) && value.equalsAnyOf(NodeType.Text, NodeType.NUMBERED_TEXT, NodeType.CONTENT_AND_TEXT, NodeType.BULLET_TEXT))) {
                    view.element.model.userSize = null;
                }

                view.element.model.nodeType = value;

                let transition = true;
                if (value.equalsAnyOf(NodeType.NUMBERED_TEXT, NodeType.CONTENT_AND_TEXT, NodeType.CONTENT)) {
                    transition = false;
                }

                ds.selection.element = null;
                view.element.canvas.refreshCanvasAutoRevert({ transition })
                    .then(() => view.element.canvas.saveCanvasModel())
                    .then(() => {
                        // switching the nodeType will create a new nodeElement which this editor is not pointing to
                        // so we can refresh by setting selection.element to the new node which is at the same index as the
                        // old element
                        ds.selection.element = view.element.parentElement.itemElements[view.element.itemIndex];
                    });
            }
        });
    }

    if (view.element.canChangeTextDirection) {
        view.addControl({
            id: "textDirection",
            type: controls.POPUP_BUTTON,
            icon: getStaticUrl("/images/ui/icons/text-position-white.svg"),
            showArrow: false,
            menuContents: closeMenu => {
                let $menu = $.div();
                $menu.append(controls.createPositionPicker(view, {
                    label: "Text Position",
                    excludeCorners: true,
                    showAuto: true,
                    showLabels: true,
                    value: view.element.textDirection,
                    callback: value => {
                        ds.selection.element = null;
                        view.element.model.textDirection = value;
                        view.element.canvas.updateCanvasModel(false).then(() => {
                            ds.selection.element = view.element;
                        });
                    }
                }));
                return $menu;
            }
        });
    }

    if (view.element.text && view.element.text.canSelect) {
        view.addControl({
            type: controls.POPUP_BUTTON,
            label: "Add Block",
            menuClass: "icon-menu",
            items: _.map(view.element.text.availableBlockTypes, type => {
                return { value: type, label: type, image: getStaticUrl(`/images/ui/contentblocktypes/${type}.svg`) };
            }),
            callback: type => {
                AddContentBlock(view.element.text, type, false);
            }
        });
    }

    if (view.element.showSizeSlider || showSlider) {
        view.addControl({
            type: controls.SLIDER,
            min: 25,
            max: 400,
            step: 0.1,
            property: view.element.nodeType == NodeType.FLEX_CIRCLE ? "size" : "userSize",
        });
    }

    if (view.element.nodeType == NodeType.CONTENT && view.element.content) {
        CreateMediaMenu(view, view.element.content);
        CreateImageFrameMenu(view, view.element.content);
        if (view.element.canChangeColors) {
            CreateColorChit(view, view.element.content, { showTextColors: false, showBackgroundColors: true });
        }
    } else if (view.element.canChangeColors) {
        CreateColorChit(view, view.element, {
            showBackgroundColors: true,
            showTextColor: view.element.nodeType.equalsAnyOf(NodeType.CONTENT_AND_TEXT, NodeType.NUMBERED_TEXT, NodeType.BULLET_TEXT, NodeType.TEXT)
        });
    }
}

export function renderNodeConnectorWidget(view, callback) {
    view.$connectorWidget = view.$el.addEl($.div("create-connector-widget control"));
    view.$connectorWidget.append($.icon("my_location"));
    view.$connectorWidget.on("mousedown", event => callback(event));
    view.$connectorWidget.on("mouseenter", event => {
        view.selectionLayer.hideWidgets(view.$connectorWidget);
    });
    view.$connectorWidget.on("mouseleave", event => {
        if (!app.isDraggingItem) {
            view.selectionLayer.showWidgets(view.$connectorWidget);
        }
    });
}

export const NodeElementSelection = CollectionItemElementSelection.extend({
    showDragDropTarget: false,

    getOffset: function() {
        return 10;
    },

    canDrag() {
        // Returning false only if model.canDrag is explicitly set to false
        return this.model.canDrag === false ? false : true;
    },

    renderControls() {
        renderNodeElementControls(this);

        if (this.element.canDragResize) {
            this.renderResizeHandle();
        }
    },

    refreshElement() {
        if (this.element.canRefreshElement) {
            this.element.refreshElement(false);
            return Promise.resolve();
        } else {
            return this.element.canvas.refreshCanvas();
        }
    },

    onDrag: function(event, position, dragProps) {
        if (!this.canvas.layouter.isGenerating) {
            const registrationPoint = this.element.registrationPoint;

            const containerElement = this.element.parentElement;

            const dragPosition = position.elementPosition.offset(registrationPoint);
            this.element.dragWidgetPosition = position.elementPosition;
            this.element.model.x = Math.clamp(dragPosition.x / containerElement.canvasBounds.width, 0, 1);
            this.element.model.y = Math.clamp(dragPosition.y / containerElement.canvasBounds.height, 0, 1);

            // If the element has snap options - remove them after it's been moved
            if (this.element.model.snapOptions) {
                delete this.element.model.snapOptions;
            }

            if (containerElement.allowDragDropElements) {
                this.calcDropTarget(dragProps, position);
            }

            // this.element.findClosestOfType(AnnotationLayer).refreshElement();
            this.element.getRootElement().refreshElement();
        }
    },

    _layout: function() {
        if (this.$connectorWidget) {
            let selectionBounds = this.getElementSelectionBounds();
            this.$connectorWidget.left(selectionBounds.zeroOffset().right - 9).top(selectionBounds.zeroOffset().bottom - 9);
        }
    },

    renderResizeHandle: function() {
        let position = PositionType.RIGHT;
        switch (this.element.horizontalScaleOrigin) {
            case "right":
                position = PositionType.LEFT;
                break;
            case "left":
            case "center":
            default:
                position = PositionType.RIGHT;
        }

        createResizer({
            view: this,
            element: this.element,
            position,
            scaleFromCenter: this.element.horizontalScaleOrigin == "center",
            minSize: this.element.minWidth ?? 100,
            maxSize: this.element.maxWidth ?? 1000,
            onResize: value => {
                this.element.model.userSize = value;
                this.element.canvas.refreshCanvas();
            }
        });
    },

});

export const NodeContentBlockItemSelection = ContentBlockItemSelection.extend({
    canDrag() {
        return false;
    },

    getWidgetPosition() {
        return "inner";
    },

    canDelete() {
        if (this.element.content.type == "ContentBlockDivider" || this.element.content.type == "ContentBlockIcon") {
            return true;
        } else {
            return false;
        }
    }
});

export const editors = {
    NodeElementRollover, NodeElementSelection, NodeContentBlockItemSelection
};
