import { controls } from "js/editor/ui";

import { ElementUI } from "../BaseElementEditor";

const SwotDiagramSelection = ElementUI.extend({
    renderControls: function() {
        this.addControl({
            type: controls.TOGGLE,
            label: "Show Initials",
            property: "showCellChar"
        });
        this.addControl({
            type: controls.TOGGLE,
            label: "Show Titles",
            property: "showCellTitle"
        });
    }
});

export const editors = {
    SwotDiagramSelection,
};
