import { CollectionItemElementSelection } from "../CollectionElementEditor";
import { ElementSelection } from "../BaseElementEditor";
import { controls } from "js/editor/ui";
import { $, _ } from "js/vendor";
import * as geom from "js/core/utilities/geom";
import { app } from "js/namespaces";
import { ds } from "js/core/models/dataService";
import { ConnectorType, NodeType } from "common/constants";
import { Convert } from "js/core/utilities/geom";

import { CollectionElement } from "../../elements/base/CollectionElement";
import { NodeElement } from "../../elements/base/NodeElement";
import ConnectorItem from "../../elements/elements/connectors/ConnectorItem";

export function renderConnectorStyleMenu(view, element, label) {
    element = element || view.element;

    view.addControl({
        id: "connectorMenu",
        type: controls.POPUP_BUTTON,
        label,
        icon: "compare_arrows",
        showArrow: false,
        menuContents: closeMenu => {
            let $menu = $.grid();

            let $connectorStyle = $menu.addEl($.div().css({ display: "flex", alignItems: "center" }));
            $connectorStyle.append(controls.createIconDropdownMenu(view, {
                label: "Line Style",
                model: element.model,
                property: "lineStyle",
                items: [{
                    value: "solid", label: "Solid", image: "/images/ui/connectors/line-style-solid.svg"
                }, {
                    value: "dotted", label: "Dotted", image: "/images/ui/connectors/line-style-dotted.svg"
                }, {
                    value: "dashed", label: "Dashed", image: "/images/ui/connectors/line-style-dashed.svg"
                }, {
                    value: "animate_pulse",
                    label: "Pulse (animated)",
                    image: "/images/ui/connectors/line-style-pulse.svg"
                }, {
                    value: "animate_dash",
                    label: "Dashed (animated)",
                    image: "/images/ui/connectors/line-style-dashed.svg"
                }],
                callback: () => {
                    closeMenu();
                }
            }));
            $connectorStyle.append($.gap(20, 0));
            $connectorStyle.append(controls.createColorPalettePicker(view, {
                model: element.model,
                label: "Color",
                property: "color",
                includePrimary: true,
                includeSecondary: true,
                includeAuto: true,
                autoLabel: element.model.source ? "AUTO" : "SLIDE",
                getAutoColor: element.model.source && (() => {
                    return element.canvas.getTheme().palette.getColor(element.startTarget?.model.color).toRgbString();
                }),
                callback: color => {
                    element.model.color = color;
                    element.markStylesAsDirty();
                    element.canvas.updateCanvasModel(false);
                    closeMenu();
                }
            }));

            $menu.append(controls.createDropdownMenu(view, {
                label: "Line Weight",
                model: element.model,
                property: "lineWeight",
                items: [{
                    value: 1, label: "Extra Thin"
                }, {
                    value: 2, label: "Thin"
                }, {
                    value: 3, label: "Medium"
                }, {
                    value: 5, label: "Thick"
                }, {
                    value: "bold", label: "Bold"
                }],
                callback: () => {
                    closeMenu();
                }
            }));

            $menu.append($.hr());

            let $decorations = $menu.addEl($.div().css({ display: "flex", alignItems: "center" }));
            $decorations.append(controls.createIconDropdownMenu(this, {
                label: "Decoration",
                model: element.model,
                property: "startDecoration",
                items: [{
                    value: "none", label: "None", image: "/images/ui/connectors/line-start-none.svg"
                }, {
                    value: "arrow", label: "Arrow", image: "/images/ui/connectors/line-start-arrow.svg"
                }, {
                    value: "circle", label: "Circle", image: "/images/ui/connectors/line-start-circle.svg"
                }],
                callback: () => {
                    closeMenu();
                }
            }));
            $decorations.append($.gap(10, 0));
            $decorations.append(controls.createIconDropdownMenu(this, {
                model: element.model,
                property: "endDecoration",
                items: [{
                    value: "none", label: "None", image: "/images/ui/connectors/line-end-none.svg"
                }, {
                    value: "arrow", label: "Arrow", image: "/images/ui/connectors/line-end-arrow.svg"
                }, {
                    value: "circle", label: "Circle", image: "/images/ui/connectors/line-end-circle.svg"
                }],
                callback: () => {
                    closeMenu();
                }
            }));

            if (element.model.canChangeConnectorType !== false) {
                $menu.append($.hr());

                const stepEnabled = (
                    element.model.source &&
                    element.endTarget instanceof NodeElement &&
                    element.endTarget.nodeType !== NodeType.BULLET_TEXT &&
                    element.endTarget.nodeType !== NodeType.NUMBERED_TEXT
                );

                const angleEnabled = (
                    (element.model.source || element.model.target) &&
                    element.model.disableAngle !== true
                );

                $menu.append(controls.createIconDropdownMenu(this, {
                    label: "Connector Type",
                    model: element.model,
                    property: "connectorType",
                    items: [{
                        value: ConnectorType.STRAIGHT,
                        label: "Straight",
                        image: "/images/ui/connectors/connector-straight.svg",
                    }, {
                        value: ConnectorType.STEP,
                        label: "Step",
                        image: "/images/ui/connectors/connector-step.svg",
                        enabled: stepEnabled,
                    }, {
                        value: ConnectorType.ANGLE,
                        label: "Angle",
                        image: "/images/ui/connectors/connector-angle.svg",
                        enabled: angleEnabled,
                    }],
                    callback: () => {
                        closeMenu();
                    }
                }));
            }

            return $menu;
        }
    });
}

export const ConnectorItemSelection = ElementSelection.extend({

    captureMouseEvents: true,
    showSelectionBox: false,

    canDelete: function() {
        return true;
    },

    showOptionsMenu: function() {
        return false;
    },

    onMouseDown: function(event) {
        ElementSelection.prototype.onMouseDown.call(this, event);

        if (this.element.startTarget || this.element.endTarget) {
            return;
        }

        const clickPoint = Convert.ScreenToElementCoordinates(this.element.canvas, this.element, event.pageX, event.pageY);
        if (this.element.connectorPath.containsPoint(clickPoint, 5)) {
            this.selectionLayer.hideWidgets();

            const startPt = new geom.Point(event.pageX, event.pageY);
            const connectorStartPt = new geom.Point(this.element.model.sourcePoint);
            const connectorEndPt = new geom.Point(this.element.model.targetPoint);

            $("body").on("mousemove.drag", event => {
                window.requestAnimationFrame(timestamp => {
                    const offsetX = (startPt.x - event.pageX) / this.element.canvas.canvasScale;
                    const offsetY = (startPt.y - event.pageY) / this.element.canvas.canvasScale;
                    const start = connectorStartPt.offset(-offsetX, -offsetY);
                    const end = connectorEndPt.offset(-offsetX, -offsetY);

                    const container = this.element.parentElement;
                    if (start.x < 0 || end.x < 0 || start.y < 0 || end.y < 0 || start.x > container.bounds.width || start.y > container.bounds.height || end.x > container.bounds.width || end.y > container.bounds.height) {
                        return;
                    }

                    this.element.model.sourcePoint = start;
                    this.element.model.targetPoint = end;

                    container.refreshElement();
                });
            });

            $("body").on("mouseup.drag", () => {
                $("body").off(".drag");
                this.selectionLayer.showWidgets();
                this.element.canvas.updateCanvasModel(false);
            });
        }
    },

    renderControls: function() {
        let container = this.element.parentElement;

        this.$controlBar.addClass("icon-control-bar");

        // TODO ?
        // this.currentPathStyles = this.element.path.svg.style(); // store the current path styles so we can restore in cleanup

        if (!container.startPointsAreLocked || this.element.model.source == null) {
            this.renderConnectorWidget(true);
        }
        if (!container.endPointsAreLocked || (this.element.model.target == null && this.element.model.targetPoint == null)) {
            if (!this.model.endPointIsLocked) {
                this.renderConnectorWidget(false);
            }
        }

        renderConnectorStyleMenu(this);

        if (container.canAddLabels) {
            this.addControl({
                type: controls.BUTTON,
                label: "Add Label",
                callback: () => {
                    let label = this.element.labels.addItem();
                    this.element.canvas.updateCanvasModel(false).then(() => {
                        ds.selection.element = this.element.labels.findChildById(label.id)?.text;
                    });
                }
            });
        }

        if (container.canDeleteConnectors) {
            this.addControl({
                type: controls.BUTTON,
                icon: "delete",
                callback: () => {
                    container.deleteItem(this.element.id);
                    this.element.canvas.updateCanvasModel(false);
                }
            });
        }
    },

    convertPathPtToSelectionLayer(pt) {
        return pt.offset(this.getElementSelectionBounds());
    },

    _layout: function() {
        let selectionBounds = this.getElementSelectionBounds();

        const OFFSET = 5;

        // position the start and end point connector widget
        let startPt = this.element.calculatedProps.start.offset(this.element.parentElement.canvasBounds.position).multiply(app.canvasScale).offset(-selectionBounds.left, -selectionBounds.top);
        this.$el.find(".start").left(startPt.x - OFFSET).top(startPt.y - OFFSET);

        let endPt = this.element.calculatedProps.end.offset(this.element.parentElement.canvasBounds.position).multiply(app.canvasScale).offset(-selectionBounds.left, -selectionBounds.top);
        this.$el.find(".end").left(endPt.x - OFFSET).top(endPt.y - OFFSET);

        // create any adjustment widgets
        this.$el.find(".connector-adjustment-widget").remove();
        let adjY;
        for (let point of this.element.connectorPath.points) {
            if (point.adjustmentId) {
                let $adjustmentWidget = this.createAdjustmentWidget(point);
                let pt = this.element.connectorPath.getSegment($adjustmentWidget.data("pointIndex")).midpoint.multiply(this.canvasScale);
                $adjustmentWidget.left(pt.x - 5).top(pt.y - 5);
                adjY = pt.y;
            }
        }

        if (adjY) {
            // if there is an adjustment handle, position the control bar below the lowest point to ensure it's not occluding anything
            let lowestPoint = startPt.y > endPt.y ? startPt : endPt;
            this.$controlBar.left(lowestPoint.x - this.$controlBar.width() / 2).top(lowestPoint.y + 20);
        } else {
            let pointOnLine = this.canvasToSelectionCoordinates(this.element.calculatedProps.path.getPointAt(0.5));
            this.$controlBar.left(pointOnLine.x - this.$controlBar.width() / 2).top(pointOnLine.y - this.$controlBar.height() / 2);
            if (this.element.calculatedProps.path.length < 200) {
                // if the path is short, position controlbar beneath the lowest point in the path so we don't occlude the end points
                this.$controlBar.top(_.maxBy(this.element.calculatedProps.path.points.map(p => p.y)) - 10);
            }
        }
    },

    createAdjustmentWidget: function(point) {
        let $widget = this.$el.addEl($.div("connector-adjustment-widget control"));
        $widget.data("pointIndex", this.element.connectorPath.points.indexOf(point));

        $widget.on("mousedown", event => {
            event.stopPropagation();
            app.isDraggingItem = true;

            $("body").on("mousemove.drag", event => {
                event.stopPropagation();

                window.requestAnimationFrame(timestamp => {
                    app.mainView.editorView.selectionLayer.hideWidgets();

                    let mousePt = new geom.Point(event.pageX, event.pageY).offset(-this.$el.offset().left, -this.$el.offset().top).multiply(1 / app.canvasScale);

                    if (point.adjustmentDirection == "H") {
                        this.element.model.adjustments[point.adjustmentId] = mousePt.x;
                    } else {
                        this.element.model.adjustments[point.adjustmentId] = mousePt.y;
                    }

                    if (this.element.canRefreshElement) {
                        this.element.refreshElement();
                    } else {
                        this.element.canvas.refreshCanvas({ suppressRefreshCanvasEvent: true });
                    }
                });
            });

            $("body").on("mouseup.drag", event => {
                event.stopPropagation();
                $("body").off(".drag");

                app.isDraggingItem = false;
                app.mainView.editorView.selectionLayer.showWidgets();

                this.element.canvas.updateCanvasModel(false);
            });
        });
        return $widget;
    },

    renderConnectorWidget: function(isStart) {
        let $el = this.$el.addEl($.div("connector-dragger control"));
        if (isStart) {
            $el.addClass("start");
        } else {
            $el.addClass("end");
        }

        $el.on("mousedown", event => {
            event.stopPropagation();
            app.isDraggingItem = true;

            app.mainView.editorView.selectionLayer.hideWidgets();

            let targetElement;

            $("body").on("mousemove.drag", event => {
                event.stopPropagation();

                window.requestAnimationFrame(() => {
                    // null out the source/target of the connector because we will be setting source/target point in mousemove
                    if (isStart) {
                        this.element.model.source = null;
                    } else {
                        this.element.model.target = null;
                    }

                    let mousePt = new geom.Point(event.pageX, event.pageY).offset(-this.$el.offset().left, -this.$el.offset().top).multiply(1 / app.canvasScale);

                    if (event.shiftKey) {
                        // constrain to 45deg angles
                        let startPt;
                        if (isStart) {
                            startPt = _.last(this.element.connectorPath.points);
                        } else {
                            startPt = this.element.connectorPath.points[0];
                        }

                        let length = startPt.distance(mousePt);
                        let angle = startPt.angleToPoint(mousePt);
                        angle = Math.round(angle / 45) * 45;
                        mousePt = startPt.offsetAngle(angle, length);
                    }

                    // check if the mouse is over another node
                    let mouseOverElement = _.find(this.element.parentElement.containerElement.itemElements, element => element.bounds.inflate(20).contains(mousePt));

                    if (mouseOverElement && mouseOverElement != targetElement) {
                        // if we are over an element, set it as the new target element and draw it's anchor points
                        targetElement = mouseOverElement;
                        this.selectionLayer.renderAnchorPoints(targetElement);
                    } else if (mouseOverElement == null) {
                        // if we aren't over an element, clear the target element and remove any anchor points
                        targetElement = null;
                        this.selectionLayer.renderAnchorPoints(null);
                    }

                    this.selectionLayer.$el.find(".anchor-widget.selected").removeClass("selected"); // clear any previouslyselected anchor

                    // get the anchor type if we are dragging over an anchor widget
                    let anchor;// = AnchorType.FREE;
                    this.$el.find(".anchor-widget.selected").removeClass("selected");
                    if ($(event.target).hasClass("anchor-widget")) {
                        anchor = $(event.target).data("anchor");
                        $(event.target).addClass("selected");
                    }

                    // point the connector model at the drag mouse pt and refresh canvas to draw
                    if (isStart) {
                        this.element.model.startAnchor = anchor;
                        if (targetElement && anchor) {
                            this.element.model.adjustments = null;
                            this.element.model.source = targetElement.id;
                            this.element.model.sourcePoint = null;
                        } else {
                            this.element.model.sourcePoint = mousePt;
                            this.element.model.source = null;
                        }
                    } else {
                        this.element.model.endAnchor = anchor;
                        if (targetElement && anchor) {
                            this.element.model.adjustments = null;
                            this.element.model.target = targetElement.id;
                            this.element.model.targetPoint = null;
                        } else {
                            this.element.model.targetPoint = mousePt;
                            this.element.model.target = null;
                        }
                    }

                    this.element.parentElement.refreshElement();
                });
            });
            $("body").on("mouseup.drag", event => {
                event.stopPropagation();
                app.isDraggingItem = false;
                $("body").off(".drag");

                this.element.canvas.updateCanvasModel(false);

                app.mainView.editorView.selectionLayer.showWidgets();
                this.selectionLayer.renderAnchorPoints(null);
            });
        });
    }
});

export const ConnectorItemLabelSelection = CollectionItemElementSelection.extend({

    canDrag() {
        return true;
    },

    renderControls() {
        this.createDeleteComponentWidget({
            target: this.element,
            action: () => {
                // ds.selection.element = null;
                let containerElement = this.element.parentElement;
                containerElement.deleteItem(this.element.id);
                this.element.canvas.updateCanvasModel(false);
            }
        });
    },

    setupGridDragging: function() {
        let dragProps = {
            dragItem: this.element,
            containerElement: this.element.findClosestOfType(CollectionElement)
        };

        this.$el.makeDraggable({
            element: dragProps.dragItem,
            axis: this.getDragAxis(),
            dragDistance: 5,
            check: event => this.onCheckDrag(event),
            start: event => this.onStartDrag(event, dragProps),
            drag: (event, position) => this.onDrag(event, position, dragProps),
            stop: (event, position) => this.onStopDrag(event, position, dragProps),
        });
    },

    onStartDrag(event, dragProps) {
        app.isDraggingItem = true;
        app.mainView.editorView.selectionLayer.hideWidgets();
    },

    onDrag(event, dragProps) {
        let connector = this.element.findClosestOfType(ConnectorItem);

        let percentage = connector.connectorPath.findClosestPointAsPercentageOfLength(dragProps.elementPosition);
        let pointOnLine = connector.connectorPath.getPointAt(percentage);
        let segment = connector.connectorPath.getSegmentForPoint(pointOnLine);

        if (segment && segment.isHorizontal()) {
            if (dragProps.elementPosition.y < pointOnLine.y - 20) {
                this.element.model.offset = -1;
            } else if (dragProps.elementPosition.y > pointOnLine.y + 20) {
                this.element.model.offset = 1;
            } else {
                this.element.model.offset = 0;
            }
        } else if (segment && segment.isVertical()) {
            if (dragProps.elementPosition.x < pointOnLine.x - 20) {
                this.element.model.offset = -1;
            } else if (dragProps.elementPosition.x > pointOnLine.x + 20) {
                this.element.model.offset = 1;
            } else {
                this.element.model.offset = 0;
            }
        } else {
            this.element.model.offset = 0;
        }

        this.element.model.position = percentage;

        this.element.canvas.refreshCanvas({ suppressRefreshCanvasEvent: true });
    },

    onStopDrag(event, dragProps) {
        app.mainView.editorView.selectionLayer.showWidgets();
        this.element.canvas.updateCanvasModel(false);
    }

});

export const editors = {
    ConnectorItemSelection,
    ConnectorItemLabelSelection
};
