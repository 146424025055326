import React from "reactn";
import { _ } from "js/vendor";
import getLogger, { LogGroup } from "js/core/logger";
import { timeoutRace, loadImage } from "js/core/utilities/promiseHelper";
import { ASSET_LOAD_TIMEOUT, AssetType } from "common/constants";
import { getCSSTransform } from "js/core/utilities/geom";
import { getElementTransition, SVGGroup, ELEMENT_TRANSITION_DURATION } from "js/core/utilities/svgHelpers";
import { renderAdjustmentFilter, renderColorFilter } from "js/core/utilities/svgFilters";
import { ds } from "js/core/models/dataService";
import { isRenderer } from "js/config";

import { MediaElement } from "./MediaElement";

const logger = getLogger(LogGroup.ELEMENTS);

class Picture extends MediaElement {
    async _load() {
        if (
            this.asset &&
            this.hasMedia &&
            this.assetId == this.asset.id &&
            this.modifiedAt == this.asset.get("modifiedAt")
        ) {
            return;
        }

        if (this.asset) {
            this.modifiedAt = this.asset.get("modifiedAt");
        }

        if (this.assetId) {
            try {
                const asset = await ds.assets.getAssetById(this.assetId, AssetType.IMAGE);

                // perform resizing, as required - for video elements this
                // function does not exist
                if (asset.resizeIfNeeded) {
                    asset.resizeIfNeeded();
                }

                this.assetUrl = await asset.getURL(this.initialSizeToLoadMediaAt);
                this.asset = asset;

                this.isOptimalSizeLoaded = false;
                this.hasMedia = true;
            } catch (err) {
                logger.error(err, "[Picture] failed to load asset", { slideId: this.canvas.dataModel?.id, assetId: this.assetId });
                this.hasMedia = false;
            }
        } else {
            this.asset = null;
            this.hasMedia = false;
        }
    }

    filterVersion = Date.now()

    renderChildren(transition) {
        let props = this.calculatedProps;
        if (this.asset) {
            // If the optimal size image hasn't been loaded, load it and then refresh to render
            if (!this.isOptimalSizeLoaded && !isRenderer && !this.canvas.isExport) {
                // Setting isOptimalSizeLoaded to true immediately to avoid multiple loadOptimalSize() runs
                this.isOptimalSizeLoaded = true;
                this.canvas.layouter.runPostRender(() => {
                    if (transition) {
                        // We want to load the optimal size only after the transition is finished to avoid
                        // breaking the animation when the optimal sized image is loaded and rendered
                        _.delay(() => this.loadOptimalSize(), ELEMENT_TRANSITION_DURATION);
                    } else {
                        this.loadOptimalSize();
                    }
                });
            }

            return this.renderImage(props, transition);
        } else {
            return (
                <SVGGroup key={this.id}>
                    <g id="imageNode"></g>
                </SVGGroup>
            );
        }
    }

    updateFilterVersion(value = Date.now()) {
        this.filterVersion = value;
    }

    get filterId() {
        return `filter_${this.uniqueId}_${this.filterVersion.toString("16")}`;
    }

    renderImage(props, transition) {
        let { transformProps } = props;

        let imageFilterId;
        let colorFilter;
        if (props.filter && props.filter != "None" && props.filter != "none") {
            if (this.colorFilters.includes(props.filter)) {
                colorFilter = renderColorFilter(this, props);
                imageFilterId = `url(#${this.filterId}-color-filter)`;
            } else {
                imageFilterId = `url(#${props.filter})`;
            }
        }

        let adjustmentFilter;
        if (props.filterBrightness || props.filterBlur || props.filterContrast) {
            adjustmentFilter = renderAdjustmentFilter(this, props);
        }

        // create a unique ID to help apply styling
        const hasImageFilter = !!imageFilterId;
        const { filterId } = this;

        return (
            <React.Fragment key={this.id}>
                {/* Safari struggles respecting the attribute change, but a unique style each time works well */}
                {hasImageFilter && (
                    <style type="text/css">{`
                        #${filterId} {
                            filter: ${imageFilterId};
                        }
                    `}</style>
                )}
                <SVGGroup overflowHidden>
                    <g id="imageNode"
                        filter={adjustmentFilter ? `url(#${this.filterId}-adj-filter)` : null}
                        style={{
                            transform: getCSSTransform(transformProps),
                            transition: getElementTransition(transition)
                        }}
                    >
                        {adjustmentFilter}
                        {colorFilter}
                        <image
                            id={filterId}
                            href={this.assetUrl}
                            width={this.mediaSize.width}
                            height={this.mediaSize.height}
                            style={{ imageOrientation: "from-image" }}
                            onLoad={this.getImageOnLoadPromiseResolver(this.assetUrl)}
                        />
                    </g>
                </SVGGroup>
            </React.Fragment>
        );
    }

    loadImageFromURL(url, size) {
        const asset = this.asset;
        return timeoutRace(ASSET_LOAD_TIMEOUT, loadImage(url)
            .catch(() => {
                logger.warn("[Picture] could not load asset, will try again without cache", { slideId: this.canvas.dataModel?.id, assetId: this.assetId });
                return asset.getURL(size, true).then(url => loadImage(url));
            }));
    }
}

class Logo extends Picture {
    get isTransparent() {
        return this.asset.get("hasAlpha");
    }

    get backgroundColor() {
        if (this.asset.get("hasAlpha")) {
            return "none";
        } else {
            return "white";
        }
    }

    // get showBackdrop() {
    //     return this.model.showBackdrop ?? false;
    // }

    get constrainAspectRatio() {
        return "fit";
    }

    minScale(size) {
        return this.calcFitMedia(size, 0.5).scale;
    }

    maxScale(size) {
        return this.calcFitMedia(size, 1).scale;
    }

    getDefaultMediaTransform(size) {
        return this.calcFitMedia(size, this.options.defaultScale || 0.9);
    }

    _build() {
        super._build();

        // if (this.model.showBackdrop && this.pre) {
        //     this.createDecoration({
        //         type: "frame",
        //         shape: "rect",
        //         fillColor: "white"
        //     });
        // }
        // this.styles.paddingLeft = this.styles.paddingRight = this.styles.paddingTop = this.styles.paddingBottom = 15;// * options.scale;
    }
    //
    // _calcProps(props, options) {
    //     let calculatedProps = super._calcProps(props, options);
    //
    //     if (this.showBackdrop) {
    //         const SCALE = 0.75;
    //
    //         let assetSize = this.mediaSize.scale(props.transformProps.scaleX * SCALE);
    //         let framedSize = assetSize.inflate(10);
    //
    //         props.transformProps.scaleX *= SCALE;
    //         props.transformProps.scaleY *= SCALE;
    //
    //         // props.transformProps.translateX = framedSize.width / 2 - assetSize.width / 2;
    //         // props.transformProps.translateY = framedSize.height / 2 - assetSize.height / 2;
    //
    //         // calculatedProps.size = framedSize;
    //     }
    //     return calculatedProps;
    // }

    // renderChildren(transition) {
    //     let image = super.renderChildren(transition);
    //
    //     // if (this.model.showBackdrop && !this.parentElement.type == "AutoSizeContentElement") {
    //     //     let transform = this.calculatedProps.transformProps;
    //     //     let backdropBounds = new geom.Rect(transform.translateX, transform.translateY, this.mediaSize.width * transform.scaleX, this.mediaSize.height * transform.scaleY).inflate(20);
    //     //     return [
    //     //         <SVGGroup>
    //     //             <rect fill="white"
    //     //                 x={backdropBounds.left}
    //     //                 y={backdropBounds.top}
    //     //                 width={backdropBounds.width}
    //     //                 height={backdropBounds.height}/>
    //     //         </SVGGroup>,
    //     //         image
    //     //     ];
    //     // } else {
    //         return image;
    //     // }
    // }
}

export { Picture, Logo };
