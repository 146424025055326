import * as geom from "js/core/utilities/geom";
import { _ } from "js/vendor";
import { getValueOrDefault } from "js/core/utilities/extensions";
import { Shape } from "js/core/utilities/shapes";

import { TextElement } from "../base/TextElement";
import { CollectionElement, CollectionItemElement } from "../base/CollectionElement";
import { TextGroup } from "../base/TextGroup";
import { Icon } from "../base/MediaElements/IconElement";
import { SVGPathElement } from "../base/SVGElement";

export class VerticalTaskLists extends CollectionElement {
    static get schema() {
        return {
            showTitles: true,
            style: "box",
            taskLayout: "stack"
        };
    }

    getChildItemType() {
        return VerticalTaskListsColumn;
    }

    get maxItemCount() {
        return 7;
    }

    get isVertical() {
        return this.model.orientation == "vertical";
    }

    get lockColumns() {
        return getValueOrDefault(this.options.lockColumns, false);
    }

    get showTitles() {
        return this.model.showTitles;
    }

    get taskLayout() {
        return this.model.taskLayout;
    }

    get userStyle() {
        let style = getValueOrDefault(this.model.style, "box");
        if (style == "divider" && this.itemElements.length == 1) {
            style = "none";
        }
        return style;
    }

    _loadStyles(styles) {
        // load userStyle
        styles.applyStyles(styles.styles[this.userStyle]);
    }

    _calcProps(props, options) {
        let { size } = props;

        let maxHeaderHeight = 0;
        if (this.showTitles) {
            const MAX_ROW_HEADER_HEIGHT = 200;
            // calculate the optimal header height from all the columns
            for (let row of this.itemElements) {
                let headerProps = row.columnHeader.calcProps(new geom.Size(size.width / this.itemCount, MAX_ROW_HEADER_HEIGHT), {
                    autoHeight: true,
                    styles: this.styles.VerticalTaskListsColumn.label
                });
                maxHeaderHeight = Math.max(maxHeaderHeight, headerProps.size.height);
            }
        }

        let layouter = this.getLayouter(props, this.itemElements, size);
        layouter.calcHorizontalLayout({
            itemOptions: {
                headerHeight: maxHeaderHeight
            }
        });

        return { size };
    }
}

export class VerticalTaskListsColumn extends CollectionItemElement {
    get selectionPadding() {
        return 0;
    }

    get canDrag() {
        return !this.parentElement.lockColumns;
    }

    get canDelete() {
        return !this.parentElement.lockColumns;
    }

    get showTitle() {
        return this.parentElement.showTitles;
    }

    async convertToAuthoring(groupElement, groupChildren, convertToAuthoring) {
        // gather up individual items first
        const items = Object.values(this.elements.taskList.elements);

        // remove the exported items
        delete this.elements.taskList;

        // export the container or divided areas
        if (this.parentElement.model.style === "divider") {
            await convertToAuthoring(this);
        } else {
            await groupElement(this);
        }

        // then export each of the items
        for (const child of items) {
            await groupElement(child);
        }
    }

    _build() {
        if (this.showTitle) {
            this.columnHeader = this.addElement("label", () => ColumnHeaderTextElement, {
                placeholder: "Type title",
                canRollover: this.canDelete,
                canEdit: this.canDelete,
                canSelect: this.canDelete,
                isTabbable: this.canDelete,
            });
        }
        this.taskList = this.addElement("taskList", () => VerticalTaskList, {
            orientation: "vertical"
        });
    }

    _calcProps(props, options) {
        let { size } = props;
        let y = 0;

        if (this.decoration && this.parentElement.model.style == "divider" && this.itemIndex == this.itemCount - 1) {
            this.decoration.updateStyles({ type: "none" });
        }

        if (this.showTitle) {
            let headerProps = this.columnHeader.calcProps(new geom.Size(size.width, options.headerHeight), options);
            headerProps.bounds = new geom.Rect(0, 0, headerProps.size);
            y += headerProps.size.height;
        }

        let taskListProps = this.taskList.calcProps(new geom.Size(size.width, size.height - y));
        taskListProps.bounds = new geom.Rect(0, y, taskListProps.size);

        return { size };
    }
}

class ColumnHeaderTextElement extends TextElement {
    get selectionPadding() {
        return { top: 10, bottom: 10, left: 0, right: 0 };
    }
}

export class VerticalTaskList extends CollectionElement {
    getChildItemType() {
        return VerticalTaskElement;
    }

    get _canSelect() {
        return false;
    }

    get _canRollover() {
        return false;
    }

    get minItemCount() {
        return 0;
    }

    _calcProps(props, options) {
        let { size } = props;

        let rootElement = this.getRootElement();

        let fixedItemHeight;

        switch (rootElement.taskLayout) {
            case "stack":
                // leave null so items try to use their calculated width
                break;
            case "fill":
                fixedItemHeight = (size.height - (this.itemCount - 1) * this.styles.vGap) / this.itemCount;
                break;
            case "fit":
                let maxItemsInColumn = _.maxBy(this.getRootElement().itemCollection, c => c.items.length).items.length;
                fixedItemHeight = (size.height - (maxItemsInColumn - 1) * this.styles.vGap) / maxItemsInColumn;
                break;
        }

        let layouter = this.getLayouter(props, this.itemElements, size);
        layouter.distributeVertically({
            gap: this.styles.vGap,
            itemOptions: {
                fixedItemHeight: fixedItemHeight
            },
            reserveMinHeights: true
        });
        props.isFit = layouter.isFit && this.itemElements.filter(item => !item.text.isTextFit).length == 0;

        return { size };
    }
}

class VerticalTaskElement extends CollectionItemElement {
    get selectionPadding() {
        return 0;
    }

    get _canSelect() {
        return true;
    }

    get minHeight() {
        return this.text.minHeight;
    }

    get hasIcon() {
        return this.model.icon && this.model.icon != "none";
    }

    _loadStyles(styles) {
        if (this.hasIcon) {
            styles.text.title.paddingRight = 40;
        }
    }

    getBackgroundColor(forElement) {
        if (forElement !== this.shape && forElement?.isChildOf(this)) {
            return this.getShapeFillColor(this.shape);
        }

        return super.getBackgroundColor(forElement);
    }

    _build() {
        this.shape = this.addElement("shape", () => SVGPathElement);

        if (this.hasIcon) {
            this.icon = this.addElement("icon", () => Icon, {
                icon: this.model.icon,
            });
        }

        this.text = this.addElement("text", () => TextGroup, {
            autoHeight: true,
            showAddButton: false,
            title: {
                constrainWidth: true,
                scaleTextToFit: true
            }
        });
    }

    _calcProps(props, options) {
        let { size } = props;

        if (options.fixedItemHeight) {
            size.height = options.fixedItemHeight - this.styles.paddingTop - this.styles.paddingBottom;
        }

        let textProps = this.text.calcProps(size);
        textProps.bounds = new geom.Rect(0, 0, textProps.size);

        if (this.hasIcon) {
            let iconProps = this.icon.calcProps(new geom.Size(30, 30));
            iconProps.bounds = new geom.Rect(size.width - iconProps.size.width, Math.min(0, textProps.size.height / 2 - iconProps.size.height / 2), iconProps.size);
        }

        if (!options.fixedItemHeight) {
            size.height = textProps.size.height;
        }

        let shapeProps = this.shape.createProps();
        shapeProps.path = Shape.drawRect(new geom.Rect(0, 0, size).inflate(this.styles.padding).zeroOffset(), 6);
        shapeProps.isDecoration = true;
        shapeProps.layer = -1;

        return { size };
    }
}

export const elements = {
    VerticalTaskLists
};
