import { _ } from "js/vendor";
import { ds } from "js/core/models/dataService";
import { controls } from "js/editor/ui";
import { position } from "common/constants";
import { renderReactDialog } from "js/react/renderReactRoot";
import { LinkToSlideDialog } from "js/react/views/Editor/LinkToSlideDialog";
import PresentationEditorController from "js/editor/PresentationEditor/PresentationEditorController";

import { CollectionElementSelection, CollectionItemElementSelection } from "../CollectionElementEditor";
import { ElementOptionsMenu } from "../BaseElementEditor";

export const AgendaSelection = CollectionElementSelection.extend({

    renderControls: function() {
        this.addControl({
            position: position.BOTTOM_CENTER,
            type: controls.BUTTON,
            label: "Add Agenda Item",
            icon: "add_circle",
            enabled: this.element.maxItemCount ? (this.element.itemCount < this.element.maxItemCount) : true,
            callback: () => {
                this.addItem({
                    model: {
                        type: "item"
                    }
                }).then(item => {
                    if (item) {
                        this.selectNewItemText(item.id);
                    }
                });
            }
        });

        this.addControl({
            position: position.BOTTOM_CENTER,
            type: controls.BUTTON,
            label: "Add Section",
            icon: "add_circle",
            enabled: this.element.maxItemCount ? (this.element.itemCount < this.element.maxItemCount) : true,
            callback: () => {
                this.addItem({
                    model: {
                        type: "section"
                    }
                }).then(item => {
                    if (item) {
                        this.selectNewItemText(item.id);
                    }
                });
            }
        });
    }
});

const AgendaOptionsMenu = ElementOptionsMenu.extend({

    renderControls() {
        this.addControl({
            type: controls.NUMERIC,
            label: "Start Numbering At",
            property: "startNum",
            min: 1,
            max: 9999,
            step: 1
        });
    }

});

const AgendaItemSelection = CollectionItemElementSelection.extend({

    renderControls: function() {
        // if this is the slide editor, then it means they are changing
        // a shared slide, which is not supported
        if (PresentationEditorController.isSingleSlideEditor) {
            return;
        }

        let isMissingSlide = false;
        let label = "Link to Slide...";
        let icon = "link";
        if (this.element.model.linkToSlide) {
            let slideIndex = ds.selection.presentation.slides.findIndex(slide => slide.id == this.element.model.linkToSlide);
            if (slideIndex > -1) {
                label = `Linked to Slide #${slideIndex + 1}`;
            } else {
                label = "Linked to Missing Slide";
                icon = "warning";
                isMissingSlide = true;
            }
        }

        let $linkButton = this.addControl({
            type: controls.BUTTON,
            label: label,
            icon: icon,
            callback: () => {
                renderReactDialog(LinkToSlideDialog, {
                    presentation: ds.selection.presentation,
                    linkedSlide: this.element.model.linkToSlide,
                    callback: slideId => {
                        this.element.model.linkToSlide = slideId;
                        ds.selection.element = null;
                        ds.selection.element = this.element;
                        this.element.canvas.updateCanvasModel(false);
                    }
                });
            }
        });

        if (isMissingSlide) {
            $linkButton.css("background", "orangered");
        }
    }

});
const AgendaSectionSelection = CollectionItemElementSelection.extend({});

export const editors = {
    AgendaSelection,
    AgendaOptionsMenu,
    AgendaItemSelection,
    AgendaSectionSelection,
};
