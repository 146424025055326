import { _ } from "js/vendor";
import * as geom from "js/core/utilities/geom";

import { ElementLayouter } from "../../../layouts/ElementLayouter";

ElementLayouter.prototype.calcHorizontalTreeLayout = function(options = {}) {
    const hGap = this.element.styles.hGap;
    let vGap = this.element.styles.vGap;

    const MIN_VGAP = 10;
    const MAX_ITEM_WIDTH = 200;
    const MIN_ITEM_WIDTH = 150;
    const optimalWidth = (this.containerSize.width - (this.element.rowCount - 1) * hGap) / this.element.rowCount;
    const itemWidth = Math.clamp(optimalWidth, MIN_ITEM_WIDTH, MAX_ITEM_WIDTH);

    const calcChildrenHeight = node => {
        if (node.childNodes.length > 0) {
            let totalHeight = -vGap;
            for (const childNode of node.childNodes) {
                totalHeight += calcTotalHeight(childNode) + vGap;
            }
            return totalHeight;
        } else {
            return 0;
        }
    };

    const calcTotalHeight = node => {
        return Math.max(calcChildrenHeight(node), node.calculatedProps.size.height);
    };

    const layoutNode = (node, position) => {
        const nodeHeight = node.calculatedProps.size.height;
        const childrenHeight = calcChildrenHeight(node);
        const totalHeight = calcTotalHeight(node);
        const fillHeight = options.isTable;

        let nodeBounds;
        if (options.isTable) {
            nodeBounds = new geom.Rect(position.x, position.y, itemWidth, totalHeight);
        } else {
            let yShift = 0;
            if (childrenHeight && childrenHeight > nodeHeight) {
                yShift = (childrenHeight - nodeHeight) / 2;
            }
            nodeBounds = new geom.Rect(position.x, position.y + yShift, node.calculatedProps.size);
        }

        const nodeProps = node.calcProps(nodeBounds.getSize(), { fillHeight });
        nodeProps.bounds = nodeBounds;

        let childY = 0;
        for (const childNode of node.childNodes) {
            const childPosition = new geom.Point(position.x + childNode.calculatedProps.size.width + hGap, position.y + childY);
            if (!options.isTable && childrenHeight < nodeHeight) {
                childPosition.y += (nodeHeight - childrenHeight) / 2;
            }
            layoutNode(childNode, childPosition);
            childY += calcTotalHeight(childNode) + vGap;
        }
    };

    const rootNode = this.items.find(item => item.model.parent == null);

    for (const node of this.items) {
        // Assigning default size, will be recalced by layoutNode()
        node.calcProps(new geom.Size(itemWidth, 200));
    }

    let totalWidth = 0;
    let totalHeight = calcTotalHeight(rootNode);

    while (totalHeight > this.containerSize.height && vGap > MIN_VGAP) {
        vGap -= 1;
        totalHeight = calcTotalHeight(rootNode);
    }

    layoutNode(rootNode, new geom.Point(0, 0));

    for (const node of this.items) {
        totalWidth = Math.max(totalWidth, node.bounds.right);
        totalHeight = Math.max(totalHeight, node.bounds.bottom);
    }

    this.props.isFit = (totalWidth <= this.containerSize.width && totalHeight <= this.containerSize.height);
    this.size = new geom.Size(totalWidth, totalHeight);

    this.hGap = hGap;

    return this;
};
