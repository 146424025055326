import React, { Component } from "react";
import styled from "styled-components";
import { Button, DialogContent } from "@material-ui/core";
import { BeautifulDialog, ShowConfirmationDialog } from "./BaseDialog";
import { getStaticUrl } from "js/config";
import { DataSourceManager } from "js/core/services/dataSourceManager";

const Container = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 25px 30px;
    gap: 15px;
    color: #222;
    text-align: center;
    .main-text {
        font-weight: 600;
        font-size: 23px;
        padding: 10px 10px 0px;
    }
    .source-url-text {
        font-weight: 600;
        font-size: 14px;
        & a { color: #11A9E2; }
    }
    .description-text {
        font-weight: 400;
        font-size: 16px;
    }
    .buttons-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 25px 5px 0px;
    }
`;

interface Props {
    element: any,
    elementEditor: any,
    closeDialog: Function,
}

interface State {
    linkedSourceUrl: string;
    linkedSourceName: string;
}

export default class DataLockedDialog extends Component<Props, State> {
    state = { linkedSourceUrl: "", linkedSourceName: "" };

    componentDidMount() {
        this.fetchDataSource();
    }

    fetchDataSource = async () => {
        const { dataSourceId } = this.props.element.model.dataSourceLink || {};
        if (dataSourceId) {
            const dataSourceEntry = await DataSourceManager.fetchById(dataSourceId, true);

            const { title: linkedSourceName, url: linkedSourceUrl } = dataSourceEntry.sourceMetadata;
            this.setState({ linkedSourceName, linkedSourceUrl });
        }
    }

    handleUnlink = () => {
        const { element, elementEditor, closeDialog } = this.props;

        ShowConfirmationDialog({
            title: "Are you sure you want to unlink this data?",
            message: "Once unlinked your data will no longer update when your source file is updated.",
            okButtonLabel: "Unlink",
            acceptCallback: () => {
                element.removeDataSource();

                element.model.dataSourceLink = null;
                element.canvas.updateCanvasModel(false);
                if (elementEditor.refreshRender) elementEditor.refreshRender();

                closeDialog();
            }
        });
    }

    render() {
        const { linkedSourceName, linkedSourceUrl } = this.state;

        return (
            <BeautifulDialog closeDialog={this.props.closeDialog}>
                <DialogContent>
                    <Container>
                        <img src={getStaticUrl(`/images/bai-bot/bai-bot-neutral.svg`)} width={60} height={60}></img>
                        <span className="main-text">Data is linked to an external source and cannot be edited.</span>
                        <span className="source-url-text">{
                            linkedSourceUrl
                                ? <a href={linkedSourceUrl} target="_blank">{linkedSourceName ? `[${linkedSourceName}]` : "[File Name]"}</a>
                                : linkedSourceName ? `[${linkedSourceName}]` : "[File Name]"
                        }</span>
                        <span className="description-text">Edit data from the source file or unlink your data. Your slide will no longer update automatically if unlinked.</span>
                        <div className="buttons-container">
                            <Button onClick={this.handleUnlink} variant="text" color="secondary">Unlink Data</Button>
                            <Button onClick={() => this.props.closeDialog()} variant="text" color="primary">Ok</Button>
                        </div>
                    </Container>
                </DialogContent>
            </BeautifulDialog>
        );
    }
}
