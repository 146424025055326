import * as geom from "js/core/utilities/geom";
import { _ } from "js/vendor";

import { BaseElement } from "../../base/BaseElement";
import { TextElement } from "../../base/TextElement";
import { SVGRectElement } from "../../base/SVGElement";

export default class ConnectorItemLabel extends BaseElement {
    get canSelect() {
        return true;
    }

    get canRollover() {
        return false;
    }

    get passThroughSelection() {
        return false;
    }

    get autoWidth() {
        return true;
    }

    get autoHeight() {
        return true;
    }

    get bindTo() {
        return "text";
    }

    get matchUserScaleWithSiblings() {
        return false;
    }

    get position() {
        return this.model.position != undefined ? this.model.position : 0.5;
    }

    get offset() {
        return this.model.offset != undefined ? this.model.offset : 0;
    }

    _build() {
        if (this.offset === 0) {
            this.background = this.addElement("background", () => SVGRectElement);
        }

        this.text = this.addElement("text", () => TextElement, {
            autoWidth: true,
            autoHeight: true,
        });
    }

    _calcProps(props, options) {
        const { size, children } = props;

        const textProps = this.text.calcProps(size);
        textProps.bounds = new geom.Rect(10, 10, textProps.size);

        if (this.offset === 0) {
            const backgroundProps = this.background.calcProps(size);
            backgroundProps.bounds = textProps.bounds.inflate(10);
        }

        return { size: textProps.size.inflate(10) };
    }
}
