import { controls } from "js/editor/ui";
import { $, _ } from "js/vendor";
import { ds } from "js/core/models/dataService";
import { app } from "js/namespaces.js";
import { HorizontalAlignType, TextEditorEvent } from "common/constants";
import { Convert } from "js/core/utilities/geom";
import { ShowWarningDialog } from "js/react/components/Dialogs/BaseDialog";

import { CollectionElement } from "../../elements/base/CollectionElement";

import { TextElementSelection, TextEditor } from "./textEditor";
import { ElementSelection } from "../BaseElementEditor";

const TextGroupTitleSelection = TextElementSelection.extend({
    renderControls: function() {
        this.setupTextEditor();

        let textGroup = this.element.parentElement;
        this.listenTo(this.textEditor, TextEditorEvent.ENTER_KEY, () => {
            if (textGroup.showBody) {
                ds.selection.element = textGroup.elements.body;
            } else {
                textGroup.showBodyPlaceholder = true;
                textGroup.canvas.refreshCanvas({ transition: false }).then(() => {
                    ds.selection.element = textGroup.elements.body;
                }).catch(err => {
                    textGroup.showBodyPlaceholder = false;
                    textGroup.canvas.revertCanvasModel();
                    ShowWarningDialog({
                        title: "Sorry, we were unable to make this change",
                        message: err.message,
                    });
                });
            }
        });

        if (!textGroup.showBody && textGroup.showAddButton) {
            let $bar = this.renderAddComponentBar();

            $bar.append(controls.createButton(this, {
                label: "Add Text",
                callback: () => {
                    textGroup.showBodyPlaceholder = true;
                    textGroup.canvas.refreshCanvas({ transition: false }).then(() => {
                        ds.selection.element = textGroup.elements.body;
                    }).catch(err => {
                        textGroup.showBodyPlaceholder = false;
                        textGroup.canvas.revertCanvasModel();
                        ShowWarningDialog({
                            title: "Sorry, we were unable to make this change",
                            message: err.message,
                        });
                    });
                }
            }));
            // $addSubtitle.css("left", "calc(50% - 11px)").css("bottom", "-5px");
        }

        this.listenTo(this, TextEditor.DOWN_ARROW, event => {
            if (textGroup.showBody) {
                ds.selection.element = textGroup.elements.body;
                ds.selection.element.overlay.textEditor.setSelection(0);
            }
        });
    },

});

const TextGroupBodySelection = TextElementSelection.extend({
    renderControls: function() {
        this.setupTextEditor();

        this.listenTo(this.textEditor, TextEditorEvent.ENTER_ON_BLANK_LINE, () => {
            let container = this.element.findClosestOfType(CollectionElement);
            if (container) {
                let newItem = container.addItem({
                    col: this.element.model.col,
                }, this.element.itemIndex + 1);
                if (this.element.model.body && this.element.model.body.text.slice(-1).codePointAt(0) === 13) {
                    this.element.model.body.text = this.element.model.body.text.slice(0, -1);
                }
                let textGroup = this.element.parentElement;
                textGroup.showBodyPlaceholder = false;
                ds.selection.element = null;
                container.canvas.updateCanvasModel(false).then(() => {
                    ds.selection.element = container.elements[newItem.id].findChildById("title");
                });
            }
        });

        this.listenTo(this.textEditor, TextEditorEvent.BACKSPACE_ON_BLANK, () => {
            ds.selection.element = this.element.parentElement.title;
            ds.selection.element.overlay.textEditor.setSelectionToEnd();
        });

        this.listenTo(this, TextEditor.UP_ARROW, event => {
            ds.selection.element = this.element.parentElement.elements.title;
            ds.selection.element.overlay.textEditor.setSelectionToEnd();
        });
    },

    cleanUp: function() {
        if (!this.element.model.body || this.element.model.body == "" || this.element.model.body.text == "") {
            this.element.parentElement.showBodyPlaceholder = false;

            // need to defer or the refreshCanvas will intefere with the selection layer rendering anything that was selected from the click
            // that blurred the empty textbox
            _.defer(() => {
                this.element.canvas.refreshCanvasAutoRevert({ transition: false, suppressRefreshCanvasEvent: true });
            });
        }
        TextElementSelection.prototype.cleanUp.apply(this, arguments);
    }
});

export const TextGroupSelection = ElementSelection.extend({

    renderControls() {
        if (this.element.canResize) {
            this.renderResizeHandle();
        }
    },

    renderResizeHandle() {
        this.$resizeHandle = this.$el.addEl($.div("resize-handle control horizontal"));
        this.$resizeHandle.on("mousedown", event => {
            app.isDraggingItem = true;

            ds.selection.element = this.element;

            this.$el.find(".resize-handle").addClass("selected");
            this.selectionLayer.hideWidgets(this.$el.find(".resize-handle, .selection-box"));

            let bounds = this.element.canvasBounds.multiply(this.canvasScale);

            $("body").on("mousemove.drag", event => {
                event.stopPropagation();

                const dragPt = Convert.ScreenToSelectionLayerCoordinates(event.pageX, event.pageY);

                let width;
                switch (this.element.horizontalScaleOrigin ?? HorizontalAlignType.CENTER) {
                    case HorizontalAlignType.LEFT:
                        width = Math.abs(dragPt.x - bounds.left);
                        break;
                    case HorizontalAlignType.RIGHT:
                        width = Math.abs(dragPt.x - bounds.right);
                        break;
                    case HorizontalAlignType.CENTER:
                        width = Math.abs(dragPt.x - bounds.centerH) * 2;
                        break;
                }

                width /= this.element.canvas.canvasScale;
                width -= this.element.selectionPadding * 2;

                this.element.model.textWidth = Math.clamp(width, this.element.minTextWidth || 100, this.element.maxTextWidth || 1280);
                this.element.refreshElement(false);
                this.layout();
            });

            $("body").on("mouseup.drag", event => {
                event.stopPropagation();
                app.isDraggingItem = false;

                $("body").off(".drag");

                this.$el.find(".resize-handle").removeClass("selected");
                this.selectionLayer.showWidgets();

                this.element.canvas.updateCanvasModel(false).then(() => {
                    ds.selection.element = this.element;
                });
            });
        });
    },

    _layout: function() {
        if (this.element.canResize && this.$resizeHandle) {
            this.$resizeHandle.right(-5);
        } else if (this.$resizeHandle) {
            this.$resizeHandle.hide();
        }
    },

});

export const editors = {
    TextGroupTitleSelection,
    TextGroupBodySelection,
    TextGroupSelection
};
