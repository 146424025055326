import { TextElementSelection } from "./textEditor";
import { ds } from "js/core/models/dataService";
import { $, _ } from "js/vendor";
import { app } from "js/namespaces";
import { controls } from "js/editor/ui";
import * as geom from "js/core/utilities/geom";
import { ShowWarningDialog } from "js/react/components/Dialogs/BaseDialog";

import { ElementOptionsMenu, ElementRollover } from "../BaseElementEditor";
import { CollectionElementSelection, CollectionItemElementSelection } from "../CollectionElementEditor";

const TimelineSelection = CollectionElementSelection.extend({
    renderControls: function() {
        this.addControl({
            type: controls.BUTTON,
            label: "Add Milestone",
            icon: "add_circle",
            callback: () => {
                let item = this.element.addItem();
                this.element.distributeMilestones();

                this.element.canvas.updateCanvasModel(true).then(() => {
                    ds.selection.element = this.element.getChild(item.id).infoBlock.text.title;
                }).catch(err => {
                    ShowWarningDialog({
                        title: "Unable to add item",
                        message: err.message,
                    });
                });
            }
        });

        this.addControl({
            type: controls.BUTTON,
            label: "Distribute Evenly",
            icon: "view_column",
            callback: () => {
                this.element.distributeMilestones(true);
                this.element.canvas.updateCanvasModel(true);
            }
        });
    }
});

const TimelineRollover = ElementRollover.extend({

    captureMouseEvents: true,

    renderControls: function() {
        let left = this.elementBounds.left;
        let right = this.elementBounds.right;
        // if (this.element.model.showStartMarker) {
        // left += this.canvasToSelectionCoordinates(this.element.styles.markerSize);
        // }
        // if (this.element.model.showEndMarker) {
        // right -= this.canvasToSelectionCoordinates(this.element.styles.markerSize);
        // }

        let $addMilestoneButton = this.$el.addEl($.div("ui_widget").css("pointer-events", "none"));
        $addMilestoneButton.append($.div("add_component_button button", "Add Milestone").left(-55).top(-40));
        $addMilestoneButton.top(this.elementBounds.height / 2).width(120);
        $addMilestoneButton.append($.div("vertical_line").left(0).top(-30));
        $addMilestoneButton.append($.div("add_item_button button").left(-10).top(0));

        $addMilestoneButton.on("click", event => {
            let x = this.screenToCanvasCoordinates(new geom.Point(event.pageX, event.pageY)).x - this.element.bounds.left;
            // convert x to percentage
            let percentX = (x - this.element.timelineBounds.left) / this.element.timelineBounds.width;
            this.addItem(percentX);
        });

        this.registerSubComponent({
            widget: $addMilestoneButton,
            bounds: new geom.Rect(left, this.elementBounds.height / 2 - 15, right - left, 30),
            rollover: event => {
                $addMilestoneButton.left(event.pageX - this.$el.offset().left).top(this.elementBounds.height / 2 - 10);
            },
        });

        // these widget subcomponents exist just to hide the $addMilestoneButton widget when rolling over a milestone point
        for (let item of this.element.itemElements) {
            let $milestoneDrag = this.$el.addEl($.div("ui_widget"));
            this.registerSubComponent({
                widget: $milestoneDrag,
                target: item,
                bounds: new geom.Rect(this.canvasToSelectionCoordinates(item.bounds).left - 15, this.elementBounds.height / 2 - 15, 30, 30),
                rollover: event => {
                    $addMilestoneButton.hide();
                }
            });
        }
    },

    addItem: function(x) {
        this.element.addItem({ x });
        this.element.canvas.updateCanvasModel(true).catch(err => {
            ShowWarningDialog({
                title: "Unable to add item",
                message: err.message,
            });
        });
        // ds.selection.childElement = _.last(this.element.itemViews);
        // ds.selection.slide.commit();
    }
});

const TimelineItemSelection = CollectionItemElementSelection.extend({
    dragDistance: 1,
    showSelectionBox: false,

    getDragAxis: function() {
        return "x";
    },

    canDrag: function() {
        return true;
    },

    getName: function() {
        return `Milestone #${this.element.getItemIndex() + 1}`;
    },

    renderControls: function() {
        this.$dragHandle = this.$el.addEl($.div("ui_widget").css("pointer-events", "auto"));
    },

    _layout: function(bounds) {
        let elementBounds = this.canvasToSelectionCoordinates(this.element.getRootElement().canvasBounds);

        if (bounds.top < elementBounds.centerV) {
            this.$dragHandle.setBounds(new geom.Rect(0, bounds.height, 30, elementBounds.centerV - bounds.top + 15 - bounds.height));
        } else {
            this.$dragHandle.setBounds(new geom.Rect(0, elementBounds.centerV - bounds.top - 15, 30, bounds.top - elementBounds.centerV + 15));
        }
    },

    onStartDrag: function(event, dragProps) {
        app.isDraggingItem = true;
        ds.selection.rolloverElement = null;
        dragProps.dragItem.isDragging = true;
        app.mainView.editorView.selectionLayer.hideWidgets();
    },

    onDrag: function(event, position, dragProps) {
        dragProps.dragItem.model.x = Math.clamp((position.elementPosition.x - dragProps.containerElement.timelineBounds.left) / dragProps.containerElement.timelineBounds.width, 0.01, 0.99);
        dragProps.dragItem.refreshElement();
    },

    onStopDrag: function(event, position, dragProps) {
        event.stopPropagation(); // prevents selecting when mouse ends drag over another milestone

        dragProps.containerElement.isDraggingItem = false;
        const { dragItem } = dragProps;
        const interp = (position.elementPosition.x - dragProps.containerElement.timelineBounds.left) / dragProps.containerElement.timelineBounds.width;
        dragItem.isDragging = false;
        dragItem.model.x = Math.clamp(interp, 0.01, 0.99);
        dragItem.model.hasBeenDragged = true;

        dragItem.canvas.updateCanvasModel(false).then(() => {
            app.mainView.editorView.selectionLayer.showWidgets();
        });
    }
});

const TimelineMarkerSelection = TextElementSelection.extend({
    getCursor: function() {
        return "text";
    },

    renderControls: function() {
        TextElementSelection.prototype.renderControls.apply(this, arguments);

        this.createDeleteComponentWidget({
            target: this.element,
            action: () => {
                if (this.element.id == "start_marker") {
                    this.element.getRootElement().model.showStartMarker = false;
                } else if (this.element.id == "end_marker") {
                    this.element.getRootElement().model.showEndMarker = false;
                }
                this.element.canvas.updateCanvasModel(true);
            }
        });
    }
});

const TimelineOptionsMenu = ElementOptionsMenu.extend({
    renderControls: function() {
        //this.addControl({
        //    type: controls.NUMERIC,
        //    label: "Scale",
        //    property: "scale",
        //    min: "1",
        //    max: "20",
        //    step: ".1"
        //});
        this.addControl({
            type: controls.TOGGLE,
            label: "Start Marker",
            property: "showStartMarker"
        });

        this.addControl({
            type: controls.TOGGLE,
            label: "End Marker",
            property: "showEndMarker"
        });

        this.addControl({
            type: controls.TOGGLE,
            label: "Milestone Icons",
            property: "showContent"
        });
    }
});

export const editors = {
    TimelineSelection,
    TimelineRollover,
    TimelineItemSelection,
    TimelineMarkerSelection,
    TimelineOptionsMenu,
};
