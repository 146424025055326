import { ds } from "js/core/models/dataService";
import { controls } from "js/editor/ui";
import { app } from "js/namespaces";
import * as geom from "js/core/utilities/geom";
import { _, $, Backbone } from "js/vendor";
import { Key } from "js/core/utilities/keys";
import { ShowWarningDialog } from "js/react/components/Dialogs/BaseDialog";

import { ElementSelection } from "../BaseElementEditor";
import { CollectionItemElementSelection } from "../CollectionElementEditor";

const OrgChartSelection = ElementSelection.extend({
    renderControls: function() {
        this.addControl({
            id: "orgchartLayout",
            type: controls.POPUP_BUTTON,
            label: "Layout",
            items: closeMenu => [{
                type: "control",
                view: () => controls.createIconGrid(this, {
                    cols: 4,
                    value: this.element.model.layout,
                    items: [{
                        value: "expanded",
                        label: "Expanded",
                        icon: "/images/ui/orgchart/org_expanded.svg"
                    }, {
                        value: "compressed",
                        label: "Collapsed",
                        icon: "/images/ui/orgchart/org_collapsed.svg",
                        enabled: this.element.model.layoutDirection == "vertical"
                    }, {
                        value: "stacked",
                        label: "Stacked",
                        icon: "/images/ui/orgchart/org_stacked.svg",
                        enabled: this.element.model.layoutDirection == "vertical"
                    }, {
                        value: "table",
                        label: "Table",
                        icon: "/images/ui/orgchart/org_table.svg"
                    }],
                    callback: value => {
                        if (value == "table" && _.some(this.element.itemElements, node => node.isAssistant)) {
                            return ShowWarningDialog({
                                title: "Sorry, we can't make this change",
                                message: "Assistant nodes are not supported in table layout",
                            });
                        }
                        ds.selection.element = null;
                        this.element.model.layout = value;
                        this.element.markStylesAsDirty();
                        this.element.canvas.updateCanvasModel(true).catch(() => {
                            ShowWarningDialog({
                                title: "Sorry, we can't make this change",
                                message: "The org chart will not fit on this slide with that layout",
                            });
                        });
                    }
                })
            }, {
                type: "divider"
            }, {
                type: "control",
                view: () => controls.createControlGroup(this, {
                    items: [
                        controls.createMultiToggle(this, {
                            label: "Direction",
                            singleLine: true,
                            options: [{
                                value: "vertical", label: "Vertical"
                            }, {
                                value: "horizontal", label: "Horizontal"
                            }],
                            value: this.element.model.layoutDirection,
                            callback: value => {
                                const ERROR_TITLE = "Sorry, we are unable to switch the layout direction";

                                if (value == "horizontal" && _.some(this.element.itemElements, node => node.isAssistant)) {
                                    return ShowWarningDialog({
                                        title: ERROR_TITLE,
                                        message: "Assistant nodes are not supported in horizontal layout",
                                    });
                                }

                                if (value == "horizontal" && this.element.model.layout.equalsAnyOf("stacked", "compressed")) {
                                    this.element.model.layout = "expanded";
                                }
                                if (value == "horizontal" && this.element.model.defaultNodeStyle == "minimal") {
                                    this.element.model.defaultNodeStyle = "box";
                                }
                                if (value == "horizontal" && this.element.model.defaultNodeStyle.equalsAnyOf("photo1", "photo3")) {
                                    this.element.model.defaultNodeStyle = "photo2";
                                }

                                this.element.model.layoutDirection = value;
                                this.element.markStylesAsDirty();
                                this.element.canvas.updateCanvasModel(true).catch(err => {
                                    if (value == "vertical") {
                                        // if we couldn't switch from expanded horizontal to expanded vertical, try compressed vertical
                                        this.element.model.layoutDirection = value;
                                        this.element.model.layout = "compressed";
                                        this.element.canvas.updateCanvasModel(true).catch(err => {
                                            ShowWarningDialog({
                                                title: ERROR_TITLE,
                                                message: "Layout won't fit in this orientation. Try removing one or more nodes from the org chart.",
                                            });
                                        });
                                    } else {
                                        ShowWarningDialog({
                                            title: ERROR_TITLE,
                                            message: "Layout won't fit in this orientation. Try removing one or more nodes from the org chart.",
                                        });
                                    }
                                });
                            }
                        }),
                        controls.createToggle(this, {
                            type: controls.TOGGLE,
                            label: "Hide Root Node",
                            value: this.element.hideRootNode,
                            enabled: this.element.model.layoutDirection == "vertical" && (this.element.model.layout == "compressed" || this.element.model.layout == "expanded") && this.element.rootNode.assistantNodes.length == 0,
                            callback: value => {
                                this.element.model.hideRootNode = value;
                                this.element.markStylesAsDirty();
                                this.element.canvas.updateCanvasModel(true).catch(err => {
                                    ShowWarningDialog({
                                        title: "Sorry, we can't make this change",
                                        message: "Unable to show root node until some nodes are removed.",
                                    });
                                });
                            }
                        })
                    ]
                })
            }]
        });

        if (this.element.layout != "table") {
            this.addControl({
                type: controls.POPUP_BUTTON,
                label: "Style",
                items: () => [{
                    type: "control",
                    view: () => controls.createIconGrid(this, {
                        cols: 4,
                        clearSelectionAfterChange: true,
                        value: this.element.model.defaultNodeStyle,
                        items: [{
                            value: "minimal", label: "Minimal", icon: "/images/ui/orgchart/style_minimal.svg",
                            enabled: this.element.model.layoutDirection == "vertical"
                        }, {
                            value: "box", label: "Box", icon: "/images/ui/orgchart/style_box.svg"
                        }, {
                            value: "circle", label: "Circle", icon: "/images/ui/orgchart/style_circle.svg",
                            enabled: () => {
                                if (this.element.canvas.model.layout.headerPosition == "top" && this.element.rowCount > 3) {
                                    return false;
                                }
                                if (this.element.rowCount > 4) {
                                    return false;
                                }
                            }
                        }, {
                            value: "photo1", label: "Photo1", icon: "/images/ui/orgchart/style_photo1.svg",
                            enabled: this.element.model.layoutDirection == "vertical"
                        }, {
                            value: "photo2", label: "Photo2", icon: "/images/ui/orgchart/style_photo2.svg"
                        }, {
                            value: "photo3", label: "Photo3", icon: "/images/ui/orgchart/style_photo3.svg",
                            enabled: () => {
                                if (this.element.canvas.model.layout.headerPosition == "top" && this.element.rowCount > 3) {
                                    return false;
                                }
                                if (this.element.rowCount > 4) {
                                    return false;
                                }
                                if (this.element.layoutDirection == "horizontal") {
                                    return false;
                                }
                            }
                        }],
                        callback: value => {
                            ds.selection.element = null;
                            this.element.model.defaultNodeStyle = value;
                            this.element.markStylesAsDirty();
                            this.element.canvas.updateCanvasModel(true).catch(err => {
                                ShowWarningDialog({
                                    title: "Sorry, we can't make this change",
                                    message: "Your current org chart won't fit with this style",
                                });
                            });
                        }
                    })
                }]
            });
        }
    }
});

const OrgChartNodeSelection = CollectionItemElementSelection.extend({
    getWidgetPosition() {
        if (this.element.nodeStyle == "circle") {
            return "outer";
        } else {
            return "inner";
        }
    },

    getOffset: function() {
        return 20;
    },

    canDelete: function() {
        return !this.element.isRootNode;
    },

    canDrag: function() {
        return !this.element.isRootNode;
    },

    onClick: function() {
        // Skipping the first click when the selection is rendered
        if (!this.hasClicked) {
            this.hasClicked = true;
            return;
        }

        if (ds.selection.rolloverElement == this.element.content) return;
        event.stopPropagation();
        this.showEditor();
    },

    renderControls: function() {
        let tree = this.element.parentElement;

        let isVertical = this.element.getRootElement().layoutDirection == "vertical";

        let elementId = this.element.id; // we need to store the element id because changing the node style can recreate the element

        this.addControl({
            id: "nodeType",
            type: controls.POPUP_BUTTON,
            label: "Style",
            items: () => [{
                type: "control",
                view: () => controls.createIconGrid(this, {
                    cols: 4,
                    value: this.element.model.hilited ? "emphasized" : this.element.nodeType,
                    items: [{
                        value: "person", label: "Person", icon: "/images/ui/orgchart/node_person.svg"
                    }, {
                        value: "emphasized", label: "Emphasized", icon: "/images/ui/orgchart/node_emphasized.svg"
                    }, {
                        value: "placeholder", label: "Placeholder", icon: "/images/ui/orgchart/node_placeholder.svg"
                    }, {
                        value: "label",
                        label: "Label",
                        icon: "/images/ui/orgchart/node_label.svg",
                        enabled: this.element.getRootElement().layout != "table"
                    }],
                    callback: value => {
                        if (value == "emphasized") {
                            this.element.model.nodeType = "person";
                            this.element.model.hilited = true;
                        } else {
                            this.element.model.nodeType = value;
                            this.element.model.hilited = false;
                        }
                        tree.markStylesAsDirty();
                        this.element.canvas.updateCanvasModel(false).then(() => {
                            ds.selection.element = this.element.getRootElement().getChild(elementId); // because changing the nodeStyle can re-create the childelement type, we need to reselect the correct node
                        }).catch(err => {
                            ShowWarningDialog({
                                title: "Sorry, we can't make this change",
                                message: err.message,
                            });
                        });
                    }
                })
            }, {
                //     type: "divider"
                // },  {
                type: "control",
                view: () => controls.createControlGroup(this, {
                    items: [
                        // controls.createColorPalettePicker(this, {
                        //     label: "Color",
                        //     model: this.element.model,
                        //     property: "color",
                        //     includeAuto: true,
                        // }),
                        controls.createMultiToggle(this, {
                            label: "Connector",
                            model: this.element.model,
                            property: "connectorType",
                            singleLine: true,
                            options: [{
                                value: "solid", label: "Solid"
                            }, {
                                value: "dot", label: "Dotted"
                            }],
                            enabled: this.element.getRootElement().layout != "table" && !this.element.isRootNode
                        }),
                        controls.createMultiToggle(this, {
                            label: "Row Size",
                            singleLine: true,
                            defaultValue: "normal",
                            options: [{
                                value: "normal", label: "Normal"
                            }, {
                                value: "small", label: "Small"
                            }],
                            selectedValue: tree.isRowSmall(this.element.rowIndex) ? "small" : "normal",
                            callback: value => {
                                if (tree.model.smallRows == null) {
                                    tree.model.smallRows = [];
                                }
                                if (value == "small") {
                                    if (!tree.model.smallRows.contains(this.element.rowIndex)) {
                                        tree.model.smallRows.push(this.element.rowIndex);
                                    }
                                } else {
                                    if (tree.model.smallRows.contains(this.element.rowIndex)) {
                                        tree.model.smallRows.remove(this.element.rowIndex);
                                    }
                                }

                                tree.markStylesAsDirty();
                                tree.canvas.updateCanvasModel(true).catch(err => {
                                    ShowWarningDialog({
                                        title: "Sorry, we can't make this change",
                                        message: "There is not enough vertical space to change the size of this row.",
                                    });
                                });
                            },
                            enabled: this.element.getRootElement().layout != "table" && tree.options.forceSmallNodes != true
                        })
                    ]
                })
            }]
        });

        this.addControl({
            type: controls.COLOR_PALETTE_PICKER,
            property: "color",
            includeAuto: true,
        });

        if (this.element.isRootNode && isVertical && tree.layout != "table") {
            this.addControl({
                type: controls.BUTTON,
                label: "Add Assistant",
                callback: () => {
                    this.addAssistant();
                },
                enabled: this.element.assistantNodes.length < 2
            });
        }

        if (this.element.isAssistant) {
            return;
        }

        let isStackedLeafNode = this.element.getRootElement().model.stackBottomNodes && this.element.childNodes.length == 0;

        if (isVertical) {
            let $addBottom = this.$el.addEl($.icon("add_circle", "add_child add_node_btn control"));
            $addBottom.on("click", event => {
                event.stopPropagation();
                if (isStackedLeafNode || !isVertical) {
                    this.addRightSibling();
                } else {
                    this.addChild();
                }
            });

            if (this.element.parentNode) {
                let $addTop = this.$el.addEl($.icon("add_circle", "add_parent add_node_btn control"));
                $addTop.on("click", event => {
                    event.stopPropagation();
                    if (isStackedLeafNode) {
                        this.addLeftSibling();
                    } else {
                        this.addParent();
                    }
                });

                let $addLeft = this.$el.addEl($.icon("add_circle", "add_left_sibling add_node_btn control"));
                $addLeft.on("click", event => {
                    event.stopPropagation();
                    if (isStackedLeafNode) {
                        this.addParent();
                    } else {
                        this.addLeftSibling();
                    }
                });

                let $addRight = this.$el.addEl($.icon("add_circle", "add_right_sibling add_node_btn control"));
                $addRight.on("click", event => {
                    event.stopPropagation();
                    if (isStackedLeafNode) {
                        this.addChild();
                    } else {
                        this.addRightSibling();
                    }
                });
            }
        } else {
            if (this.element.parentNode) {
                let $addBottom = this.$el.addEl($.icon("add_circle", "add_child add_node_btn control"));
                $addBottom.on("click", event => {
                    event.stopPropagation();
                    this.addRightSibling();
                });

                let $addTop = this.$el.addEl($.icon("add_circle", "add_parent add_node_btn control"));
                $addTop.on("click", event => {
                    event.stopPropagation();
                    this.addLeftSibling();
                });

                let $addLeft = this.$el.addEl($.icon("add_circle", "add_left_sibling add_node_btn control"));
                $addLeft.on("click", event => {
                    event.stopPropagation();
                    this.addParent();
                });
            }

            let $addRight = this.$el.addEl($.icon("add_circle", "add_right_sibling add_node_btn control"));
            $addRight.on("click", event => {
                event.stopPropagation();
                this.addChild();
            });
        }
    },

    addChild: _.debounce(function() {
        let tree = this.element.getRootElement();
        let newItem = tree.addItem({
            parent: this.model.id,
            index: this.element.childNodes.length
        });

        tree.canvas.updateCanvasModel(true).then(() => {
            ds.selection.element = tree.getItemElementById(newItem.id);
        }).catch(err => {
            ShowWarningDialog({
                title: "Unable to add node",
                message: err.message,
            });
        });
    }, 500, { leading: true }),

    addParent: _.debounce(function() {
        let tree = this.element.getRootElement();
        let newItem = tree.addItem({
            parent: this.model.parent,
            index: this.model.index
        });
        this.model.parent = newItem.id;

        tree.canvas.updateCanvasModel(true).then(() => {
            ds.selection.element = tree.getItemElementById(newItem.id);
        }).catch(err => {
            ShowWarningDialog({
                title: "Unable to add node",
                message: err.message,
            });
        });
    }, 500, { leading: true }),

    addLeftSibling: _.debounce(function() {
        let tree = this.element.getRootElement();
        let siblings = _.map(this.element.siblingNodes, node => node.model);

        let newItem = tree.addItem({
            parent: this.model.parent
        });

        siblings.insert(newItem, this.element.nodeIndex);
        let index = 0;
        for (let node of siblings) {
            node.index = index++;
        }

        tree.canvas.updateCanvasModel(true).then(() => {
            ds.selection.element = tree.getItemElementById(newItem.id);
        }).catch(err => {
            ShowWarningDialog({
                title: "Unable to add node",
                message: err.message,
            });
        });
    }, 500, { leading: true }),

    addRightSibling: _.debounce(function() {
        let tree = this.element.getRootElement();
        let siblings = _.map(this.element.siblingNodes, node => node.model);

        let newItem = tree.addItem({
            parent: this.model.parent
        });

        siblings.insert(newItem, this.element.nodeIndex + 1);
        let index = 0;
        for (let node of siblings) {
            node.index = index++;
        }

        tree.canvas.updateCanvasModel(true).then(() => {
            ds.selection.element = tree.getItemElementById(newItem.id);
        }).catch(err => {
            ShowWarningDialog({
                title: "Unable to add node",
                message: err.message,
            });
        });
    }, 500, { leading: true }),

    addAssistant: _.debounce(function() {
        let tree = this.element.getRootElement();
        let newItem = tree.addItem({
            parent: this.model.id,
            index: this.element.childNodes.length,
            isAssistant: true
        });

        tree.canvas.updateCanvasModel(true).then(() => {
            ds.selection.element = tree.getItemElementById(newItem.id);
        }).catch(err => {
            ShowWarningDialog({
                title: "Unable to add node",
                message: err.message,
            });
        });
    }, 500, { leading: true }),

    onDeleteItem: _.debounce(function() {
        let orgChart = this.element.parentElement;
        if (orgChart.itemCollection.length > 1) {
            orgChart.deleteItem(this.element.id);
            this.element.canvas.updateCanvasModel(true).then(() => {
                ds.selection.rolloverElement = null;
                ds.selection.element = null;
            });
        } else {
            ShowWarningDialog({
                title: "Oops!",
                message: "Can't delete last item",
            });
        }
    }, 500, { leading: true }),

    showEditor: function() {
        let editor = new OrgChartNodeTextEditor({ node: this.element });

        $("body").append(editor.render().$el);

        editor.$el.left(this.$el.offset().left + this.$el.width() / 2 - editor.$el.width() / 2).top(this.$el.offset().top + this.$el.height() / 2 - editor.$el.height() / 2);
        editor.$el.clickShield(() => {
            editor.commit();
        });

        editor.$el.find(".name").focus();
    },

    setupGridDragging: function() {
        const canvasOffset = $("#selection_layer").offset();

        let tree = this.element.parentElement;

        this.$el.draggable({
            handle: ".drag_button",
            helper: () => {
                return $.div("tree-node-drag-helper", "Drag Node");
            },
            cursorAt: { left: 50 },
            start: (event, ui) => {
                app.mainView.editorView.selectionLayer.hideWidgets($(".tree-node-drop-target"));
            },
            drag: (event, ui) => {
                this.dropTarget = null;
                this.dropIntersection = null;

                $(".tree-node-drop-target").remove();
                let dragBounds = new geom.Rect(ui.offset.left, ui.offset.top, 100, 20);
                for (let target of tree.itemElements) {
                    if (target == this.element) continue;
                    if (target.isChildNodeOf(this.element)) continue;

                    let targetBounds = target.screenBounds;
                    let intersection;

                    if (dragBounds.intersects(new geom.Rect(targetBounds.left, targetBounds.top, 2, targetBounds.height))) {
                        intersection = "left";
                    } else if (dragBounds.intersects(new geom.Rect(targetBounds.right, targetBounds.top, 2, targetBounds.height))) {
                        intersection = "right";
                    } else if (dragBounds.intersects(new geom.Rect(targetBounds.left, targetBounds.bottom, targetBounds.width, 10))) {
                        intersection = "bottom";
                    } else if (dragBounds.intersects(new geom.Rect(targetBounds.left, targetBounds.top - 5, targetBounds.width, 10))) {
                        intersection = "top";
                    }

                    if (intersection && target.isRootNode) {
                        intersection = "bottom";
                    }
                    if (intersection == "left" && tree.layoutDirection == "horizontal") {
                        intersection = "bottom";
                    }
                    if (intersection == "top" && tree.layoutDirection == "vertical") {
                        intersection = "bottom";
                    }

                    if (intersection) {
                        let $dropIndicator = $("body").addEl($.div("tree-node-drop-target"));

                        switch (intersection) {
                            case "left":
                                $dropIndicator.setBounds(new geom.Rect(targetBounds.left - 10, targetBounds.top, 5, targetBounds.height));
                                break;
                            case "right":
                                $dropIndicator.setBounds(new geom.Rect(targetBounds.right + 5, targetBounds.top, 5, targetBounds.height));
                                break;
                            case "bottom":
                                $dropIndicator.setBounds(new geom.Rect(targetBounds.left, targetBounds.bottom + 5, targetBounds.width, 5));
                                break;
                            case "top":
                                $dropIndicator.setBounds(new geom.Rect(targetBounds.left, targetBounds.top - 10, targetBounds.width, 5));
                                break;
                        }

                        this.dropTarget = target;
                        this.dropIntersection = intersection;
                        break;
                    }
                }
            },
            stop: (event, ui) => {
                app.mainView.editorView.selectionLayer.showWidgets($(".tree-node-drop-target"));
                $(".tree-node-drop-target").remove();
                if (this.dropTarget && this.dropIntersection) {
                    let originalParent = this.element.parentNode;

                    let addLeftSibling = () => {
                        var dropindex = this.dropTarget.model.index;
                        if (this.element.model.parent == this.dropTarget.parentNode.model.id && this.element.model.index < dropindex) {
                            dropindex -= 1;
                        }

                        this.element.model.parent = null;
                        var index = 0;
                        for (let childNode of this.dropTarget.parentNode.childNodes) {
                            if (index == dropindex) {
                                index++;
                            }
                            childNode.model.index = index;
                            index++;
                        }
                        this.element.model.parent = this.dropTarget.model.parent;
                        this.element.model.index = dropindex;
                    };

                    let addRightSibling = () => {
                        var dropindex = this.dropTarget.model.index + 1;
                        if (this.element.model.parent == this.dropTarget.parentNode.model.id && this.element.model.index < dropindex) {
                            dropindex -= 1;
                        }

                        this.element.model.parent = null;
                        var index = 0;
                        for (let childNode of this.dropTarget.parentNode.childNodes) {
                            childNode.model.index = index;
                            index++;
                            if (index == dropindex) {
                                index++;
                            }
                        }
                        this.element.model.parent = this.dropTarget.model.parent;
                        this.element.model.index = dropindex;

                        this.element.model.parent = this.dropTarget.model.parent;
                        this.element.model.index = dropindex;
                    };

                    let addChild = () => {
                        this.element.model.parent = this.dropTarget.model.id;
                        var index = 0;
                        for (let childNode of this.dropTarget.childNodes) {
                            childNode.model.index = index;
                            index++;
                        }
                    };

                    // the root element can't have siblings, so automatically
                    // make it a child
                    if (!this.dropTarget.parentNode) {
                        addChild();
                    } else {
                        // there's a drop target to work with, determine
                        // the correct behavior
                        switch (this.dropIntersection) {
                            case "left":
                                if (tree.layoutDirection == "vertical") {
                                    addLeftSibling();
                                }
                                break;
                            case "right":
                                if (tree.layoutDirection == "vertical") {
                                    addRightSibling();
                                } else {
                                    addChild();
                                }
                                break;
                            case "bottom":
                                if (tree.layoutDirection == "vertical") {
                                    addChild();
                                } else {
                                    addRightSibling();
                                }
                                break;
                            case "top":
                                if (tree.layoutDirection == "horizontal") {
                                    addLeftSibling();
                                }
                        }
                    }

                    // fix indices of original parent node's children
                    var index = 0;
                    for (let childNode of originalParent.childNodes) {
                        childNode.model.index = index;
                        index++;
                    }

                    this.element.canvas.updateCanvasModel(true).catch(() => {
                        ShowWarningDialog({
                            title: "Sorry, we can't make this change.",
                            message: "Unable to drag node to new location.",
                        });
                    });
                }
            }

        });
    }

});

const OrgChartNodeTextEditor = Backbone.View.extend({
    className: "orgchartnode-texteditor",

    events: {
        "click": "onClick"
    },

    initialize: function(options) {
        this.node = options.node;
    },

    shouldShowRoleInput() {
        return this.node.model.nodeType !== "label";
    },

    render: function() {
        this.$name = this.$el.addEl($.input("text", "Type name").addClass("name").val(this.node.model.title.text));
        this.$name.on("keydown", event => {
            if (event.which == Key.ENTER && this.shouldShowRoleInput()) {
                this.$role.focus();
            } else if (event.which == Key.ESCAPE) {
                this.close();
            }
        });

        if (this.shouldShowRoleInput()) {
            this.$role = this.$el.addEl($.input("text", "Type role/title").addClass("role").val(this.node.model.body.text));
            this.$role.on("keydown", event => {
                if (event.which == Key.ENTER) {
                    this.commit();
                } else if (event.which == Key.ESCAPE) {
                    this.close();
                }
            });
            this.$role.on("blur", () => this.commit());
        } else {
            this.$name.on("blur", () => this.commit());
        }

        return this;
    },

    onClick: function(event) {
        event.stopPropagation();
    },

    commit: function() {
        this.node.model.title.text = this.$name.val();
        if (this.shouldShowRoleInput()) {
            this.node.model.body.text = this.$role.val();
        }
        this.node.canvas.updateCanvasModel(true).catch(err => {
            ShowWarningDialog({
                title: "Sorry, we can't fit this text in your org chart",
                message: "There is not enough room for both a name and title in this node of your org chart. You can try changing one or more row sizes to SMALL or update your layout options to provide more vertical space on your slide (for example, you can remove or change the position of your slide header)",
            });
        });
        this.close();
    },

    close: function() {
        this.$el.clickShield(false);
        this.remove();
    }

});

const OrgChartRowLabelSelection = ElementSelection.extend({
    renderControls() {
        this.createDeleteComponentWidget({
            target: this.element,
            action: () => {
                delete this.element.getRootElement().model.rowLabels[this.element.model.rowIndex];
                this.element.canvas.updateCanvasModel(true);
            }
        });
    }
});

export const editors = {
    OrgChartSelection,
    OrgChartNodeSelection,
    OrgChartNodeTextEditor,
    OrgChartRowLabelSelection
};
