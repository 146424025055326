import React, { Component } from "react";
import styled from "styled-components";

import { PopupMenu } from "js/react/components/PopupMenu";

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 50px;
    height: 34px;
    border: ${props => props.showBorder ? "solid 1px #ccc" : "none"};
    .MuiIcon-root {
      opacity: 0.4;
    }
`;

const StyledInput = styled.input`
    width: 100%;
    border: none;
    background: none;
    text-align: right;
    font-weight: 600;
    font-size: 16px;
    outline: none;
    position: relative;
    top: -1px;
  
     /* Chrome, Safari, Edge, Opera */
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
`;

export class SelectInput extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.value,
            isChanged: false
        };

        this.inputRef = React.createRef();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.value != this.props.value) {
            this.setState({ value: this.props.value });
        }
    }

    componentWillUnmount() {
        this.handleCommitValue();
    }

    handleSelect = value => {
        this.setState({ value });
        this.props.onChange(value);
    }

    handleCommitValue = () => {
        if (!this.state.isChanged) return;

        const { minValue = 0, maxValue = 99 } = this.props;
        this.props.onChange(parseInt(Math.clamp(this.state.value, minValue, maxValue)));
    }

    handleChange = event => {
        this.setState({ value: event.target.value, isChanged: true });
    }

    handleFocus = event => {
        event.target.select();
    }

    handleKeyDown = event => {
        event.stopPropagation();
    }

    handleKeyPress = event => {
        if (event.which == 13) {
            this.handleCommitValue();
        }
    }

    render() {
        const { className, styles, showBorder = true, minValue = 0, maxValue = 99 } = this.props;
        const { value } = this.state;

        return (
            <Container className={className} style={styles} showBorder={showBorder}>
                <StyledInput
                    type="number"
                    ref={this.inputRef}
                    value={value}
                    min={minValue}
                    max={maxValue}
                    onFocus={this.handleFocus}
                    onBlur={this.handleCommitValue}
                    onChange={this.handleChange}
                    onKeyDown={this.handleKeyDown}
                    onKeyPress={this.handleKeyPress}
                />
                <PopupMenu
                    icon="arrow_drop_down"
                    onChange={this.handleSelect}
                    style={{ padding: 0 }}
                    childrenAreMenuItems
                >
                    {this.props.children}
                </PopupMenu>
            </Container>
        );
    }
}
