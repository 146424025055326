import { VerticalBlockAlignType, FormatType, HorizontalAlignType } from "common/constants";

import { TextGroup } from "../../base/TextGroup";
import { RadialBarGraph } from "../infographics/RadialBarGraph";
import CompareValuesItem from "./CompareValuesItem";
import CompareValuesItemLabel from "./CompareValuesItemLabel";

export default class CompareRadialBarItem extends CompareValuesItem {
    _build() {
        this.radialBar = this.addElement("radialBar", () => RadialBarGraph, {
            showLabel: this.parentElement.format != FormatType.NONE,
            valueFunction: () => this.percentageValue,
            labelElement: CompareValuesItemLabel
        });
        this.text = this.addElement("text", () => TextGroup, {
            autoHeight: true
        });
    }

    _calcProps(props, options) {
        let { size } = props;

        let layouter = this.getLayouter(props, [this.radialBar, this.text], size);
        if (options.orientation == "vertical") {
            layouter.calcVerticalBlockLayout({
                contentSize: size.square(),
                horizontalAlign: HorizontalAlignType.CENTER
            });
        } else {
            layouter.calcHorizontalBlockLayout({
                verticalAlign: VerticalBlockAlignType.MIDDLE_OR_TOP
            });
        }
        this.isTextFit = layouter.isTextFit;

        return { size: layouter.size };
    }

    get animationElementName() {
        return `Radial bar #${this.itemIndex + 1}`;
    }

    _getAnimations() {
        return [{
            name: "Grow in",
            prepare: () => {
                this.text.animationState.fadeInProgress = 0;
                this.radialBar.animationState.value = 0;
                this.radialBar.animationState.fadeInProgress = 0;
            },
            onBeforeAnimationFrame: progress => {
                this.text.animationState.fadeInProgress = Math.clamp((progress - 0.7) / 0.3, 0, 1);
                this.radialBar.animationState.value = progress;
                this.radialBar.animationState.fadeInProgress = Math.min(1, progress * 3);
                return this;
            }
        }];
    }
}
