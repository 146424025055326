import { flextree as makeFlexTree } from "d3-flextree";
import { _ } from "js/vendor";
import * as geom from "js/core/utilities/geom";

import { ElementLayouter } from "../../../layouts/ElementLayouter";

ElementLayouter.prototype.calcVerticalTreeLayout = function(options = {}) {
    const hGap = this.element.styles.hGap;
    const STACKED_X_OFFSET = 30;

    // function to calculate the width of a node and it's children
    const calcChildrenWidth = node => {
        if (node.children) {
            if (options.stackLeafNodes && node.height == 1) {
                // children are stacked so just return width of widest child
                return _.maxBy(node.children, child => child.size[0]).size[0] + STACKED_X_OFFSET;
            } else {
                // return total width of all children
                let totalWidth = -hGap;
                for (const childNode of node.children) {
                    totalWidth += calcChildrenWidth(childNode) + hGap;
                }
                return Math.max(totalWidth, node.size[0]);
            }
        } else {
            // node has no children so just return it's own width
            return node.size[0];
        }
    };

    // function to recursively calculate node x for tree using expanded layout where each node leaves horizontal room for it's siblings width including child nodes
    const layoutExpandedTree = (node, xPos = 0) => {
        const childrenWidth = calcChildrenWidth(node);

        node.data.childrenWidth = childrenWidth; // store childrenWidth in node item for table layout

        if (options.isTable) {
            node.x = xPos;
        } else {
            node.x = xPos + childrenWidth / 2 - node.size[0] / 2;
        }

        if (node.children) {
            if (options.stackLeafNodes && node.height == 1) {
                // stacked nodes are directly under parentnode offset by STACKED_X_OFFSET
                for (const childNode of node.children) {
                    childNode.x = node.x + STACKED_X_OFFSET / 2;
                    childNode.data.isStackedLeafNode = true;
                }
            } else {
                let childX;
                if (options.isTable) {
                    childX = node.x;
                } else {
                    childX = node.x + node.size[0] / 2 - (_.sumBy(node.children, child => calcChildrenWidth(child) + hGap) - hGap) / 2;
                }
                for (const childNode of node.children) {
                    layoutExpandedTree(childNode, childX);
                    childX += calcChildrenWidth(childNode) + hGap;
                }
            }
        }
    };

    const MAX_ITEM_WIDTH = 250;

    const MAX_VGAP = this.element.styles.vGap;
    const MIN_VGAP = 20;

    const rootNode = this.items.find(item => item.model.parent == null);
    let itemWidth = MAX_ITEM_WIDTH;
    let vGap = MAX_VGAP;

    let totalWidth, totalHeight, totalReservedHeight;

    // create a d3 flextree
    const flexTree = makeFlexTree({
        nodeSize: node => {
            if (options.table) {
                return [node.childrenWidth, 100];
            } else {
                return [node.data.calculatedProps.size.width, 100];
            }
        },
        spacing: (nodeA, nodeB) => {
            if (nodeA.parentNode == nodeB.parentNode) {
                return hGap;
            } else {
                return hGap * 2;
            }
        }
    });

    // generate a shadow tree hierarchy of our nodes
    const tree = flexTree.hierarchy(rootNode);

    this.element.options.forceSmallNodes = false;
    // loop to calculate fitting layout coordinates in the shadow tree
    do {
        const MIN_ITEM_WIDTH = Math.min(...this.items.map(item => {
            if (this.element.options.forceSmallNodes && item.styles.small) {
                return item.styles.small.minWidth;
            } else {
                return item.styles.minWidth;
            }
        })) || 100;

        // calculate the size of each node at the target itemWidth
        for (const node of this.items) {
            node.isStackedLeafNode = false;

            if (options.stackLeafNodes && node.childNodes.length == 0) {
                node.calcProps(new geom.Size(itemWidth - STACKED_X_OFFSET, 200), options);
            } else {
                node.calcProps(new geom.Size(itemWidth, 200), options);
            }
        }

        // calculate x position of the nodes in the shadow hierarchy tree str=ructure
        if (options.compressed) {
            // use d3 flextree library to calculate node layout with compressed horizontal node layout
            flexTree(tree);
        } else {
            // use custom algorithm to calculate node layout with expanded horizontal node layout
            layoutExpandedTree(tree);
        }

        totalWidth = tree.extents.right - tree.extents.left;

        // copy shadow hiearchy tree's node x positions to itemElements
        tree.each(node => {
            node.data.x = node.x;
        });

        // calculate the y values of each row in the tree and the total height of the tree
        totalHeight = 0;
        totalReservedHeight = 0;
        const calcVerticalLayout = (curNode, y) => {
            curNode.y = y;
            // set the rowheight of this node to the tallest node in it's row
            curNode.rowHeight = _.maxBy(_.filter(this.items, node => node.rowIndex == curNode.rowIndex), node => node.calculatedProps.size.height).calculatedProps.size.height;

            if (curNode.childNodes.length || curNode.assistantNodes.length) {
                y += curNode.rowHeight + vGap;

                // position any assistant nodes
                if (curNode.assistantNodes.length) {
                    for (const assistantNode of curNode.assistantNodes) {
                        calcVerticalLayout(assistantNode, y);
                    }
                    y += curNode.assistantNodes[0].rowHeight + vGap;
                }

                // recursively calc children
                for (const childNode of curNode.childNodes) {
                    calcVerticalLayout(childNode, y);
                    if (childNode.isStackedLeafNode && curNode.childNodes.indexOf(childNode) < curNode.childNodes.length - 1) {
                        y += curNode.rowHeight + vGap / 2;
                    }
                }
            }
            totalHeight = Math.max(totalHeight, y + curNode.rowHeight);
        };
        calcVerticalLayout(rootNode, 0);

        if (totalWidth <= this.containerSize.width && totalHeight <= this.containerSize.height) {
            // we fit
            break;
        } else {
            if (totalWidth > this.containerSize.width && itemWidth > MIN_ITEM_WIDTH) {
                // if we are too wide, try shrinking the itemWidth
                itemWidth -= 10;
            } else if (totalHeight > this.containerSize.height && vGap > MIN_VGAP) {
                // if we are too tall, try shrinking the vGap
                vGap -= 2;
            } else if (this.element.options.forceSmallNodes == false) {
                // if we still aren't fitting after reducing itemWidth and vGap, try forceSmallRows
                this.element.options.forceSmallNodes = true;
            } else {
                // we don't fit but we can't shrink anymore
                break;
            }
        }
    } while (true);

    // calculate the offsetX so that all the nodes are left-zero-aligned
    let offsetX = 0;
    for (const node of this.items) {
        if (node.x != undefined) {
            offsetX = Math.min(node.x - node.calculatedProps.size.width / 2, offsetX);
        }
    }
    offsetX = Math.abs(offsetX);

    // recursively lock in the position of each node by final calcSize at actual width and applying bounds
    const layoutNodes = node => {
        let nodeX, nodeWidth;
        if (options.isTable) {
            // table node
            nodeX = node.x;
            if (node.children) {
                // when a table node has children, set it's width to the width of it's children
                nodeWidth = node.childrenWidth;
            } else {
                nodeWidth = node.calculatedProps.size.width;
            }
        } else {
            // normal node
            nodeX = node.x + offsetX - node.calculatedProps.size.width / 2;
            nodeWidth = node.calculatedProps.size.width;
        }

        // recalc node and set bounds
        const nodeProps = node.calcProps(new geom.Size(nodeWidth, node.rowHeight), { ...options, fillHeight: true });
        nodeProps.bounds = new geom.Rect(nodeX, node.y, nodeWidth, node.rowHeight);

        if (node.childNodes.length) {
            // recursively layout children
            for (const childNode of node.childNodes) {
                layoutNodes(childNode);
            }
        }

        // assistant nodes were not layed out by the tree algorithms and so they won't have their x set so we calc and set now
        if (node.assistantNodes.length) {
            let side = 1;
            for (const assistantNode of node.assistantNodes) {
                const assistantNodeProps = assistantNode.calcProps(new geom.Size(assistantNode.calculatedProps.size.width, assistantNode.rowHeight || assistantNode.calculatedProps.size.height));

                let assistantNodeX;
                if (side == 1) {
                    assistantNodeX = node.x + offsetX + hGap * 2;
                } else {
                    assistantNodeX = node.x + offsetX - hGap * 2 - assistantNode.calculatedProps.size.width;
                }

                let assistantNodeY = assistantNode.y;
                if (node.childNodes.length > 1) {
                    assistantNodeY -= vGap / 4;
                }

                assistantNodeProps.bounds = new geom.Rect(assistantNodeX, assistantNodeY, assistantNode.calculatedProps.size.width, assistantNode.rowHeight || assistantNode.calculatedProps.size.height);
                side *= -1;
            }
        }
    };

    layoutNodes(rootNode);

    this.vGap = vGap;

    this.size = new geom.Size(totalWidth, totalHeight);
    this.props.isFit = totalWidth <= this.containerSize.width && totalHeight <= this.containerSize.height;

    return this;
};
