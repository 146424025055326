import React, { Component } from "react";
import { Icon, Menu, MenuItem } from "@material-ui/core";
import FlipToFront from "mdi-material-ui/FlipToFront";
import FlipToBack from "mdi-material-ui/FlipToBack";
import AlignHorizontalLeft from "mdi-material-ui/AlignHorizontalLeft";
import AlignHorizontalCenter from "mdi-material-ui/AlignHorizontalCenter";
import AlignHorizontalRight from "mdi-material-ui/AlignHorizontalRight";
import AlignVerticalTop from "mdi-material-ui/AlignVerticalTop";
import AlignVerticalCenter from "mdi-material-ui/AlignVerticalCenter";
import AlignVerticalBottom from "mdi-material-ui/AlignVerticalBottom";
import DistributeHorizontalCenter from "mdi-material-ui/DistributeHorizontalCenter";
import DistributeVerticalCenter from "mdi-material-ui/DistributeVerticalCenter";
import Group from "mdi-material-ui/Group";
import Ungroup from "mdi-material-ui/Ungroup";
import ContentCut from "mdi-material-ui/ContentCut";
import ContentCopy from "mdi-material-ui/ContentCopy";
import Eyedropper from "mdi-material-ui/Eyedropper";
import EyedropperPlus from "mdi-material-ui/EyedropperPlus";
import styled from "styled-components";
import { NestedMenuItem } from "js/react/components/NestedMenuItem";
import { isString, trim, truncate } from "lodash";
import FountainPenTip from "mdi-material-ui/FountainPenTip";
import * as browser from "js/core/utilities/browser";
import { sanitizeHtml } from "js/core/utilities/dompurify";

const PREFERRED_ICON_SIZE = 20;

const MenuDivider = styled.hr`
    border: 1px solid #eeeeee;
`;

const ImagePreview = styled.div`
    width: ${PREFERRED_ICON_SIZE}px;
    height: ${PREFERRED_ICON_SIZE}px;
    background-image: url(${props => props.src});
    background-size: cover;
    background-position: 50% 50%;
`;

const FillSvgPreview = styled.div`
    svg {
        width: ${PREFERRED_ICON_SIZE}px;
        height: ${PREFERRED_ICON_SIZE}px;
    }
    svg path {
        fill: #696969;
    }
`;

const OutlineSvgPreview = styled.div`
    svg {
        width: ${PREFERRED_ICON_SIZE}px;
        height: ${PREFERRED_ICON_SIZE}px;
    }

    svg path {
        stroke: #696969;
        stroke-width: 2;
        vector-effect: non-scaling-stroke;
        fill: none;
    }
`;

const MenuItemPair = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;

    .menu-item--icon {
        width: 25px;
        display: flex;
        justify-content: center;
        text-align: center;

        // some icons have margins on them
        // so this ensures they don't nudge
        > * {
            margin: 0 !important;
        }
    }
`;

export class SelectionContextMenu extends Component {
    handleContextMenuAction = action => {
        this.props.onContextMenuAction(action);
    }

    handleSelectElement = (event, element) => {
        event.stopPropagation();
        this.props.onSelectElement(element);
    }

    render() {
        const {
            open, contextMenuPosition,
            canAlignAndDistribute,
            canCopyStyles,
            canGroup,
            canUngroup,
            isLocked,
            onClose,
            elementsUnderMouse = []
        } = this.props;

        return (
            <Menu
                open={open}
                anchorReference="anchorPosition"
                anchorPosition={{ left: contextMenuPosition.x, top: contextMenuPosition.y }}
                onClose={onClose}
            >

                <MenuItem onMouseDown={() => this.handleContextMenuAction("bringToFront")}>
                    <FlipToFront />
                    Bring to Front
                </MenuItem>
                <MenuItem onMouseDown={() => this.handleContextMenuAction("sendToBack")}>
                    <FlipToBack />
                    Send to Back
                </MenuItem>
                {canAlignAndDistribute && <MenuDivider />}
                {canAlignAndDistribute && <NestedMenuItem label="Align">
                    <MenuItem onMouseDown={() => this.handleContextMenuAction("align-left")}>
                        <AlignHorizontalLeft />
                        Left
                    </MenuItem>
                    <MenuItem onMouseDown={() => this.handleContextMenuAction("align-center")}>
                        <AlignHorizontalCenter />
                        Center
                    </MenuItem>
                    <MenuItem onMouseDown={() => this.handleContextMenuAction("align-right")} divider>
                        <AlignHorizontalRight />
                        Right
                    </MenuItem>
                    <MenuItem onMouseDown={() => this.handleContextMenuAction("align-top")}>
                        <AlignVerticalTop />
                        Top
                    </MenuItem>
                    <MenuItem onMouseDown={() => this.handleContextMenuAction("align-middle")}>
                        <AlignVerticalCenter />
                        Middle
                    </MenuItem>
                    <MenuItem onMouseDown={() => this.handleContextMenuAction("align-bottom")} divider>
                        <AlignVerticalBottom />
                        Bottom
                    </MenuItem>
                </NestedMenuItem>}
                {canAlignAndDistribute && <NestedMenuItem label="Distribute">
                    <MenuItem onMouseDown={() => this.handleContextMenuAction("distribute-horizontal")}>
                        <DistributeHorizontalCenter />
                        Horizontally
                    </MenuItem>
                    <MenuItem onMouseDown={() => this.handleContextMenuAction("distribute-vertical")}>
                        <DistributeVerticalCenter />
                        Vertically
                    </MenuItem>
                </NestedMenuItem>}
                <MenuDivider />
                {canGroup && <MenuItem onMouseDown={() => this.handleContextMenuAction("group")}>
                    <Group />
                    Group
                </MenuItem>}
                {!canGroup && canUngroup && <MenuItem onMouseDown={() => this.handleContextMenuAction("ungroup")}>
                    <Ungroup />
                    Ungroup
                </MenuItem>}
                {isLocked && <MenuItem onMouseDown={() => this.handleContextMenuAction("unlock")}>
                    <Icon>lock_open</Icon>
                    Unlock
                </MenuItem>}
                {!isLocked && <MenuItem onMouseDown={() => this.handleContextMenuAction("lock")}>
                    <Icon>lock</Icon>
                    Lock
                </MenuItem>}
                <MenuDivider />
                {!browser.isFirefox &&
                    <NestedMenuItem label="Copy/Paste">
                        <MenuItem onMouseDown={() => this.handleContextMenuAction("cut")}>
                            <ContentCut />
                            Cut
                        </MenuItem>
                        <MenuItem onMouseDown={() => this.handleContextMenuAction("copy")}>
                            <ContentCopy />
                            Copy
                        </MenuItem>
                        <MenuDivider />
                        <MenuItem onMouseDown={() => this.handleContextMenuAction("copyStyles")} disabled={!canCopyStyles}>
                            <Eyedropper />
                            Copy Styles
                        </MenuItem>
                        <MenuItem onMouseDown={() => this.handleContextMenuAction("pasteStyles")}>
                            <EyedropperPlus />
                            Paste Styles
                        </MenuItem>
                    </NestedMenuItem>
                }
                {!browser.isFirefox && <MenuDivider />}
                <MenuItem onMouseDown={() => this.handleContextMenuAction("delete")}>
                    <Icon>delete_outline</Icon>
                    Delete
                </MenuItem>
                {elementsUnderMouse.length > 1 && <MenuDivider />}
                {elementsUnderMouse.length > 1 &&
                    <NestedMenuItem label="Select Layer">
                        {elementsUnderMouse.map((element, i) => {
                            let { label, icon } = getElementDisplayName(element);

                            // if string

                            return (
                                <MenuItem key={`item_${i}`} onMouseDown={event => this.handleSelectElement(event, element)}>
                                    <MenuItemPair>
                                        <div className="menu-item--icon">{icon}</div>
                                        <div className="menu-item--label">{label}</div>
                                    </MenuItemPair>
                                </MenuItem>
                            );
                        })}
                    </NestedMenuItem>
                }
            </Menu>
        );
    }
}

function getElementDisplayName(element) {
    const { childElement: target } = element;

    function toText(html) {
        const el = document.createElement("pre");
        el.innerHTML = sanitizeHtml(html);
        return el.innerText;
    }

    let includeQuotes;
    let label = target.type;
    let icon = null;

    switch (target.type) {
        case "Dashboard":
            // for now the request is just to use a general
            // chart icon. We could identify the chart type
            // and show more specific icons
            label = "Chart";
            icon = "bar_chart";
            break;

        case "TableFrame":
            label = "Table";
            icon = "grid_on";
            break;

        case "AuthoringPathElement":
        case "AuthoringShapeElement":

            // check for nested text
            const block = element.model.blocks?.find(item => item.type === "text");
            if (block && block.html?.length) {
                includeQuotes = true;
                label = block.html;
                icon = "text_fields";
            } else {
                // maybe a path or rectangle
                const resource = target.DOMNode.querySelector("path, rect, ellipse");

                // for rectangles, just show the icon
                if (/rect/i.test(resource?.tagName)) {
                    label = "Rectangle";
                    icon = "rectangle";
                } else if (/ellipse/i.test(resource?.tagName)) {
                    label = "Ellipse";
                    icon = "circle";
                } else if (/path/i.test(resource?.tagName)) {
                    label = element.name;
                    icon = /path/i.test(element.name) ? <FountainPenTip /> : "extension";
                } else {
                    label = "Unknown";
                    icon = "help";
                }
            }

            break;

        case "AuthoringContentElement":
            const contentType = element.model.element?.content_type;
            const resource = contentType && target.DOMNode.querySelector("video, image, path");

            if (/video/i.test(contentType)) {
                const src = resource?.getAttribute("poster");
                label = "Video";
                icon = src ? <ImagePreview src={src} /> : "video_library";
            } else if (/image/i.test(contentType)) {
                const src = resource?.getAttribute("href");
                label = "Image";
                icon = src ? <ImagePreview src={src} /> : "photo_camera";
            } else if (contentType === "icon") {
                label = "Icon";
                icon = "filter_vintage";
            } else {
                label = "Component";
                icon = "settings";
            }

            break;
    }

    // remove any HTML
    label = toText(label);

    // trim text to a maximum length
    if (isString(label)) {
        label = truncate(label, 20);

        // wrap with quotes, if needed
        if (includeQuotes) {
            label = `"${label}"`;
        }
    }

    // fallback icon
    if (!icon) {
        icon = "extension";
    }

    // if the icon is a string, look up the icon
    if (isString(icon)) {
        icon = <Icon>{icon}</Icon>;
    }

    return { label, icon };
}
