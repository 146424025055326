import { _ } from "js/vendor";
import { FormatType } from "common/constants";
import { formatter } from "js/core/utilities/formatter";

import { CollectionElement } from "../../base/CollectionElement";

export default class CompareValuesCollection extends CollectionElement {
    get defaultItemData() {
        return {
            value: 50
        };
    }

    get maxItemCount() {
        return 10;
    }

    get minValue() {
        return this.model.minValue ?? 0;
    }

    get maxValue() {
        return this.model.maxValue ?? 100;
    }

    get format() {
        return this.model.format ?? FormatType.PERCENT;
    }

    get formatOptions() {
        return this.model.formatOptions ?? formatter.getDefaultFormatOptions();
    }

    formatValue(value) {
        let displayValue = value;
        if (this.format == FormatType.PERCENT) {
            displayValue = value / 100;
        } else {
            displayValue = value;
        }

        return formatter.formatValue(displayValue, this.format, this.formatOptions);
    }

    _build() {
        this.buildItems();
        this.model.minValue = Math.min(this.minValue, _.minBy(this.itemCollection, "value").value);
        this.model.maxValue = Math.max(this.maxValue, _.maxBy(this.itemCollection, "value").value);
    }

    autoFitRange() {
        this.model.minValue = 0;
        this.model.maxValue = _.maxBy(this.itemCollection, "value").value;
        this.canvas.updateCanvasModel(true);
    }
}
