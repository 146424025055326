import React from "react";
import { controls } from "js/editor/ui";
import { ShowInputDialog } from "js/react/components/Dialogs/BaseDialog";

import { ElementOptionsMenu, ElementSelection } from "../BaseElementEditor";

const WebViewSelection = ElementSelection.extend({
    renderControls() {
        this.addControl({
            type: controls.BUTTON,
            label: "Set URL",
            callback: () => {
                let getURL = async () => {
                    let url = await ShowInputDialog({ title: <div>Set iFrame URL<div style={{ fontSize: 14, marginTop: 20 }}>Please note that most popular websites prevent embedding their URLs in iFrames and will not work in a WebView slide. In some cases, a webpage may offer an embed url or iframe version of their page which may work instead.</div></div>,
                        value: this.element.model.url });
                    if (url) {
                        if (url.startsWith("<iframe")) {
                            let match = /src=("|')(.*?)("|')/.exec(url);
                            url = match[2];
                        }

                        if (!url.startsWith("http")) {
                            url = "https://" + url;
                        }

                        this.element.model.url = url;
                        this.element.canvas.updateCanvasModel();
                    }
                };
                getURL();
            }
        });
    }
});

const WebViewOptionsMenu = ElementOptionsMenu.extend({
    renderControls() {
        this.addControl({
            type: controls.TOGGLE,
            label: "Full Bleed",
            property: "fullBleed"
        });

        this.addControl({
            type: controls.NUMERIC,
            label: "Scale",
            property: "scale",
            min: 0.1,
            max: 2,
            step: 0.01
        });
    }
});

export const editors = {
    WebViewSelection,
    WebViewOptionsMenu
};

