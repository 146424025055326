import { ds } from "js/core/models/dataService";
import { $, _, Backbone, SVG, tinycolor } from "js/vendor";
import { app } from "js/namespaces";
import * as geom from "js/core/utilities/geom";
import {
    AssetType,
    BackgroundStyleType,
    HorizontalAlignType,
    ImageErrorType,
    WidgetPositionType,
    TaskState,
    TaskType,
} from "common/constants";
import { getImageType, isValidMediaType } from "js/core/utilities/imageUtilities";
import isConnected from "js/core/utilities/isConnected";
import getLogger, { LogGroup } from "js/core/logger";
import { controls } from "js/editor/ui";
import {
    ShowDialog,
    ShowOfflineDialog,
    ShowUnsupportedFileTypesDialog,
    ShowUpgradeDialog,
    ShowWarningDialog
} from "js/react/components/Dialogs/BaseDialog";
import ProgressDialog from "js/react/components/Dialogs/ProgressDialog";
import { UpgradePlanDialogType } from "js/react/views/MarketingDialogs/UpgradePlanDialog";
import shouldShowProBadge from "js/core/utilities/shouldShowProBadge";
import { FeatureType } from "js/core/models/features";
import { UIController } from "js/editor/dialogs/UIController";
import { uploadFileAndCreateTask } from "js/core/services/tasks";
import { trackActivity } from "js/core/utilities/utilities";
import { getDataSourceControl } from "js/editor/dataSourceMenu";
import AppController from "js/core/AppController";

import { BaseElement } from "../elements/base/BaseElement";
import editorManager from "./editorManager";
import { mergeMediaElementModelDefaults } from "common/assetUtils";

const logger = getLogger(LogGroup.ELEMENTS);

const BaseElementUI = Backbone.View.extend({

    // --------------------------------------------------------------------------------------------------------------------------------------------------------
    // Initialize
    // --------------------------------------------------------------------------------------------------------------------------------------------------------
    initialize: function(options) {
        this.element = options.element;
        this.canvas = this.element.canvas;
        this.model = options.element.model;

        this.canvasScale = this.element.canvas.getScale();

        this._initialize(options);

        this.elementBounds = this.canvasToSelectionCoordinates(this.element);

        this.setup(options);

        this.$el.addClass(this.element.type);
        this.$el.attr("data-id", this.element.id);
    },

    _initialize: function() {
        // protected
    },

    setup: function() {
        // can be overridden
    },

    show: function() {
        this.$el.opacity(1);
    },

    hide: function() {
        this.$el.opacity(0);
    },

    getElementSelectionBounds: function() {
        if (this.element) {
            return this.element.selectionBounds.multiply(this.canvasScale);
        }
    },

    refreshElementPointer: function() {
        if (this.element) {
            ds.selection.element = null;
            ds.selection.element = this.element;
        }
    },

    showWarning: function(title, message) {
        _.defer(() => {
            let $warning = this.$el.find(".editor_warning");
            if ($warning.length == 0) {
                $warning = $.div("editor_warning");
                this.$el.append($warning);
            }
            $warning.empty();
            $warning.append($.div("warning_title", title));
            $warning.append($.div("warning_message", message));

            if (this.$el.find(".control_bar").length) {
                $warning.top(this.$el.find(".control_bar").position().top + 40).left(this.$el.width() / 2 - 150);
            } else {
                $warning.top(this.$el.height() + 30);
            }
        });
    },

    hideWarning: function() {
        this.$el.find(".editor_warning").remove();
    },

    getSelectionLayerScreenBounds: function() {
        let $selectionLayer = $("#selection_layer");
        return new geom.Rect($selectionLayer.offset().left, $selectionLayer.offset().top, $selectionLayer.width(), $selectionLayer.height());
    },

    canvasToSelectionCoordinates: function(value) {
        if (value instanceof geom.Rect || value instanceof geom.Point) {
            return value.multiply(this.canvasScale);
        } else if (value instanceof BaseElement) {
            return value.canvasBounds.offset(value.styles.marginLeft, value.styles.marginTop).multiply(this.canvasScale);
        } else {
            return value * this.canvasScale;
        }
    },

    canvasToScreenCoordinates: function(value) {
        let elementCanvasBounds = this.element.getRootElement().canvasBounds;
        let selectionLayerBounds = this.getSelectionLayerScreenBounds();
        return this.element.getRootElement().canvasBounds.multiply(this.canvasScale).offset(selectionLayerBounds.left, selectionLayerBounds.top);
    },

    selectionToCanvasCoordinates: function(value) {
        if (value instanceof geom.Rect || value instanceof geom.Point) {
            return value.multiply(1 / this.canvasScale);
        } else if (value instanceof BaseElement) {
            return value.elementBounds.offset(value.styles.marginLeft, value.styles.marginTop);
        } else {
            return value / this.canvasScale;
        }
    },

    screenToSelectionCoordinates: function(point, element = this.element) {
        return point.offset(-$("#selection_layer").offset().left, -$("#selection_layer").offset().top).offset(-element.canvasBounds.left, -element.canvasBounds.top);
    },

    screenToElementCoordinates: function(point, element = this.element) {
        return this.canvasToElementCoordinates(this.screenToCanvasCoordinates(point), element);
    },

    screenToCanvasCoordinates: function(value) {
        let selectionLayerBounds = this.getSelectionLayerScreenBounds();
        if (value instanceof geom.Rect || value instanceof geom.Point) {
            return value.offset(-selectionLayerBounds.left, -selectionLayerBounds.top).multiply(1 / this.canvasScale);
        } else if (value instanceof BaseElement) {
            return value.elementBounds.offset(value.styles.marginLeft, value.styles.marginTop).offset(-selectionLayerBounds.left, -selectionLayerBounds.top);
        } else {
            throw new Error("Can only convert geom.Rect, geom.Point, or element bounds from screen to canvas");
        }
    },

    canvasToElementCoordinates: function(value, element = this.element) {
        return value.offset(-element.canvasBounds.left, -element.canvasBounds.top);
    },

    cleanUp: function() {
    },

    handleKeyboardShortcut(event) {
        if (this._handleKeyboardShortcut) {
            this._handleKeyboardShortcut(event);
        }
    }

});

export const ElementEditor = BaseElementUI.extend({
    className: "element-ui",

    _initialize: function(options) {
        this.$el.on("mousedown", function(event) {
            event.stopPropagation();
            // ds.selection.element = self.element;
        });
        this.$el.on("click", () => {
            ds.selection.element = this.element;
        });
        //         this.$el.on("mouseenter", function () {
        //             if (ds.selection.element != self.element){
        //                 ds.selection.element = self.element;
        //             }
        //         });
    },

    getOffset: function() {
        return -1;
    },

    render: function() {
        this.$el.empty();

        // create a selection box
        if (this.showFocusRect) {
            let $selectionBox = $.div("element_selection_box").attr("data-element-id", this.element.cid);
            $selectionBox.css("width", "100%").css("height", "100%").left(0).top(0);
            this.$el.prepend($selectionBox);
        }

        this.$controlBar = this.$el.addEl($.div("widget_bar control_bar"));

        this.renderControls();

        let name = this.element.type + "OptionsMenu";
        if (editorManager.has(name)) {
            let editor = editorManager.get(name);
            let optionsMenu = new editor({
                element: this.element
            });
            if (optionsMenu) {
                this.$controlBar.append(controls.createPopupButton(this, {
                    icon: "settings",
                    showArrow: false,
                    menuContents: function(closeMenu) {
                        return optionsMenu.render().$el;
                    }
                }));
            }
        }

        if (this.$controlBar.children().length == 0) {
            this.$controlBar.remove();
        }

        return this;
    },

    showElementFocus: function() {
        const $shield = this.element.canvas.$el.addEl($.div("image_drag_shield"));
        const shieldSVG = SVG($shield[0]);
        const shieldRect = shieldSVG.rect().width("100%").height("100%").fill("black").opacity(0.5);

        const mask = shieldSVG.group();
        mask.rect().width("100%").height("100%").fill("white");
        const maskBox = mask.rect().fill("black");
        shieldRect.maskWith(mask);

        maskBox.setBounds(this.element.canvasBounds);

        $shield.one("click", event => {
            $shield.velocity("transition.fadeOut", {
                duration: 300,
                complete: () => {
                    $shield.remove();
                    ds.selection.element = null;
                }
            });
        });

        $shield.velocity("transition.fadeIn", { duration: 300 });
    },

    addDeleteButton: function(callback) {
        let $deleteBtn = controls.createButton(this, {
            icon: "close",
            className: "delete_button",
            callback: () => {
                callback();
            }
        });
        this.$el.append($deleteBtn);
    },

    deleteItem: function() {
        ds.selection.element = null;
    },

    addUIHint: function(hint) {
        let $hintContainer = this.$el.addEl($.div("ui_hint_container"));
        $hintContainer.append($.div("ui_hint", hint));
    },

    renderControls: function() {
    },

    addControl: function(config) {
        if (config.callback) {
            // setup the proxy
            config.callback = $.proxy(config.callback, this);

            // if this has requirements
            if (config.callbackRequiresLayouterReady) {
                let callbackRequestId = 1;

                // save the action to run
                const action = config.callback;

                // replace the action with a condition check
                // this will only execute a request if it's
                // the most recent request
                config.callback = (...args) => {
                    const requestId = ++callbackRequestId;

                    // if the layouter is busy
                    if (this.canvas.layouter.isGenerating) {
                        // wait till rendering is finished
                        this.canvas.layouter.runPostRender(() => {
                            // if the IDs are different, then that means a new request
                            // has come in and is not longer valid
                            if (callbackRequestId !== requestId) {
                                return;
                            }

                            // execute the action
                            action(...args);
                        });
                    } else {
                        // execute the action
                        action(...args);
                    }
                };
            }
        }

        if (!config.model) {
            config.model = this.model;
        }

        let $control = controls.createControl(this, config, ds.selection.slide);

        this.$controlBar.append($control);
        return $control;
    },

    layout: function() {
        let bounds = this.getElementSelectionBounds();

        this.$el.setBounds(bounds);

        if (this.$controlBar) {
            this.$controlBar.width(1200);
            let width = _.reduce(this.$controlBar.children(), function(width, child) {
                return width + $(child).outerWidth(true);
            }, 1);
            this.$controlBar.width(width);

            this.$controlBar.left(bounds.width / 2 - this.$controlBar.outerWidth(true) / 2);
            this.$controlBar.top(bounds.height + this.getOffset());
        }
        this._layout(bounds);
    },

    _layout: function(bounds) {
    },

});

export const ElementUI = BaseElementUI.extend({
    className: "element-ui",

    showSelectionBox: true,
    captureMouseEvents: false,

    getWidgetPosition() {
        return "outer";
    },

    getWidgetColor() {
        return "auto";
    },

    getDragWidgetPosition() {
        if (this.getWidgetPosition() == "inner") {
            return WidgetPositionType.DRAG_HANDLE_INSIDE;
        } else {
            return WidgetPositionType.DRAG_HANDLE;
        }
    },
    // --------------------------------------------------------------------------------------------------------------------------------------------------------
    // can be overridden to offset the controlBar vertically
    // --------------------------------------------------------------------------------------------------------------------------------------------------------
    getOffset: function() {
        if (this.element.isRootElement()) {
            return "canvas";
        } else {
            return 10;
        }
        // return -17;
    },

    showOptionsMenu: function() {
        return true;
    },

    showDataSourceMenu: function() {
        return false;
    },

    getCursor: function() {
        return "pointer";
    },

    // --------------------------------------------------------------------------------------------------------------------------------------------------------
    // Initialize
    // --------------------------------------------------------------------------------------------------------------------------------------------------------
    _initialize: function(options) {
        this.subComponentBounds = [];
        this.customWidgets = [];

        // listen for mouse events passed from selectionlayer
        this.listenTo(this, "mousemove_delegate", event => this.onMouseMove(event));
        this.listenTo(this, "click_delegate", event => this.onClick(event));
        this.listenTo(this, "mousedown_delegate", event => this.onMouseDown(event));
    },

    // --------------------------------------------------------------------------------------------------------------------------------------------------------
    // Registers a region and subcomponent widget to show on rollover
    // --------------------------------------------------------------------------------------------------------------------------------------------------------
    registerSubComponent: function(options) {
        this.subComponentBounds.push(options);

        if (options.widget) {
            if (!options.widget.parentElement) {
                this.$el.append(options.widget);
            }
            // hide widget at start
            if (options.showWidgetOnRollover != false) {
                options.widget.hide();
            }
        }

        // var $debug = $.div("debug").css("position", "absolute").css("background", "rgba(0,255,0,0.5)");
        // $debug.setBounds(options.bounds);
        // this.$el.append($debug);

        return options;
    },

    // --------------------------------------------------------------------------------------------------------------------------------------------------------
    // MouseMove events are forwarded from the selectionLayer so we can check against any registered subcomponent bounds
    // --------------------------------------------------------------------------------------------------------------------------------------------------------
    onMouseMove: function(event) {
        var mouseX = event.clientX - this.$el.offset().left;
        var mouseY = event.clientY - this.$el.offset().top;
        let subComponentBounds = this.subComponentBounds.slice(0);
        for (let sub of subComponentBounds) {
            if (sub.bounds.contains(mouseX, mouseY)) {
                // show the widget and call the rollover callback
                if (sub.showWidgetOnRollover != false) {
                    // sub.widget.opacity(1);
                    sub.widget.show();
                }
                if (sub.rollover) {
                    sub.rollover(event, mouseX, mouseY);
                }
            } else {
                // hide the widget
                if (sub.showWidgetOnRollover != false) {
                    // sub.widget.opacity(0);
                    sub.widget.hide();
                }
            }
        }
    },

    // --------------------------------------------------------------------------------------------------------------------------------------------------------
    // Click events are forwarded from the selectionLayer so we can check against any registered subcomponent bounds
    // --------------------------------------------------------------------------------------------------------------------------------------------------------
    onClick: function(event) {
        var mouseX = event.clientX - this.$el.offset().left;
        var mouseY = event.clientY - this.$el.offset().top;
        let subComponentBounds = this.subComponentBounds.slice(0);

        for (let sub of subComponentBounds) {
            if (sub.click && sub.bounds && sub.bounds.contains(mouseX, mouseY)) {
                sub.click(event, mouseX, mouseY);
            }
        }
    },

    onMouseDown: function(event) {
        var mouseX = event.clientX - this.$el.offset().left;
        var mouseY = event.clientY - this.$el.offset().top;
        let subComponentBounds = this.subComponentBounds.slice(0);

        for (let sub of subComponentBounds) {
            if (sub.mouseDown && sub.bounds && sub.bounds.contains(mouseX, mouseY)) {
                sub.mouseDown(event, mouseX, mouseY);
            }
        }
    },

    render: function() {
        this.subComponentBounds = [];
        this.customWidgets = [];

        this.$el.empty();
        this.$el.addClass("focused");

        // create the rollover hilite
        if (this.element !== this.element.canvas.layouter.elements.primary) {
            if (this.captureMouseEvents) {
                // this.$el.addEl($.div("mouse-event-capture"));
            }
            if (this.showSelectionBox) {
                this.$el.addEl($.div("selection-box"));
            }
        }

        // create a controlbar to hold any controls added during renderControls
        this.$controlBar = this.$el.addEl($.div("widget_bar control_bar"));

        // create a widgets container to hold any widgets added during renderControls
        this.$widgets = this.$el.addEl($.div("widgets"));

        // render datasource button if element supports it
        if (this.showDataSourceMenu()) {
            this.addDataSourceControl();
        }

        // let all the overridding classes render their controls
        this.renderControls();

        // render any editor options menu if defined
        let name = this.element.type + "OptionsMenu";
        if (this.showOptionsMenu() && editorManager.has(name)) {
            let editor = editorManager.get(name);
            let optionsMenu = new editor({
                element: this.element
            });
            if (optionsMenu) {
                let $optionsMenuButton = this.$controlBar.addEl(controls.createPopupButton(this, {
                    id: this.element.id + "optionsMenu",
                    icon: "settings",
                    showArrow: false,
                    menuContents: function(closeMenu) {
                        optionsMenu.closeMenuCallback = closeMenu;
                        return optionsMenu.render().$el;
                    }
                }));
            }
        }

        // if there ended up being no controls in the controlBar, just remove it
        if (this.$controlBar.children().length == 0) {
            this.$controlBar.remove();
        }

        return this;
    },

    renderControls: function() {
        // can be overridden to render any widgets or controls
    },

    renderAddComponentBar: function(align = HorizontalAlignType.LEFT, offsetX = 0, offsetY = 5) {
        this.addComponentBarProps = { align, offsetX, offsetY };
        let $addComponentBar = $.div("small-control-list bottom");
        this.$el.append($addComponentBar);
        return $addComponentBar;
    },

    addDataSourceControl: function() {
        // if element has "datasource" linked to its model then render its generic element selection control button
        let dataSourceButton = getDataSourceControl(this.element, this);
        this.$controlBar.addEl(dataSourceButton);
    },

    layout: function() {
        let offset = this.getOffset();
        let element = this.element;

        // set the bounds to the element's selection bounds
        let bounds = this.getElementSelectionBounds();
        this.bounds = bounds;
        this.$el.setBounds(bounds);
        if (this.$controlBar) {
            const controlBarBounds = this.$controlBar[0].getBoundingClientRect();

            // vertically position the controlBar relative to the bounds unless it's returns "canvas" and then relative to the canvasBounds
            if (offset == "canvas") {
                this.$controlBar.addClass("primary-controlbar");

                let y = this.selectionLayer.$el.height();
                this.$controlBar.top(y - bounds.top + 17);
                let left = bounds.centerH - controlBarBounds.width / 2 - this.$el.left();
                if (offset.contains(" ")) {
                    left += parseInt(offset.split(" ")[1]);
                }
                this.$controlBar.left(left);
            } else if (typeof (offset) == "string" && offset.startsWith("parent")) {
                let parentBounds = this.element.parentElement.selectionBounds.multiply(this.canvasScale).offset(-bounds.left, -bounds.top);
                offset = offset.contains(" ") ? parseInt(offset.split(" ")[1]) : 0;

                this.$controlBar.top(parentBounds.bottom + offset);
                this.$controlBar.left(parentBounds.centerH - controlBarBounds.width / 2);
            } else if (typeof (offset) == "string" && offset.startsWith("top")) {
                offset = offset.contains(" ") ? parseInt(offset.split(" ")[1]) : 0;
                this.$controlBar.top(offset);
                this.$controlBar.left(bounds.width / 2 - controlBarBounds.width / 2);
            } else {
                this.$controlBar.top(bounds.height + offset);
                // horizontally center align the controlBar within the element's selection bounds
                this.$controlBar.left(bounds.width / 2 - controlBarBounds.width / 2);
            }
        }

        for (let widget of this.customWidgets) {
            this.layoutCustomWidget(widget);
        }

        if (this.addComponentBarProps) {
            this.$el.find(".small-control-list.bottom").left(this.addComponentBarProps.offsetX * this.canvasScale).top(this.$el.height() + this.addComponentBarProps.offsetY);
        }

        this._layout(bounds);
    },

    _layout: function(bounds) {
        // let inheriting class do any custom layout
    },

    showRolloverSelection: function() {
        this.$el.find(".rollover_hilite.fill_hilite").show();
        for (let widget of this.customWidgets) {
            widget.$el.show();
        }
    },

    hideRolloverSelection: function() {
        this.$el.find(".rollover_hilite.fill_hilite").hide();
        for (let widget of this.customWidgets) {
            widget.$el.hide();
        }
    },

    createStylesThumbnail: function(label, thumbnail, value) {
        let $thumbnail = $.div("styles_thumbnail").attr("data-value", value);
        $thumbnail.append($.img(thumbnail));
        if (label) {
            $thumbnail.append($.label(label));
        }
        return $thumbnail;
    },

    // --------------------------------------------------------------------------------------------------------------------------------------------------------
    // Widgets
    //
    // options
    //      target: element to position component relative to
    //      action: callback when widget is clicked
    //      position: WidgetPositionType to indicate where widget should be positioned relative to target
    //      offset: amount to adjust positioning
    //      buttonAlign: HorizontalAlignType to align widget button
    //      label: text for widget button label
    // --------------------------------------------------------------------------------------------------------------------------------------------------------

    addUIHint: function(hint) {
        let $hintContainer = this.$el.addEl($.div("ui_hint_container"));
        $hintContainer.append($.div("ui_hint", hint));
    },

    createAddComponentWidget: function(options) {
        options.target = options.target || this.element; // if no target, use this rollover's element

        // create widget container
        let $uiButton = this.$widgets.addEl($.div("ui_widget"));

        if (options.showLine !== false) {
            $uiButton.append($.div("horizontal_line"));
        }

        // create the button label
        let $button;
        if (options.icon) {
            $button = $uiButton.addEl($.div("icon_button button"));
            $button.append($.icon(options.icon));
        } else {
            $button = $uiButton.addEl($.div("add_component_button button", options.label));
        }

        // call action on button label click
        if (options.action) {
            $button.on("click", event => {
                event.stopPropagation();
                options.action(event);
            });
        }

        options.$el = $uiButton;

        options.width = "fill";
        options.height = 30;

        this.customWidgets.push(options);

        return $button;
    },

    createDeleteComponentWidget: function(options) {
        options.target = options.target || this.element; // if no target, use this rollover's element

        // create widget container
        const $uiButton = this.$widgets.addEl($.div("ui_widget"));

        // create delete button
        const $closeButton = $.div("delete_component_button button");
        $closeButton.addEl($.icon("close"));
        const $button = $uiButton.addEl($closeButton);

        // call action on button click
        $button.on("click", event => {
            event.stopPropagation();
            options.action(event);
        });

        options.$el = $uiButton;

        options.width = 25;
        options.height = 25;

        options.position = options.position || WidgetPositionType.DELETE_BUTTON;
        options.buttonAlign = HorizontalAlignType.CENTER;

        this.customWidgets.push(options);
    },

    createDragWidget: function(draggableElement, options = {}) {
        let $uiButton = this.$widgets.addEl($.div("ui_widget"));
        let $button = $uiButton.addEl($.div("icon_button_square button drag_button"));
        $button.append($.icon("drag_indicator"));

        $button.data("element", draggableElement);

        options.target = this.element;
        options.position = options.position || WidgetPositionType.DRAG_HANDLE;
        options.buttonAlign = HorizontalAlignType.CENTER;
        options.width = 25;
        options.height = 25;
        options.$el = $uiButton;

        this.customWidgets.push(options);

        return $uiButton;
    },

    createEmphasizeWidget: function(showLabel = false) {
        let icon = this.element.model.hilited ? "star" : "star_border";
        let label;
        if (showLabel) {
            label = this.element.model.hilited ? "De-emphasize" : "Emphasize";
        }

        let $emphasizeButton = this.addControl({
            type: controls.BUTTON,
            icon: icon,
            label: label,
            callback: () => {
                this.element.model.hilited = !this.element.model.hilited;
                this.element.canvas.markStylesAsDirty();
                this.element.canvas.updateCanvasModel(true).then(() => {
                    this.render();
                    this.layout();
                });
            }
        });
    },

    layoutCustomWidget: function(widget) {
        widget.offset = widget.offset || 0;

        let elementBounds = widget.target.selectionBounds.multiply(this.canvasScale).offset(-this.bounds.left, -this.bounds.top);

        let position;
        switch (widget.position) {
            case WidgetPositionType.ABOVE:
                position = new geom.Point(elementBounds.left, -(widget.offset + widget.$el.height()));
                widget.width = "fill";
                break;
            case WidgetPositionType.BELOW:
                position = new geom.Point(elementBounds.left, elementBounds.bottom + widget.offset);
                widget.width = "fill";
                break;
            case WidgetPositionType.RIGHT:
                position = new geom.Point(elementBounds.right + widget.offset, elementBounds.height / 2 - widget.height / 2);
                break;
            case WidgetPositionType.LEFT:
                position = new geom.Point(elementBounds.left - widget.offset, elementBounds.height / 2 - widget.height / 2);
                break;
            case WidgetPositionType.UPPER_RIGHT:
                if (this.getWidgetPosition() == "inner") {
                    position = new geom.Point(elementBounds.right - widget.width - 3, 3);
                } else {
                    position = new geom.Point(elementBounds.right - widget.width / 2, -widget.height / 2);
                }
                break;
            case WidgetPositionType.DELETE_BUTTON:
                if (this.getWidgetPosition() == "inner") {
                    position = new geom.Point(elementBounds.right - widget.width - 1, 1);
                } else {
                    position = new geom.Point(elementBounds.right, Math.min(elementBounds.height / 2 - widget.height / 2, -5));
                }
                break;
            case WidgetPositionType.UPPER_LEFT:
                position = new geom.Point(elementBounds.left, 0);
                break;
            case WidgetPositionType.BOTTOM_LEFT:
                position = new geom.Point(elementBounds.left + 3, elementBounds.bottom - widget.height - 3);
                break;
            case WidgetPositionType.BOTTOM_RIGHT:
                position = new geom.Point(elementBounds.right - widget.width - 3, elementBounds.bottom - widget.height - 3);
                break;
            case WidgetPositionType.DRAG_HANDLE:
                position = new geom.Point(elementBounds.left - widget.width, Math.min(elementBounds.height / 2 - widget.height / 2, 0));
                break;
            case WidgetPositionType.DRAG_HANDLE_INSIDE:
                position = new geom.Point(elementBounds.left, 0);
                break;
            default:
                if (typeof (widget.position) == "function") {
                    position = widget.position();
                } else {
                    position = widget.position;
                }
        }

        let bounds = new geom.Rect(position.x, position.y, 0, 0);
        if (widget.width == "fill") {
            bounds.width = elementBounds.width;
        } else {
            bounds.width = widget.width;
        }
        if (widget.height == "fill") {
            bounds.height = elementBounds.height;
        } else {
            bounds.height = widget.height;
        }

        widget.$el.setBounds(bounds);

        let buttonAlign = widget.buttonAlign;

        let $buttons = widget.$el.find(".button");

        if (!buttonAlign && widget.target.type == "Text" && $buttons.length) {
            buttonAlign = widget.target.styles.textAlign;
        }

        let buttonGap = 10;
        let buttonWidth = _.sumBy($buttons, button => {
            let width = $(button).outerWidth();
            button.width = width;
            return width + buttonGap;
        }) - buttonGap;

        let offsetX = 0;
        switch (buttonAlign) {
            case HorizontalAlignType.CENTER:
                offsetX = widget.$el.width() / 2 - buttonWidth / 2;
                // $button.left(widget.$el.width() / 2 - $button.outerWidth() / 2);
                break;
            case HorizontalAlignType.RIGHT:
                offsetX = widget.$el.width() - buttonWidth - 40;
                // $button.left(widget.$el.width() - $button.outerWidth());
                break;
            case HorizontalAlignType.LEFT:
                offsetX = 40;
                break;
        }

        offsetX += widget.buttonOffset || 0;

        $buttons.each((index, button) => {
            $(button).left(offsetX);
            offsetX += button.width + buttonGap;
        });

        //vertical align in middle
        $buttons.top(bounds.height / 2 - $buttons.first().outerHeight() / 2);

        widget.$el.find(".horizontal_line").top(bounds.height / 2);
        widget.$el.find(".vertical_line").left(bounds.width / 2 - 1);
    },

    // --------------------------------------------------------------------------------------------------------------------------------------------------------
    // Controls
    // --------------------------------------------------------------------------------------------------------------------------------------------------------

    // helper function to make it easy to add a control
    addControl: function(config) {
        if (config.callback) {
            config.callback = $.proxy(config.callback, this);
        }

        if (!config.model) {
            config.model = this.model;
        }

        // This is pretty dangerous code -- any two controls in the same element of the same tpe with end up with the same
        // id if neither of them has a label or id specifically defined. And plenty of our controls throughout the codebase
        // omit one or both. We should probably create a more unique way to generate id's than this to prevent multiple controls
        // from receiving click events without breaking the current functionality. Adding the 'config.label' here is a minor
        // improvement, but it's still not going to cover 100% of cases. [JWF]
        if (!config.id) {
            config.id = (this.element.id + config.type + (config.label || "")).replace(/ /g, "_");
        }

        let $control = controls.createControl(this, config, ds.selection.slide);
        this.$controlBar.append($control);
        return $control;
    },

    addGap: function(width = 20) {
        this.$controlBar.append($.div("control-gap").width(width));
    },

    addDivider: function() {
        this.$controlBar.append($.div("").css({ border: "solid 1px rgba(255,255,255,0.2)", height: "100%" }));
    }
});

export const ElementRollover = ElementUI.extend({
    render: function() {
        this.subComponentBounds = [];
        this.customWidgets = [];

        this.$el.empty();
        this.$el.attr("id", this.element.type + "-rollover");
        this.$el.addClass("rollover");

        // create the rollover hilite
        if (this.element !== this.element.canvas.layouter.elements.primary) {
            if (this.captureMouseEvents) {
                // this.$el.addEl($.div("mouse-event-capture"));
            }
            if (this.showSelectionBox && ds.selection.element != this.element) {
                this.$el.addEl($.div("selection-box"));
            }
        }

        // create a widgets container to hold any widgets added during renderControls
        this.$widgets = this.$el.addEl($.div("widgets"));

        // let all the overridding classes render their controls
        this.renderControls();

        return this;
    }

});

export const ElementSelection = ElementUI.extend({
    render: function() {
        this.subComponentBounds = [];
        this.customWidgets = [];

        this.$el.empty();

        this.$el.attr("id", this.element.type + "-selection");

        let backgroundColor = this.element.getBackgroundColor();

        let widgetColor;
        if (this.getWidgetColor() == "auto") {
            if (this.getWidgetPosition() == "inner" && (backgroundColor == BackgroundStyleType.IMAGE || (backgroundColor instanceof tinycolor && backgroundColor.getLuminance() < .75))) {
                widgetColor = "light";
            } else {
                widgetColor = "dark";
            }
        } else {
            widgetColor = this.getWidgetColor();
        }

        this.$el.addClass(widgetColor);

        // create the rollover hilite
        if (this.element !== this.element.canvas.layouter.elements.primary) {
            if (this.captureMouseEvents) {
                // this.$el.addEl($.div("mouse-event-capture"));
            }
            if (this.showSelectionBox) {
                this.$el.addEl($.div("selection-box"));
            }
        }

        // create a controlbar to hold any controls added during renderControls
        this.$controlBar = this.$el.addEl($.div("widget_bar control_bar"));

        // create a widgets container to hold any widgets added during renderControls
        this.$widgets = this.$el.addEl($.div("widgets"));

        // render datasource button if element supports it
        if (this.showDataSourceMenu()) {
            this.addDataSourceControl();
        }

        // let all the overridding classes render their controls
        this.renderControls();

        // render any editor options menu if defined
        let name = this.element.type + "OptionsMenu";
        if (this.showOptionsMenu() && editorManager.has(name)) {
            let editor = editorManager.get(name);
            let optionsMenu = new editor({
                element: this.element
            });
            if (optionsMenu) {
                let $optionsMenuButton = this.$controlBar.addEl(controls.createPopupButton(this, {
                    id: this.element.id + "optionsMenu",
                    icon: "settings",
                    showArrow: false,
                    menuContents: function(closeMenu) {
                        optionsMenu.closeMenuCallback = closeMenu;
                        return optionsMenu.render().$el;
                    }
                }));
            }
        }

        // this.$controlBar.find(".control").last().css({ borderRadius: "0px 2px 2px 0px" });
        this.$controlBar.find(".control").last().addClass("last-control");

        if (this.getOffset() == "canvas" && this.element.canvas.showSmartSlideWatermark()) {
            this.$controlBar.addEl(controls.createButton(this, {
                id: "remove-watermark",
                icon: "auto_awesome",
                className: "remove-watermark-button",
                label: "Remove Watermark",
                callback: () => {
                    ShowUpgradeDialog({
                        type: UpgradePlanDialogType.REMOVE_BRANDING,
                        analytics: { cta: "RemoveWatermark" },
                        workspaceId: AppController.workspaceId
                    });
                }
            }));
        }

        // if there ended up being no controls in the controlBar, just remove it
        if (this.$controlBar.children().length == 0) {
            this.$controlBar.remove();
        }

        return this;
    },
});

export const ElementDefaultOverlay = BaseElementUI.extend({
    className: "element-default-overlay",

    layout() {
        this.$el.setBounds(this.getElementSelectionBounds());
    }
});

export const ElementDropTarget = ElementUI.extend({
    className: "element-drop-target",

    render: function() {
        this.$el.fileDrop({
            leave: () => this.trigger("file:dragleave"),
            drop: files => {
                if (!isConnected.connected) {
                    ShowOfflineDialog();
                    return;
                }

                this.trigger("file:drop");
                let file = files[0];
                if (!isValidMediaType(file).isValidMedia) {
                    return ShowUnsupportedFileTypesDialog();
                }

                if (file.type.includes("image/")) {
                    let fileType = getImageType(file);
                    const dialog = ShowDialog(ProgressDialog, {
                        title: "Importing image...",
                    });
                    ds.assets.getOrCreateImage({
                        file,
                        fileType,
                        assetType: AssetType.IMAGE,
                        metadata: {
                            source: "drag/drop"
                        }
                    })
                        .then(asset => {
                            this.element.model.content_type = AssetType.IMAGE;
                            this.element.model.content_value = asset.id;

                            // set the initial scale
                            this.element.model.scale = Math.min(
                                this.bounds.width / asset.attributes.w,
                                this.bounds.height / asset.attributes.h
                            );

                            // must reset these props to scale correctly
                            this.element.model.aoiBottom = null;
                            this.element.model.aoiLeft = null;
                            this.element.model.aoiRight = null;
                            this.element.model.aoiTop = null;

                            // update
                            this.element.canvas.updateCanvasModel(true);
                        })
                        .catch(err => {
                            const warning =
                                err.type === ImageErrorType.SIZE
                                    ? {
                                        title: err.title,
                                        message: err.message,
                                    }
                                    : {
                                        title: "Sorry!",
                                        message: "We were unable to upload this image. Please try again.",
                                    };
                            ShowWarningDialog(warning);

                            logger.error(err, "[ElementDropTarget] drop() image failed", { slideId: this.element.canvas.dataModel?.id });
                        })
                        .finally(() => {
                            dialog.props.closeDialog();
                        });
                } else if (file.type.includes("video/")) {
                    const uploadDisabled = shouldShowProBadge(FeatureType.VIDEO_UPLOAD, UIController.getWorkspaceId());
                    if (uploadDisabled) {
                        ShowUpgradeDialog({
                            type: UpgradePlanDialogType.UPGRADE_PLAN,
                            analytics: { cta: "MediaUpload", ...(ds.selection.presentation?.getAnalytics() ?? {}) },
                            workspaceId: UIController.getWorkspaceId()
                        });
                    } else {
                        const dialog = ShowDialog(ProgressDialog, {
                            title: "Importing video...",
                        });

                        uploadFileAndCreateTask(file, TaskType.VIDEO_UPLOAD, async task => {
                            if (task.state === TaskState.ERROR) {
                                dialog.setProgress(0);
                                dialog.setMessage(task.errorMessage);
                                return;
                            }

                            if (task.state === TaskState.PREPARING) {
                                dialog.setProgress(task.stateProgressPercents / 2);
                                return;
                            }

                            if (task.state === TaskState.PROCESSING) {
                                dialog.setProgress(task.stateProgressPercents / 2 + 50);
                                return;
                            }

                            if (task.state === TaskState.FINISHED) {
                                dialog.setProgress(100);
                                this.getVideoAsset(task.videoAssetId)
                                    .then(asset => {
                                        let {
                                            id,
                                            type,
                                            size,
                                            assetProps,
                                        } = asset;

                                        if (!size) {
                                            size = new geom.Size(asset.get("w"), asset.get("h"));
                                        }

                                        mergeMediaElementModelDefaults(
                                            this.element.model,
                                            {
                                                content_value: id,
                                                content_type: type,
                                                content_url: null,
                                                assetProps: _.merge({}, assetProps, {
                                                    originalSize: size,
                                                }),
                                            },
                                        );
                                        this.element.canvas.updateCanvasModel(true);
                                    })
                                    .catch(err => {
                                        const warning = {
                                            title: "Sorry!",
                                            message: "We were unable to upload this video. Please try again.",
                                        };
                                        ShowWarningDialog(warning);

                                        logger.error(err, "[ElementDropTarget] drop() video failed", { slideId: this.element.canvas.dataModel?.id });
                                    })
                                    .finally(() => {
                                        dialog.props.closeDialog();
                                    });
                            }
                        }, undefined, undefined);
                    }
                }
            }
        });

        return this;
    },

    getVideoAsset: async videoAssetId => {
        let backgroundVideoOnly = true;

        const assetModel = await ds.assets.getAssetById(videoAssetId, "video");
        await assetModel.load();
        let assetAttributes = { ...assetModel.attributes };
        let size = new geom.Size(assetAttributes.w, assetAttributes.h);
        let duration = assetAttributes.duration;

        trackActivity("Video", "AddedToSlide", null, null, {
            "slide_id": ds.selection.slide?.id,
            "video_type": "upload",
            "url": null,
            "id": videoAssetId,
        }, { audit: true });

        const controls = !backgroundVideoOnly && duration > 30;

        const asset = {
            hostedVideoUrl: null,
            id: videoAssetId,
            type: AssetType.VIDEO,
            size,
            assetProps: {
                muted: true,
                duration,
                startTime: 0,
                endTime: duration,
                autoPlay: !controls,
                loop: !controls,
                speed: 1.0,
                controls,
            },
            ...assetAttributes,
        };

        return asset;
    },
});

export const ElementOptionsMenu = Backbone.View.extend({
    className: "options-menu",

    initialize: function(options) {
        this.element = options.element;
        this.model = options.element.model;
        this.setup();
    },

    setup: function() {
    },

    render: function() {
        this.$el.empty();

        // ensure that we aren't editing text or have a child of our element selected
        ds.selection.element = this.element;

        this.renderControls();

        this.element.canvas.lockSlideForCollaborators(30);
        // Explicitly binding to the element's remove event because closeMenu()
        // is called not in every case
        this.$el.on("remove", () => {
            if (this.element.canvas.isLockedForCollaborators()) {
                this.element.canvas.unlockSlideForCollaborators();
            }
        });

        return this;
    },

    renderControls: function() {
    },

    refreshElementPointer: function() {
        if (this.element) {
            ds.selection.element = null;
            ds.selection.element = this.element;
        }
    },

    addSection: function(label, withRule) {
        this.$el.append($.div("section", label).toggleClass("rule", withRule));
    },

    addDivider: function() {
        this.$el.append($.hr());
    },

    addControl: function(config) {
        if (config.callback) {
            config.callback = $.proxy(config.callback, this);
        }

        if (!config.model) {
            config.model = this.model;
        }

        let $control = controls.createControl(this, config, ds.selection.slide);

        if (config.enabled != undefined && !config.enabled) {
            $control.addClass("disabled");
        }

        this.$el.append($control);
        return $control;
    },

    closeMenu: function() {
        if (this.closeMenuCallback) {
            this.closeMenuCallback();
        }
    }

});

export const ChildElementOptionsMenu = ElementOptionsMenu.extend({});
