import React, { Component, Fragment } from "react";
import styled from "styled-components";
import ContentEditable from "react-contenteditable";

import { FlexBox } from "js/react/components/LayoutGrid";
import { TextStyleType, AuthoringBlockType } from "common/constants";
import { Key } from "js/core/utilities/keys";
import { _, $ } from "js/vendor";
import { themeColors } from "js/react/sharedStyles";
import LoremIpsum from "js/core/utilities/loremIpsum";
import * as browser from "js/core/utilities/browser";
import {
    getSelection,
    getSelectionState,
    convertSpansToFontElements,
    setSelection,
    sanitizeHtmlText
} from "js/core/utilities/htmlTextHelpers";
import { TextBlockContainer } from "../../../../elements/elements/authoring/AuthoringBlocks/TextBlock";
import { BoundsBox, ReverseScaleBox } from "../../SelectionBox";
import { TextFormatWidgetBar } from "./TextFormatBar";
import { ClipboardType, clipboardRead } from "js/core/utilities/clipboard";
import { sanitizeHtml } from "js/core/utilities/dompurify";

const EditContentEditable = styled(ContentEditable)`
    width: 100%;
    height: 100%;
    user-select: text;
    -webkit-user-select: text;
    
    a {
        user-select: text;
        -webkit-user-select: text;
    }
    
    ::selection{
       background: ${themeColors.textSelection};
    }
    ::-moz-selection{
       background: ${themeColors.textSelection};
    }
      
    * {
      &::selection{
        background: ${themeColors.textSelection};
      }
      &::-moz-selection{
        background: ${themeColors.textSelection};
      }
    }
    
    // must force visibility and content when targeting
    // the actual editor content-editable element
    &:empty:before {
        content: "${props => props.placeholder || "Type '/' for styles"}" !important;
        opacity: ${props => props.showPlaceholder ? 1 : 0} !important;
    }
`;

export class TextBlockEditor extends Component {
    state = {
        hasFocus: false
    }

    handleChanges = true;
    hasPendingTextChanges = false;
    saveTextChangesTimeout = null;
    renderedScale;

    postUpdateCallbacks = [];

    constructor() {
        super();

        this.textRef = React.createRef();
    }

    componentDidMount() {
        const { block } = this.props;
        block.onStartEditing();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { block, isFocused, selectionPosition } = this.props;

        // Running requested post-update tasks
        this.postUpdateCallbacks.forEach(callback => callback());
        this.postUpdateCallbacks = [];

        if (prevProps.block !== block) {
            if (prevProps.block) {
                prevProps.block.onStopEditing();
            }
            if (block) {
                block.onStartEditing();
            }

            this.savePendingTextChangesIfNeeded();
        }

        if (prevProps.isFocused !== isFocused) {
            // Became focused (our block got selected)
            if (isFocused) {
                // If the focus was triggered not by an organic click,
                // then we have to select the block explicitly
                if (document.activeElement !== this.textRef.current) {
                    this.textRef.current.focus();
                }

                const selection = window.getSelection();
                if ((!selection.anchorNode || !$(this.textRef.current).has(selection.anchorNode)) ||
                    (getSelectionState(this.textRef.current).isAtEnd === false && selectionPosition === "end")) {
                    const range = document.createRange();
                    range.selectNodeContents(this.textRef.current);
                    range.collapse(selectionPosition !== "end");
                    selection.removeAllRanges();
                    selection.addRange(range);
                } else if (typeof selectionPosition == "number") {
                    this.setSelection({ start: selectionPosition, end: selectionPosition });
                }
            } else {
                this.savePendingTextChangesIfNeeded();
            }
        }
    }

    componentWillUnmount() {
        const { block } = this.props;

        block.onStopEditing();

        this.savePendingTextChangesIfNeeded();
    }

    // used to prevent accidential loss of cursor position
    // when a change might cause the contenteditable to re-render
    // and lose the cursor position
    tryRestoreCursorPosition = () => {
        const { current: target } = this.textRef;

        // if it's active, it means they're currently
        // editing this area
        if (target !== document.activeElement) {
            return;
        }

        // get the current position and attempt to restore
        // it a moment later
        const position = browser.getCaretPosition(target);
        requestAnimationFrame(() => {
            if (target === document.activeElement) {
                browser.setCaretPosition(target, position);
            }
        });
    }

    runPostUpdate = callback => {
        this.postUpdateCallbacks.push(callback);
    }

    savePendingTextChangesIfNeeded = async () => {
        const { saveChanges } = this.props;

        if (this.hasPendingTextChanges) {
            this.hasPendingTextChanges = false;
            await saveChanges();
        }
    }

    /**
     * WARNING: use this instead of props.refreshCanvasAndSaveChanges()
     */
    refreshCanvasAndSaveChanges = () => {
        const { refreshCanvasAndSaveChanges } = this.props;

        this.hasPendingTextChanges = false;
        return refreshCanvasAndSaveChanges();
    }

    setSelection = selectionState => {
        setSelection(selectionState, this.textRef.current);
    }

    updateHtml = html => {
        return this.handleChange({ target: { value: html } });
    }

    handleChange = async event => {
        if (!this.handleChanges) {
            return;
        }

        const { block, refreshElement } = this.props;

        // clean up the HTML
        let html = convertSpansToFontElements(event.target.value);
        html = sanitizeHtmlText(html);

        if (block.model.html === html) {
            return;
        }

        block.model.html = html;

        // Won't be saving immediately on key press
        if (["insertText", "deleteContentBackward", "deleteContentForward", "historyUndo", "historyRedo"].includes(event.nativeEvent?.inputType)) {
            this.hasPendingTextChanges = true;
            refreshElement();

            clearTimeout(this.saveTextChangesTimeout);
            this.saveTextChangesTimeout = setTimeout(this.savePendingTextChangesIfNeeded, 5000);
        } else {
            await this.refreshCanvasAndSaveChanges();
        }
    }

    handleKeyDown = event => {
        const { isFocused, block, onDeleteBlock, onAddBlockOnEnterKey, onFocusOtherBlock, onMergeBlocks, onShowMenu, onSelectAllBlocks } = this.props;

        if (!isFocused) {
            return;
        }

        event.stopPropagation();

        const contentEditableElement = this.textRef.current;
        const textContentLength = contentEditableElement.textContent.length;
        const { isAtStart, isAtEnd, isAllSelected } = getSelectionState(contentEditableElement);

        switch (event.which) {
            case Key.TAB:
                event.preventDefault();
                if (block.model.textStyle == TextStyleType.NUMBERED_LIST || block.model.textStyle == TextStyleType.BULLET_LIST) {
                    if (event.shiftKey) {
                        block.model.indent = Math.max(0, (block.model.indent || 0) - 1);
                    } else {
                        block.model.indent = Math.min(5, (block.model.indent || 0) + 1);
                    }
                    this.refreshCanvasAndSaveChanges();
                } else {
                    document.execCommand("insertText", false, "        ");
                }
                break;

            case Key.KEY_B:
                if (event.metaKey || event.ctrlKey) {
                    document.execCommand("bold");

                    // try to prevent default behavior
                    event.preventDefault();
                    event.stopPropagation();
                }
                break;

            case Key.KEY_I:
                if (event.metaKey || event.ctrlKey) {
                    document.execCommand("italic");

                    // try to prevent default behavior
                    // not possible in Safari
                    event.preventDefault();
                    event.stopPropagation();
                }
                break;

            case Key.ENTER:
                if (!event.shiftKey && !event.altKey) {
                    event.preventDefault();
                    if (isAtEnd) {
                        const blockModel = {
                            type: AuthoringBlockType.TEXT
                        };
                        if (block.model.textStyle === TextStyleType.NUMBERED_LIST || block.model.textStyle === TextStyleType.BULLET_LIST) {
                            blockModel.fontSize = block.model.fontSize;
                            blockModel.textStyle = block.model.textStyle;
                            blockModel.indent = block.model.indent ?? 0;
                        } else {
                            blockModel.textStyle = TextStyleType.BODY;
                        }

                        this.savePendingTextChangesIfNeeded()
                            .then(() => onAddBlockOnEnterKey({ model: blockModel }));
                    } else {
                        const range = window.getSelection().getRangeAt(0);
                        range.deleteContents();

                        const startRange = range.cloneRange();
                        startRange.setStart(contentEditableElement, 0);
                        const startDiv = document.createElement("div");
                        startDiv.appendChild(startRange.cloneContents());
                        const startHtml = sanitizeHtml(startDiv.innerHTML);

                        range.setEndAfter(contentEditableElement);
                        const endDiv = document.createElement("div");
                        endDiv.appendChild(range.cloneContents());
                        const endHtml = sanitizeHtml(endDiv.firstElementChild.innerHTML);

                        block.model.html = startHtml;

                        const blockModel = {
                            ...block.model,
                            id: _.uniqueId("block"),
                            html: endHtml
                        };

                        this.savePendingTextChangesIfNeeded()
                            .then(() => onAddBlockOnEnterKey({ model: blockModel }));
                    }
                }
                break;
            case Key.BACKSPACE:
            case Key.DELETE:
                if (textContentLength === 0) {
                    event.preventDefault();

                    if (block.model.textStyle == TextStyleType.NUMBERED_LIST || block.model.textStyle == TextStyleType.BULLET_LIST) {
                        block.model.textStyle = TextStyleType.BODY;
                        this.refreshCanvasAndSaveChanges();
                    } else {
                        this.savePendingTextChangesIfNeeded()
                            .then(() => onDeleteBlock(block, true));
                    }
                } else if (isAtStart && block.index > 0) {
                    event.preventDefault();

                    this.savePendingTextChangesIfNeeded()
                        .then(() => onMergeBlocks(this.props.container.blocks[block.index - 1], block));
                } else if (browser.isSafari) {
                    const selectionStateBeforeUpdate = getSelectionState(contentEditableElement);
                    selectionStateBeforeUpdate.start--;
                    selectionStateBeforeUpdate.end--;

                    this.runPostUpdate(() => {
                        const selectionStateAfterUpdate = getSelectionState(contentEditableElement);
                        if (selectionStateAfterUpdate.start !== selectionStateBeforeUpdate.start || selectionStateAfterUpdate.end !== selectionStateBeforeUpdate.end) {
                            this.setSelection(selectionStateBeforeUpdate);
                        }
                    });
                }
                break;
            case Key.LEFT_ARROW:
                if (isAtStart) {
                    event.preventDefault();
                    onFocusOtherBlock(block, "prev", "end");
                }
                break;
            case Key.RIGHT_ARROW:
                if (isAtEnd) {
                    event.preventDefault();
                    onFocusOtherBlock(block, "next", "start");
                }
                break;
            case Key.UP_ARROW:
                if (isAtStart) {
                    event.preventDefault();
                    onFocusOtherBlock(block, "prev", "end");
                }
                break;
            case Key.DOWN_ARROW:
                if (isAtEnd) {
                    event.preventDefault();
                    onFocusOtherBlock(block, "next", "start");
                }
                break;
            case Key.FORWARD_SLASH:
                if (textContentLength === 0) {
                    event.preventDefault();
                    onShowMenu(event, "below", true);
                }
                break;
            case Key.KEY_A:
                if (isAllSelected && (event.metaKey || event.ctrlKey)) {
                    event.preventDefault();
                    event.stopPropagation();
                    onSelectAllBlocks();
                }
                break;
            case Key.KEY_E:
                if (window.isDevelopment) {
                    if (event.ctrlKey || event.metaKey) {
                        let loremText;
                        if (event.shiftKey) {
                            loremText = LoremIpsum.getTitle();
                        } else {
                            loremText = LoremIpsum.getParagraph(1);
                        }
                        document.execCommand("insertText", false, loremText);
                    }
                }
                break;
            case Key.KEY_Z:
                event.stopPropagation();
                break;
        }
    }

    handlePaste = async event => {
        event.preventDefault();
        event.stopPropagation();

        let text = await clipboardRead(
            [
                ClipboardType.HTML,
                ClipboardType.TEXT,
            ],
            event
        );
        // TOOD: mitch: support pasting blocks?

        if (!text?.length) {
            return;
        }

        const selection = getSelection();
        if (selection) {
            const range = selection.getRangeAt(0);
            range.deleteContents();

            const wrapperElement = document.createElement("div");
            wrapperElement.innerHTML = sanitizeHtml(text);
            Array.from(wrapperElement.childNodes).reverse().forEach(node => {
                range.insertNode(node);
            });

            // Preserve the selection
            selection.removeAllRanges();
            selection.addRange(range);
        }
    }

    handleMouseDown = event => {
        if (event.button === 2) {
            // Prevent default context menu from opening
            event.preventDefault();
        }
    }

    render() {
        const { block, bounds, containerBounds, refreshElement, element, isFocused } = this.props;
        const { textStyle } = block.model;

        let placeholder;
        if (textStyle == TextStyleType.BODY) {
            placeholder = "Type '/' for styles";
        } else {
            placeholder = "Type " + textStyle;
        }

        // check if the placeholder is needed
        const showPlaceholder = !_.trim(block.html).length;

        // if scaling changes, the cursor will most likely be
        // lost, so before rendering queue up an attempt to
        // restore the cursor
        if (this.renderedScale !== block.scale) {
            this.renderedScale = block.scale;
            this.tryRestoreCursorPosition();
        }

        return (
            <Fragment>
                <BoundsBox bounds={bounds} rotate={element.model.rotation ?? 0}>
                    <ReverseScaleBox bounds={bounds}>
                        <FlexBox fill middle left>
                            <TextBlockContainer>
                                <EditContentEditable
                                    innerRef={this.textRef}
                                    html={block.html}
                                    showPlaceholder={showPlaceholder}
                                    placeholder={placeholder}
                                    className={`style-${block.type}`}
                                    style={{
                                        ...block.getTextStyles(),
                                        caretColor: themeColors.ui_blue,
                                        background: "transparent"
                                    }}
                                    onKeyDown={this.handleKeyDown}
                                    onChange={this.handleChange}
                                    onPaste={this.handlePaste}
                                    onMouseDown={this.handleMouseDown}
                                />
                            </TextBlockContainer>
                        </FlexBox>
                    </ReverseScaleBox>
                </BoundsBox>
                {isFocused && <TextFormatWidgetBar
                    isMultiSelectMode={false}
                    selectedBlocks={[block]}
                    containers={[element]}
                    bounds={containerBounds}
                    refreshCanvasAndSaveChanges={this.refreshCanvasAndSaveChanges}
                    refreshElement={refreshElement}
                    textRef={this.textRef}
                    updateHtml={this.updateHtml}
                    runPostUpdate={this.runPostUpdate}
                    stopHandlingChanges={() => this.handleChanges = false}
                    startHandlingChanges={() => this.handleChanges = true}
                    setSelection={this.setSelection}
                />}
            </Fragment>
        );
    }
}

