import { app } from "js/namespaces.js";
import { _ } from "js/vendor";
import * as geom from "js/core/utilities/geom";
import { linearPartition } from "js/core/utilities/linearPartition";
import { tileImages } from "js/core/utilities/imageTiling";
import {
    BackgroundStyleType,
    ElementTextBlockPositionType,
    PaletteColorType,
    PositionType,
    ContentBlockType,
    TextBlockPreset, AssetType
} from "common/constants";
import { getValueOrDefault } from "js/core/utilities/extensions";

import { CollectionElement, CollectionItemElement } from "../base/CollectionElement";
import { ContentElement } from "../base/ContentElement";
import { ContentBlockContainer } from "./ContentBlock";

class PhotoCollage extends CollectionElement {
    getChildItemType(itemModel) {
        return PhotoCollageItem;
    }

    get fullBleed() {
        if (this.aspectRatio == "fit") { // || this.canvas.model.layout.elementTextBlockPosition == ElementTextBlockPositionType.INLINE) {
            return false;
        } else {
            return this.options.fullBleed || this.model.fullBleed;
        }
    }

    get showCaption() {
        return this.model.showCaption;
    }

    get aspectRatio() {
        return this.model.aspectRatio || "fill";
    }

    get allowText() {
        return true;
    }

    get allowImage() {
        return true;
    }

    get allowFrame() {
        return true;
    }

    get showFrame() {
        return this.model.showFrame || false;
    }

    get frame() {
        return this.model.frame || "none";
    }

    get showGutter() {
        if (this.itemElements.length > 1 || (this.fullBleed)) { // && (!isTrayContainer || !element.canvas.model.layout.trayLayout.contains("inline"))
            return this.model.showGutter || false;
        } else {
            return false;
        }
    }

    get reserveFooterSpace() {
        if (this.fullBleed && this.showGutter) {
            return true;
        } else {
            return false;
        }
    }

    get showShadow() {
        return this.frame == "light";
    }

    get gutterSize() {
        return 10;
    }

    get layoutType() {
        return this.model.layoutType || "grid";
    }

    getCanvasMargins() {
        if (this.fullBleed) {
            let margins;
            if (this.showGutter) {
                margins = {
                    left: this.gutterSize,
                    right: this.gutterSize,
                    top: this.gutterSize,
                    bottom: this.gutterSize
                };
            } else {
                margins = { left: 0, right: 0, top: 0, bottom: 0 };
            }

            if (this.canvas.model.layout.elementTextBlockPosition == ElementTextBlockPositionType.INLINE) {
                margins.bottom = 50;
            }

            return margins;
        } else {
            return super.getCanvasMargins();
        }
    }

    _loadStyles(styles) {
        if (this.showShadow && this.showGutter == false && this.aspectRatio != "fit") {
            // if no gutter, we need to add the shadow to the entire collage so we don't get overlap in the seams
            // otherwise we assign shadow to each item
            styles.shadow = {
                blur: 14,
                offsetX: 0,
                offsetY: 0,
                opacity: 0.2
            };
        }
    }

    _calcProps(props, options) {
        let { size } = props;

        let showGutter = options.showGutter != undefined ? options.showGutter : this.showGutter;
        let layoutType = options.layoutType ? options.layoutType : this.layoutType;
        let fitAspectRatio = options.aspectRatio ? options.aspectRatio == "fit" : this.aspectRatio == "fit";

        let availableSize = size.clone();

        if (showGutter) {
            this.gap = this.gutterSize;
        } else if (this.frame != "none") {
            this.gap = -this.canvas.styleSheet.decorationVariables[this.canvas.styleSheet.BoxFrame.strokeWidth.substr(1)];
        } else {
            this.gap = 0;
        }

        let calculatedSize;
        switch (layoutType) {
            case "grid":
            default:
                if (fitAspectRatio) {
                    calculatedSize = this.layoutTiles(props, availableSize);
                } else {
                    calculatedSize = this.layoutGrid(props, availableSize);
                }
                break;
        }

        if (this.model.matchTextScale) {
            // if any headline textscales differ, recalc all the cells with a forceTextScale option to make them all the same
            let textScales = _.map(this.itemElements, cell => cell.contentBlocks ? cell.contentBlocks.calculatedProps.scale : null);
            if (_.uniq(textScales).length > 1) {
                let minTextScale = _.min(textScales);
                for (let cell of this.itemElements) {
                    if (cell.contentBlocks?.calculatedProps.scale != minTextScale) {
                        let bounds = cell.bounds;
                        let cellProps = cell.calcProps(cell.calculatedProps.size, { forceTextScale: minTextScale });
                        cellProps.bounds = bounds;
                    }
                }
            }
        }

        props.isFit = this.itemCount <= (this.styles.maxItemCount || 5);

        return { size: calculatedSize };
    }

    layoutCollage(size) {
        let sizes = _.map(this.itemElements, item => item.contentSize);

        let bestFitLayout;
        let bestFitScore = 10000000;

        for (let w = (size.width / 2); w <= size.width; w += 20) {
            let layout = linearPartition(sizes, {
                containerWidth: w,
                idealElementHeight: size.height / 3,
                spacing: this.gap
            });

            let scale = Math.min(1, size.height / layout.height);

            let scaledWidth = layout.width * scale;
            if (size.width - scaledWidth < bestFitScore) {
                bestFitScore = size.width - scaledWidth;
                bestFitLayout = layout;
            }
        }

        let scale = Math.min(1, size.height / bestFitLayout.height);

        let offsetX = size.width / 2 - bestFitLayout.width * scale / 2;
        let offsetY = size.height / 2 - bestFitLayout.height * scale / 2;

        let itemBounds = _.map(bestFitLayout.positions, item => {
            return new geom.Rect(item.x * scale + offsetX, item.y * scale + offsetY, item.width * scale, item.height * scale);
        });

        this.itemElements.forEach((item, index) => {
            item.calcSize(itemBounds[index].size);
            item.bounds = itemBounds[index].offset(10, 10);
        });

        return new geom.Size(bestFitLayout.width * scale, bestFitLayout.height * scale);
    }

    layoutTiles(props, size) {
        let ratios = _.map(this.itemElements, item => 1 / item.contentSize.aspectRatio);

        let layouts = tileImages(size.width, size.height, ratios);

        this.tileLayouts = layouts;
        let index = parseInt(this.model.gridLayout) || 0;

        if (index >= layouts.length) {
            index = 0;
        }

        let itemBounds = _.map(layouts[index], rect => {
            return new geom.Rect(rect.x, rect.y, rect.w, rect.h).deflate(this.gap / 2, this.gap / 2);
        });

        this.itemElements.forEach((item, index) => {
            let itemProps = item.calcProps(itemBounds[index].size, { fitAsset: false });
            itemProps.bounds = itemBounds[index];
        });

        return size;
    }

    layoutGrid(props, size) {
        let layouter = this.getLayouter(props, this.itemElements, size);

        let gridLayout = this.model.gridLayout;
        if (!gridLayout && this.type == "TrayPhotoCollage") {
            gridLayout = 1; // this defaults the grid to top/bottom layout when adding the first additional cell to a tray
        }

        layouter.calcGridLayout({
            gridLayout: gridLayout,
            showGutter: this.showGutter,
            gap: this.gap
        });

        return size;
    }

    // endregion

    getCustomSlideColorsLabel() {
        return "Cell Color";
    }

    getCustomBackgroundColorsLabel() {
        return "Gutter";
    }

    getCustomSlideColors() {
        let slideColors = [];
        slideColors.push({
            name: PaletteColorType.BACKGROUND_LIGHT,
            isColor: false,
            color: this.canvas.getTheme().palette.getColor(PaletteColorType.BACKGROUND_LIGHT),
            enabled: true
        });
        slideColors.push({
            name: PaletteColorType.BACKGROUND_DARK,
            isColor: false,
            color: this.canvas.getTheme().palette.getColor(PaletteColorType.BACKGROUND_DARK),
            enabled: true
        });

        _.each(this.canvas.getTheme().palette.getSlideColors(), (color, key) => {
            slideColors.push({
                name: key,
                color: color,
                enabled: true
            });
        });
        slideColors.push({
            name: "colorful",
            enabled: true
        });

        return slideColors;
    }

    _migrate_8() {
        super._migrate_8();
        if (this.model.items && this.model.items.length === 2 && !this.model.gridLayout) {
            this.model.gridLayout = "0";
        }
    }

    get disableAnimationsByDefault() {
        return this.itemCollection.length === 1;
    }
}

class PhotoCollageItem extends CollectionItemElement {
    static get schema() {
        return {
            cellColor: "auto"
        };
    }

    get selectionPadding() {
        return 0;
    }

    get requireParentSelection() {
        return false;
    }

    get passThroughSelection() {
        return true;
    }

    get canDropImage() {
        return true;
    }

    get canHandlePaste() {
        return true;
    }

    get showDefaultOverlay() {
        return !this.showImage && !this.showText;
    }

    get frameStyle() {
        if (this.model.frame && this.model.frame != "none") {
            return this.model.frame;
        } else {
            return this.parentElement.model.frame || "none";
        }
    }

    get aspectRatio() {
        return this.parentElement.aspectRatio;
    }

    get showImage() {
        return this.model.content_value != null;
    }

    get allowText() {
        return this.parentElement.allowText;
    }

    get showText() {
        if (this.parentElement.allowText) {
            return this.model.blocks && this.model.blocks.length > 0;
        } else {
            return false;
        }
    }

    get textPosition() {
        return getValueOrDefault(this.model.textPosition, PositionType.CENTER);
    }

    get listStyle() {
        return this.model.listStyle || "bullets";
    }

    get showFrame() {
        return this.frameStyle != "none";
    }

    get showShadow() {
        return this.parentElement.model.showShadow;
    }

    get captionHeight() {
        return 50;
    }

    get contentSize() {
        let contentSize;
        if (this.showImage) {
            contentSize = this.content.getContentSize();
        } else {
            contentSize = new geom.Size(100, 100);
        }
        // if (this.text && this.text.textPosition == PositionType.BOTTOM) {
        //     contentSize.height = contentSize.height * 1.4;
        // }
        return contentSize;
    }

    resetUserColors() {
        this.model.cellColor = null;
    }

    _build() {
        if (this.showImage) {
            this.content = this.addElement("contentElement", () => PhotoCollageContent, {
                allowImageScaling: this.aspectRatio != "fit",
                allowBackdrop: false
            });
            this.content.layer = -1;
        } else {
            this.content = null;
        }

        if (this.showText) {
            this.contentBlocks = this.addElement("contentBlocks", () => ContentBlockContainer, {
                allowedBlockTypes: TextBlockPreset.HEADLINE,
                defaultBlockType: ContentBlockType.HEADLINE,
                autoHeight: true,
                autoWidth: this.model.textWidth == null,
                scaleTextToFit: true,
                selectionPadding: 0,
                canDragPosition: true,
                canDragResize: true,
                canRefreshElement: !this.parentElement.model.matchTextScale
                // canSelect: false
            });
        }
    }

    _calcProps(props, options) {
        const { size, children } = props;

        if (this.hasStoredPropChanged("showImage", this.showImage)) {
            this.markStylesAsDirty();
        }

        // create decoration for cell
        const decorationStyles = {
            type: "frame",
            shape: "rect",
        };

        switch (this.frameStyle) {
            case "light":
                decorationStyles.strokeColor = "white";
                decorationStyles.strokeWidth = 2;
                if (this.parentElement.showGutter || this.parentElement.aspectRatio == "fit") {
                    decorationStyles.shadow = {
                        blur: 14,
                        offsetX: 0,
                        offsetY: 0,
                        opacity: 0.2
                    };
                }
                break;
            case "dark":
                decorationStyles.strokeColor = "background_dark";
                decorationStyles.strokeWidth = 2;
                break;
            default:
        }

        // calculate available content bounds
        let contentBounds = new geom.Rect(0, 0, size);
        if (this.showFrame && this.showImage) {
            contentBounds = contentBounds.deflate(decorationStyles.strokeWidth);
        }

        // calcProps on image
        if (this.showImage) {
            const contentProps = this.content.calcProps(contentBounds.size, options);
            contentProps.bounds = contentBounds;
        }

        // calcProps on text
        if (this.showText) {
            const contentBlocksProps = this.contentBlocks.calcProps(size, options);
            contentBlocksProps.bounds = new geom.Rect(0, 0, contentBlocksProps.size);
        }

        // create item decoration (need to do this after calcProps is called on content so we can evaluate whether
        // an image is completely occluding the decoration and then use transparent color so that we don't see
        // anti-aliasing artifacts on the edges

        if (this.parentElement.aspectRatio == "fit" && this.showImage && this.content.assetType == AssetType.IMAGE) {
            decorationStyles.fillColor = "none";
        } else {
            decorationStyles.fillColor = this.getSlideColor().name;
        }

        this.createDecoration(decorationStyles);

        return { size };
    }

    getUserDefinedFillColor() {
        if (this.model.cellColor == null || this.model.cellColor == "auto" || this.model.cellColor == "slide") {
            return null;
        } else {
            return this.canvas.getTheme().palette.getColor(this.model.cellColor);
        }
    }

    getBackgroundColor(forElement) {
        if (this.showImage && forElement && this.content && forElement != this.content.assetElement) {
            return BackgroundStyleType.IMAGE;
            //  } else if (this.showFrame) {
            //      return this.canvas.getTheme().palette.getColor(this.frame.styles.fillColor);
        } else {
            return super.getBackgroundColor(forElement);
        }
    }

    _migrate_8() {
        // migrate no set cellColor to use theme instead of backgroundAccent which is default now
        if (!this.model.cellColor) {
            this.model.cellColor = this.canvas.getSlideColor();
        }
    }

    _migrate_9() {
        if (this.showImage) {
            this.model.cellColor = "none";
        }
    }

    get animationElementName() {
        return `Picture #${this.itemIndex + 1}`;
    }
}

class PhotoCollageContent extends ContentElement {
    get defaultOverlayType() {
        return "ContentElementDefaultOverlay";
    }

    get _canSelect() {
        return false;
    }

    get _canRollover() {
        return false;
    }
}

export { PhotoCollage, PhotoCollageItem, PhotoCollageContent };

export const elements = {
    PhotoCollage
};
