import { controls } from "js/editor/ui";
import { $, _ } from "js/vendor";
import renderReactRoot from "js/react/renderReactRoot";

import { CollectionElementSelection, CollectionItemElementSelection } from "../../CollectionElementEditor";
import { CreateMediaMenu } from "../pictureEditor";
import { ImageFrameMenu } from "../../EditorComponents/ImageFrameMenu";

const IconTextGridSelection = CollectionElementSelection.extend({
    renderControls: function() {
        this.createAddItemButton({
            model: {
                frameType: _.last(this.element.itemCollection).frameType,
                decorationStyle: _.last(this.element.itemCollection).decorationStyle
            }
        });

        this.addControl({
            id: "frame-menu",
            type: controls.POPUP_BUTTON,
            icon: "filter_frames",
            showArrow: false,
            customMenuClass: "frame-popup",
            menuContents: closeMenu => {
                let $menu = $.div();
                renderReactRoot(ImageFrameMenu, {
                    onSelect: frame => {
                        for (let item of this.element.itemElements) {
                            item.model.frameType = frame;
                        }
                        this.element.markStylesAsDirty();
                        this.element.canvas.updateCanvasModel(false);
                        closeMenu();
                    }
                }, $menu[0]);
                return $menu;
            },
            transitionModel: false
        });

        this.addControl({
            type: controls.MULTI_TOGGLE,
            label: "Orientation",
            markStylesAsDirty: true,
            options: [{
                value: "auto", label: "Auto"
            }, {
                value: "horizontal", label: "Horizontal"
            }],
            property: "orientation"
        });
    }
});

const IconTextGridItemSelection = CollectionItemElementSelection.extend({

    renderControls: function() {
        CreateMediaMenu(this, this.element.media.content);

        this.addControl({
            type: controls.COLOR_PALETTE_PICKER,
            property: "color",
            showAuto: true,
            showBackgroundColors: true,
            omitCurrentBackgroundColor: true,
            showDecorationStyles: () => (this.element.media.allowDecorationStyles)
        });
    }

});

export const editors = {
    IconTextGridSelection,
    IconTextGridItemSelection,
};
