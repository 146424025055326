import getLogger, { LogGroup } from "js/core/logger";
import { $, _ } from "js/vendor";
import React, { Component } from "reactn";
import { ds } from "js/core/models/dataService";
import { app } from "js/namespaces";
import * as geom from "js/core/utilities/geom";
import { getElementTransition } from "js/core/utilities/svgHelpers";

import { BaseElement } from "../BaseElement";

const logger = getLogger(LogGroup.ELEMENTS);

class Icon extends BaseElement {
    get iconId() {
        let iconId = this._migrateIcons(this.options.icon || this.model.content_value);
        return iconId;
    }

    get _canSelect() {
        return false;
    }

    get _canRollover() {
        return false;
    }

    get _doubleClickToSelect() {
        return this.options.canDoubleClickToEdit ?? true;
    }

    get passThroughSelection() {
        return false;
    }

    get iconStyle() {
        return app.currentTheme.get("iconStyle") || "chunky";
    }

    _build() {
    }

    _migrateIcons(iconId) {
        let oldIconMap = {
            "table-check-circle": "check-yes-circle",
            "table-check": "check-yes",
            "table-x-circle": "cross-out-circle",
            "table-x": "cross-out",
            "table-circle-cross": "prohibit",
            "table-star-outline": "star-none",
            "table-star-half": "star-half",
            "table-star-full": "star",
            "table-thumb-down": "thumbs-down",
            "table-thumb-up": "thumbs-up",
            "table-circle-full": "full",
            "table-circle-three-fourths": "quarters-three",
            "table-circle-half": "contrast",
            "table-circle-one-fourth": "quarter",
            "table-circle-outline": "none",
            "family-dad-dad": "family",
            "family-mom-dad": "family",
            "family-mom-mom": "family",
            "hand-shake-multi": "hand-shake"
        };
        if (oldIconMap.hasOwnProperty(iconId)) {
            return oldIconMap[iconId];
        } else {
            return iconId;
        }
    }

    _load() {
        // don't reload if the iconid hasn't change
        if (this.iconId && this.iconId === this.loadedIconId && this.loadedStyle == this.iconStyle) {
            return;
        }

        this.loadedIconId = null;
        this.loadedStyle = null;
        this.iconPath = null;

        if (!this.iconId) {
            return;
        }

        return ds.assets.getAssetById(this.iconId, "icon").then(icon => {
            const url = icon.get("original");
            if (!url.startsWith("<")) {
                return fetch(url)
                    .catch(e => {
                        if (e.message === "Failed to fetch") {
                            logger.info("[IconElement] fetching icon failed. Fetching without cache", { slideId: this.canvas.dataModel.id, iconId: this.iconId });
                            return fetch(url, { cache: "reload" });
                        } else {
                            throw e;
                        }
                    }).then(res => {
                        return res.text();
                    });
            } else {
                return Promise.resolve(url);
            }
        }).then(svg => {
            const pathRegex = /\sd="(.*?)"/m;
            const pathString = pathRegex.exec(svg) && pathRegex.exec(svg)[1];

            const fillRuleRegex = /\sfill-rule="(.*?)"/m;
            const fillRuleString = fillRuleRegex.exec(svg) && fillRuleRegex.exec(svg)[1];

            const clipRuleRegex = /\sclip-rule="(.*?)"/m;
            const clipRuleString = clipRuleRegex.exec(svg) && clipRuleRegex.exec(svg)[1];

            const mirrorScaleRegex = /scale\(-1,\s*1\)/;
            this.shouldMirror = mirrorScaleRegex.test(svg);

            this.iconPath = pathString;
            this.fillRule = fillRuleString;
            this.clipRule = clipRuleString;

            // we need to set the correct viewbox for icons which can only be found if we had the path to the DOM so we can call
            // getBox() on the SvG path
            const tempIconId = `${this.uniqueId}-temp-icon`;
            const tempIconSVG = `<svg id=${tempIconId}><path fill-rule="${fillRuleString}" clip-rule="${clipRuleString}" d="${pathString}" ${this.shouldMirror ? "transform=\"scale(-1, 1)\"" : ""}></path></svg>`;
            $("body").append(tempIconSVG);
            this.innerViewbox = new geom.Rect($(`#${tempIconId}`)[0].getBBox());
            this.viewbox = new geom.Rect(0, 0, 128, 128);
            $(`#${tempIconId}`).remove();

            this.loadedIconId = this.iconId;
            this.loadedStyle = this.iconStyle;
        }).catch(err => {
            // remove any existing icon paths
            if (err.statusCode !== 404) {
                logger.error(err, "[IconElement] failed to load icon", { slideId: this.canvas.dataModel?.id, iconId: this.iconId });
            }
        });
    }

    _calcProps(props, options = {}) {
        const { size } = props;

        let iconSize;
        if (this.viewbox) {
            iconSize = this.viewbox.fitToSize(size).size;
        } else {
            iconSize = size;
        }

        let iconColor;

        if (this.isOnAuthoringCanvas) {
            // when frame is filled decorationstyle, use primary for iconColor so it
            if (this.model.frameType != "none" && this.findClosestOfType("AuthoringContentElement").decorationStyle == "filled") {
                iconColor = "primary";
            } else {
                iconColor = this.model.color;
            }
        } else {
            if (this.model.iconColor && this.model.iconColor != "auto") {
                // if an explicit iconColor is set in the model, use that
                iconColor = this.model.iconColor;
            } else if (options.forceIconColor) {
                // we are forcing icon color because the icon is on a muted or fill&stroke background
                if (this.model.color == "background_accent") {
                    iconColor = "primary";
                } else {
                    iconColor = this.model.color || "slide!";
                }
                if (iconColor == "auto") {
                    iconColor = "slide!";
                }
            }
        }

        if (iconColor) {
            this.styles.fillColor = iconColor;
        }

        return {
            size: options.fitAsset ? size : iconSize,
            iconSize,
            iconPath: this.iconPath,
            fillRule: this.fillRule,
            clipRule: this.clipRule,
            viewbox: this.viewbox,
            scale: this.model.iconScale ?? options.iconScale ?? this.styles.iconScale ?? 1
        };
    }

    renderChildren(transition) {
        const props = this.calculatedProps;

        if (!props.iconPath) {
            return;
        }

        const transform = [`scale(${props.scale})`];
        if (!this.isAnimating) {
            transform.push(`translateZ(${parseInt(Math.random() * 100)}px)`);
        }

        return (
            <svg
                key={this.id}
                width={props.bounds.width}
                height={props.bounds.height}
                style={{ position: "absolute", transition: getElementTransition(transition) }}
            >
                <g style={{ transform: transform.join(" "), transformOrigin: "center", transition: getElementTransition(transition) }}>
                    <svg
                        width="100%"
                        height="100%"
                        viewBox={`${props.viewbox.x} ${props.viewbox.y} ${props.viewbox.width} ${props.viewbox.height}`}
                    >
                        <path d={props.iconPath} fill={this.styles.resolved_fillColor} transform={this.shouldMirror ? "scale(-1, 1)" : ""} fillRule={this.fillRule} clipRule={this.clipRule} />
                    </svg>
                </g>
            </svg>
        );
    }
}

export { Icon };
