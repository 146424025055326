import React, { Component } from "react";
import styled from "styled-components";
import { Icon, Slider, Switch } from "@material-ui/core";

import { ds } from "js/core/models/dataService";
import { $, SVG, Backbone, _ } from "js/vendor";
import { app } from "js/namespaces.js";
import { controls } from "js/editor/ui";
import * as geom from "js/core/utilities/geom";
import { PaletteColorType, AssetType } from "common/constants";
import getLogger, { LogGroup } from "js/core/logger";
import { AddAssetsContainer } from "js/react/views/AddAssets";
import { BeautifulDialog, ShowDialog } from "js/react/components/Dialogs/BaseDialog";
import { renderReactRootJSX } from "js/react/renderReactRoot";
import { getStaticUrl } from "js/config";
import { FlexSpacer } from "js/react/components/Gap";
import { BlueButton } from "js/react/components/UiComponents";
import { renderAdjustmentFilter } from "js/core/utilities/svgFilters";
import { themeColors } from "js/react/sharedStyles";
import { LabeledContainer } from "js/react/components/LabeledContainer";

import { ElementEditor } from "../BaseElementEditor";
import { InputSlider } from "../EditorComponents/InputSlider";
import { LabelContainer, SmallLabel } from "../EditorComponents/Controls";
import { mergeMediaElementModelDefaults } from "common/assetUtils";

const logger = getLogger(LogGroup.ELEMENTS);

const PictureSelection = ElementEditor.extend({
    type: "picture",

    getTitle: function() {
        return false;
    },

    getOffset: function() {
        if (this.$el.height() < 175) {
            return 10;
        } else {
            return -40;
        }
    },

    renderControls: function() {
        // only show this warning if an image has been added
        if (this.element.assetId && this.element.model.scale > 1.5) {
            this.showWarning("Warning", "Image is scaled beyond 1.5X and may appear pixelated");
        }

        CreateMediaMenu(this, this.element.parentElement, { allowAdjust: false });

        if (this.element.assetId) {
            let adjustScale = scale => {
                let minScale = this.element.minScale(this.element.elementBounds);
                let maxScale = this.element.maxScale(this.element.elementBounds);

                return (scale - minScale) / (maxScale - minScale) * 100;
            };

            if (this.element.options.allowImageScaling !== false) {
                this.$scaleSlider = this.addControl({
                    type: controls.SLIDER,
                    value: adjustScale(this.element.getScale(this.element.elementBounds.size)),
                    callbackRequiresLayouterReady: true,
                    callback: this.scaleImage,
                    onEnd: () => {
                        this.element.canvas.saveCanvasModel();
                        this.render();
                        this.layout();
                    },
                    min: 0,
                    max: 100,
                    step: 1,
                });

                this.addControl({
                    type: controls.BUTTON,
                    icon: "settings_overscan",
                    callback: () => {
                        this.reset();
                    }
                });
            }

            if (this.element.options.allowOpacity) {
                this.addControl({
                    type: controls.SLIDER,
                    label: "Opacity",
                    property: "opacity",
                    min: .1,
                    max: 2,
                    step: 0.01
                });
            }

            this.addControl({
                type: controls.BUTTON,
                icon: "flip",
                callback: () => {
                    this.element.model.flipHorizontal = !this.element.model.flipHorizontal;
                    this.element.canvas.updateCanvasModel(true);
                }
            });

            if (this.type === "video" || this.type === "stock_video") {
                this.addControl({
                    type: controls.POPUP_BUTTON,
                    icon: "video_settings",
                    showArrow: false,
                    ...BuildVideoSettingsMenu(this.element),
                });
            }

            if (this.type === "picture") {
                this.addControl({
                    type: controls.BUTTON,
                    icon: "palette",
                    callback: () => {
                        ShowDialog(FilterDialog, { element: this.element });
                    }
                });
            }

            if ((this.type == AssetType.LOGO || this.element.hasAlpha || this.element.isScaledBelowFill) && this.element.allowBackdrop) {
                this.addControl({
                    type: controls.COLOR_PALETTE_PICKER,
                    property: "backgroundColor",
                    showBackgroundColors: true,
                    showNone: true
                });
            }

            this.addControl({
                type: controls.BUTTON,
                icon: "close",
                callback: () => {
                    ds.selection.element = null;
                }
            });

            this.$dragImage = $.div("image_drag");
            this.$el.prepend(this.$dragImage);

            this.$dragImage.on("mousedown", $.proxy(this.onImageStartDrag, this));
            this.$dragImage.on("click", event => {
                event.stopPropagation();
            });

            if (!$(".image_drag_shield").length) {
                this.showElementFocus();
            }

            app.isEditingImage = true;
        }
    },

    cleanUp: function() {
        $(".image_drag_shield").remove();
        app.isEditingImage = false;
    },

    reset: function() {
        var element = this.element.content || this.element;
        element.resetMediaTransform();
        element.canvas.updateCanvasModel();
        this.render();
    },

    scaleImage: function(value) {
        let min = this.element.minScale(this.element.elementBounds);
        let max = this.element.maxScale(this.element.elementBounds);

        value = value / 100;

        let scale = min + (max - min) * value;

        this.element.updateModel({ scale }, this.element.elementBounds);
        this.element.canvas.refreshCanvas(false);

        if (this.element.model.scale > 1.5) {
            this.showWarning("Warning", "Image is scaled beyond 1.5X and may appear pixelated");
        } else {
            this.hideWarning();
        }
    },

    onImageStartDrag: function(event) {
        event.stopPropagation();
        let element = this.element.content || this.element;

        app.isDraggingItem = true;

        let dragStartPoint = new geom.Point(event.clientX, event.clientY);
        let offset = element.getOffset(element.innerBounds);

        let scale = element.getScale(element.innerBounds);

        let onImageDrag = event => {
            let hflip = element.model.flipHorizontal ? -1 : 1;
            element.updateModel({
                offset: offset.plus(
                    hflip * (dragStartPoint.x - event.clientX) / scale,
                    (dragStartPoint.y - event.clientY) / scale)
            }, element.innerBounds);
            // this.element.refresh();
            this.element.canvas.refreshCanvas({ suppressRefreshCanvasEvent: true });
        };

        let onImageDragEnd = event => {
            $(document).off("mousemove", onImageDrag);
            $(document).off("mouseup", onImageDragEnd);
            event.stopPropagation();
            app.isDraggingItem = false;
            this.element.canvas.updateCanvasModel();
        };

        $(document).on("mousemove", onImageDrag);
        $(document).on("mouseup", onImageDragEnd);
    }
});

const LogoSelection = PictureSelection.extend({
    type: "logo"

});

const VideoElementSelection = PictureSelection.extend({
    type: "video"
});

const PreviewImage = styled.div`
  position: relative;
  display: inline-block;
  width: 200px;
  height: 160px;
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;

  label {
    position: absolute;
    background: white;
    padding: 5px;
    color: #333;
    opacity: 0;
    transition: opacity 300ms;
    text-transform: uppercase;
    font-size: 11px;
  }

  &:hover {
    label {
      opacity: 1;
    }
  }
`;

const SelectedPreview = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  width: 30px;
  height: 30px;
  color: white;
  background: ${themeColors.ui_blue};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 20px;
`;

const ThumbnailContainer = styled.div`
  display: grid;
  //grid-template-columns: repeat(auto, max-content);
  gap: 10px;
  //min-width: 970px;
  max-height: calc(100vh - 200px);
  overflow-y: scroll;
  width: calc(100% - 300px);

`;

export class FilterDialog extends Component {
    constructor(props) {
        super(props);

        this.gridRef = React.createRef();

        let dialogWidth = window.innerWidth - 64;
        let gridWidth = dialogWidth - 300 - 20 - 20 - 30 - 40;

        let assetWidth, assetHeight;

        if (props.element.asset.get("h") > props.element.asset.get("w")) {
            assetWidth = Math.min(220, gridWidth / 4);
        } else {
            assetWidth = Math.min(298, gridWidth / 3);
        }
        assetHeight = props.element.asset.get("h") / props.element.asset.get("w") * assetWidth;

        let filter = props.element.model.filter;
        if (filter == "None" || !filter) {
            filter = "none";
        }

        this.state = {
            activeFilterId: 1,
            filterBrightness: props.element.model.filterBrightness ?? 1,
            filterContrast: props.element.model.filterContrast ?? 1,
            filterBlur: props.element.model.filterBlur ?? 0,
            filter,
            assetWidth, assetHeight,
            originalBrightness: props.element.model.filterBrightness,
            originalContrast: props.element.model.filterContrast,
            originalBlur: props.element.model.filterBlur,
            originalFilter: props.element.model.filter
        };
    }

    onChangeBrightness = (event, value) => {
        this.setState({ filterBrightness: value });
        this.refreshEditor();

        this.props.element.model.filterBrightness = value;
        this.props.element.canvas.refreshCanvas(false);
    }

    onChangeContrast = (event, value) => {
        this.setState({ filterContrast: value });
        this.refreshEditor();

        this.props.element.model.filterContrast = value;
        this.props.element.canvas.refreshCanvas(false);
    }

    onChangeBlur = (event, value) => {
        this.setState({ filterBlur: value });
        this.refreshEditor();

        this.props.element.model.filterBlur = value;
        this.props.element.canvas.refreshCanvas(false);
    }

    onChangeFilter = value => {
        this.setState({ filter: value });
        this.refreshEditor();
    }

    refreshEditor() {
        // HACK: For Safari, we need to make sure to sync all
        // filter IDs and use a unique ID each time, otherwise
        // sometimes the filter is not applied
        const activeFilterId = this.state.activeFilterId + 1;
        this.setState({ activeFilterId });
        this.gridRef.current.querySelectorAll("[preview-filter]")
            .forEach(node => node.setAttribute("filter", `url(#preview-filter--${activeFilterId}-adj-filter)`));

        // make sure to reuse the shared filter ID
        this.props.element.updateFilterVersion(activeFilterId);
    }

    applyFilter = () => {
        let { filterBrightness, filterContrast, filterBlur, filter } = this.state;

        this.props.element.model.filterBrightness = filterBrightness;
        this.props.element.model.filterContrast = filterContrast;
        this.props.element.model.filterBlur = filterBlur;
        this.props.element.model.filter = filter;

        this.props.element.updateFilterVersion();
        this.props.element.canvas.updateCanvasModel(false);
        this.props.closeDialog();
    }

    resetFilter = () => {
        this.setState({
            filter: "none",
            filterBrightness: 1,
            filterContrast: 1,
            filterBlur: 0
        });

        this.props.element.model.filter = "none";
        this.props.element.model.filterBrightness = null;
        this.props.element.model.filterContrast = null;
        this.props.element.model.filterBlur = null;
        this.props.element.canvas.refreshCanvas(false);
    }

    onCloseDialog = () => {
        this.props.element.model.filterBrightness = this.state.originalBrightness;
        this.props.element.model.filterContrast = this.state.originalContrast;
        this.props.element.model.filterBlur = this.state.originalBlur;
        this.props.element.model.filter = this.state.originalFilter;

        this.props.element.updateFilterVersion();
        this.props.element.canvas.refreshCanvas(false);
        this.props.closeDialog();
    }

    render() {
        let { element } = this.props;
        let { assetWidth, assetHeight, filterBrightness, filterContrast, filterBlur, filter, activeFilterId } = this.state;

        let colors = _.extend({ [PaletteColorType.THEME]: element.canvas.getTheme().palette.getColor(PaletteColorType.THEME).toHexString() }, element.canvas.getTheme().palette.getAccentColors(), { [PaletteColorType.BACKGROUND_ACCENT]: element.canvas.getTheme().palette.getColor(PaletteColorType.BACKGROUND_ACCENT).toHexString() });
        let filterId = `preview-filter--${activeFilterId}`;

        let filters = ["none", "brannan", "earlybird", "inkwell", "lofi", "mayfair", "nashville"];
        _.forIn(colors, (color, key) => {
            filters.push(key);
        });

        return (
            <BeautifulDialog maxWidth="lg" closeDialog={this.onCloseDialog} style={{ width: "auto" }}>
                <svg width={0} height={0}>
                    {renderAdjustmentFilter({ filterId }, this.state)}
                </svg>

                <Container>
                    <ThumbnailContainer ref={this.gridRef}
                        style={{ gridTemplateColumns: `repeat(auto-fill, ${assetWidth}px)` }}>
                        {filters.map((f, i) => (
                            <FilterPreview
                                key={`filter_${i}`}
                                selected={f === filter}
                                width={assetWidth} height={assetHeight}
                                filter={f}
                                element={element}
                                activeFilterId={activeFilterId}
                                onClick={() => this.onChangeFilter(f)}
                            />
                        ))}
                    </ThumbnailContainer>
                    {/*<Gap20/>*/}
                    <div style={{ display: "grid", gap: 10, width: 300, gridAutoRows: "min-content", paddingLeft: 20 }}>
                        <LabelContainer>
                            <SmallLabel>Brightness</SmallLabel>
                            <Slider value={filterBrightness} onChange={this.onChangeBrightness}
                                min={0.1} max={2}
                                step={0.01}
                                valueLabelDisplay="auto"
                            />
                        </LabelContainer>
                        <LabelContainer>
                            <SmallLabel>Contrast</SmallLabel>
                            <Slider value={filterContrast} onChange={this.onChangeContrast}
                                min={0.1} max={2}
                                step={0.01}
                                valueLabelDisplay="auto"
                            />
                        </LabelContainer>
                        <LabelContainer>
                            <SmallLabel>Blur</SmallLabel>
                            <Slider value={filterBlur} onChange={this.onChangeBlur}
                                min={0} max={100} step={1}
                                valueLabelDisplay="auto"
                            />
                        </LabelContainer>
                        <FlexSpacer />
                        <BlueButton onClick={this.resetFilter}>Reset Filter</BlueButton>
                        <BlueButton onClick={this.applyFilter}>Apply Filter</BlueButton>
                    </div>

                </Container>

            </BeautifulDialog>
        );
    }
}

class FilterPreview extends Component {
    constructor() {
        super();

        this.ref = React.createRef();
    }

    componentDidMount() {
        let { width, height, filter, element, activeFilterId } = this.props;
        let colors = _.extend({ [PaletteColorType.THEME]: element.canvas.getTheme().palette.getColor(PaletteColorType.THEME).toHexString() }, element.canvas.getTheme().palette.getAccentColors(), { [PaletteColorType.BACKGROUND_ACCENT]: element.canvas.getTheme().palette.getColor(PaletteColorType.BACKGROUND_ACCENT).toHexString() });

        let draw = SVG(this.ref.current);
        let g = draw.group();
        g.transform({ scale: width / element.mediaSize.width });
        g.node.setAttribute("filter", `url(#preview-filter--${activeFilterId}-adj-filter)`);

        // HACK: For Safari, make sure to identify this as the
        // node to refresh the filter ID for
        g.node.setAttribute("preview-filter", "");

        g.image(element.assetUrl).loaded(function() {
            this.width(element.mediaSize.width).height(element.mediaSize.height);

            switch (filter) {
                case "none":
                    break;
                case "brannan":
                case "earlybird":
                case "inkwell":
                case "lofi":
                case "mayfair":
                case "nashville":
                    this.attr("filter", `url(#${filter})`);
                    break;
                default:
                    this.filter(add => {
                        let flood = add.flood(colors[filter], 1);
                        let grayscale = add.colorMatrix("saturate", 0);
                        add.blend(flood, grayscale, "multiply");
                    });
            }
        });
    }

    render() {
        let { width, height, filter, assetUrl, onClick, selected } = this.props;

        return (
            <PreviewImage ref={this.ref} style={{ width, height }} onClick={onClick} selected={selected}>
                {selected && <SelectedPreview><Icon>check</Icon></SelectedPreview>}
                <label>{filter}</label>
            </PreviewImage>
        );
    }
}

const FilterMenu = Backbone.View.extend({
    className: "filter_dialog",

    initialize: function(options) {
        this.element = options.element;
    },

    render: function() {
        let colors = _.extend({ [PaletteColorType.THEME]: this.element.canvas.getTheme().palette.getColor(PaletteColorType.THEME).toHexString() }, this.element.canvas.getTheme().palette.getAccentColors(), { [PaletteColorType.BACKGROUND_ACCENT]: this.element.canvas.getTheme().palette.getColor(PaletteColorType.BACKGROUND_ACCENT).toHexString() });

        let assetHeight = 200;
        let assetWidth = this.element.asset.get("w") / this.element.asset.get("h") * assetHeight;

        const addFilteredThumbnail = async (name, $row) => {
            let $container = $row.addEl($.div("filterThumbnail"));
            $container.width(assetWidth).height(assetHeight);

            let mediaSize = this.element.mediaSize;

            $container.attr("data-id", name);
            let draw = SVG($container[0]);

            let group = draw.group();
            group.transform({ scale: assetWidth / mediaSize.width });
            group.node.setAttribute("filter", `url(#${this.element.uniqueId}-adj-filter)`);

            let displayName = name.replace("primary_", "").replace("accent", "accent ");
            $container.addEl($.div("name", displayName), 100);

            // const assetUrl = await this.element.asset.getURL("xsmall");
            const assetUrl = this.element.assetUrl;

            return group.image(assetUrl)
                .loaded(function() {
                    // this.height("100%").width("100%");
                    this.width(mediaSize.width).height(mediaSize.height);
                });
        };

        let $row = this.$el.addEl($.div("row"));

        addFilteredThumbnail("None", $row)
            .catch(err => logger.error(err, "[pictureEditor] addFilteredThumbnail() failed"));

        ["brannan", "earlybird", "inkwell", "lofi", "mayfair", "nashville"]
            .forEach(filter => {
                addFilteredThumbnail(filter, $row).then(image => {
                    image.attr("filter", `url(#${filter})`);
                }).catch(err => logger.error(err, "[pictureEditor] addFilteredThumbnail() failed"));
            });
        _.forIn(colors, (color, key) => {
            addFilteredThumbnail(key, $row).then(image => {
                image.filter(add => {
                    let flood = add.flood(color, 1);
                    let grayscale = add.colorMatrix("saturate", 0);
                    add.blend(flood, grayscale, "multiply");
                });
            }).catch(err => logger.error(err, "[pictureEditor] addFilteredThumbnail() failed"));
        });

        this.$el.on("click", ".filterThumbnail", event => {
            event.stopPropagation();
            this.element.model.filter = $(event.currentTarget).data("id");
            this.element.canvas.updateCanvasModel(false);
        });

        let renderControls = () => {
            $(".controls").remove();

            let $controls = $.div("controls");
            this.$el.append($controls);

            $controls.append(controls.createSlider(this, {
                label: "Brightness",
                model: this.element.model,
                property: "filterBrightness",
                min: 0.1,
                max: 2,
                step: 0.01
            }));

            $controls.append(controls.createSlider(this, {
                label: "Contrast",
                model: this.element.model,
                property: "filterContrast",
                min: 0.1,
                max: 2,
                step: 0.01
            }));

            $controls.append(controls.createSlider(this, {
                label: "Blur",
                model: this.element.model,
                property: "filterBlur",
                min: 0,
                max: 100,
                value: this.element.model.filterBlur == undefined ? 0 : this.element.model.filterBlur
            }));

            $controls.append($.div().css("flex-grow", 2));

            $controls.append(controls.createButton(this, {
                label: "Reset Filters",
                callback: () => {
                    this.element.model.filter = null;
                    this.element.model.filterBrightness = null;
                    this.element.model.filterContrast = null;
                    this.element.model.filterBlur = null;
                    this.element.canvas.updateCanvasModel(false);
                    renderControls();
                }
            }));
        };

        renderControls();

        return this;
    }

}
);

export const CreateAdjustImageMenu = function(view, contentElement, options = {}) {
    return view.addControl(Object.assign({
        type: controls.POPUP_BUTTON,
        icon: "photo_camera",
        label: options.label,
        showArrow: options.label != null,
    }, BuildAdjustImageMenu(contentElement, options)));
};

export const CreateImageFrameMenu = function(view, contentElement) {
    return view.addControl({
        type: controls.POPUP_BUTTON,
        // label: "Frame",
        icon: "vignette",
        showArrow: false,
        menuClass: "icon-menu threecol",
        items: [{
            value: "none", label: "No Frame", image: getStaticUrl("/images/ui/frame_nocrop.svg")
        }, {
            value: "square", label: "Square", image: getStaticUrl("/images/ui/frame_square.svg")
        }, {
            value: "circle", label: "Circle", image: getStaticUrl("/images/ui/frame_circle.svg")
        }],
        callback: value => {
            contentElement.model.frameType = value;
            contentElement.markStylesAsDirty();
            contentElement.canvas.updateCanvasModel(false);
        }
    });
};

export function BuildAdjustImageMenu(contentElement, options = {}) {
    let items;
    if (contentElement.hasValidAsset) {
        const type = contentElement.model.content_type.replace(/_/g, " ").toTitleCase();

        items = [{
            value: "replace", label: `Replace ${type}...`, icon: "add_a_photo"
        }];

        if (options.allowAdjust !== false) {
            items.push({ value: "edit", label: `Adjust ${type}`, icon: "crop" });
        }

        items.push({ type: "divider" });
        items.push({ value: "remove", label: `Remove ${type}`, icon: "delete" });
    } else {
        items = [{
            value: "replace", label: `Add Media...`, icon: "add_a_photo"
        }];
    }

    return {
        items: items,
        callback: action => {
            switch (action) {
                case "replace":
                    ShowDialog(AddAssetsContainer, {
                        assetType: contentElement.model.content_type || contentElement.options.defaultAssetType || AssetType.IMAGE,
                        workspaceId: ds.selection.presentation.getWorkspaceId(),
                        backgroundVideoOnly: true,
                        callback: model => {
                            mergeMediaElementModelDefaults(
                                contentElement.model,
                                model,
                            );

                            contentElement.canvas.updateCanvasModel(false);
                        },
                    });
                    break;
                case "edit":
                    ds.selection.element = contentElement.assetElement;
                    break;
                case "filter":
                    ds.selection.element = contentElement.assetElement;
                    break;
                case "remove":
                    contentElement.model.content_value = null;
                    contentElement.model.content_type = null;
                    if (options.onRemove) {
                        options.onRemove();
                    }
                    contentElement.canvas.updateCanvasModel(false).then(() => {
                        ds.selection.element = null;
                    });
                    break;
            }
        }
    };
}

export const CreateVideoMenu = function(view, contentElement, options = {}) {
    view.addControl(Object.assign({
        type: controls.POPUP_BUTTON,
        icon: "photo_camera",
        showArrow: false,
    }, BuildVideoSourceMenu(contentElement, options)));
};

export function BuildVideoSourceMenu(contentElement, options = {}) {
    let items;
    if (contentElement.hasValidAsset) {
        let type = contentElement.model.content_type;

        items = [{
            value: "replace", label: `Replace Video...`, icon: "video_library"
        }];

        items.push({ value: "edit", label: `Adjust Video`, icon: "crop" });
        items.push({ type: "divider" });
        items.push({ value: "remove", label: `Remove Video`, icon: "delete" });
    } else {
        let type = contentElement.options.defaultAssetType || AssetType.VIDEO;
        items = [{
            value: "replace", label: `Add Video...`, icon: "video_library"
        }];
    }

    return {
        items: items,
        callback: action => {
            switch (action) {
                case "replace":
                    ShowDialog(AddAssetsContainer, {
                        assetType: contentElement.model.content_type || contentElement.options.defaultAssetType || AssetType.VIDEO,
                        workspaceId: ds.selection.presentation.getWorkspaceId(),
                        backgroundVideoOnly: true,
                        callback: model => {
                            mergeMediaElementModelDefaults(
                                contentElement.model,
                                model,
                            );

                            contentElement.canvas.updateCanvasModel(false);
                        },
                    });
                    break;
                case "edit":
                    ds.selection.element = contentElement.assetElement;
                    break;
                case "filter":
                    ds.selection.element = contentElement.assetElement;
                    break;
                case "remove":
                    contentElement.model.content_value = null;
                    contentElement.model.content_url = null;
                    contentElement.model.content_type = null;
                    if (options.onRemove) {
                        options.onRemove();
                    }
                    contentElement.canvas.updateCanvasModel(false).then(() => {
                        ds.selection.element = null;
                    });
                    break;
            }
        }
    };
}

export function BuildVideoSettingsMenu(videoElement, options = {}) {
    const { canBeBackgroundVideo } = videoElement;

    const refreshCanvasAndSaveChanges = (save = true) => {
        save && videoElement.canvas.saveCanvasModel();
        videoElement.canvas.refreshElement(videoElement, null, true);
    };

    const refreshList = [];
    const refreshMenu = () => {
        refreshList.forEach(refresh => refresh());
    };

    const items = [
        // {
        //     type: "control",
        //     icon: "dvr",
        //     view: () => {
        //         const $menu = $.div();
        //         const $reactWrapper = $menu.addEl($.div("control"));

        //         const handleChangeVideoControls = event => {
        //             if (videoElement) {
        //                 const value = event.currentTarget.checked;
        //                 videoElement.toggleControls({ value, refresh: false });
        //             }
        //             refreshCanvasAndSaveChanges();
        //             refreshMenu();
        //         };

        //         const refresh = () => {
        //             const jsx = (
        //                 <LabeledContainer label="Interactive">
        //                     <Switch
        //                         name="controls"
        //                         color="primary"
        //                         checked={videoElement.model.assetProps?.controls || false}
        //                         onChange={handleChangeVideoControls}
        //                         disabled={!canBeBackgroundVideo}
        //                     />
        //                 </LabeledContainer>
        //             );
        //             renderReactRootJSX(jsx, $reactWrapper[0]);
        //         };
        //         refreshList.push(refresh);
        //         refresh();
        //         return $menu;
        //     },
        // },
        {
            type: "control",
            icon: "volume_up",
            view: () => {
                const $menu = $.div();
                const $reactWrapper = $menu.addEl($.div("control"));

                const handleChangeVideoMuted = event => {
                    if (videoElement) {
                        videoElement.model.assetProps.muted = !event.target.checked;
                        videoElement.toggleAudio({ value: !videoElement.model.assetProps.muted, refresh: false });
                    }
                    refreshCanvasAndSaveChanges();
                    refresh();
                };

                const refresh = () => {
                    const jsx = (
                        <LabeledContainer label="Audio">
                            <Switch
                                name="muted"
                                color="primary"
                                checked={!(videoElement.model.assetProps?.muted || false)}
                                onChange={handleChangeVideoMuted}
                                disabled={!videoElement.canPlayAudio}
                            />
                        </LabeledContainer>
                    );
                    renderReactRootJSX(jsx, $reactWrapper[0]);
                };
                refreshList.push(refresh);
                refresh();
                return $menu;
            },
        },
        // {
        //     type: "control",
        //     icon: "play_circle_filled",
        //     view: () => {
        //         const $menu = $.div();
        //         const $reactWrapper = $menu.addEl($.div("control"));

        //         const handleChangeVideoAutoPlay = event => {
        //             if (videoElement) {
        //                 videoElement.model.assetProps.autoPlay = event.target.checked;
        //                 videoElement.togglePlayback({ value: event.target.checked });
        //             }
        //             refreshCanvasAndSaveChanges();
        //             refresh();
        //         };

        //         const refresh = () => {
        //             const jsx = (
        //                 <LabeledContainer label="Auto Play">
        //                     <Switch
        //                         name="autoPlay"
        //                         color="primary"
        //                         checked={videoElement.model.assetProps?.autoPlay || false}
        //                         onChange={handleChangeVideoAutoPlay}
        //                         disabled={!videoElement.model.assetProps?.controls}
        //                     />
        //                 </LabeledContainer>
        //             );
        //             renderReactRootJSX(jsx, $reactWrapper[0]);
        //         };
        //         refreshList.push(refresh);
        //         refresh();
        //         return $menu;
        //     },
        // },
        {
            type: "control",
            icon: "loop",
            view: () => {
                const $menu = $.div();
                const $reactWrapper = $menu.addEl($.div("control"));

                const handleChangeVideoLoop = event => {
                    const loop = event.target.checked;
                    if (videoElement) {
                        videoElement.model.assetProps.loop = loop;
                        if (!videoElement.isPlaying && loop) {
                            videoElement.togglePlayback({ value: true, refresh: false });
                        }
                    }
                    refreshCanvasAndSaveChanges();
                    refresh();
                };

                const refresh = () => {
                    const jsx = (
                        <LabeledContainer label="Loop Video">
                            <Switch
                                name="loop"
                                color="primary"
                                checked={videoElement.model.assetProps?.loop || false}
                                onChange={handleChangeVideoLoop}
                                disabled={!canBeBackgroundVideo}
                            />
                        </LabeledContainer>
                    );
                    renderReactRootJSX(jsx, $reactWrapper[0]);
                };
                refreshList.push(refresh);
                refresh();
                return $menu;
            },
        },
        {
            type: "control",
            icon: "timer",
            view: () => {
                const $menu = $.div();
                const $reactWrapper = $menu.addEl($.div("control"));

                const handleChangeVideoTime = (value, save = true) => {
                    const [
                        startTime,
                        endTime,
                    ] = value;

                    if (videoElement) {
                        videoElement.model.assetProps.startTime = startTime;
                        videoElement.model.assetProps.endTime = endTime;
                    }
                    refreshCanvasAndSaveChanges(save);
                    videoElement.togglePlayback({
                        value: startTime < endTime,
                        refresh: false,
                        reset: true,
                    });
                    // refresh(); // Not needed for InputSlider
                };

                const refresh = () => {
                    const jsx = (
                        <LabeledContainer label="Video Range">
                            <div style={{ paddingRight: "10px" }}>
                                <InputSlider
                                    value={[
                                        videoElement.model.assetProps?.startTime || 0,
                                        videoElement.model.assetProps?.endTime || videoElement.model.assetProps?.duration,
                                    ]}
                                    onChange={values => handleChangeVideoTime(values, false)}
                                    onChangeCommitted={values => handleChangeVideoTime(values)}
                                    sliderMin={0}
                                    sliderMax={videoElement.model.assetProps?.duration}
                                    inputMin={0}
                                    inputMax={videoElement.model.assetProps?.duration}
                                    step={1}
                                />
                            </div>
                        </LabeledContainer>
                    );
                    renderReactRootJSX(jsx, $reactWrapper[0]);
                };
                refreshList.push(refresh);
                refresh();
                return $menu;
            },
        },
        {
            type: "control",
            icon: "speed",
            view: () => controls.createDropdownMenu(this, {
                label: "Speed",
                items: [
                    { value: 2.0, label: "2.0x" },
                    { value: 1.75, label: "1.75x" },
                    { value: 1.5, label: "1.5x" },
                    { value: 1.25, label: "1.25x" },
                    { value: 1.0, label: "1.0x" },
                    { value: 0.75, label: "0.75x" },
                    { value: 0.5, label: "0.5x" },
                    { value: 0.25, label: "0.25x" },
                ],
                property: "speed",
                model: videoElement.model.assetProps,
                enabled: canBeBackgroundVideo,
            }),
        },
    ];

    return {
        items: items,
    };
}

export const CreateMediaMenu = function(view, contentElement, options = {}) {
    const type = contentElement.model.content_type;

    switch (type) {
        case AssetType.VIDEO:
        case AssetType.STOCK_VIDEO:
            return CreateVideoMenu(view, contentElement, options);
        default:
            return CreateAdjustImageMenu(view, contentElement, options);
    }
};

export const editors = {
    PictureSelection,
    LogoSelection,
    VideoElementSelection,
    FilterMenu,
};

