import { HorizontalAlignType, PositionType } from "common/constants";

import { BaseElement } from "../base/BaseElement";
import { ContentBlockContainer } from "./ContentBlock";

class TextSlide extends BaseElement {
    static get schema() {
        return {
            blocks: [{
                type: "body"
            }],
            textAlign: HorizontalAlignType.LEFT
        };
    }

    _build() {
        if (!this.model.blocks || this.model.blocks.length == 0) {
            this.model.blocks = [{
                type: "body"
            }];
        }

        this.contentBlock = this.addElement("contentBlockContainer", () => TextSlideContentBlockContainer, {
            textAlign: HorizontalAlignType.LEFT
        });
    }

    _calcProps(props, options) {
        let { size } = props;
        this.contentBlock.calcProps(size);
        return { size };
    }
}

class TextSlideContentBlockContainer extends ContentBlockContainer {
    get canSelect() {
        return false;
    }

    get canDelete() {
        return false;
    }

    get canDragPosition() {
        return false;
    }

    get canDragResize() {
        return false;
    }

    get canSelectContentBlockFrame() {
        return false;
    }

    get textPosition() {
        return PositionType.FILL;
    }

    getDefaultTextAlign() {
        return HorizontalAlignType.LEFT;
    }
}

export const elements = {
    TextSlide,
};
