import React from "react";
import styled from "styled-components";
import { DialogTitle, DialogActions, Button } from "@material-ui/core";

import getLogger, { LogGroup } from "js/core/logger";
import { ds } from "js/core/models/dataService";
import { getStaticUrl } from "js/config";
import AppController from "js/core/AppController";

import { fontManager } from "js/core/services/fonts";
import { builtInFonts } from "common/fontConstants";
import { AssetType, CanvasEventType } from "common/constants";

import { FontMap } from "js/react/views/AddSlide/Panes/Components/FontMap";
import ErrorDialog from "js/react/components/Dialogs/ErrorDialog";
import { BeautifulDialog, DialogContent } from "js/react/components/Dialogs/BaseDialog";
import FetchingClickShield from "js/react/components/FetchingClickShield";

const logger = getLogger(LogGroup.AUTHORING);

const Subtitle = styled.div`
    margin-top: 30px;

    font-weight: normal;
    font-size: 16px;
    line-height: 125%;   
    letter-spacing: 0.5px;
    color: #222222;
`;

const FontsMapContainerTitle = styled.div`
    margin-bottom: 15px;

    font-weight: 600;
    font-size: 15px;
    line-height: 125%;
    letter-spacing: 0.5px;
    color: #222222;
`;

const FontsMapContainer = styled.div`
    padding: 15px 20px;
    background: #f7fafc;
`;

const FontsMapContainerTip = styled.div`
    margin-top: 15px;

    font-weight: normal;
    font-size: 15px;
    line-height: 125%;
    letter-spacing: 0.5px;
    color: #222222;
`;

const ConvertingDialogIconContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 45px;

    >img {
        height: 80px;
    }
`;

const ConvertingDialogTitle = styled(DialogTitle)`
    &&& {
        padding: 20px 34px 25px;
        text-align: center;
    }
`;

const ConvertingDialogContent = styled(DialogContent)`
    &&& {
        margin-bottom: 20px;
        text-align: center;
    }
`;
class ConvertPPTSlideToAuthoringDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isSavingSlide: false,
            isLoadingFonts: false,
            isWaitingForImport: false,
            hasImportFailed: false,
            customFonts: [],
            builtInFonts: [],
            pptFontsMap: {},
            sourceFontFamilies: []
        };
    }

    componentDidMount() {
        const { canvas } = this.props;

        if (canvas.model.elements.primary.importFailed) {
            this.setState({ hasImportFailed: true });
            return;
        }

        if (!canvas.model.elements.primary.authoringElements) {
            // Wait for parsed authoring elements to get populated by the importer service
            this.setState({ isWaitingForImport: true });
            canvas.on(CanvasEventType.DATA_STATE_UPDATED, this.onCanvasDataStateUpdate);
            return;
        }

        this.load();
    }

    componentWillUnmount() {
        const { canvas } = this.props;
        canvas.off(CanvasEventType.DATA_STATE_UPDATED, this.onCanvasDataStateUpdate);
    }

    onCanvasDataStateUpdate = () => {
        const { canvas } = this.props;

        if (canvas.model.elements.primary.authoringElements || canvas.model.elements.primary.importFailed) {
            canvas.off(CanvasEventType.DATA_STATE_UPDATED, this.onCanvasDataStateUpdate);

            this.setState({
                hasImportFailed: !!canvas.model.elements.primary.importFailed,
                isWaitingForImport: false
            });

            if (!canvas.model.elements.primary.importFailed) {
                this.load();
            }
        }
    }

    load() {
        const { canvas } = this.props;

        const sourceFontFamilies = new Set();
        canvas.model.elements.primary.authoringElements.forEach(elementModel => {
            canvas.AuthoringShapeElement.getFontFamilies(elementModel)
                .forEach(fontFamily => {
                    sourceFontFamilies.add(fontFamily);
                });
        });

        this.setState({ sourceFontFamilies: [...sourceFontFamilies] });

        if (sourceFontFamilies.size === 0) {
            // Nothing to load
            return;
        }

        this.setState({ isLoadingFonts: true });

        // Loading custom fonts
        const customFontsLoadPromise = ds.assets.getAssetsByType(AssetType.FONT, AppController.workspaceId)
            .then(assets => Promise.all(assets.map(asset => fontManager.loadFont(asset.id))))
            .then(customFonts => {
                // Filtering out fonts that failed to load (fallback fonts)
                return customFonts.filter(font => font.isCustomFont);
            })
            .catch(err => logger.error(err, "[ConvertPPTSlideToAuthoringDialog] failed to load custom fonts"));

        // Loading built in fonts
        const builtInFontsLoadPromise = Promise.all(Object.keys(builtInFonts).map(fontId => fontManager.loadFont(fontId)))
            .catch(err => logger.error(err, "[ConvertPPTSlideToAuthoringDialog] failed to load built in fonts"));

        Promise.all([customFontsLoadPromise, builtInFontsLoadPromise])
            .then(([customFonts, builtInFonts]) =>
                this.setState({
                    customFonts,
                    builtInFonts,
                    isLoadingFonts: false
                })
            );
    }

    handleFontUploaded = fontAssetId => {
        fontManager.loadFont(fontAssetId)
            .then(loadedFont => {
                // Making sure we don't have a duplicate font record in case this flow was running simultaneously for the same font
                this.setState(prevState => ({ customFonts: [...prevState.customFonts.filter(font => font.id !== loadedFont.id), loadedFont] }));
            })
            .catch(err => logger.error(err, "[ConvertPPTSlideToAuthoringDialog] fontManager.loadFont() failed", { fontAssetId }));
    }

    handleConfirm = async () => {
        const { canvas, editor, closeDialog } = this.props;
        const { sourceFontFamilies, pptFontsMap } = this.state;

        this.setState({ isSavingSlide: true });

        ds.selection.element = null;

        if (sourceFontFamilies.length > 0) {
            canvas.model.elements.primary.authoringElements.forEach(elementModel => {
                canvas.AuthoringShapeElement.updateFontFamilies(elementModel, pptFontsMap);
            });
        }

        canvas.model.elements.primary.elements = canvas.model.elements.primary.authoringElements;
        delete canvas.model.elements.primary.authoringElements;

        canvas.model.template_id = "authoring";
        canvas.updateTemplate(canvas.model.template_id);

        await canvas.refreshCanvas({ transition: false });
        await canvas.saveCanvasModel();

        closeDialog();
    }

    render() {
        const { closeDialog } = this.props;
        const {
            sourceFontFamilies,
            customFonts,
            builtInFonts,
            pptFontsMap,
            isSavingSlide,
            isLoadingFonts,
            isWaitingForImport,
            hasImportFailed
        } = this.state;

        if (hasImportFailed) {
            return (<ErrorDialog
                title="Cannot convert slide to editable"
                message={"Sorry, we could not convert this slide to editable but you can still use it for presenting."}
                closeDialog={closeDialog}
            />);
        }

        if (isWaitingForImport) {
            return (<BeautifulDialog closeDialog={closeDialog}>
                <ConvertingDialogIconContainer>
                    <img src={getStaticUrl("/images/bai-bot/bai-bot-shake.gif")}></img>
                </ConvertingDialogIconContainer>
                <ConvertingDialogTitle>Please wait...</ConvertingDialogTitle>
                <ConvertingDialogContent>
                    <p>We're still finalizing the import to allow you to convert the slide to an editable Beautiful.ai slide, please wait a minute or so.</p>
                </ConvertingDialogContent>
            </BeautifulDialog>);
        }

        return (<BeautifulDialog closeDialog={closeDialog}>
            <FetchingClickShield visible={isSavingSlide} backgroundColor="#ffffff" style={{ borderRadius: "4px" }} />
            <DialogTitle>
                <div>Make this slide editable?</div>
                <Subtitle>You may notice some differences when converting an imported slide to an editable Classic Slide.</Subtitle>
            </DialogTitle>
            <DialogContent>
                {sourceFontFamilies.length > 0 && <>
                    <FontsMapContainerTitle>Select the fonts you would like to use, or upload new ones.</FontsMapContainerTitle>
                    <FontsMapContainer>
                        {sourceFontFamilies.map((sourceFontFamily, idx) => (
                            <FontMap
                                key={idx}
                                workspaceId={AppController.workspaceId}
                                fetching={isLoadingFonts}
                                sourceFontFamily={sourceFontFamily}
                                customFonts={customFonts}
                                builtInFonts={builtInFonts}
                                selectedFontId={pptFontsMap[sourceFontFamily]?.fontId}
                                selectedFontWeight={pptFontsMap[sourceFontFamily]?.fontWeight}
                                onFontMapped={(fontId, fontWeight) => this.setState(prevState => ({ ...prevState, pptFontsMap: { ...prevState.pptFontsMap, [sourceFontFamily]: (fontId ? { fontId, fontWeight } : null) } }))}
                                onFontUploaded={this.handleFontUploaded}
                            />
                        ))}
                    </FontsMapContainer>
                    <FontsMapContainerTip><b>Tip!</b> Selecting your theme font will keep your slide consistent with the rest of your presentation, and up to date if your theme changed.</FontsMapContainerTip>
                </>}
            </DialogContent>
            <DialogActions>
                <Button
                    color="secondary"
                    onClick={closeDialog}
                    disabled={isLoadingFonts}
                >
                    Cancel
                </Button>
                <Button
                    color="primary"
                    onClick={this.handleConfirm}
                    disabled={isLoadingFonts}
                >
                    Confirm
                </Button>
            </DialogActions>
        </BeautifulDialog>);
    }
}

export default ConvertPPTSlideToAuthoringDialog;
