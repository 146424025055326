import { ds } from "js/core/models/dataService";
import { controls } from "js/editor/ui";
import * as geom from "js/core/utilities/geom";
import { $, _ } from "js/vendor";
import { AssetType } from "common/constants";
import { ShowDialog, ShowErrorDialog, ShowWarningDialog } from "js/react/components/Dialogs/BaseDialog";
import { AddAssetsContainer } from "js/react/views/AddAssets";

import { VerticalTaskLists } from "../../elements/elements/VerticalTaskList";

import { ElementOptionsMenu } from "../BaseElementEditor";
import { CollectionElementSelection, CollectionItemElementSelection } from "../CollectionElementEditor";

const VerticalTaskListsSelection = CollectionElementSelection.extend({

    getAddItemLabel: function() {
        return "Add Column";
    },

    renderControls: function() {
        this.createAddItemButton();

        this.addControl({
            id: "vertical-task-styles",
            type: controls.POPUP_BUTTON,
            label: "Style",
            items: () => [{
                type: "control",
                view: () => controls.createIconGrid(this, {
                    model: this.element.model,
                    transitionModel: false,
                    markStylesAsDirty: true,
                    property: "style",
                    items: [{
                        value: "no-frame",
                        label: "None",
                        icon: "/images/ui/vertical-tasks-style-none.svg"
                    }, {
                        value: "divider",
                        label: "Divider",
                        icon: "/images/ui/vertical-tasks-style-divider.svg",
                        enabled: this.element.itemCount != 1
                    }, {
                        value: "box",
                        label: "Box",
                        icon: "/images/ui/vertical-tasks-style-box.svg"
                    }]
                })
            }]
        });

        this.addControl({
            id: "vertical-task-layout",
            type: controls.POPUP_BUTTON,
            label: "Layout",
            items: () => [{
                type: "control",
                view: () => controls.createIconGrid(this, {
                    model: this.element.model,
                    transitionModel: true,
                    property: "taskLayout",
                    markStylesAsDirty: true,
                    items: [{
                        value: "stack",
                        label: "Stack",
                        icon: "/images/ui/vertical-tasks-layout-stack.svg"
                    }, {
                        value: "fill",
                        label: "Fill",
                        icon: "/images/ui/vertical-tasks-layout-fill.svg",
                    }, {
                        value: "fit",
                        label: "Fit",
                        icon: "/images/ui/vertical-tasks-layout-fit.svg"
                    }]
                })
            }]
        });
    }
});

const VerticalTaskListsOptionsMenu = ElementOptionsMenu.extend({
    renderControls: function() {
        this.addControl({
            type: controls.TOGGLE,
            label: "Show Titles",
            property: "showTitles"
        });
    }
});

const VerticalTaskListsColumnSelection = CollectionItemElementSelection.extend({
    captureMouseEvents: false,

    getWidgetPosition() {
        return "inner";
    },

    getDragAxis: function() {
        return "x";
    },

    renderControls: function() {
        this.$addItem = this.$el.addEl(controls.createButtonWidget({
            icon: "add",
            className: "large",
            callback: () => {
                this.element.taskList.addItem();
                this.element.canvas.updateCanvasModel(false).catch(() => {
                    ShowErrorDialog({ error: "Sorry, we can't fit any more items in this column", message: "If you need to add more items, you can either reduce the text size of the existing items or remove any headers or trays" });
                });
            }
        }));
    },

    _layout: function() {
        if (this.$addItem) {
            if (this.element.taskList.itemElements.length) {
                this.$addItem.top((this.element.taskList.bounds.top + _.maxBy(this.element.taskList.itemElements, element => element.bounds.bottom).bounds.bottom + 20) * this.canvasScale);
            } else {
                this.$addItem.top(this.element.taskList.bounds.top * this.canvasScale + 20);
            }
            this.$addItem.left(this.element.bounds.width / 2 * this.canvasScale - this.$addItem.find(".icon_button").width() / 2);
        }
    }
});

const VerticalTaskElementSelection = CollectionItemElementSelection.extend({

    canDrag: function() {
        return this.element.canDrag;
    },

    renderControls: function() {
        this.addControl({
            type: controls.POPUP_BUTTON,
            label: "Icon",
            property: "icon",
            menuContents: closeMenu => {
                let $menu = $.div("pictorial-chart-menu");

                let loadIcon = ($menu, iconId) => {
                    let $item = $menu.addEl($.div("icon"));
                    $item.data("icon", iconId);

                    if (iconId != "none") {
                        ds.assets.getAssetById(iconId, "icon").then(icon => {
                            const url = icon.get("original");
                            if (url.startsWith("http")) {
                                return fetch(url).then(res => {
                                    return res.text();
                                });
                            } else {
                                return Promise.resolve(url);
                            }
                        }).then(svgData => {
                            $item.append(svgData);
                        });
                    } else {
                        $item.append($.div("none", "NONE"));
                    }

                    $item.on("click", () => {
                        this.element.model.icon = iconId;
                        this.element.canvas.updateCanvasModel(false);
                        closeMenu();
                    });
                };

                let $icons = $menu.addEl($.div("preset-icons"));
                loadIcon($icons, "none");

                loadIcon($icons, "check-yes");
                loadIcon($icons, "x");
                loadIcon($icons, "thumbs-up");
                loadIcon($icons, "thumbs-down");
                loadIcon($icons, "star");
                loadIcon($icons, "star-half");
                loadIcon($icons, "star-none");
                loadIcon($icons, "question");
                loadIcon($icons, "warning");

                loadIcon($icons, "full");
                loadIcon($icons, "quarters-three");
                loadIcon($icons, "contrast");
                loadIcon($icons, "quarter");
                loadIcon($icons, "number-one");
                loadIcon($icons, "number-two");
                loadIcon($icons, "number-three");
                loadIcon($icons, "number-four");
                loadIcon($icons, "number-five");
                loadIcon($icons, "number-six");
                loadIcon($icons, "number-seven");
                loadIcon($icons, "number-eight");
                loadIcon($icons, "number-nine");

                let $choose = $menu.addEl(controls.createButton(this, {
                    label: "Choose Icon...",
                    callback: () => {
                        closeMenu();
                        ShowDialog(AddAssetsContainer, {
                            assetType: AssetType.ICON,
                            workspaceId: ds.selection.presentation.getWorkspaceId(),
                            callback: model => {
                                const {
                                    content_type,
                                    content_value,
                                } = model;
                                if (content_type == AssetType.ICON) {
                                    this.element.model.icon = content_value;
                                    this.element.canvas.updateCanvasModel(false);
                                } else {
                                    ShowWarningDialog({
                                        title: "Unsupported asset type",
                                        message: "Please choose an icon from the asset chooser.",
                                    });
                                }
                            },
                        });
                    }
                }));

                return $menu;
            }
        });

        this.addControl({
            type: controls.COLOR_PALETTE_PICKER,
            property: "color",
            includeAuto: true,
            includeNone: true,
            includePositiveNegative: true
        });
    },

    setupGridDragging: function() {
        if (this.canDrag()) {
            let rootContainer = this.element.findClosestOfType(VerticalTaskLists);
            let dropModel = { ...this.element.model, id: "drop-placeholder" };

            this.$el.draggable({
                helper: () => {
                    return $(`<div style="transform-origin: 0 0; transform: scale(${this.canvasScale})"><svg>${this.element.DOMNode.outerHTML.replace(/style="(.*?)"/, "")}</svg></div>`);
                    //return $(`<div style="transform-origin: 0 0; transform: scale(${this.canvasScale})"><svg>${this.element.svg.node.outerHTML.replace(/style="(.*?)"/, "")}</svg></div>`);
                },
                handle: ".drag_button",
                start: (event, ui) => {
                    this.element.isDragging = true;
                    this.sourceParent = this.element.parentElement;
                    this.dropContainer = this.sourceParent;

                    this.element.parentElement.deleteItem(this.element.id);
                    this.element.canvas.updateCanvasModel(true).then(() => {
                        ds.selection.rolloverElement = null;
                        ds.selection.element = null;
                    });
                },
                drag: (event, ui) => {
                    let score = 0;
                    let dropTarget;

                    if (this.canvas.layouter.isGenerating) {
                        return;
                    }

                    let dragBounds = new geom.Rect(ui.position.left, ui.position.top, this.element.bounds.width * this.canvasScale, this.element.bounds.height * this.canvasScale);

                    _.each(rootContainer.itemElements, column => {
                        _.each(column.taskList.itemElements, task => {
                            if (task == this.element) return;

                            let overlap = dragBounds.intersection(task.canvasBounds.multiply(this.canvasScale)).area();
                            if (overlap > score) {
                                score = overlap;
                                dropTarget = task;
                            }
                        });
                    });

                    // Track the previous state in case we need to revert
                    const prevDropContainer = this.dropContainer;
                    const prevDropTargetIndex = this.dropTargetIndex;

                    if (dropTarget) {
                        // drop where an existing task is
                        this.dropContainer = dropTarget.parentElement;
                        this.dropTargetIndex = dropTarget.itemIndex;
                    } else {
                        score = 0;
                        // See if we are over a task list (but not a task element) and append to the end of the list
                        _.each(rootContainer.itemElements, column => {
                            let overlap = dragBounds.intersection(column.canvasBounds.multiply(this.canvasScale)).area();
                            if (overlap > score) {
                                score = overlap;
                                this.dropContainer = column.taskList;
                            }
                        });
                        // Default to the source parent if we don't have a target
                        if (!this.dropContainer) {
                            this.dropContainer = this.sourceParent;
                        }
                        this.dropTargetIndex = this.dropContainer.itemElements.length;
                    }

                    const layoutTransaction = async () => {
                        _.each(rootContainer.itemElements, column => {
                            column.taskList.deleteItem("drop-placeholder");
                        });

                        this.dropContainer.addItem(dropModel, this.dropTargetIndex);
                        return await this.element.canvas.refreshCanvas();
                    };

                    // If we get an error on refresh, revert the layout changes
                    layoutTransaction().catch(error => {
                        this.dropContainer = prevDropContainer;
                        this.dropTargetIndex = prevDropTargetIndex;

                        layoutTransaction();
                    });
                },
                stop: event => {
                    this.element.isDragging = false;
                    // remove the final drop-placeholder
                    _.each(rootContainer.itemElements, column => {
                        column.taskList.deleteItem("drop-placeholder");
                    });

                    // add the real model in the final location
                    this.dropContainer.addItem(this.element.model, this.dropTargetIndex);

                    this.sourceParent = null;

                    this.element.canvas.updateCanvasModel(false);
                }
            });
        }
    },
});

export const editors = {
    VerticalTaskListsSelection,
    VerticalTaskListsColumnSelection,
    VerticalTaskElementSelection,
    VerticalTaskListsOptionsMenu
};
