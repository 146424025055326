import Cookies from "js-cookie";

import firebase from "js/firebase";
import { openWindowAndWaitForItToBeClosed } from "js/core/utilities/selfClosingWindow";

export class ZohoAuthError extends Error { }
export class ZohoAuthFlowWasInterruptedError extends ZohoAuthError { }

interface ZohoAuthTokens {
    accessToken: string
}

/**
 * Grants our app offline access for the given scope, executes the redirect ouath flow in a separate window
 * and saves the tokens
 */
export async function grantOfflineAccess(): Promise<ZohoAuthTokens> {
    const userIdToken = await firebase.auth().currentUser.getIdToken();
    Cookies.set("user_id_token", userIdToken, { sameSite: "strict", expires: new Date(Date.now() + 60 * 1000) });

    const url = `${window.location.origin}/zoho/auth` +
        `?continueUrl=${encodeURIComponent("/close")}` +
        "&requestOfflineAccess=true";

    const lastSearchParams = await openWindowAndWaitForItToBeClosed(url);

    const error = lastSearchParams.get("zoho_auth_error");
    if (error) {
        throw new ZohoAuthError(error);
    }

    const accessToken = Cookies.get("zoho_access_token");
    Cookies.remove("zoho_access_token");

    if (!accessToken && !error) {
        throw new ZohoAuthFlowWasInterruptedError();
    }

    return { accessToken };
}
