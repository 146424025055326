import * as geom from "js/core/utilities/geom";

import { BaseElement } from "../base/BaseElement";
import { CollectionElement } from "../base/CollectionElement";
import { ContentElement } from "../base/ContentElement";
import { ImageFrame } from "../base/Frame";
import { TextListItem } from "./Lists/TextList";
import { IconTextGridItem } from "./Lists/IconsList";
import { FramedMediaElement } from "../base/MediaElements/FramedMediaElement";

class ProductScreenshot extends BaseElement {
    _build() {
        super._build();

        if (this.model.items && this.model.items.length > 0) {
            this.list = this.addElement("list1", () => ProductScreenshotList);
        }
        this.screenshot = this.addElement("screenshot", () => FramedMediaElement, {
            autoWidth: true,
            allowUnframedImages: false
        });
    }

    _calcProps(props, options) {
        let { size } = props;

        if (this.model.items && this.model.items.length > 0) {
            let listProps = this.list.calcProps(new geom.Size(size.width / 3, size.height), { cols: 1 });

            if (listProps.isFit) {
                this.screenshot.styles.marginRight = this.styles.gap;
                let frameProps = this.screenshot.calcProps(new geom.Size(size.width - listProps.size.width, size.height));

                frameProps.bounds = new geom.Rect(size.width / 2 - (listProps.size.width + frameProps.size.width) / 2, size.height / 2 - frameProps.size.height / 2, frameProps.size);
                listProps.bounds = new geom.Rect(frameProps.bounds.right, 0, listProps.size);
            } else {
                this.screenshot.styles.marginRight = this.screenshot.styles.marginLeft = this.styles.gap;
                let frameProps = this.screenshot.calcProps(new geom.Size(570, size.height));

                frameProps.bounds = new geom.Rect(size.width / 2 - frameProps.size.width / 2, size.height / 2 - frameProps.size.height / 2, frameProps.size);

                listProps = this.list.calcProps(size, { cols: 2, gap: frameProps.size.width });
                listProps.bounds = new geom.Rect(0, 0, size);
            }
        } else {
            let frameProps = this.screenshot.calcProps(size);
            frameProps.bounds = new geom.Rect(size.width / 2 - frameProps.size.width / 2, 0, frameProps.size);
        }

        return { size };
    }

    _migrate_9() {
        this.model.frameType = this.model.frame;
    }
}

class ProductScreenshotImage extends BaseElement {
    get preventTransitionDuringRender() {
        return true;
    }

    _build() {
        this.screenshot = this.addElement("screenshot", () => ContentElement);
        this.frame = this.addElement("frame", () => ImageFrame);
        this.frame.layer = 2;
    }

    _calcProps(props, options) {
        let { size } = props;

        let frameProps = this.frame.calcProps(size);
        frameProps.bounds = new geom.Rect(0, 0, frameProps.size);

        let contentProps = this.screenshot.calcProps(frameProps.clipBounds.size, { backgroundColor: "#222" });
        contentProps.bounds = new geom.Rect(0, 0, contentProps.size).offset(frameProps.bounds.left, frameProps.bounds.top).offset(frameProps.clipBounds.left, frameProps.clipBounds.top);

        if (frameProps.clipPath) {
            contentProps.customClip = frameProps.clipPath;
        }
        return { size: frameProps.size };
    }
}

class ProductScreenshotList extends CollectionElement {
    get showRollover() {
        return false;
    }

    get showSelectionUI() {
        return false;
    }

    getChildItemType() {
        switch (this.parentElement.model.listStyle || "icons") {
            case "icons":
                return IconTextGridItem;
            default:
                return ProductScreenshotTextListItem;
        }
    }

    get minItemCount() {
        return 0;
    }

    _calcProps(props, options) {
        let { size } = props;

        let layouter = this.getLayouter(props, this.itemElements, new geom.Size(size.width, size.height));

        this.styles.minCols = options.cols;
        this.styles.maxCols = options.cols;
        this.styles.hGap = options.gap || 0;

        layouter.calcColumnLayout({
            itemOptionsCallback: (item, colIndex, totalCols, itemsInColumn) => {
                // Will be used for migration
                item.model.col = colIndex;

                if (this.parentElement.model.listStyle !== "none") {
                    if (colIndex === 0 && totalCols > 1) {
                        return { bulletAlign: "right" };
                    }
                }
            }
        });
        layouter.alignVertically();

        props.isFit = layouter.isFit;

        return { size };
    }
}

class ProductScreenshotTextListItem extends TextListItem {

}

export const elements = {
    ProductScreenshot
};
