import React, { Component } from "react";
import styled from "styled-components";
import * as XLSX from "xlsx";
import {
    Button,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@material-ui/core";
import { ArrowBackIos } from "@material-ui/icons";

import { _ } from "js/vendor";
import getLogger, { LogGroup } from "js/core/logger";
import { getStaticUrl } from "js/config";
import { trackActivity } from "js/core/utilities/utilities";
import { SourceType } from "common/interfaces/models";
import { getDataSourceManagerInstance, DataSourceManager, ElementTypesSupported } from "js/core/services/dataSourceManager";
import { withFirebaseAuth } from "js/react/views/Auth/FirebaseAuthContext";
import { withFirebaseUser } from "js/react/views/Auth/FirebaseUserContext";
import { BeautifulDialog, ShowErrorDialog } from "js/react/components/Dialogs/BaseDialog";
import Spinner from "js/react/components/Spinner";
import * as xlsxUtils from "js/core/utilities/xlsx";
import type { SpreadsheetData, ImportedChartData } from "js/core/utilities/xlsx";

import ImportCSVPane from "./panes/ImportCSVPane";
import GoogleSheetsPane from "./panes/GoogleSheetsPane";
import OneDriveExcelPane from "./panes/OneDriveExcelPane";
import BoxSelectorPane from "./panes/BoxSelectorPane";
import DropboxSelectorPane from "./panes/DropboxSelectorPane";
import AirtableSelectorPane from "./panes/AirtableSelectorPane";
import SmartsheetSelectorPane from "./panes/SmartsheetSelectorPane";
import ZohoSelectorPane from "./panes/ZohoSelectorPane";
import FormatDataPane from "./panes/FormatDataPane";
import PreviewDataPane from "./panes/PreviewDataPane";

const logger = getLogger(LogGroup.DATA_SOURCE);

const ContentContainer = styled.div`
    overflow: hidden;
    display: flex;
    padding: 0 10px;
`;

const LeftContainer = styled.div`
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

const RightContainer = styled.div`
    width: 100%;
    min-height: 500px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    margin-left: 30px;
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 10px;
    .title-text {
        font-size: 24px;
        font-weight: 600;
        color: #222;
    }
    .description-text {
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        & a { color: #11A9E2; }
    }
`;

const LeftTab = styled.button<{ selected?: boolean }>`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 190px;
    height: 46px;
    border: none;
    background-color: ${p => p.selected ? "#DBE9F0" : "white"};
    cursor: pointer;
    &:hover {
        background-color: #f3fbff;
    }
    span {
        font-family: "Source Sans Pro";
        font-size: 18px;
        font-weight: 600;
        color: #222;
        padding-left: 10px;
    }
`;

const ActionsContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    .back-button {
        color: #666666;
        margin-right: 8px;
    }
`;

const ActionsRightContainer = styled.div`
    display: flex;
    gap: 10px;
`;

interface Props {
    auth: any,
    firebaseUser: any,
    element: any,
    closeDialog: Function,
    handleSelectedData: Function,
    handleDisconnect: Function,
    viewCurrLinkedData: boolean,
}

interface State {
    elementType: ElementTypesSupported,
    viewType: "add-data" | "format-data" | "preview-data",
    selectedTab: "csv" | SourceType,
    savingType: "link" | "import",
    isSaving: boolean,
    isLoadingCurrDataSource: boolean,
    viewCurrLinkedData: boolean,
    validatedData: string[][] | ImportedChartData,
    workbookRefStrings: string[],
    workbookData: XLSX.WorkBook,
    spreadsheetData: SpreadsheetData,
    selectedCellRange: string, // A1 notation
    selectedSheetIndex: number, // selected sheet in the spreadsheet
    isDataTransposed: boolean,
    useFirstRowAsCategory: boolean,
    useFirstColAsLegend: boolean,
}

class LinkDataDialog extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            elementType: this.getElementType(props.element),
            viewType: props.viewCurrLinkedData ? "format-data" : "add-data",
            selectedTab: "csv",
            savingType: "import",
            isSaving: false,
            isLoadingCurrDataSource: false,
            viewCurrLinkedData: props.viewCurrLinkedData,
            validatedData: null,
            workbookRefStrings: [],
            workbookData: null,
            spreadsheetData: null,
            selectedCellRange: "",
            selectedSheetIndex: 0,
            isDataTransposed: false,
            useFirstRowAsCategory: false,
            useFirstColAsLegend: false,
        };
    }

    componentDidMount() {
        (async () => {
            if (this.props.viewCurrLinkedData && this.props.element.hasDataSourceLink()) {
                this.setState({ isLoadingCurrDataSource: true });

                try {
                    const {
                        dataSourceId, selectedSheetIndex, selectedCellRange, isDataTransposed, useFirstRowAsCategory, useFirstColAsLegend
                    } = this.props.element.model.dataSourceLink;

                    const dataSourceEntry = await DataSourceManager.fetchById(dataSourceId);
                    const spreadsheetData = { sheets: dataSourceEntry.spreadsheetData, ...dataSourceEntry.sourceMetadata } as SpreadsheetData;

                    const workbookData: XLSX.WorkBook = xlsxUtils.spreadsheetDataToXlsxWorkbook(spreadsheetData);
                    const workbookRefStrings = xlsxUtils.getWorkbookRefStrings(workbookData);
                    const selectedData = xlsxUtils.getSelectedSpreadsheetData(
                        spreadsheetData, selectedSheetIndex, selectedCellRange
                    );

                    this.setState({
                        isLoadingCurrDataSource: false,
                        viewType: "format-data", savingType: "link", selectedTab: dataSourceEntry.sourceType,
                        validatedData: selectedData.csvData, spreadsheetData, workbookData, workbookRefStrings,
                        selectedSheetIndex, selectedCellRange, isDataTransposed, useFirstRowAsCategory, useFirstColAsLegend
                    });
                } catch (err) {
                    logger.error(err, "[LinkDataDialog] failed to load existing datasource link", { dataSourceId: this.props.element?.model?.dataSourceLink?.dataSourceId });
                    this.setState({ isLoadingCurrDataSource: false });

                    ShowErrorDialog({
                        error: "Sorry, we could not process your request",
                        message: err.message
                    });
                }
            }
        })();
    }

    getElementType = (element: any): ElementTypesSupported => {
        if (element.isInstanceOf("TableFrame")) {
            return ElementTypesSupported.Table;
        }

        if (element.isInstanceOf("DashboardItem")) {
            return element.infographic.isInstanceOf("PieChart")
                ? ElementTypesSupported.PieChart : element.infographic.getChartType() === "waterfall"
                    ? ElementTypesSupported.WaterfallChart : ElementTypesSupported.Chart;
        }
    }

    handleClose = () => {
        this.props.closeDialog();
    }

    handleDisconnect = async () => {
        const sourceType = this.state.selectedTab as SourceType;
        try {
            const dataSources = await DataSourceManager.fetchBySourceType(sourceType);

            if (dataSources && dataSources.length) {
                await Promise.all(dataSources.map(({ id: dataSourceId }) => {
                    const managerInstance = getDataSourceManagerInstance({ dataSourceId, createIfMissing: false });
                    if (managerInstance) return managerInstance.refreshData(true);
                    return Promise.resolve();
                }));
            }

            if (this.props.element.hasDataSourceLink()) {
                this.props.handleDisconnect();
            }
        } catch (err) {
            logger.error(err, `[LinkDataDialog] failed to disconnect datasources with ${sourceType}`);
        }
    }

    handleSave = async (appendSeries: boolean = false) => {
        this.setState({ isSaving: true });

        try {
            const { element, firebaseUser } = this.props;
            const {
                selectedTab, savingType, validatedData, spreadsheetData,
                selectedCellRange, selectedSheetIndex, isDataTransposed, useFirstRowAsCategory, useFirstColAsLegend
            } = this.state;

            const analyticsParams = {
                savingType, sourceType: selectedTab, isDataTransposed, useFirstRowAsCategory, useFirstColAsLegend
            };

            if (savingType === "import") {
                if (appendSeries) {
                    (validatedData as ImportedChartData).appendSeries = appendSeries;
                    analyticsParams["appendSeries"] = appendSeries;
                }

                if (element.hasDataSourceLink()) {
                    analyticsParams["unlinkedDataSourceId"] = element.model.dataSourceLink.dataSourceId;
                    element.model.dataSourceLink = null;
                    element.removeDataSource();
                }
            } else if (savingType === "link") {
                const sourceType = selectedTab as SourceType;
                const dataSourceEntry = await DataSourceManager.fetchOrCreate(sourceType, spreadsheetData);
                if (!dataSourceEntry) {
                    throw new Error("Failed to create data source entry");
                }

                element.model.dataSourceLink = {
                    dataSourceId: dataSourceEntry.id, elementUniquePath: element.uniquePath,
                    selectedSheetIndex, selectedCellRange, isDataTransposed, useFirstRowAsCategory, useFirstColAsLegend,
                    createdBy: firebaseUser.uid
                };
            }

            trackActivity("DataLink", _.camelCase(savingType), null, null, analyticsParams);

            this.setState({ isSaving: false });
            this.props.handleSelectedData({ validatedData });
            this.props.closeDialog();
        } catch (err) {
            trackActivity("DataLink", "ErroredSaves", null, null, { error: err.message });
            logger.error(err, "[LinkDataDialog] failed to save data");
            this.setState({ isSaving: false });

            ShowErrorDialog({
                error: "Sorry, we could not process your request",
                message: err.message
            });
        }
    }

    handleSheetIndexChange = (selectedSheetIndex: number) => {
        this.setState({ selectedSheetIndex });
    }

    handleCellRangeSelection = (selectedCellRange: string) => {
        this.setState({ selectedCellRange });
    }

    handleViewTypeChange = (viewType: State["viewType"]) => {
        this.setState({ viewType });
    }

    handleSavingTypeChange = (savingType: State["savingType"]) => {
        this.setState({ savingType });
    }

    handleTabChange = (selectedTab: State["selectedTab"]) => {
        this.setState({ selectedTab });
    }

    handleAddedData = (workbookData: XLSX.WorkBook, spreadsheetData?: SpreadsheetData) => {
        const workbookRefStrings = xlsxUtils.getWorkbookRefStrings(workbookData);
        this.setState({ workbookRefStrings, workbookData, spreadsheetData, viewType: "format-data" });
    }

    handleValidatedData = (data: string[][], isDataTransposed: boolean, useFirstRowAsCategory: boolean, useFirstColAsLegend: boolean) => {
        let validatedData: State["validatedData"];
        if (data) {
            switch (this.state.elementType) {
                case ElementTypesSupported.Table:
                    validatedData = data;
                    break;
                case ElementTypesSupported.Chart:
                case ElementTypesSupported.PieChart:
                case ElementTypesSupported.WaterfallChart:
                    validatedData = xlsxUtils.csvDataToChartData(
                        data, useFirstRowAsCategory, useFirstColAsLegend,
                        this.state.elementType === ElementTypesSupported.PieChart,
                        this.state.elementType === ElementTypesSupported.WaterfallChart
                    );
                    break;
                default:
                    break;
            }
        }

        this.setState({ validatedData, isDataTransposed, useFirstRowAsCategory, useFirstColAsLegend });
    }

    handleBack = () => {
        const {
            viewType, workbookData, workbookRefStrings, selectedSheetIndex, selectedCellRange, viewCurrLinkedData
        } = this.state;

        const newViewType = viewType === "preview-data" ? "format-data" : viewType === "format-data" ? "add-data" : viewType;
        const newWorkbookData = xlsxUtils.setWorkbookRefStrings(workbookData, workbookRefStrings);

        let newSelectedSheetIndex = selectedSheetIndex,
            newSelectedCellRange = selectedCellRange,
            newViewCurrLinkedData = viewCurrLinkedData;

        if (newViewType === "add-data") {
            newSelectedSheetIndex = 0, newSelectedCellRange = "", newViewCurrLinkedData = false;
        }

        this.setState({
            viewType: newViewType, workbookData: newWorkbookData, viewCurrLinkedData: newViewCurrLinkedData,
            selectedSheetIndex: newSelectedSheetIndex, selectedCellRange: newSelectedCellRange
        });
    }

    renderAddingDataView() {
        const { auth, firebaseUser } = this.props;
        const { selectedTab } = this.state;

        return (
            <>
                <DialogTitle>Import data or select a data source</DialogTitle>
                <DialogContent>
                    <ContentContainer>
                        <LeftContainer>
                            <LeftTab selected={selectedTab === "csv"} onClick={() => this.handleTabChange("csv")}>
                                <img src={getStaticUrl("/images/data-linking/csv-import.svg")} />
                                <span>CSV</span>
                            </LeftTab>
                            <LeftTab selected={selectedTab === SourceType.Google} onClick={() => this.handleTabChange(SourceType.Google)}>
                                <img src={getStaticUrl("/images/data-linking/google-sheets.svg")} />
                                <span>Google Sheets</span>
                            </LeftTab>
                            <LeftTab selected={selectedTab === SourceType.OneDrive} onClick={() => this.handleTabChange(SourceType.OneDrive)}>
                                <img src={getStaticUrl("/images/data-linking/onedrive-excel.svg")} />
                                <span>OneDrive</span>
                            </LeftTab>
                            <LeftTab selected={selectedTab === SourceType.Dropbox} onClick={() => this.handleTabChange(SourceType.Dropbox)}>
                                <img src={getStaticUrl("/images/data-linking/dropbox.svg")} />
                                <span>Dropbox</span>
                            </LeftTab>
                            <LeftTab selected={selectedTab === SourceType.Box} onClick={() => this.handleTabChange(SourceType.Box)}>
                                <img src={getStaticUrl("/images/data-linking/box.svg")} />
                                <span>Box</span>
                            </LeftTab>
                            <LeftTab selected={selectedTab === SourceType.Airtable} onClick={() => this.handleTabChange(SourceType.Airtable)}>
                                <img src={getStaticUrl("/images/data-linking/airtable.svg")} />
                                <span>Airtable</span>
                            </LeftTab>
                            <LeftTab selected={selectedTab === SourceType.Smartsheet} onClick={() => this.handleTabChange(SourceType.Smartsheet)}>
                                <img src={getStaticUrl("/images/data-linking/smartsheet.svg")} width={24} height={24} />
                                <span>Smartsheet</span>
                            </LeftTab>
                            <LeftTab selected={selectedTab === SourceType.Zoho} onClick={() => this.handleTabChange(SourceType.Zoho)}>
                                <img src={getStaticUrl("/images/data-linking/zoho.svg")} />
                                <span>Zoho</span>
                            </LeftTab>
                        </LeftContainer>
                        <RightContainer>
                            {selectedTab === "csv" && <ImportCSVPane firebaseUser={firebaseUser} handleAddedData={this.handleAddedData} />}
                            {selectedTab === SourceType.Google && <GoogleSheetsPane handleAddedData={this.handleAddedData} handleDisconnect={this.handleDisconnect} auth={auth} firebaseUser={firebaseUser} />}
                            {selectedTab === SourceType.OneDrive && <OneDriveExcelPane handleAddedData={this.handleAddedData} handleDisconnect={this.handleDisconnect} />}
                            {selectedTab === SourceType.Dropbox && <DropboxSelectorPane handleAddedData={this.handleAddedData} handleDisconnect={this.handleDisconnect} />}
                            {selectedTab === SourceType.Box && <BoxSelectorPane handleAddedData={this.handleAddedData} handleDisconnect={this.handleDisconnect} />}
                            {selectedTab === SourceType.Airtable && <AirtableSelectorPane handleAddedData={this.handleAddedData} handleDisconnect={this.handleDisconnect} />}
                            {selectedTab === SourceType.Smartsheet && <SmartsheetSelectorPane handleAddedData={this.handleAddedData} handleDisconnect={this.handleDisconnect} />}
                            {selectedTab === SourceType.Zoho && <ZohoSelectorPane handleAddedData={this.handleAddedData} handleDisconnect={this.handleDisconnect} />}
                        </RightContainer>
                    </ContentContainer>
                </DialogContent>
            </>
        );
    }

    renderFormattingDataView() {
        const { isLoadingCurrDataSource, selectedTab, savingType, workbookData, spreadsheetData, selectedSheetIndex, selectedCellRange } = this.state;

        return (
            <>
                <DialogTitle>
                    <TitleContainer>
                        <span className="title-text">Format Data</span>
                        {!isLoadingCurrDataSource && (
                            spreadsheetData && spreadsheetData.title ? (
                                <span className="description-text">Select cell range and choose to import once or create a dynamic link to {
                                    spreadsheetData.url ? <a href={spreadsheetData.url} target="_blank">[{spreadsheetData.title}]</a> : `[${spreadsheetData.title}]`
                                }.</span>
                            ) : (
                                <span className="description-text">Select cell range and choose to import once.</span>
                            )
                        )}
                    </TitleContainer>
                </DialogTitle>
                <DialogContent style={{ paddingBottom: 0 }}>
                    <ContentContainer>
                        {isLoadingCurrDataSource && <div style={{ padding: "60px" }}><Spinner /></div>}
                        {!isLoadingCurrDataSource && (
                            <FormatDataPane
                                workbookData={workbookData}
                                savingType={selectedTab !== "csv" ? savingType : undefined}
                                selectedSheetIndex={selectedSheetIndex}
                                selectedCellRange={selectedCellRange}
                                handleBack={this.handleBack}
                                handleSavingTypeChange={this.handleSavingTypeChange}
                                handleSheetIndexChange={this.handleSheetIndexChange}
                                handleCellRangeSelection={this.handleCellRangeSelection}
                            />
                        )}
                    </ContentContainer>
                </DialogContent>
                <DialogActions>
                    <ActionsContainer>
                        <Button className="back-button" onClick={this.handleBack}>
                            <ArrowBackIos /> <span>Back</span>
                        </Button>
                        <Button disabled={!selectedCellRange} variant="contained" color="primary" onClick={() => this.handleViewTypeChange("preview-data")}>
                            Next
                        </Button>
                    </ActionsContainer>
                </DialogActions>
            </>
        );
    }

    renderPreviewDataView() {
        const {
            elementType, workbookData, validatedData, savingType, isSaving, viewCurrLinkedData,
            selectedSheetIndex, selectedCellRange, isDataTransposed, useFirstRowAsCategory, useFirstColAsLegend
        } = this.state;

        return (
            <>
                <DialogTitle>Format Data</DialogTitle>
                <DialogContent style={{ paddingBottom: 0 }}>
                    <ContentContainer>
                        <PreviewDataPane
                            elementType={elementType}
                            workbookData={workbookData}
                            viewCurrLinkedData={viewCurrLinkedData}
                            selectedSheetIndex={selectedSheetIndex}
                            selectedCellRange={selectedCellRange}
                            isDataTransposed={isDataTransposed}
                            useFirstRowAsCategory={useFirstRowAsCategory}
                            useFirstColAsLegend={useFirstColAsLegend}
                            handleValidatedData={this.handleValidatedData}
                        />
                    </ContentContainer>
                </DialogContent>
                <DialogActions>
                    <ActionsContainer>
                        <Button className="back-button" onClick={this.handleBack}>
                            <ArrowBackIos /> <span>Back</span>
                        </Button>
                        <ActionsRightContainer>
                            {savingType === "import" && elementType === ElementTypesSupported.Chart && (
                                <Button color="primary" disabled={!validatedData} onClick={() => this.handleSave(true)}>
                                    {isSaving && <Spinner />}
                                    {!isSaving && <span>Create new series</span>}
                                </Button>
                            )}
                            <Button variant="contained" color="primary" disabled={!validatedData} onClick={() => this.handleSave()}>
                                {isSaving && <Spinner />}
                                {!isSaving && <span>Replace Data</span>}
                            </Button>
                        </ActionsRightContainer>
                    </ActionsContainer>
                </DialogActions>
            </>
        );
    }

    render() {
        const { viewType } = this.state;

        return (
            <BeautifulDialog maxWidth="lg" closeButton closeDialog={this.handleClose}>
                {viewType === "add-data" && this.renderAddingDataView()}
                {viewType === "format-data" && this.renderFormattingDataView()}
                {viewType === "preview-data" && this.renderPreviewDataView()}
            </BeautifulDialog>
        );
    }
}

export default withFirebaseAuth(withFirebaseUser(LinkDataDialog));
