import { _, $ } from "js/vendor";
import * as geom from "js/core/utilities/geom";
import {
    ElementTextBlockPositionType,
    TrayElementType,
    TrayType
} from "common/constants";
import perf from "js/core/utilities/perf";
import { delay } from "js/core/utilities/promiseHelper";
import { ELEMENT_TRANSITION_DURATION } from "js/core/utilities/svgHelpers";
import { reactMount, reactUnmount } from "js/react/renderReactRoot";
import getLogger, { LogGroup } from "js/core/logger";

import { CanvasLayouter } from "./baseCanvasLayouter";
import { CanvasElement } from "../elements/base/CanvasElement";

const logger = getLogger(LogGroup.CANVAS_LAYOUTER);

class SlideCanvasLayouter extends CanvasLayouter {
    constructor(canvas, template) {
        super(canvas, template);

        this.generationPromise = null;
        this.postRenderCallbacks = [];

        this.options = {
            editable: true
        };

        this.elementsToRefreshOnAnimationFrame = [];
        this.onAnimationFrameCallbacks = [];
        this.removedElements = [];
        this.cleanupRemovedElementsTimeout = null;
    }

    get primary() {
        return this.canvasElement.elements.primary;
    }

    get annotations() {
        return this.canvasElement.elements.annotations;
    }

    get isGenerating() {
        return !!this.generationPromise;
    }

    clear() {
        reactUnmount(this.canvas.el);
    }

    /**
     * Waits for a new animation frame, runs all callbacks from this.onAnimationFrameCallback
     * and refreshes all elements from this.elementsToRefreshOnAnimationFrame
     */
    waitForAnimationFrame() {
        this.isWaitingForAnimationFrame = true;

        const handleAnimationFrame = timestamp => {
            if (!this.canvas.isCurrentCanvas) {
                // Waiting for the canvas to become current
                window.requestAnimationFrame(handleAnimationFrame);
                return;
            }

            // We don't want to refresh deleted elements
            const elementsToRender = this.elementsToRefreshOnAnimationFrame.filter(element => !element.isDeleted);
            this.elementsToRefreshOnAnimationFrame = [];

            const callbacks = this.onAnimationFrameCallbacks;
            this.onAnimationFrameCallbacks = [];

            this.isWaitingForAnimationFrame = false;

            callbacks.forEach(callback => callback(timestamp));
            if (elementsToRender.length > 0) {
                if (!this.isGenerating) {
                    this.renderElements(elementsToRender, false);
                }
            }
        };

        window.requestAnimationFrame(handleAnimationFrame);
    }

    /**
     * A substitude for window.requestAnimationFrame which calls the callback,
     * recalcs props for the element and refreshes it upon the next animation frame
     */
    requestAnimationFrame = (element, callback) => {
        // We should only recalc props once for an element
        if (!this.elementsToRefreshOnAnimationFrame.includes(element)) {
            this.elementsToRefreshOnAnimationFrame.push(element);
        }

        this.onAnimationFrameCallbacks.push(callback);

        if (!this.isWaitingForAnimationFrame) {
            this.waitForAnimationFrame();
        }
    };

    /**
     * WARNING: supposed to be called only from within a method that is being called as a
     * part of the generate() loop, i.e. _calcProps, renderChildren(), _build, etc.
     * Callback has to be sync, if you need to run an async code in it, please take care
     * of promise rejections yourself.
     */
    runPostRender(callback) {
        this.postRenderCallbacks.push(callback);
    }

    async _generate(
        model,
        {
            transition = false,
            forceRender = false,
            lowerBoundInclusiveVersion,
            upperBoundInclusiveVersion,
            doMigration
        },
        canvasSize
    ) {
        this.model = model;

        perf.start("generate");

        // generate a unique ID for this generation pass so we can determine elements that might have been removed on a future pass
        let generationKey = _.uniqueId();

        if (!this.canvasElement) {
            this.canvasElement = new CanvasElement({ id: "root", canvas: this.canvas });
            this.canvasElement.template = this.template;
        }

        perf.start("build");
        this.canvasElement.build(model, generationKey, doMigration, lowerBoundInclusiveVersion, upperBoundInclusiveVersion);
        perf.stop("build");

        perf.start("load");
        await this.canvasElement.load();
        perf.stop("load");

        perf.start("calcProps");
        let canvasProps = this.canvasElement.calcProps(canvasSize);
        canvasProps.bounds = new geom.Rect(0, 0, canvasSize);

        // support old elements ref
        this.elements = this.canvasElement.elements;

        perf.stop("calcProps");

        let layoutFit = this.doesLayoutFit(this.canvasElement);

        // dont get stuck in a layoutNotFit state - allow the user to move from bad state to another bad state
        if (!layoutFit && this.lastLayoutFit === false) {
            forceRender = true;
        }
        this.lastLayoutFit = layoutFit;

        if (layoutFit || forceRender) {
            if (!layoutFit) {
                logger.warn("Layout does not fit but is being forced to render", { slideId: this.canvas.dataModel.id });
            }

            perf.start("render");
            this.render(transition);
            perf.stop("render");
        } else {
            throw new Error("Layout doesn't fit");
        }

        if (transition) {
            await delay(ELEMENT_TRANSITION_DURATION);
        }

        perf.stop("generate");
    }

    waitingForGenerationComplete = [];
    async waitForGeneration() {
        if (!this.isGenerating) {
            return;
        }

        return new Promise(resolve => {
            this.waitingForGenerationComplete.push(resolve);
        });
    }

    async generate(
        model,
        {
            transition = false,
            forceRender = false,
            lowerBoundInclusiveVersion,
            upperBoundInclusiveVersion,
            doMigration
        },
        canvasSize
    ) {
        if (this.generationPromise) {
            logger.warn("Attempt to call generate before previous generation has completed", { slideId: this.canvas.dataModel.id });
            // Avoiding multiple simultaneously running generate() processes
            await this.generationPromise;

            // TODO - talk to Igor about this because the await here is resolving before any generate().then() are called which
            // defeats the whole purpose. See changing color for a pie chart slice
            await delay(1);
        }

        this.generationPromise = this._generate(
            model,
            {
                transition,
                forceRender,
                lowerBoundInclusiveVersion,
                upperBoundInclusiveVersion,
                doMigration
            },
            canvasSize
        )
            .finally(() => {
                this.generationPromise = null;

                // flush out waiting tasks
                for (let i = this.waitingForGenerationComplete.length; i-- > 0;) {
                    this.waitingForGenerationComplete.pop()();
                }
            });

        return this.generationPromise;
    }

    async testCanvas(iterations) {
        perf.reset();
        perf.start("total");
        for (let i = 0; i < iterations; i++) {
            perf.start("calc");
            await this.updateCanvas(this.model);
            perf.stop("calc");
        }
        perf.stop("total");
        perf.print();
    }

    async updateCanvas(model) {
        this.canvasElement = new CanvasElement({ id: "root", canvas: this.canvas });
        this.canvasElement.template = this.template;

        this.canvasElement.build(this.model, 1);

        await this.canvasElement.load();

        let canvasProps = this.canvasElement.calcProps(new geom.Size(this.canvas.CANVAS_WIDTH, this.canvas.CANVAS_HEIGHT));

        return canvasProps;
    }

    refreshLayout(canvasSize) {
        perf.start("calcLayout");
        this.calcProps(this.getLayoutProps(), canvasSize.width, canvasSize.height);
        perf.stop("calcLayout");
        return this.render(false);
    }

    doesLayoutFit(element) {
        let isFit = element.calculatedProps?.isFit !== false;
        for (let child of Object.values(element.elements)) {
            if (!this.doesLayoutFit(child)) {
                isFit = false;
            }
        }

        return isFit;
    }

    async tryLayout(model) {
        // perf.reset();
        // perf.start("try");
        let canvasElement = new CanvasElement({ id: "root", canvas: this.canvas });
        canvasElement.template = this.template;

        canvasElement.build(model, 0);
        await canvasElement.load();
        let canvasProps = canvasElement.calcProps(new geom.Size(this.canvas.CANVAS_WIDTH, this.canvas.CANVAS_HEIGHT), {
            isTryingLayout: true,
        });

        // perf.stop("try");
        // perf.print();

        return canvasProps.isFit;
    }

    reportRemovedElement(element) {
        this.removedElements.push(element);
    }

    refreshRender(transition) {
        this.render(transition);
    }

    render(transition) {
        clearTimeout(this.cleanupRemovedElementsTimeout);

        // recurse through the element tree and resolve any dirty color styles
        this.canvasElement.resolveColorStyles("root", this.canvasElement.styles);

        // render the React DOM from the element tree starting with the canvasElement
        const renderedCanvasElement = this.canvasElement.renderElement(transition);

        reactMount(renderedCanvasElement, this.canvas.el);

        // If there are removed elements and transition then set up a timer to
        // refresh render in order to delete the removed elements from dom after transition
        // If there's no transition then the removed elements were not rendered
        if (this.removedElements.length > 0 && transition) {
            this.cleanupRemovedElementsTimeout = setTimeout(
                () => this.refreshRender(false),
                ELEMENT_TRANSITION_DURATION
            );
        }
        this.removedElements = [];

        // Cleaning up this.postRenderCallbacks before calling the callbacks to
        // avoid endless loops in cases when a callback invokes rendering
        const postRenderCallbacks = this.postRenderCallbacks;
        this.postRenderCallbacks = [];
        postRenderCallbacks.forEach(callback => callback());

        this.isLayedOut = true;
    }

    async forceSpellcheck() {
        this.forcedSpellcheck = true;
        let $contentEditables = $("[contenteditable=true]");
        for (let i = 0; i < $contentEditables.length; i++) {
            $contentEditables[i].focus();
            $contentEditables[i].blur();
            await delay(50);
        }
    }

    renderElement(element, transition, requireFit = false) {
        perf.start("recalcProps");
        let newProps = element.recalcProps();

        const isFit = this.doesLayoutFit(element);
        if (!isFit && requireFit) {
            throw new Error("Layout not fit");
        }
        perf.stop("recalcProps");
        perf.start("rerender");
        this.refreshRender(transition);
        perf.stop("rerender");
    }

    renderElements(elements, transition) {
        elements.forEach(element => {
            element.recalcProps();
        });
        this.refreshRender(transition);
    }

    getElementLayoutOptions(type) {
        let layoutOptions = [];
        let layoutProps = this.getLayoutProps();

        let availableTrayLayouts = this.template.availableTrayLayouts;

        if (type === TrayElementType.TEXT) {
            layoutOptions.push({
                type: "text-header",
                label: "Slide Header",
                enabled: this.template.allowHeader,
                selected: layoutProps.showHeader === true,
                props: {
                    showHeader: true
                }
            });
        }
        layoutOptions.push({
            type: "tray-left",
            label: "Left Tray",
            enabled: availableTrayLayouts.contains(TrayType.LEFT_TRAY) && (layoutProps.trayLayout === TrayType.NONE || layoutProps.trayElementType === type),
            selected: layoutProps.trayLayout === TrayType.LEFT_TRAY,
            props: {
                trayLayout: TrayType.LEFT_TRAY
            }
        });
        layoutOptions.push({
            type: "tray-right",
            label: "Right Tray",
            enabled: availableTrayLayouts.contains(TrayType.RIGHT_TRAY) && (layoutProps.trayLayout === TrayType.NONE || layoutProps.trayElementType === type),
            selected: layoutProps.trayLayout === TrayType.RIGHT_TRAY,
            props: {
                trayLayout: TrayType.RIGHT_TRAY
            }
        });
        layoutOptions.push({
            type: "tray-top",
            label: "Top Tray",
            enabled: availableTrayLayouts.contains(TrayType.TOP_TRAY) && (layoutProps.trayLayout === TrayType.NONE || layoutProps.trayElementType === type),
            selected: layoutProps.trayLayout === TrayType.TOP_TRAY,
            props: {
                trayLayout: TrayType.TOP_TRAY
            }
        });
        layoutOptions.push({
            type: "tray-bottom",
            label: "Bottom Tray",
            enabled: availableTrayLayouts.contains(TrayType.BOTTOM_TRAY) && (layoutProps.trayLayout === TrayType.NONE || layoutProps.trayElementType === type),
            selected: layoutProps.trayLayout === TrayType.BOTTOM_TRAY,
            props: {
                trayLayout: TrayType.BOTTOM_TRAY
            }
        });
        layoutOptions.push({
            type: "background",
            label: "Background",
            enabled: availableTrayLayouts.contains(TrayType.BACKGROUND) && (layoutProps.trayLayout === TrayType.NONE || layoutProps.trayElementType === type),
            selected: layoutProps.trayLayout === TrayType.BACKGROUND,
            props: {
                trayLayout: TrayType.BACKGROUND
            }
        });
        layoutOptions.push({
            type: "inline-left",
            label: "Left Inline",
            enabled: availableTrayLayouts.contains(TrayType.LEFT_INLINE) && (layoutProps.trayLayout === TrayType.NONE || layoutProps.trayElementType === type),
            selected: layoutProps.trayLayout === TrayType.LEFT_INLINE,
            props: {
                trayLayout: TrayType.LEFT_INLINE
            }
        });
        layoutOptions.push({
            type: "inline-right",
            label: "Right Inline",
            enabled: availableTrayLayouts.contains(TrayType.RIGHT_INLINE) && (layoutProps.trayLayout === TrayType.NONE || layoutProps.trayElementType === type),
            selected: layoutProps.trayLayout === TrayType.RIGHT_INLINE,
            props: {
                trayLayout: TrayType.RIGHT_INLINE
            }
        });
        if (type === TrayElementType.TEXT) {
            layoutOptions.push({
                type: "text-tray-bottom",
                label: "Bottom Tray",
                enabled: this.template.allowElementTextTray,
                selected: layoutProps.elementTextBlockPosition === ElementTextBlockPositionType.TRAY,
                props: {
                    elementTextBlockPosition: ElementTextBlockPositionType.TRAY
                }
            });
            layoutOptions.push({
                type: "text-inline-bottom",
                label: "Bottom Text",
                enabled: this.template.allowElementTextInline,
                selected: layoutProps.elementTextBlockPosition === ElementTextBlockPositionType.INLINE,
                props: {
                    elementTextBlockPosition: ElementTextBlockPositionType.INLINE
                }
            });
            layoutOptions.push({
                type: "text-attribution",
                label: "Footnote/Attribution",
                enabled: this.template.allowElementAttribution,
                selected: layoutProps.showElementAttribution === true,
                props: {
                    showElementAttribution: true
                }
            });
        }

        return layoutOptions;
    }
}

export { SlideCanvasLayouter };

