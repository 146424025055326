/*eslint no-constant-condition: ["error", { "checkLoops": false }]*/
import React from "reactn";
import {
    HorizontalAlignType,
    ParagraphStyle,
    TextStyleEnum,
    VerticalAlignType
} from "common/constants";
import { ds } from "js/core/models/dataService";
import { fontManager } from "js/core/services/fonts";
import * as geom from "js/core/utilities/geom";
import { getCSSTransform } from "js/core/utilities/geom";
import { emailRegex } from "js/core/utilities/regex";
import { ELEMENT_TRANSITION, SVGGroup } from "js/core/utilities/svgHelpers";
import { blendColors } from "js/core/utilities/utilities";
import { app } from "js/namespaces";
import { tinycolor, _ } from "js/vendor";
import { isRenderer } from "js/config";
import { ShowConfirmationDialog } from "js/react/components/Dialogs/BaseDialog";
import SvgTextModel from "common/utils/SvgTextModel";

import { BaseElement } from "./BaseElement";
import { layoutHelper } from "../layouts/LayoutHelper";
import { DefaultTextLayout, EvenBreakTextLayout } from "../layouts/TextLayout";

class TextElement extends BaseElement {
    get _canSelect() {
        return true;
    }

    get canSelect() {
        if (ds.selection.element && ds.selection.element instanceof TextElement) {
            return true;
        }
        return super.canSelect;
    }

    get _canRollover() {
        return true;
    }

    get doubleClickToSelect() {
        return false;
    }

    get passThroughSelection() {
        return false;
    }

    get minHeight() {
        return this.styles.marginTop + this.styles.marginBottom + this.styles.paddingTop + this.styles.paddingBottom + this.styles.fontSize;
    }

    get isTabbable() {
        return this.options.isTabbable === undefined ? true : this.options.isTabbable;
    }

    get singleLine() {
        return this.options.singleLine;
    }

    get allowEmptyLines() {
        return this.options.allowEmptyLines === undefined ? true : this.options.allowEmptyLines;
    }

    get allowStyling() {
        return this.options.allowStyling === undefined ? true : this.options.allowStyling;
    }

    get allowUserColor() {
        return this.options.allowUserColor ?? true;
    }

    get showStyleBar() {
        return this.options.showStyleBar === undefined ? true : this.options.showStyleBar;
    }

    get styleBarOffset() {
        return this.options.textStyleBarOffset || 6;
    }

    get allowAlignment() {
        return this.options.allowAlignment || false;
    }

    get allowedTextStyles() {
        return this.options.allowedTextStyles || [];
    }

    get textStylePropertyName() {
        return this.options.textStylePropertyName || "textStyle";
    }

    get placeholderPrompt() {
        return { text: this.options.placeholder !== undefined ? this.options.placeholder : "Type text", style: [] };
    }

    get isTextFit() {
        return this.calculatedProps.isTextFit;
    }

    get showDefaultOverlay() {
        return !this.isTextFit;
    }

    get defaultOverlayType() {
        return "TextElementDefaultOverlay";
    }

    get scaleTextToFit() {
        return !!this.options.scaleTextToFit;
    }

    get defaultTextScale() {
        return 1;
    }

    get textScale() {
        return this._textScale;
    }

    get userFontScale() {
        if (this.matchUserScaleWithSiblings) {
            let path = this.getElementTreePath();
            let userFontScale = this.getRootElement().model.userFontScale;
            if (userFontScale && userFontScale[path]) {
                return userFontScale[path];
            } else {
                return 1;
            }
        } else {
            return this.model[this.bindTo] ? (this.model[this.bindTo].userFontScale || 1) : 1;
        }
    }

    set userFontScale(value) {
        if (this.matchUserScaleWithSiblings) {
            let path = this.getElementTreePath();
            if (!this.getRootElement().model.userFontScale) {
                this.getRootElement().model.userFontScale = {};
            }
            this.getRootElement().model.userFontScale[path] = value;
        } else {
            this.model[this.bindTo].userFontScale = value;
        }
    }

    get allowUserScale() {
        return true;
    }

    get matchUserScaleWithSiblings() {
        return this.options.matchUserScaleWithSiblings ?? true;
    }

    get minTextScale() {
        return this.options.minTextScale ?? this.styles.minTextScale ?? 0.5;
    }

    get minFontSize() {
        return null;
    }

    get selectionPadding() {
        return 10;
    }

    get pasteCharLimit() {
        return 1000;
    }

    get paragraphStyle() {
        if (this.options.allowParagraphStyles) {
            return this.model.text_format || ParagraphStyle.PARAGRAPH;
        } else {
            return ParagraphStyle.PARAGRAPH;
        }
    }

    get bindTo() {
        return this.options.bindTo || this.id;
    }

    get textLayoutType() {
        return this.options.textLayout || "default";
    }

    get autoWidth() {
        return this.options.autoWidth || this.styles.autoWidth || false;
    }

    get autoHeight() {
        return this.options.autoHeight || this.styles.autoHeight || false;
    }

    get textModelValue() {
        return this.model[this.bindTo];
    }

    get canEdit() {
        if (this.model.dataSource) {
            return false;
        }

        return super.canEdit;
    }

    get firstTextLineHeight() {
        const verticalPadding = this.calculatedProps.paddedSize.height - this.calculatedProps.innerSize.height;
        const firstLineHeight = this.calculatedProps.textLayout.lines[0].fontHeight;
        return firstLineHeight + verticalPadding;
    }

    get font() {
        return this.fonts[this.styles.fontId];
    }

    get hasAllGlyphs() {
        for (const paragraph of this.textModel.paragraphs) {
            for (const word of paragraph.words) {
                const textStyle = word.style || TextStyleEnum.REGULAR;
                const font = this.getFontForTextStyle(textStyle);
                for (const char of word.text) {
                    if (!this.getFallbackFont(textStyle, char) && !fontManager.fontHasGlyphForChar(font, char)) {
                        return false;
                    }
                }
            }
        }

        return true;
    }

    get baseFontWeight() {
        return parseInt(this.styles.fontWeight);
    }

    get boldFontWeight() {
        if (this.styles.fontBoldWeight) {
            return parseInt(this.styles.fontBoldWeight);
        }

        return this.baseFontWeight >= 700 ? 900 : 700;
    }

    get shouldLoadOpentypeFonts() {
        return true;
    }

    get shouldLoadCssFonts() {
        return false;
    }

    get textLayout() {
        return this.calculatedProps.textLayout;
    }

    get textLayouter() {
        return this.calculatedProps.textLayouter;
    }

    _build() {
        this.textModel = new SvgTextModel(this.textModelValue);
    }

    async _load() {
        // Has data source and already loaded fallback fonts
        if (this.fallbackFonts && this.model.dataSource) {
            return;
        }

        // Checking if we need to load fonts
        if (this.fallbackFonts && this.hasAllGlyphs) {
            return;
        }
        // Reset fallback fonts to ensure the generate() loop idempotency
        this.fallbackFonts = {};

        const addFallbackFontIfNeeded = async (font, char, textStyle) => {
            // Note: getFallbackFontIfNeeded() has caching
            const fallbackFont = await fontManager.getFallbackFontIfNeeded(font, char, textStyle);
            if (fallbackFont) {
                if (!this.fallbackFonts[textStyle]) {
                    this.fallbackFonts[textStyle] = {};
                }
                this.fallbackFonts[textStyle][char] = fallbackFont;
            }
        };

        // This is a special-case logic for text elements using data sources (chart annotations),
        // they receive their text model upon calcProps() so we can't correctly calc fallback fonts at this moment,
        // so instead we'll just pull fallback fonts for all currency chars which may be part of the assigned text
        if (this.model.dataSource) {
            // Currency chars used in axis format options menu (js/editor/elementUI/FormatOptionsMenu.js)
            const currencyChars = ["$", "€", "£", "¥", "₹", "₽", "₩", "₪", "₫"];
            // Text elements with data sources always use the regular style
            const textStyle = TextStyleEnum.REGULAR;
            const font = this.getFontForTextStyle(textStyle);
            for (const char of currencyChars) {
                await addFallbackFontIfNeeded(font, char, textStyle);
            }
            return;
        }

        // Iterating through all chars and fetching fallback fonts if needed
        for (const paragraph of this.textModel.paragraphs) {
            for (const word of paragraph.words) {
                const textStyle = word.style || TextStyleEnum.REGULAR;
                const font = this.getFontForTextStyle(textStyle);
                const chars = new Set(word.text);
                for (const char of [...chars]) {
                    await addFallbackFontIfNeeded(font, char, textStyle);
                }
            }
        }
    }

    updateText(text) {
        this.textModel = new SvgTextModel({ text: text });
    }

    _migrate_5() {
        this.model[this.bindTo] = migrateTextModel(this.model[this.bindTo]);
    }

    getFontStyleForTextStyle(style) {
        switch (style) {
            case TextStyleEnum.BOLDITALIC:
                return this.font.getStyle(this.boldFontWeight, true);
            case TextStyleEnum.BOLD:
                return this.font.getStyle(this.boldFontWeight, false);
            case TextStyleEnum.ITALIC:
                return this.font.getStyle(this.baseFontWeight, true);
            default:
                return this.font.getStyle(this.baseFontWeight, false);
        }
    }

    getFontForTextStyle(style) {
        return this.getFontStyleForTextStyle(style).font;
    }

    getFallbackFont(style, char) {
        if (!this.fallbackFonts[style]) {
            return null;
        }

        return this.fallbackFonts[style][char];
    }

    _loadStyles(styles) {
        if (this.isEditingText) {
            styles.filter = null;
        }

        if (this.allowedTextStyles && this.allowedTextStyles.length > 0 && this.model[this.textStylePropertyName]) {
            Object.assign(styles, styles.textStyles[this.model[this.textStylePropertyName]]);
        }

        // mjg 4/18/20 Added so we could support user-defined textAlign within single text boxes (previously only worked on TextGroups which would override the textAlign style on child TextElements)
        // if (this.model.textAlign) {
        //     styles.textAlign = this.model.textAlign;
        // }
    }

    _calcProps(props, options) {
        let { size, styles } = props;

        let textLayout;
        let textLayouter;

        let textAlign = options.textAlign || this.model.textAlign || this.styles.textAlign || HorizontalAlignType.LEFT;
        this.styles.textAlign = textAlign;

        const { dataSource } = this.model;
        if (dataSource) {
            const sourceElement = this.canvas.getElementByUniquePath(dataSource.elementId);
            const value = sourceElement.getDynamicValue(dataSource);
            this.updateText(value);
        }

        let scaleTextToFit = options.scaleTextToFit ?? this.scaleTextToFit;
        let breakType = options.breakType ?? this.breakType;

        switch (breakType) {
            case "even":
                textLayouter = new EvenBreakTextLayout(this);
                break;
            default:
                textLayouter = new DefaultTextLayout(this);
        }

        if (options.forceTextScale) {
            this._textScale = options.forceTextScale;
        } else {
            this._textScale = this.defaultTextScale;
        }

        let textModel = this.textModel;

        if (this.textModel.text === "" && ((!this.canvas.isPlayback && !isRenderer) || this.canvas.slide.get("isTeamSlideTemplate"))) {
            this.isPlaceholder = true;
            textModel = new SvgTextModel(this.placeholderPrompt);
        } else {
            this.isPlaceholder = false;
        }

        let isTextFit;

        while (true) {
            // layout the text
            textLayout = textLayouter.layout(size, textModel);

            // check if the text fits in size
            isTextFit = textLayout.size.height <= (size.height + 0.9) &&
                textLayout.lines.length <= (this.styles.maxLines || 1000) &&
                textLayout.size.width <= (size.width + 0.9);

            if (options.constrainWidth) {
                isTextFit = isTextFit && textLayout.size.width <= (size.width + 0.9);
            }

            // if options.scaleTextToFit is true and the text doesn't fit, keep reducing the scale until it does fit
            this._textScale -= 0.05;

            if (scaleTextToFit !== true || isTextFit || this._textScale < this.minTextScale || (this.minFontSize && this.styles.fontSize * this._textScale < this.minFontSize)) {
                break;
            }
        }
        this._textScale += 0.05;

        let textBoxSize = textLayout.size.clone();
        let originalTextSize = textBoxSize.clone();
        let offsetX = 0;
        let offsetY = 0;

        // if options.autoWidth = true and/or options.autoHeight = true, the textelement will return the exact size dimension of the rendered text
        // and alignment will not be applied (or make sense since the width/height will match the text)
        // otherwise, the textElement will return the size that was provided and do both text and vertical align within that textbox

        let autoWidth = options.autoWidth !== undefined ? options.autoWidth : this.autoWidth;
        let autoHeight = options.autoHeight !== undefined ? options.autoHeight : this.autoHeight;

        let verticalAlign = options.verticalAlign || this.styles.verticalAlign || VerticalAlignType.TOP;

        if (!autoWidth) {
            offsetX = layoutHelper.getHorizontalAlignOffset(textLayout.size.width, size.width, textAlign);
            textBoxSize.width = size.width;
        }

        if (!autoHeight) {
            offsetY = layoutHelper.getVerticalAlignOffset(textLayout.size.height, size.height, verticalAlign);
            textBoxSize.height = size.height;
        }

        return {
            size: textBoxSize,
            isTextFit,
            textLayout,
            textLayouter,
            textBounds: new geom.Rect(offsetX, offsetY, textLayout.size),
            originalTextSize,
            textScale: this.textScale,
            autoWidth, autoHeight,
            fontSize: textLayout.lines[0].fontSize,
            textColor: options.textColor,
            textAlign, verticalAlign
        };
    }

    getSvgWord(index) {
        return this.renderedWords ? this.renderedWords.get(index) : null;
    }

    addSvgGroup() {
        return this.textContainer.svg.group();
    }

    get bulletSize() {
        if (this.paragraphStyle === "numbered_list") {
            return 15; // TODO: derive this value based on fontsize and digit count
        } else if (this.paragraphStyle === "bullet_list") {
            return (this.styles.bullet && this.styles.bullet.size) || 20;
        }
        return 0;
    }

    get bulletPadding() {
        if (this.paragraphStyle != "paragraph") {
            return 10;
        }
        return 0;
    }

    get textBounds() {
        return this.calculatedProps.textBounds;
    }

    get offsetX() {
        return this.textBounds.left + this.styles.paddingLeft + this.bulletSize + this.bulletPadding;
    }

    get offsetY() {
        return this.textBounds.top + this.styles.paddingTop;
    }

    renderChildren(transition) {
        return this.drawText(transition);
    }

    get fontColor() {
        return this.styles.resolved_fontColor?.toRgbString() || "black";
    }

    get boldFontColor() {
        return this.styles.bold?.resolved_fontColor?.toRgbString() || this.fontColor;
    }

    get hasItalicFont() {
        return this.font.styles.some(style => style.italic && style.weight >= this.baseFontWeight);
    }

    drawText(transition) {
        let { textLayout } = this.calculatedProps;

        let regularFontColor, boldFontColor;
        let backgroundColor = this.getBackgroundColor(this);

        if (this.calculatedProps.textColor) {
            regularFontColor = this.canvas.getTheme().palette.getColor(this.calculatedProps.textColor).toRgbString();
        } else {
            regularFontColor = this.fontColor;
        }
        boldFontColor = this.boldFontColor;

        if (this.styles.filter == "textStroke") {
            regularFontColor = blendColors(tinycolor(regularFontColor), backgroundColor).toRgbString();
        }

        // if the text is on a dark colored background and their are emphasized words, we want to fade back the color of the non-emphasized text and leave the emphasized words as white/dark
        let fadeNonEmphasizedWords = textLayout.hasEmphasizedWords && ((backgroundColor.isColor && backgroundColor.isDark()) || regularFontColor == boldFontColor);

        let offsetX = this.offsetX;
        let offsetY = this.offsetY;

        let children = [];
        let wordIndex = 0;
        let FONTSIZE = 100;

        textLayout.lines.forEach(line => {
            if (this.paragraphStyle !== "paragraph" && line.isParagraphStart) {
                let lineY = line.words.length ? line.words[0].y : line.wordY;
                switch (this.paragraphStyle) {
                    case ParagraphStyle.BULLET_LIST: {
                        let bulletSize = this.bulletSize / 2;
                        children.push(
                            <circle
                                key={`${children.length}-circle`}
                                r={bulletSize}
                                cx={this.textBounds.left + this.styles.paddingLeft + bulletSize}
                                cy={offsetY + lineY - line.height / 2 + bulletSize * 2}
                                fill={regularFontColor}
                            />
                        );
                        break;
                    }
                    case ParagraphStyle.NUMBERED_LIST: {
                        let num = (line.paragraphIndex + 1).toString();
                        children.push(
                            <text fontSize={line.fontSize}
                                key={`${children.length}-text`}
                                textAnchor="end"
                                fill={regularFontColor}
                                dx={offsetX - 10}
                                dy={offsetY + lineY}
                            >{num + "."}
                            </text>
                        );
                        break;
                    }
                }
            }

            line.words.forEach(word => {
                let x = 0;
                let glyphIndex = 0;

                let wordGlyphs = [];

                for (let i = 0; i < word.formattedText.length; i++) {
                    const char = word.formattedText[i];

                    // Will use a saved fallback font if present
                    const font = this.getFallbackFont(word.style, char) || this.getFontForTextStyle(word.style);
                    const glyphCache = app.glyphCache[font.name];

                    const pathKey = char.codePointAt(0) + word.style;
                    let pathData = glyphCache[pathKey];
                    if (!pathData) {
                        const glyph = font.charToGlyph(char);
                        pathData = glyph.getPath(0, 0, FONTSIZE, { liga: false, rlig: false }).toPathData();
                        glyphCache[pathKey] = pathData;
                    }

                    wordGlyphs.push(React.createElement("path", {
                        key: wordGlyphs.length,
                        transform: `translate(${x} 0)`,
                        d: pathData,
                    }));
                    x += word.scaledGlyphWidths[glyphIndex];
                    glyphIndex++;
                }

                let scale = word.fontSize / FONTSIZE;

                let wordProps = {
                    key: `${children.length}${wordIndex}-word`,
                    style: {
                        transform: getCSSTransform({
                            x: offsetX + word.x,
                            y: offsetY + word.y,
                            scale: scale,
                            skew: this.isPlaceholder ? -15 : 0
                        }),
                    }
                };

                if (this.isPlaceholder) {
                    wordProps.opacity = 0.5;
                }

                if (word.style.equalsAnyOf(TextStyleEnum.BOLD, TextStyleEnum.BOLDITALIC)) {
                    wordProps.fill = boldFontColor;
                } else {
                    wordProps.fill = regularFontColor;
                }

                if (transition) {
                    wordProps.style.transition = ELEMENT_TRANSITION;
                }

                if (word.link) {
                    wordProps.fill = "rgb(80, 187, 230)";
                }

                if (word.color && word.color != "auto") {
                    wordProps.fill = this.canvas.getTheme().palette.getForeColor(word.color, null, backgroundColor).toRgbString();
                }
                if (fadeNonEmphasizedWords && word.style.equalsAnyOf(TextStyleEnum.REGULAR, TextStyleEnum.ITALIC)) {
                    wordProps.opacity = 0.66;
                }

                if (this.canvas.isPlayback && word.link) {
                    let link = word.link;
                    if (!link.startsWith("http") && !link.startsWith("mailto")) {
                        if (link.match(emailRegex)) {
                            link = `mailto:${link}`;
                        } else {
                            link = `https://${link}`; //always default to https
                        }
                    }

                    const attrs = {
                        key: wordGlyphs.length,
                        className: "canvas-text-link",
                        style: {
                            cursor: "pointer",
                            pointerEvents: "auto"
                        }
                    };
                    const isPublicLink = ds.selection.presentation.get("link")?.type === "public";

                    if (isPublicLink) {
                        attrs.onClick = () => {
                            ShowConfirmationDialog({
                                title: "Are You Sure?",
                                message: `Clicking this link will open a web page that is not controlled by Beautiful.ai. Are you sure you want to go to ${link}?`,
                                acceptCallback: async () => {
                                    window.open(link, "_blank");
                                }
                            });
                        };
                    } else {
                        attrs.href = link;
                        attrs.target = "_blank";
                    }

                    wordGlyphs.push(
                        <a {...attrs}>
                            <rect fill="transparent"
                                x={0} y={-FONTSIZE}
                                width={_.sum(word.scaledGlyphWidths)}
                                height={FONTSIZE} />
                        </a>
                    );
                }

                children.push(React.createElement("g", wordProps, wordGlyphs));
                wordIndex++;
            });
        });

        if (!this.isTextFit) {
            this.calculatedProps.overflowHidden = true;
        }

        const { animation } = this.styles;

        return (
            <SVGGroup {...this.calculatedProps} animation={animation} key={`${this.id}-text`}>
                {children}
            </SVGGroup>
        );
    }

    get animationElementName() {
        return `Text "${this.textModel.text.slice(0, 30)}"`;
    }

    get animateChildren() {
        return false;
    }

    _getAnimations() {
        return [{
            name: "Fade in",
            prepare: () => this.animationState.fadeInProgress = 0,
            onBeforeAnimationFrame: progress => {
                this.animationState.fadeInProgress = progress;
            }
        }];
    }
}

// parse pre-September 2018 html model into text and styles
function migrateTextModel(model) {
    if (typeof (model) == "object") {
        return model;
    }

    if (model == null) {
        return {
            text: "",
            styles: []
        };
    }

    let text = "";
    let styles = [];

    let html = model.toString();

    if (html == "" || html == "<p></p>") {
        return { text: "", styles: [] };
    }

    let index = 0;
    let curStyle;

    while (html.length) {
        if (_.startsWith(html, "<p>")) {
            html = html.substring(3);
        } else if (_.startsWith(html, "</p>")) {
            html = html.substring(4);
            text += String.fromCodePoint(13);
            index++;
        } else if (_.startsWith(html, "<a")) {
            curStyle = {
                bold: false,
                italic: false,
                link: html.substring(html.indexOf('"') + 1, html.indexOf('"', html.indexOf('"') + 1)),
                start: index,
                end: index
            };
            html = html.substring(html.indexOf('"', html.indexOf('"') + 1) + 2);
        } else if (_.startsWith(html, "</a>")) {
            if (curStyle) {
                curStyle.end = index;
                styles.push(curStyle);
            }
            html = html.substring(4);
        } else if (_.startsWith(html, "<b>")) {
            curStyle = {
                bold: true,
                italic: false,
                link: null,
                start: index,
                end: index
            };
            html = html.substring(3);
        } else if (_.startsWith(html, "</b>")) {
            if (curStyle) {
                curStyle.end = index;
                styles.push(curStyle);
            }
            html = html.substring(4);
        } else {
            text += html[0];
            html = html.substring(1);
            index++;
        }
    }

    // strip trailing newline
    if (text.codePointAt(text.length - 1) == 13) {
        text = text.substring(0, text.length - 1);
    }

    return { text, styles };
}

export { TextElement, migrateTextModel };

export const elements = {
    TextElement
};
