import { ds } from "js/core/models/dataService";
import { _, $ } from "js/vendor";
import { app } from "js/namespaces.js";
import { WidgetPositionType, HorizontalAlignType } from "common/constants";

import { CollectionElementSelection, CollectionItemElementSelection } from "../CollectionElementEditor";

const SliceChartSelection = CollectionElementSelection.extend({
    getAddItemLabel: function() {
        return "Add Slice";
    },
});

const SliceChartItemSelection = CollectionItemElementSelection.extend({
    showSelectionBox: false,

    getWidgetPosition() {
        return "inner";
    },

    getDragAxis: function() {
        return "x";
    },

    getOffset: function() {
        return 0;
    },

    renderControls: function() {
        this.createEmphasizeWidget(true);

        if (this.element.itemIndex === 0) {
            this.addDraggable({ position: WidgetPositionType.RIGHT, offset: -7 });
        } else if (this.element.itemIndex === this.element.itemCount - 1) {
            this.addDraggable({ position: WidgetPositionType.LEFT, offset: 14 });
        } else {
            this.addDraggable({ position: WidgetPositionType.RIGHT, offset: -7 });
            this.addDraggable({ position: WidgetPositionType.LEFT, offset: 14 });
        }
    },

    updateSlices: function(amountMoved, direction) {
        let rootElement = this.element.parentElement;
        let neighbor = this.element.parentElement.itemElements[this.element.itemIndex + direction];

        let lastWidth = this.element.model.width;
        let lastNeighborWidth = neighbor.model.width;

        let totalSliceWidth = _.sum(this.element.parentElement.itemElements.map(item => item.model.width));
        let change = totalSliceWidth * (amountMoved / rootElement.availableWidth);
        let newWidth = lastWidth + direction * change;
        let newNeighborWidth = lastNeighborWidth + -1 * direction * change;

        let minWidth = this.element.styles.minWidth;
        let newPixelWidth = rootElement.getSliceWidth(newWidth);
        let newNeighborPixelWidth = rootElement.getSliceWidth(newNeighborWidth);
        if (newWidth < lastWidth && newPixelWidth < minWidth || newNeighborWidth < lastNeighborWidth && newNeighborPixelWidth < minWidth) {
            return false;
        }

        const changed = lastWidth !== newWidth;
        if (changed) {
            this.element.model.width = newWidth;
            neighbor.model.width = newNeighborWidth;
        }

        return changed;
    },

    addDraggable: function(config) {
        let $dragWidget = this.$widgets.addEl($.div("ui_widget"));
        let $button = $dragWidget.addEl($.div("tray_resizer"));
        let isPositionedRight = config.position === WidgetPositionType.RIGHT;
        let direction = isPositionedRight ? 1 : -1;
        let dragStart;

        // prevent collectionitem from dragging when clicking on resizer
        $button.on("mousedown", event => {
            event.stopPropagation();
        });

        $button.draggable({
            axis: "x",
            start: (event, ui) => {
                ds.selection.element = null;
                app.isDraggingItem = true;
                dragStart = ui.offset.left;
            },
            drag: (event, ui) => {
                let amountMoved = ui.offset.left - dragStart;
                const scale = this.element.canvas.canvasScale;
                const changed = this.updateSlices(amountMoved / scale, direction);

                if (changed) {
                    dragStart = ui.offset.left;
                    this.element.canvas.refreshCanvasAutoRevert();
                }
            },
            stop: (event, ui) => {
                ds.selection.element = this.element;
                app.isDraggingItem = false;
                this.element.canvas.updateCanvasModel();
            }
        });

        let dragWidget = {
            target: this.element.shapeContainer,
            $el: $dragWidget,
            offset: config.offset,
            height: 22,
            position: config.position,
            buttonAlign: HorizontalAlignType.CENTER
        };

        this.customWidgets.push(dragWidget);
    }

});

export const editors = {
    SliceChartSelection,
    SliceChartItemSelection,
};
