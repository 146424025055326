import React from "reactn";
import { $, SVG } from "../../vendor";

export function renderTextShadowFilter(styles) {
    let filterId = "text-shadow-" + styles.fontSize;

    let filterSVG = (
        <filter id={filterId} x="-25%" y="-25%" width="200%" height="200%">
            <feGaussianBlur in="SourceAlpha" result="blur-out" stdDeviation={styles.fontSize / 7}/>
            <feColorMatrix in="blur-out" result="color-out" type="matrix"
                values="0 0 0 0   0
                      0 0 0 0   0
                      0 0 0 0   0
                      0 0 0 .5 0"/>
            <feBlend in="SourceGraphic" in2="color-out" mode="normal"/>
        </filter>
    );

    return { filterId, filterSVG };
}

export function renderTextStrokeFilter(backgroundColor) {
    let filterId = "text-stroke-" + backgroundColor.toHexString();

    let filterSVG = (
        <filter id={filterId} x="-50%" y="-50%" width="200%" height="200%">
            <feMorphology in="SourceAlpha" result="DILATED" operator="dilate" radius="4 2"></feMorphology>

            <feFlood floodColor={backgroundColor.toHexString()} floodOpacity="1" result="WHITE"></feFlood>
            <feComposite in="WHITE" in2="DILATED" operator="in" result="OUTLINE"></feComposite>

            <feMerge>
                <feMergeNode in="OUTLINE"/>
                <feMergeNode in="SourceGraphic"/>
            </feMerge>
        </filter>
    );

    return { filterId, filterSVG };
}

export function renderAdjustmentFilter(element, props) {
    return (
        <filter id={`${element.filterId || element.uniqueId}-adj-filter`}>
            <feComponentTransfer result="out" in="SourceGraphic">
                <feFuncR id="SvgjsFeFuncR2407" type="linear" slope={props.filterBrightness || 0.5}></feFuncR>
                <feFuncG id="SvgjsFeFuncG2408" type="linear" slope={props.filterBrightness || 0.5}></feFuncG>
                <feFuncB id="SvgjsFeFuncB2409" type="linear" slope={props.filterBrightness || 0.5}></feFuncB>
                <feFuncA id="SvgjsFeFuncA2410" type="identity"></feFuncA>
            </feComponentTransfer>
            <feComponentTransfer result="out" in="out">
                <feFuncR id="SvgjsFeFuncR2412" type="linear" slope={props.filterContrast || 1}
                    intercept={-(0.3 * (props.filterContrast || 1)) + 0.3}></feFuncR>
                <feFuncG id="SvgjsFeFuncG2413" type="linear" slope={props.filterContrast || 1}
                    intercept={-(0.3 * (props.filterContrast || 1)) + 0.3}></feFuncG>
                <feFuncB id="SvgjsFeFuncB2414" type="linear" slope={props.filterContrast || 1}
                    intercept={-(0.3 * (props.filterContrast || 1)) + 0.3}></feFuncB>
                <feFuncA id="SvgjsFeFuncA2415" type="identity"></feFuncA>
            </feComponentTransfer>
            <feGaussianBlur stdDeviation={props.filterBlur || 0} result="output" in="out"/>
        </filter>
    );
}

export function renderColorFilter(element, props) {
    let color = element.canvas.getTheme().palette.getColor(props.filter);

    return (
        <filter id={`${element.filterId || element.uniqueId}-color-filter`} width="100%" height="100%" x="0" y="0">
            <feFlood floodColor={color} floodOpacity="1" result="flood"
                in="SourceGraphic"></feFlood>
            <feColorMatrix type="saturate" values="0" result="color"
                in="SourceGraphic"></feColorMatrix>
            <feBlend in="flood" in2="color" mode="multiply"
                result="out"></feBlend>
        </filter>

    );
}
