import { ds } from "js/core/models/dataService";
import { app } from "js/namespaces.js";
import * as geom from "js/core/utilities/geom";
import { controls, CreateColorChit } from "js/editor/ui";
import {
    WidgetPositionType,
    HorizontalAlignType,
    ContentBlockType,
    TextEditorEvent,
    PositionType, ListStyleType, DragType
} from "common/constants";
import { $, _ } from "js/vendor";
import { getStaticUrl } from "js/config";
import { AssetType } from "common/constants";
import { Convert } from "js/core/utilities/geom";
import { themeColors } from "js/react/sharedStyles";
import { ShowDialog, ShowDialogAsync, ShowWarningDialog } from "js/react/components/Dialogs/BaseDialog";
import { AddAssetsContainer } from "js/react/views/AddAssets";
import BadFitDialog from "js/react/components/Dialogs/BadFitDialog";
import renderReactRoot from "js/react/renderReactRoot";

import { ContentBlockCollection, ContentBlockItem } from "../../elements/elements/ContentBlock";

import { TextEditor, TextElementSelection } from "./textEditor";
import { CreateMediaMenu, CreateImageFrameMenu } from "./pictureEditor";
import { ElementSelection } from "../BaseElementEditor";
import { CollectionItemElementSelection } from "../CollectionElementEditor";
import { createResizer } from "../EditorComponents/Resizer";
import { ImageFrameMenu } from "../EditorComponents/ImageFrameMenu";
import { mergeMediaElementModelDefaults } from "common/assetUtils";

export function AddContentBlock(contentBlocks, type, insertBefore) {
    if (contentBlocks.itemElements.some(block => block.calculatedProps.isTextFit === false)) {
        ShowWarningDialog({
            title: "Sorry, we aren't able to add this block",
            message: "There isn't enough room for any more content without clipping text.",
        });
        return;
    }

    let insertIndex;
    let selectedItem = ds.selection.element.findClosestOfType(ContentBlockItem);
    if (selectedItem) {
        insertIndex = insertBefore ? selectedItem.itemIndex : selectedItem.itemIndex + 1;
    } else {
        insertIndex = contentBlocks.itemCount;
    }

    if (type == "icon") {
        ShowDialog(AddAssetsContainer, {
            assetType: AssetType.ICON,
            workspaceId: ds.selection.presentation.getWorkspaceId(),
            callback: model => {
                let newItemModel = contentBlocks.addItem({ type: type }, insertIndex);
                mergeMediaElementModelDefaults(
                    newItemModel,
                    model,
                );

                contentBlocks.canvas.updateCanvasModel(false).then(() => {
                    let newItem = contentBlocks.getChild(newItemModel.id);
                    ds.selection.element = newItem;
                }).catch(err => {
                    ShowWarningDialog({
                        title: "Sorry, we aren't able to add this block",
                        message: "There isn't enough room for this type of block",
                    });
                });
            },
        });
    } else {
        let newItemModel = contentBlocks.addItem({ type: type }, insertIndex);
        contentBlocks.canvas.updateCanvasModel(false).then(() => {
            let newItem = contentBlocks.getChild(newItemModel.id).content;
            if (!newItem.canSelect) {
                newItem = newItem.findClosestOfType(ContentBlockItem);
            }
            ds.selection.element = newItem;
        }).catch(err => {
            ShowWarningDialog({
                title: "Sorry, we aren't able to add this block",
                message: "There isn't enough room for this type of block",
            });
        });
    }
}

export function BuildContentBlockOptionsMenu(view, element) {
    let $menu = view.addControl({
        id: "contentBlockOptionsMenu",
        type: controls.POPUP_BUTTON,
        icon: "settings",
        showArrow: false,
        menuContents: close => {
            let $menu = $.grid();

            const hideDisabledControls = element.hideDisabledControls;
            const isStyleMenuEnabled = element.parentElement.isOverImage;

            $menu.append(controls.createIconDropdownMenu(this, {
                id: "textStyleMenu",
                label: "Style",
                model: element.model,
                property: "textStyle",
                menuClass: "icon-menu",
                enabled: isStyleMenuEnabled,
                transitionModel: false,
                markStylesAsDirty: true,
                items: [{
                    value: "white_text", label: "White", image: getStaticUrl("/images/ui/textstyles/text_white.svg")
                }, {
                    value: "white_text_with_shadow",
                    label: "White with Shadow",
                    image: getStaticUrl("/images/ui/textstyles/text_shadow.svg")
                }, {
                    value: "dark_text", label: "Dark", image: getStaticUrl("/images/ui/textstyles/text_dark.svg")
                }, {
                    value: "white_box",
                    label: "White Backdrop",
                    image: getStaticUrl("/images/ui/textstyles/backdrop_white.svg")
                }, {
                    value: "transparent_light_box",
                    label: "Transparent Backdrop",
                    image: getStaticUrl("/images/ui/textstyles/backdrop_transparent.svg")
                }, {
                    value: "transparent_dark_box",
                    label: "Dark Backdrop",
                    image: getStaticUrl("/images/ui/textstyles/backdrop_dark.svg")
                }],
                callback: () => {
                    // this.render();
                }

            }));

            const isPaddingMenuEnabled = element.model.textStyle != null && element.model.textStyle.contains("box");
            if (isPaddingMenuEnabled || !hideDisabledControls) {
                $menu.append(controls.createNumericStepper(this, {
                    label: "Padding",
                    model: element.model,
                    commitOnInput: false,
                    enabled: isPaddingMenuEnabled,
                    min: 0,
                    max: 200,
                    property: "backdropPadding",
                    transitionModel: false
                }));
            }

            $menu.append(controls.createPositionPicker(this, {
                label: "Position",
                value: element.parentElement?.textPosition || element.model.textPosition,
                callback: value => {
                    element.model.textAlign = null;
                    element.model.textPosition = value;
                    element.model.userPositionX = element.model.userPositionY = element.model.userWidth = null;

                    switch (value) {
                        case PositionType.LEFT:
                        case PositionType.BOTTOM_LEFT:
                        case PositionType.TOP_LEFT:
                            element.model.textAlign = HorizontalAlignType.LEFT;
                            break;
                        case PositionType.RIGHT:
                        case PositionType.BOTTOM_RIGHT:
                        case PositionType.TOP_RIGHT:
                            element.model.textAlign = HorizontalAlignType.RIGHT;
                            break;
                        default:
                            element.model.textAlign = HorizontalAlignType.CENTER;
                    }

                    element.canvas.updateCanvasModel(true)
                        .catch(() => {
                            ShowDialogAsync(BadFitDialog, {
                                title: "Sorry, we aren't able to fit all elements into the new layout",
                            });
                        });
                }
            }));

            $menu.append(controls.createNumericStepper(this, {
                type: controls.NUMERIC,
                label: "Vertical Gap",
                min: 5,
                max: 100,
                commitOnInput: false,
                model: element.model,
                property: "verticalGap",
                transitionModel: false
            }));

            return $menu;
        }
    });

    return $menu;
}

const ContentBlockFrameSelection = ElementSelection.extend({
    showSelectionBox: true,

    renderControls() {
        let items = _.map(this.element.contentBlocks.availableBlockTypes, type => {
            return { value: type, label: type, image: getStaticUrl(`/images/ui/contentblocktypes/${type}.svg`) };
        });

        if (this.element.parentElement.showAddBlockUI) {
            this.addControl({
                type: controls.POPUP_BUTTON,
                icon: "text_increase",
                menuClass: "icon-menu",
                showArrow: false,
                items: items,
                callback: type => {
                    AddContentBlock(this.element.contentBlocks, type, false);
                }
            });

            BuildContentBlockOptionsMenu(this, this.element.contentBlocks);
        }

        if (this.element.parentElement.canDelete) {
            this.createDeleteComponentWidget({
                action: () => {
                    this.element.model.blocks = null;
                    this.element.canvas.updateCanvasModel(true);
                }
            });
        }

        this.$resizeHandle = this.$el.addEl($.div("resize-handle control horizontal"));
        this.$resizeHandle.on("mousedown", event => this.handleResizeHorizontal(event));

        if (this.element.parentElement.canDragPosition) {
            let $dragWidget = this.createDragWidget(this, {
                position: WidgetPositionType.DRAG_HANDLE
            });
            $dragWidget.on("mousedown", event => this.handleDragStart(event));
        }
    },

    _layout() {
        // let selectionBounds = this.element.backdrop.bounds.multiply(this.canvasScale);
        let selectionBounds = this.getElementSelectionBounds();

        if (this.element.parentElement.canDragResize) {
            this.$resizeHandle.show();
            this.$resizeHandle.left(selectionBounds.width - 5).top(selectionBounds.height / 2 - 5);
        } else {
            this.$resizeHandle.hide();
        }
    },

    handleResizeHorizontal(event) {
        app.isDraggingItem = true;

        let containerElement = this.element.parentElement;

        ds.selection.element = this.element;
        ds.selection.rolloverElement = this.element;

        this.selectionLayer.hideWidgets();

        let $dragSelectionBox = this.selectionLayer.$el.addEl($.div().css({
            position: "absolute",
            background: themeColors.ui_blue
        }));

        this.element.isDragResizing = true;
        let elementBounds = this.getElementSelectionBounds();

        let mouseMoveHandledAt;
        $("body").on("mousemove.drag", event => {
            event.stopPropagation();

            // Making dragging smoother
            window.requestAnimationFrame(timestamp => {
                if (mouseMoveHandledAt === timestamp) {
                    return;
                }

                mouseMoveHandledAt = timestamp;

                let dragPt = Convert.ScreenToSelectionLayerCoordinates(event.pageX, event.pageY);
                let width;

                if (containerElement.textPosition == PositionType.CENTER) {
                    width = 2 * Math.abs(dragPt.x - elementBounds.centerH) / this.canvasScale;
                } else {
                    width = Math.abs(dragPt.x - elementBounds.left) / this.canvasScale;
                }

                this.model.userWidth = width;

                if (containerElement.canRefreshElement) {
                    containerElement.refreshElement();
                    $dragSelectionBox.setBounds(new geom.Rect(dragPt.x, 0, 1, this.selectionLayer.$el.height()));
                } else {
                    containerElement.canvas.refreshCanvas()
                        .then(() => {
                            $dragSelectionBox.setBounds(new geom.Rect(dragPt.x, 0, 1, this.selectionLayer.$el.height()));
                        });
                }
            });
        });

        $("body").on("mouseup.drag", event => {
            event.stopPropagation();
            $("body").off(".drag");
            app.isDraggingItem = false;
            this.element.isDragResizing = false;
            this.selectionLayer.showWidgets();
            $dragSelectionBox.remove();

            this.element.canvas.updateCanvasModel(false).then(() => {
                ds.selection.element = this.element;
            });
        });
    },

    handleDragStart(event) {
        app.isDraggingItem = true;

        let containerElement = this.element.parentElement;

        ds.selection.element = this.element;
        ds.selection.rolloverElement = this.element;

        this.selectionLayer.hideWidgets(this.$el.find(".resize-handle,.selection-box"));

        let containerBounds = containerElement.bounds;

        if (this.element.model.userWidth == null) {
            this.element.model.userWidth = this.element.bounds.width;
        }

        let mouseMoveHandledAt;
        $("body").on("mousemove.drag", event => {
            event.stopPropagation();

            // Making dragging smoother
            window.requestAnimationFrame(timestamp => {
                if (mouseMoveHandledAt === timestamp) {
                    return;
                }

                mouseMoveHandledAt = timestamp;

                let dragPt = Convert.ScreenToElementCoordinates(containerElement.canvas, containerElement, event.pageX, event.pageY);

                let elementBounds = new geom.Rect(dragPt.x, dragPt.y, this.element.bounds.width, this.element.bounds.height);

                if (elementBounds.left < 0) {
                    elementBounds.left = 0;
                }
                if (elementBounds.top < 0) {
                    elementBounds.top = 0;
                }
                if (elementBounds.right > containerBounds.width) {
                    elementBounds.left = containerBounds.width - elementBounds.width;
                }
                if (elementBounds.bottom > containerBounds.height) {
                    elementBounds.top = containerBounds.height - elementBounds.height;
                }

                this.model.userPositionX = elementBounds.x / containerBounds.width;
                this.model.userPositionY = elementBounds.y / containerBounds.height;

                if (containerElement.canRefreshElement) {
                    containerElement.refreshElement();
                    this.layout();
                } else {
                    containerElement.canvas.refreshCanvas()
                        .then(() => {
                            this.layout();
                        });
                }
            });
        });

        $("body").on("mouseup.drag", event => {
            event.stopPropagation();
            $("body").off(".drag");
            app.isDraggingItem = false;
            this.element.isDragResizing = false;
            this.selectionLayer.showWidgets();

            this.element.canvas.updateCanvasModel(false).then(() => {
                ds.selection.element = this.element;
            });
        });
    }
});

export const ContentBlockItemSelection = CollectionItemElementSelection.extend({
    showSelectionBox: true,
    captureMouseEvents: false,

    canDrag: function() {
        if (this.element.itemCount > 1) {
            return true;
        }
    },

    getDragOptions: function() {
        return {
            type: DragType.INSERT_VERTICAL,
            insertGap: this.element.parentElement.styles.verticalGap,
            transitionOnDrop: true,
        };
    },

    getDragAxis: function() {
        return "y";
    },

    getOffset() {
        return "top -40";
    },

    canDelete() {
        const contentBlockCollection = this.element.findClosestOfType(ContentBlockCollection);
        return contentBlockCollection.minItemCount < contentBlockCollection.itemElements.length;
    },

    renderControls: function() {
        // if (ds.selection.element !== this.element) {
        //     // selecting text so don't render
        //     return;
        // };

        let contentBlockCollection = this.element.findClosestOfType(ContentBlockCollection);

        let items = _.map(contentBlockCollection.availableBlockTypes, type => {
            return { value: type, label: type, image: getStaticUrl(`/images/ui/contentblocktypes/${type}.svg`) };
        });

        if (this.element.contentType.equalsAnyOf(ContentBlockType.BULLET_POINT)) {
            this.addControl({
                type: controls.POPUP_BUTTON,
                label: this.element.contentType,
                menuClass: "icon-menu",
                items: items,
                callback: type => {
                    ds.selection.element = null;
                    this.element.model.type = type;
                    this.element.canvas.updateCanvasModel(false).then(() => {
                        //ds.selection.element = this.element;
                    });
                }
            });
        }

        if (this.element.contentType == ContentBlockType.BULLET_POINT) {
            this.addControl({
                type: controls.POPUP_BUTTON,
                label: "List Style",
                items: [{
                    label: "Bullet",
                    value: ListStyleType.BULLET,
                    icon: "format_list_bulleted"
                }, {
                    label: "Numbered",
                    value: ListStyleType.NUMBERED,
                    icon: "pin"
                }, {
                    label: "Text",
                    value: ListStyleType.TEXT,
                    icon: "notes"
                }, {
                    label: "Check",
                    value: ListStyleType.CHECKBOX,
                    icon: "check"
                }, {
                    label: "Icon",
                    value: ListStyleType.ICON,
                    icon: "star"
                }],
                callback: value => {
                    contentBlockCollection.model.listStyle = value;
                    this.element.canvas.updateCanvasModel(false);
                }
            });
        }

        if (this.element.contentType == ContentBlockType.DIVIDER) {
            this.addControl({
                type: controls.POPUP_BUTTON,
                label: "Divider Style",
                property: "style",
                items: [{
                    label: "Spacer", value: "spacer"
                }, {
                    label: "Line", value: "line"
                }, {
                    label: "Dashed", value: "dashed"
                }, {
                    label: "Dotted", value: "dotted"
                }],
            });
            this.addControl({
                type: controls.POPUP_BUTTON,
                label: "Width",
                items: [{
                    label: "Full", value: "full"
                }, {
                    label: "Half", value: "half"
                }, {
                    label: "Short", value: "short"
                }],
                property: "dividerWidth"
            });
            // this.addControl({
            //     type: controls.NUMERIC,
            //     label: "Height",
            //     property: "dividerHeight",
            //     transitionModel: false
            // });

            createResizer({
                view: this,
                element: this.element,
                position: PositionType.BOTTOM,
                minSize: 5,
                maxSize: 100,
                handleType: "simple",
                showSelectionBox: true,
                scaleFromCenter: true,
                onResize: async value => {
                    this.element.model.blockHeight = value;
                    await this.element.canvas.refreshCanvas();
                }
            });
        }

        if (this.element.contentType == ContentBlockType.ICON) {
            CreateMediaMenu(this, this.element.content.content);

            this.addControl({
                type: controls.POPUP_BUTTON,
                icon: "filter_frames",
                showArrow: false,
                customMenuClass: "frame-popup",
                menuContents: closeMenu => {
                    let $menu = $.div();
                    renderReactRoot(ImageFrameMenu, {
                        onSelect: frame => {
                            this.element.model.frameType = frame;
                            this.element.markStylesAsDirty();
                            this.element.canvas.updateCanvasModel(false);
                            closeMenu();
                        }
                    }, $menu[0]);
                    return $menu;
                },
                transitionModel: false
            });

            this.addControl({
                type: controls.COLOR_PALETTE_PICKER,
                model: this.element.content.model,
                property: "color",
                showAuto: true,
                showBackgroundColors: this.element.model.frameType != "none",
                omitCurrentBackgroundColor: true,
                showDecorationStyles: () => (this.element.content.allowDecorationStyles),
            });

            createResizer({
                view: this,
                element: this.element,
                position: PositionType.RIGHT,
                minSize: 20,
                maxSize: 500,
                handleType: "simple",
                scaleFromCenter: true,
                showSelectionBox: true,
                onResize: async value => {
                    this.element.model.contentSize = value;
                    await this.element.canvas.refreshCanvas();
                }
            });
        }
    },
});

const ContentBlockIconSelection = ElementSelection.extend({

    getOffset() {
        return 10;
    },

    renderControls: function() {
        CreateMediaMenu(this, this.element);
        CreateImageFrameMenu(this, this.element);

        CreateColorChit(this, this.element, { showBackgroundColors: this.element.model.frameType != "none" });

        this.renderResizeHandle();
    },

    _layout: function() {
        let selectionBounds = this.getElementSelectionBounds();
        this.$resizeHandle.left(selectionBounds.width - 5).top(selectionBounds.height / 2 - 5);
    },

    renderResizeHandle() {
        this.$resizeHandle = this.$el.addEl($.div("resize-handle control horizontal"));
        this.$resizeHandle.on("mousedown", event => this.handleResizeHorizontal(event));
    },

    handleResizeHorizontal(event) {
        app.isDraggingItem = true;

        ds.selection.element = this.element;
        ds.selection.rolloverElement = this.element;

        this.selectionLayer.hideWidgets(this.$resizeHandle);

        this.element.isDragResizing = true;
        let elementBounds = this.getElementSelectionBounds();
        let startX = event.pageX;

        $("body").on("mousemove.drag", event => {
            event.stopPropagation();
            let dragX = event.pageX - this.selectionLayer.$el.offset().left;

            let width = Math.abs(dragX - elementBounds.centerH) * 2;

            width /= this.element.canvas.canvasScale;

            this.element.model.contentSize = Math.clamp(width, this.element.minWidth || 100, this.element.maxWidth || 1280);
            this.element.canvas.refreshCanvas({ suppressRefreshCanvasEvent: true }).then(() => {
                this.layout();
            });
        });

        $("body").on("mouseup.drag", event => {
            event.stopPropagation();
            $("body").off(".drag");
            app.isDraggingItem = false;
            this.element.isDragResizing = false;
            this.selectionLayer.showWidgets();

            this.element.canvas.updateCanvasModel(false).then(() => {
                ds.selection.element = this.element;
            });
        });
    },
});

const ContentBlockTextElementSelection = TextElementSelection.extend({

    showSelectionBox: false,

    renderControls: function() {
        this.setupTextEditor();

        let container = this.element.findClosestOfType(ContentBlockCollection);

        this.listenTo(this.textEditor, TextEditorEvent.ENTER_KEY, () => {
            let position = this.textEditor.selectionStart;
            let text = this.textEditor.text;
            let currentItemText = text.slice(0, position);
            let newItemText = text.slice(position);
            let styles = TextEditor.convertStyleMapToStyleRanges(this.textEditor.styleMap.slice(position));

            // note: this changes the text model to currentItemText, but doesn't adjust the corresponding styles.
            // this means that the styles in the model are wrong until a user action calls insertString later.
            this.textEditor.text = currentItemText;
            this.textEditor.updateModel().then(() => {
                let newType;
                if (newItemText.length) {
                    newType = this.model.type;
                } else {
                    newType = ContentBlockType.BODY;
                }

                let newItem = container.addItem({
                    type: newType,
                    content: { text: newItemText, styles }
                }, this.element.itemIndex + 1);

                container.canvas.updateCanvasModel(false).then(() => {
                    ds.selection.element = container.elements[newItem.id].content;
                }).catch(err => {
                    ShowWarningDialog({
                        title: "Sorry, we were unable to make this change",
                        message: err.message,
                    });
                });
            });
        });

        this.listenTo(this.textEditor, TextEditorEvent.BACKSPACE_ON_BEGINNING, () => {
            let prevItem = container.itemElements[this.element.itemIndex - 1];
            if (prevItem) {
                let selection = prevItem.model.content.text.length;
                prevItem.model.content.text = prevItem.model.content.text + this.textEditor.text;
                prevItem.model.content.styles = prevItem.model.content.styles.concat(TextEditor.convertStyleMapToStyleRanges(this.textEditor.styleMap, selection));
                container.deleteItem(this.model.id);

                container.canvas.updateCanvasModel(false).then(() => {
                    ds.selection.element = container.elements[prevItem.id].content;
                    ds.selection.element.overlay.textEditor.setSelection(selection);
                }).catch(err => {
                    ShowWarningDialog({
                        title: "Sorry, we were unable to make this change",
                        message: err.message,
                    });
                });
            }
        });

        this.listenTo(this.textEditor, TextEditorEvent.BACKSPACE_ON_BLANK, () => {
            let prevItem = container.itemElements[this.element.itemIndex - 1];
            if (prevItem) {
                container.deleteItem(this.model.id);

                container.canvas.updateCanvasModel(false).then(() => {
                    ds.selection.element = container.elements[prevItem.id].content;
                    let view = app.mainView.editorView.selectionLayer.selectionViews.find(view => view.textEditor);
                    if (view) {
                        view.textEditor.setSelectionToEnd();
                    }
                }).catch(err => {
                    ShowWarningDialog({
                        title: "Sorry, we were unable to make this change",
                        message: err.message,
                    });
                });
            }
        });
    },

    // cleanUp: function () {
    //     if (this.element.findClosestOfType(CollectionElement).itemElements.length > 1 && (!this.element.model.content || this.element.model.content == "" || this.element.model.content.text == "")) {
    //         this.element.findClosestOfType(CollectionElement).deleteItem(this.element.parentElement.id);
    //         _.defer(() => {
    //             this.element.canvas.refreshCanvasAutoRevert({transition: true}).then(() => {
    //                 TextElementSelection.prototype.cleanUp.apply(this, arguments);
    //             });
    //         });
    //     } else {
    //         TextElementSelection.prototype.cleanUp.apply(this, arguments);
    //     }
    // }

});

const ContentBlockBulletPointSelection = ElementSelection.extend({

    renderControls() {
        this.addControl({
            type: controls.POPUP_BUTTON,
            label: "List Style",
            items: [{
                label: "Bullet",
                value: ListStyleType.BULLET,
                icon: "format_list_bulleted"
            }, {
                label: "Numbered",
                value: ListStyleType.NUMBERED,
                icon: "pin"
            }, {
                label: "Text",
                value: ListStyleType.TEXT,
                icon: "notes"
            }, {
                label: "Check",
                value: ListStyleType.CHECKBOX,
                icon: "check"
            }],
            callback: value => {
                this.element.model.listStyle = value;
                this.element.canvas.updateCanvasModel(true);
            }
        });
    }

});

// const ContentBlockListSelection = TextListSelection.extend({
//     showSelectionBox: true,
//
//     renderControls: function() {
//         this.addControl({
//             type: controls.POPUP_BUTTON,
//             label: "List Style",
//             items: [{
//                 label: "Bullet",
//                 value: ListStyleType.BULLET,
//                 icon: "format_list_bulleted"
//             }, {
//                 label: "Numbered",
//                 value: ListStyleType.NUMBERED,
//                 icon: "pin"
//             }, {
//                 label: "Text",
//                 value: ListStyleType.TEXT,
//                 icon: "notes"
//             }, {
//                 label: "Check",
//                 value: ListStyleType.CHECKBOX,
//                 icon: "check"
//             }],
//             callback: value => {
//                 this.element.model.listStyle = value;
//                 this.element.canvas.updateCanvasModel(true);
//             }
//         });
//
//         // this.addControl({
//         //     type: controls.POPUP_BUTTON,
//         //     label: "Bullet Style",
//         //     menuContents: closeMenu => {
//         //         // NOTE: The numbered style is broken so we are not including it in the initial release.
//         //         let $menu = $.div("content-block-type-menu");
//         //
//         //         let $paragraph = $menu.addEl($.div("block-type"));
//         //         $paragraph.append($.img(getStaticUrl(`/images/ui/list_paragraph.svg`)));
//         //         $paragraph.append($.label("No Bullets"));
//         //         $paragraph.on("click", () => {
//         //             closeMenu();
//         //             this.element.model.listStyle = "no_bullets";
//         //             this.element.canvas.updateCanvasModel(false).then(() => {
//         //                 ds.selection.element = null;
//         //             });
//         //         });
//         //
//         //         let $bullets = $menu.addEl($.div("block-type"));
//         //         $bullets.append($.img(getStaticUrl(`/images/ui/list_bullets.svg`)));
//         //         $bullets.append($.label("Bullets"));
//         //         $bullets.on("click", () => {
//         //             closeMenu();
//         //             this.element.model.listStyle = "bullets";
//         //             this.element.canvas.updateCanvasModel(false).then(() => {
//         //                 ds.selection.element = null;
//         //             });
//         //         });
//         //
//         //         let $numbered = $menu.addEl($.div("block-type"));
//         //         $numbered.append($.img(getStaticUrl(`/images/ui/list_numbered.svg`)));
//         //         $numbered.append($.label("Numbered"));
//         //         $numbered.on("click", () => {
//         //             closeMenu();
//         //             this.element.model.listStyle = "numbered";
//         //             this.element.canvas.updateCanvasModel(false).then(() => {
//         //                 ds.selection.element = null;
//         //             });
//         //         });
//         //
//         //         return $menu;
//         //     },
//         //     property: "listStyle"
//         // });
//     }
//
// });
//
// const ContentBlockListRollover = ElementRollover.extend({});

// const ContentBlockSpacerSelection = ElementSelection.extend({
//
//     renderControls() {
//
//     }
//
// });
export const editors = {
    ContentBlockFrameSelection,
    ContentBlockItemSelection,
    ContentBlockIconSelection,
    ContentBlockTextElementSelection,
    // ContentBlockListSelection,
    // ContentBlockListRollover,
    ContentBlockBulletPointSelection,
    // ContentBlockFrameOptionsMenu
};
