import { controls } from "js/editor/ui";
import { _, $ } from "js/vendor";
import { Key } from "js/core/utilities/keys";
import { ShowDialogAsync } from "js/react/components/Dialogs/BaseDialog";
import BadFitDialog from "js/react/components/Dialogs/BadFitDialog";
import renderReactRoot from "js/react/renderReactRoot";
import { ListStyleType } from "common/constants";

import { CollectionElementSelection, CollectionItemElementSelection } from "../CollectionElementEditor";
import { ImageFrameMenu } from "../EditorComponents/ImageFrameMenu";

const ProductScreenshotSelection = CollectionElementSelection.extend({
    renderControls: function() {
        this.addControl({
            type: controls.BUTTON,
            label: this.getAddItemLabel(),
            icon: "add_circle",
            callback: () => {
                if (this.element.list) {
                    this.addItem(newItem => {
                        const path = `list1.elements.${newItem.id}`;
                        this.selectNewItemText(path);
                    });
                } else {
                    this.element.model.items = [{}];
                    this.element.canvas.updateCanvasModel(true).then(() => {
                        const path = `list1.elements.${this.element.list.itemElements[0].id}`;
                        this.selectNewItemText(path);
                    });
                }
            }
        });

        this.addControl({
            type: controls.POPUP_BUTTON,
            icon: "filter_frames",
            label: "Device",
            showArrow: true,
            customMenuClass: "frame-popup",
            menuContents: closeMenu => {
                let $menu = $.div();
                renderReactRoot(ImageFrameMenu, {
                    showDecorativeFrames: false,
                    onSelect: frame => {
                        this.element.model.frameType = frame;
                        this.element.markStylesAsDirty();
                        this.element.canvas.updateCanvasModel(false);
                        closeMenu();
                    }
                }, $menu[0]);
                return $menu;
            },
            transitionModel: false
        });

        this.addControl({
            type: controls.POPUP_BUTTON,
            label: "List Style",
            property: "listStyle",
            markStylesAsDirty: true,
            items: [{
                label: "Icon",
                value: "icons", // legacy style for product screenshot
                icon: "star"
            }, {
                label: "Bullet",
                value: ListStyleType.BULLET,
                icon: "format_list_bulleted"
            }, {
                label: "Numbered",
                value: ListStyleType.NUMBERED,
                icon: "pin"
            }, {
                label: "Text",
                value: ListStyleType.TEXT,
                icon: "notes"
            }, {
                label: "Check",
                value: ListStyleType.CHECKBOX,
                icon: "check"
            }]
        });
    },

    addItem(thenHandler) {
        let newItem = this.element.list.addItem({});
        this.element.canvas.updateCanvasModel(true).then(thenHandler.bind(this, newItem)).catch(err => {
            ShowDialogAsync(BadFitDialog, {
                title: "Sorry, we aren't able to fit another item to this layout",
            });
        });
    },

    _handleKeyboardShortcut(event) {
        switch (event.which) {
            case Key.KEY_D:
                this.addItem(() => { });
        }
    }
});

const ProductScreenshotTextListItemSelection = CollectionItemElementSelection.extend({});

export const editors = {
    ProductScreenshotSelection,
    ProductScreenshotTextListItemSelection
};
