import React, { Component, Fragment } from "reactn";
import FountainPenTip from "mdi-material-ui/FountainPenTip";
import { Icon, IconButton, Tooltip } from "@material-ui/core";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";

import { AuthoringBlockType, TextStyleType, VerticalAlignType, HorizontalAlignType, AuthoringShapeType, AuthoringElementType } from "common/constants";
import { _ } from "js/vendor";
import { AssetType } from "common/constants";
import * as geom from "js/core/utilities/geom";
import { app } from "js/namespaces";
import { ds } from "js/core/models/dataService";
import { ShowDialog } from "js/react/components/Dialogs/BaseDialog";
import { AddAssetsContainer } from "js/react/views/AddAssets";
import { UIDraggable, UIMenuThumbnail, UIMenuThumbnailGrid } from "js/editor/selection/components/StyledComponents";
import { Gap20 } from "js/react/components/Gap";
import { FlexBox } from "js/react/components/LayoutGrid";
import { dialogTheme } from "js/react/materialThemeOverrides";
import { trackActivity } from "js/core/utilities/utilities";
import FlipToFront from "mdi-material-ui/FlipToFront";
import FlipToBack from "mdi-material-ui/FlipToBack";
import AlignHorizontalLeft from "mdi-material-ui/AlignHorizontalLeft";
import AlignHorizontalCenter from "mdi-material-ui/AlignHorizontalCenter";
import Group from "mdi-material-ui/Group";
import Ungroup from "mdi-material-ui/Ungroup";
import AlignHorizontalRight from "mdi-material-ui/AlignHorizontalRight";
import AlignVerticalTop from "mdi-material-ui/AlignVerticalTop";
import AlignVerticalCenter from "mdi-material-ui/AlignVerticalCenter";
import AlignVerticalBottom from "mdi-material-ui/AlignVerticalBottom";
import DistributeHorizontalCenter from "mdi-material-ui/DistributeHorizontalCenter";
import DistributeVerticalCenter from "mdi-material-ui/DistributeVerticalCenter";
import { PopupMenu } from "js/react/components/PopupMenu";

import { ControlBar, ControlBarDivider, ControlBarGroup } from "../EditorComponents/ControlBar";
import { ConvertScreenToSelectionLayer } from "./Editors/AuthoringHelpers";
export class AuthoringControlBar extends Component {
    constructor(props) {
        super(props);

        this.controlBarRef = React.createRef();
    }

    get bounds() {
        if (this.controlBarRef.current) {
            return this.controlBarRef.current.gridBounds;
        }

        return null;
    }

    getDefaultModel = (type, style) => {
        switch (type) {
            case AuthoringElementType.TEXT:
                let defaultBlock;
                if (style == AuthoringBlockType.EQUATION || style == AuthoringBlockType.CODE) {
                    defaultBlock = {
                        id: _.uniqueId("block"),
                        type: style
                    };
                } else {
                    defaultBlock = {
                        id: _.uniqueId("block"),
                        type: AuthoringBlockType.TEXT,
                        textStyle: style,
                        html: ""
                    };
                }

                return {
                    type: AuthoringElementType.SHAPE,
                    width: 400,
                    height: 150,
                    shape: AuthoringShapeType.RECT,
                    fill: "none",
                    stroke: "none",
                    textAlign: HorizontalAlignType.LEFT,
                    verticalAlign: VerticalAlignType.TOP,
                    fitToText: true,
                    blocks: [defaultBlock]
                };
            case AuthoringElementType.SHAPE:
                return {
                    type: AuthoringElementType.SHAPE,
                    shape: style,
                    width: 150,
                    height: 150
                };
            case AuthoringElementType.COMPONENT:
                return {
                    type: AuthoringElementType.COMPONENT,
                    componentType: style,
                    // Give components bigger default size
                    width: 400,
                    height: 200,
                    // Will force the component to use this object
                    // for its model
                    element: {
                    }
                };
            default:
                return {
                    type,
                    width: 150,
                    height: 150
                };
        }
    }

    createElement = async (type, style) => {
        const {
            setDragCreateModel,
            containerElement,
            refreshCanvasAndSaveChanges,
            selectElement,
        } = this.props;

        const model = this.getDefaultModel(type, style);

        switch (model.type) {
            case AuthoringElementType.CONTENT:
                ShowDialog(AddAssetsContainer, {
                    assetType: AssetType.IMAGE,
                    workspaceId: ds.selection.presentation.getWorkspaceId(),
                    callback: assetModel => {
                        const {
                            content_type,
                            content_value,
                            content_url,
                            assetProps,
                            assetProps: {
                                originalSize,
                            },
                            previewUrl
                        } = assetModel;

                        let defaultSize;
                        if (content_type === AssetType.ICON) {
                            defaultSize = new geom.Size(100, 100);
                        } else {
                            const width = Math.min(originalSize.width, 400);
                            const scale = width / originalSize.width;
                            const height = originalSize.height * scale;
                            defaultSize = new geom.Size(width, height);
                        }

                        const model = {
                            type: AuthoringElementType.CONTENT,
                            // Placing in the center of canvas
                            x: containerElement.bounds.width / 2 - defaultSize.width / 2,
                            y: containerElement.bounds.height / 2 - defaultSize.height / 2,
                            width: defaultSize.width,
                            height: defaultSize.height,
                            element: {
                                content_value,
                                content_type,
                                content_url,
                                assetProps,
                                previewUrl
                            }
                        };

                        if (content_type === AssetType.ICON) {
                            model.element.color = "auto";
                        }

                        const { id } = containerElement.addItem(model);

                        refreshCanvasAndSaveChanges()
                            .then(() => {
                                selectElement(containerElement.getChild(id));
                            });
                    }
                });
                break;
            default:
                setDragCreateModel(model);
        }

        trackActivity("Slide", "AuthoringElementAdded", null, null, { type });
    }

    handleDragStart = () => {
        const { clearSelection } = this.props;
        clearSelection();
    }

    handleDragDrop = async (event, type, style) => {
        const { containerElement, selectElement, refreshCanvasAndSaveChanges } = this.props;

        const model = this.getDefaultModel(type, style);

        const dropPoint = ConvertScreenToSelectionLayer(event.pageX, event.pageY).multiply(1 / app.currentCanvas.getScale());

        // Fitting in canvas
        const elementBounds = new geom.Rect(dropPoint.x - model.width / 2, dropPoint.y - model.height / 2, model.width, model.height)
            .fitInRect(containerElement.bounds.zeroOffset());
        model.x = elementBounds.left;
        model.y = elementBounds.top;

        const { id } = containerElement.addItem(model);

        refreshCanvasAndSaveChanges()
            .then(() => {
                selectElement(containerElement.getChild(id));
            });
    }

    handleAction = (event, action) => {
        event.stopPropagation();
        this.props.onAction(action);
    }

    render() {
        const { dragCreate, canGroup, canUngroup, snapToGrid, toggleSnapToGrid, onAction, selection } = this.props;

        const shapeTypes = [AuthoringShapeType.RECT, AuthoringShapeType.ELLIPSE, AuthoringShapeType.ARROW, AuthoringShapeType.STAR, AuthoringShapeType.CHEVRON_START, AuthoringShapeType.CHEVRON, AuthoringShapeType.POLYGON, AuthoringShapeType.DIAMOND];
        const textStyles = [TextStyleType.HEADING, TextStyleType.TITLE, TextStyleType.BODY, TextStyleType.CAPTION, TextStyleType.BULLET_LIST, TextStyleType.NUMBERED_LIST, AuthoringBlockType.CODE, AuthoringBlockType.EQUATION];

        if (dragCreate) {
            let dragType = dragCreate.type;
            if (dragCreate.type == AuthoringElementType.SHAPE && dragCreate.blocks?.length > 0) {
                dragType = "Text Box";
            } else if (dragCreate.type == AuthoringElementType.COMPONENT) {
                switch (dragCreate.componentType) {
                    case "TableFrame":
                        dragType = "Table";
                        break;
                    case "Dashboard":
                        dragType = "Chart";
                        break;
                }
            }
            return (
                <MuiThemeProvider theme={dialogTheme}>
                    <ControlBar ref={this.controlBarRef}>
                        <ControlBarGroup style={{ padding: 0 }}>
                            <FlexBox fillHeight style={{ background: "orangered" }}>
                                <Gap20 />
                                <label>Drag to create {dragType}</label>
                                <Gap20 />
                            </FlexBox>
                        </ControlBarGroup>
                    </ControlBar>
                </MuiThemeProvider>
            );
        }

        return (
            <MuiThemeProvider theme={dialogTheme}>
                <ControlBar id="authoring-control-bar" ref={this.controlBarRef}>
                    <ControlBarGroup authoring>
                        <PopupMenu icon="text_fields" tooltip="Add Text">
                            <UIMenuThumbnailGrid>
                                {textStyles.map((style, index) => (
                                    <UIDraggable key={index} onDragStart={this.handleDragStart} onDragComplete={event => this.handleDragDrop(event, AuthoringElementType.TEXT, style)}>
                                        <UIMenuThumbnail
                                            key={style}
                                            value={style}
                                            label={style}
                                            path={`/images/ui/contentblocktypes/${style}.svg`}
                                            onClick={() => this.createElement(AuthoringElementType.TEXT, style)}
                                        />
                                    </UIDraggable>
                                ))}
                            </UIMenuThumbnailGrid>
                        </PopupMenu>
                        <PopupMenu icon="crop_landscape" tooltip="Add Shape">
                            <UIMenuThumbnailGrid columns={8} size={75}>
                                {shapeTypes.map((style, index) => (
                                    <UIDraggable key={index} onDragComplete={event => this.handleDragDrop(event, AuthoringElementType.SHAPE, style)}>
                                        <UIMenuThumbnail
                                            key={style}
                                            value={style}
                                            path={`/images/authoring/${style}.svg`}
                                            onClick={() => this.createElement(AuthoringElementType.SHAPE, style)}
                                        />
                                    </UIDraggable>
                                ))}
                            </UIMenuThumbnailGrid>
                        </PopupMenu>
                        <Tooltip title="Add Image" arrow>
                            <IconButton onClick={() => this.createElement(AuthoringElementType.CONTENT)}>
                                <Icon>photo_camera</Icon>
                            </IconButton>
                        </Tooltip>
                        {/*<Tooltip title="Add Video" arrow>*/}
                        {/*    <IconButton onClick={() => this.createElement(AuthoringElementType.VIDEO)}>*/}
                        {/*        <Icon>ondemand_video</Icon>*/}
                        {/*    </IconButton>*/}
                        {/*</Tooltip>*/}
                        <Tooltip title="Draw Path" arrow>
                            <IconButton onClick={() => this.createElement(AuthoringElementType.PATH)}>
                                <FountainPenTip />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Add Table" arrow>
                            <IconButton onClick={() => this.createElement(AuthoringElementType.COMPONENT, "TableFrame")}>
                                <Icon>grid_on</Icon>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Add Chart" arrow>
                            <IconButton onClick={() => this.createElement(AuthoringElementType.COMPONENT, "Dashboard")}>
                                <Icon>bar_chart</Icon>
                            </IconButton>
                        </Tooltip>
                    </ControlBarGroup>

                    {selection.length > 0 &&
                    <ControlBarGroup light>
                        {canGroup && (
                            <Tooltip title="Group Layers" arrow>
                                <IconButton onClick={event => this.handleAction(event, "group")}>
                                    <Group />
                                </IconButton>
                            </Tooltip>
                        )}
                        {canUngroup && (
                            <Tooltip title="Ungroup Layers" arrow>
                                <IconButton onClick={event => this.handleAction(event, "ungroup")}>
                                    <Ungroup/>
                                </IconButton>
                            </Tooltip>
                        )}

                        <Tooltip title="Bring to Front" arrow>
                            <IconButton onClick={event => this.handleAction(event, "bringToFront")}>
                                <FlipToFront/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Send to Back" arrow>
                            <IconButton onClick={event => this.handleAction(event, "sendToBack")}>
                                <FlipToBack/>
                            </IconButton>
                        </Tooltip>
                        {selection.length > 1 &&
                            <Fragment>
                                <ControlBarDivider/>
                                <Tooltip title="Align Left" arrow>
                                    <IconButton onClick={event => this.handleAction(event, "align-left")}>
                                        <AlignHorizontalLeft/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Align Center" arrow>
                                    <IconButton onClick={event => this.handleAction(event, "align-center")}>
                                        <AlignHorizontalCenter/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Align Right" arrow>
                                    <IconButton onClick={event => this.handleAction(event, "align-right")}>
                                        <AlignHorizontalRight/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Align Top" arrow>
                                    <IconButton onClick={event => this.handleAction(event, "align-top")}>
                                        <AlignVerticalTop/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Align Middle" arrow>
                                    <IconButton onClick={event => this.handleAction(event, "align-middle")}>
                                        <AlignVerticalCenter/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Align Bottom" arrow>
                                    <IconButton onClick={event => this.handleAction(event, "align-bottom")}>
                                        <AlignVerticalBottom/>
                                    </IconButton>
                                </Tooltip>
                                <ControlBarDivider/>
                                <Tooltip title="Distribute Horizontally" arrow>
                                    <IconButton onClick={event => this.handleAction(event, "distribute-horizontal")}>
                                        <DistributeHorizontalCenter/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Distribute Vertically" arrow>
                                    <IconButton onClick={event => this.handleAction(event, "distribute-vertical")}>
                                        <DistributeVerticalCenter/>
                                    </IconButton>
                                </Tooltip>
                            </Fragment>
                        }
                    </ControlBarGroup>
                    }

                    {/*<ControlBarGroup>*/}
                    {/*    <PopupMenu icon="settings" tooltip="Canvas Options">*/}
                    {/*        <PopupMenuPaddedContainer>*/}
                    {/*            <LabeledContainer icon="grid_4x4" label="Snap To Grid">*/}
                    {/*                <Switch*/}
                    {/*                    name="snapToGrid"*/}
                    {/*                    color="primary"*/}
                    {/*                    checked={snapToGrid}*/}
                    {/*                    onChange={toggleSnapToGrid}*/}
                    {/*                />*/}
                    {/*            </LabeledContainer>*/}
                    {/*        </PopupMenuPaddedContainer>*/}
                    {/*    </PopupMenu>*/}
                    {/*</ControlBarGroup>*/}
                </ControlBar>
            </MuiThemeProvider>
        );
    }
}
