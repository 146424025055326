import * as geom from "js/core/utilities/geom";
import { HorizontalAlignType } from "common/constants";
import { _ } from "js/vendor";

import { CollectionElement } from "../../base/CollectionElement";
import ConnectorItemLabel from "./ConnectorItemLabel";
import { connectorBoldValues } from "./ConnectorItem";

export default class ConnectorItemLabels extends CollectionElement {
    get collectionPropertyName() {
        return "labels";
    }

    getChildItemType(itemModel) {
        return ConnectorItemLabel;
    }

    get minItemCount() {
        return 0;
    }

    get maxItemCount() {
        return 3;
    }

    _calcProps(props, options) {
        const { size, children } = props;
        const { drawPercentage } = options;

        const path = options.path;

        for (const label of this.itemElements) {
            const pointOnLine = path.getPointAt(label.position);
            const segment = path.getSegmentForPoint(pointOnLine);

            const isVerticalSegment = segment && segment.isVertical();

            if (isVerticalSegment) {
                switch (label.offset) {
                    case -1:
                        label.styles.textAlign = HorizontalAlignType.RIGHT;
                        break;
                    case 0:
                        label.styles.textAlign = HorizontalAlignType.CENTER;
                        break;
                    case 1:
                        label.styles.textAlign = HorizontalAlignType.LEFT;
                        break;
                }
            } else {
                label.styles.textAlign = HorizontalAlignType.CENTER;
            }

            if (label.position > drawPercentage) {
                label.options.preventRender = true;
            } else {
                // Explicitly setting to false to correctly render it after refreshElement() runs
                // which don't reset options
                label.options.preventRender = false;
            }

            const labelProps = label.calcProps(size);
            const labelSize = labelProps.size;

            let connectorStrokeWidth = connectorBoldValues.strokeWidth;
            let lineWeight = this.parentElement.model.lineWeight || 2;
            if (lineWeight !== "bold") {
                connectorStrokeWidth = lineWeight;
            }

            let connectorOffset = connectorStrokeWidth / 2;

            let labelOffset;
            if (isVerticalSegment) {
                if (label.offset == -1) {
                    labelOffset = new geom.Point(-labelSize.width - connectorOffset, -labelSize.height / 2);
                } else if (label.offset == 1) {
                    labelOffset = new geom.Point(connectorOffset, -labelSize.height / 2);
                } else {
                    labelOffset = new geom.Point(-labelSize.width / 2, -labelSize.height / 2);
                }
            } else {
                if (label.offset == -1) {
                    labelOffset = new geom.Point(-labelSize.width / 2, -labelSize.height - connectorOffset);
                } else if (label.offset == 1) {
                    labelOffset = new geom.Point(-labelSize.width / 2, connectorOffset);
                } else {
                    labelOffset = new geom.Point(-labelSize.width / 2, -labelSize.height / 2);
                }
            }

            labelProps.bounds = new geom.Rect(pointOnLine.offset(labelOffset), labelSize);
        }

        return { size };
    }
}
