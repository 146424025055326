import { controls, Dialog, Tab } from "js/editor/ui";
import { _, $ } from "js/vendor";

import { ElementOptionsMenu, ElementSelection } from "../BaseElementEditor";

function updateWordCloud(element) {
    // Setting shouldRegen and letting the element to recalc itself
    element.shouldRegen = true;
    element.canvas.refreshCanvas();
}

const WordCloudElementSelection = ElementSelection.extend({
    setupElement() {
    },

    renderControls: function() {
        this.addControl({
            type: controls.BUTTON,
            label: "Set Words...",
            callback: () => {
                var dlg = new WordCloudDialog({
                    element: this.element
                });
                dlg.show({ closeOnBackgroundClick: false });
            }
        });

        this.addControl({
            type: controls.BUTTON,
            label: "Randomize",
            icon: "loop",
            callback: () => {
                updateWordCloud(this.element);
            }
        });
    },

});

const WordCloudElementOptionsMenu = ElementOptionsMenu.extend({
    renderControls: function() {
        this.addControl({
            type: controls.DROPDOWN_MENU,
            label: "Layout",
            items: [{ label: "Straight", value: "straight" }, {
                label: "Right Angles",
                value: "right_angles"
            }, { label: "Rotated", value: "rotated" }],
            value: this.element.model.style,
            callback: value => {
                this.element.model.style = value;
                updateWordCloud(this.element);
            }
        });

        this.addControl({
            type: controls.DROPDOWN_MENU,
            label: "Sort",
            items: [{ label: "Size", value: "size" }, { label: "Random", value: "random" }],
            value: this.element.model.sort,
            callback: value => {
                this.element.model.sort = value;
                updateWordCloud(this.element);
            }
        });

        this.addControl({
            type: controls.DROPDOWN_MENU,
            label: "Font",
            value: this.element.fontType,
            items: [{
                value: "block", label: "Block Font"
            }, {
                value: "theme", label: "Use Theme Font"
            }],
            callback: value => {
                this.element.model.font = value;
                updateWordCloud(this.element);
            }
        });

        this.addControl({
            type: controls.DROPDOWN_MENU,
            label: "Scale",
            items: [{
                value: 10, label: "Tiny"
            }, {
                value: 15, label: "Small"
            }, {
                value: 20, label: "Medium"
            }, {
                value: 25, label: "Large"
            }],
            value: this.element.model.scale,
            callback: value => {
                this.element.model.scale = value;
                updateWordCloud(this.element);
            }
        });

        this.addControl({
            type: controls.DROPDOWN_MENU,
            label: "Spacing",
            items: [{
                value: 1, label: "Tight"
            }, {
                value: 5, label: "Medium"
            }, {
                value: 8, label: "Wide"
            }],
            value: this.element.spacing,
            callback: value => {
                this.element.model.spacing = value;
                updateWordCloud(this.element);
            }
        });

        this.addControl({
            type: controls.TOGGLE,
            label: "Invert Colors",
            property: "invertColors",
            enabled: this.element.canvas.getBackgroundColor().isColor == false
        });
    }
});

const WordCloudDialog = Dialog.extend({
    className: "wordCloudDialog",
    lockSlide: true,

    getTitle: function() {
        return "Word Cloud";
    },

    setupDialog: function(options) {
        this.element = options.element;
    },

    renderContents: function($contents) {
        // $contents.append($.textarea("Enter your text").val(this.element.model.get("text")));

        $contents.addEl($.div("instructions", "Type the words you'd like to appear in your WordCloud into each size bucket. You can use spaces or commas between each word and put multiple words together with quotes."));
        $contents.addEl($.div("hint", "HINT: the more words you enter the better your WordCloud will look!"));

        let wordGroups = _.groupBy(this.element.wordList, item => item.weight);
        let { wordInputs = [] } = this.element.model;

        let labels = ["Small", "", "", "", "Large"];
        let weights = [1, 2, 3, 4, 5];

        for (let weight of weights) {
            let $group = $contents.addEl($.div("wordgroup").data("weight", weight));

            // $group.addEl($.label("Size " + weight));
            $group.addEl($.label(labels[weight - 1]));
            let $text = $group.addEl($.div("words"));
            $text.attr("contenteditable", true);

            // find the previously input text - if none is detected
            // then just generate it from the word groups
            let text = wordInputs[weight - 1];
            if (!text) {
                text = _.join(_.map(wordGroups[weight], item => {
                    return item.word.indexOf(" ") > -1 ? '"' + item.word + '"' : item.word;
                }), " ");
            }

            // set the field input
            $text.text(text);
        }

        this.renderButtons($contents);
    },

    onAcceptDialog: function() {
        let wordList = [];

        // keep track of the original input order
        this.element.model.wordInputs = [];

        for (let wordGroup of this.$el.find(".wordgroup")) {
            let weight = $(wordGroup).data("weight");

            let text = $(wordGroup).find(".words").text();

            // save the text as-is to make sure the order doesn't
            // change between editing the word cloud
            this.element.model.wordInputs.push(text);

            // create the word cloud info
            let words = [];
            const regex = /[^,\s"']+|"([^"]*)"|'([^']*)'/g;
            let m;
            while ((m = regex.exec(text)) !== null) {
                words.push(m[0]);
            }

            for (let word of words) {
                wordList.push({
                    word: word,
                    weight: weight,
                    layout: {}
                });
            }
        }

        this.element.wordList = wordList;
        updateWordCloud(this.element);
        this.close();
    },

});

const WordCloudDialogTabWords = Tab.extend({
    getTitle: function() {
        return "By Words";
    },

    initialize: function(options) {
        this.element = options.element;
    },

    render: function() {
        let wordGroups = _.groupBy(this.element.wordList, item => item.weight);

        for (let weight of Object.keys(wordGroups).sort()) {
            let $group = this.$el.addEl($.div("wordgroup").data("weight", weight));
            $group.addEl($.label("Weight " + weight));
            let $text = $group.addEl($.div("words"));
            $text.attr("contenteditable", true);
            $text.text(_.join(_.map(wordGroups[weight], item => item.word), " "));
        }

        return this;
    }
});

const WordCloudDialogTabText = Tab.extend({
    getTitle: function() {
        return "By Paragraphs";
    }
});

export const editors = {
    WordCloudElementSelection,
    WordCloudElementOptionsMenu,
    WordCloudDialog,
    WordCloudDialogTabWords,
    WordCloudDialogTabText,
};
