import { Backbone } from "js/vendor";
import { $, _ } from "js/vendor";
import { ds } from "js/core/models/dataService";
import { app } from "js/namespaces";

export const SideBarCanvasModifier = {
    NONE: "none",
    POSITION: "position",
    SCALE: "scale"
};

export const SideBar = Backbone.View.extend({
    getTitle: function() {
        return null;
    },

    getPanelHeight: function() {
        return 240;
    },

    preventCanvasEditingWhenOpen: true,
    closeOnEscape: true,
    showHeader: true,
    isResizable: false,
    allowUndo: true,

    getCanvasModifier: function() {
        return SideBarCanvasModifier.POSITION;
    },

    initialize: function(options = {}) {
        if (options.element) {
            this.element = options.element;
            this.model = this.element.model;
        }
        if (options.slide) {
            this.slide = options.slide;
        }
        if (options.canvas) {
            this.canvas = options.canvas;
        }
        if (options.model) {
            this.model = options.model;
        }

        this.setup(options);
        this.$el.on("click", () => {
            ds.selection.element = null;

            const canvas = this.canvas ?? app.currentCanvas;
            if (canvas && canvas.isAuthoringCanvas()) {
                const authoringLayer = canvas.layouter.canvasElement.elements.primary.overlay?.authoringRef?.current;
                if (authoringLayer) {
                    authoringLayer.clearSelection();
                }
            }
        });
    },

    setup: function(options) {
    },

    render: function() {
        this.$el.empty();
        this.$el.addClass("sidebar");

        if (this.isResizable) {
            this.renderResizeBar();
        }

        if (this.showHeader) {
            this.renderHeader();
        } else {
            // var $closeButton = this.$el.addEl($.div("close_button"));
            // $closeButton.on("click", $.proxy(this.close, this));
        }

        this.$contents = this.$el.addEl($.div("contents"));
        this.renderContents(this.$contents);

        return this;
    },

    renderHeader: function() {
        if (!this.$header) {
            this.$header = this.$el.addEl($.div("header"));
        }
        this.$header.empty();
        var title = this.getTitle();
        if (title) {
            this.$header.addEl($.div("title").text(this.getTitle()));
        }

        var $closeButton = this.$header.addEl($.div("close_button"));
        $closeButton.on("click", $.proxy(this.close, this));
    },

    renderResizeBar: function() {
        if (!this.$resizeBar) {
            this.$resizeBar = this.$el.addEl($.div("sidebar_resizebar"));
        }
        let dragging = false;

        this.$resizeBar.mousedown(e => {
            e.preventDefault();
            const start = e.clientY;
            const startHeight = this.$el.height();
            dragging = true;
            $(document).mousemove(e => {
                const diff = start - e.clientY;
                const newHeight = startHeight + diff;
                //321 is an arbitrary value
                //20 is an arbitrary value as well to add space between the primary control bar and the speaker notes.
                if (e.clientY > 321) {
                    this.$el.css({
                        height: newHeight,
                        transform: `translateY(${window.innerHeight - newHeight + 20}px)`
                    });

                    this.onResize();
                }
                app.mainView.editorView.throttledResize(false);
            });
        });

        $(document).mouseup(e => {
            if (dragging) {
                $(document).off("mousemove");
                dragging = false;
            }
        });
    },

    onResize: function() {

    },

    renderContents: function($contents) {
    },

    addTab: function(tab, selected) {
        if (!this.$tabBar) {
            this.$tabBar = this.$header.addEl($.div("tabs"));
        }

        var $tab = this.$tabBar.addEl($.div("tab", tab.getTitle()));
        $tab.attr("data-tab-id", tab.id);

        tab.parent = this;

        $tab.on("click", () => {
            this.$tabBar.find(".tab").removeClass("selected");
            $tab.addClass("selected");
            if (this.selectedTab) {
                this.selectedTab.hide();
            }
            tab.show();
            this.selectedTab = tab;
        });

        this.$contents.append(tab.render().$el);

        if (selected) {
            tab.show();
            this.selectedTab = tab;
            $tab.addClass("selected");
        } else {
            tab.hide();
        }
    },

    showTab: function(tabId) {
        var $tab = this.$tabBar.find(".tab[data-tab-id='" + tabId + "']");
        if ($tab) {
            $tab.trigger("click");
        }
    },

    open: function() {
        $(window).on("keydown.sidebar", event => this.onWindowKeydown(event));

        app.dialogManager.registerDialog(this);

        // increase sidebar width to account for scrollbar if window is too small
        if (this.$el[0].scrollHeight > window.innerHeight) {
            this.$el.width(this.$el.width() + 20);
        }

        this.onShown();
    },

    onWindowKeydown: function(event) {
        if (event.which == 27 && this.closeOnEscape) {
            this.close();
        }
    },

    onShown: function() {
    },

    onAnimationComplete: function() {

    },

    beforeClose: function() {
        this.$el.find(":focus").blur();
        this.cleanUp();
    },

    close: function() {
        this._onClose();
        this.trigger("close");
        app.dialogManager.unregisterDialog(this);
        $(window).off("keydown.sidebar");
        app.mainView.editorView.hideSideBar(true);
    },

    _onClose: function() {
        // void - use to override
    },

    cleanUp: function() {

    }

});
