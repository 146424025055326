import React, { Component, Fragment } from "react";
import styled from "styled-components";
import { Icon, Menu, MenuItem } from "@material-ui/core";
import { AuthoringBlockType, TextStyleType, HorizontalAlignType } from "common/constants";
import { $, _ } from "js/vendor";
import { themeColors } from "js/react/sharedStyles";
import * as geom from "js/core/utilities/geom";
import { Key } from "js/core/utilities/keys";
import { getStaticUrl } from "js/config";
import { FlexBox } from "js/react/components/LayoutGrid";
import { Gap20 } from "js/react/components/Gap";

import { CodeBlockEditor } from "./BlockEditors/CodeBlockEditor";
import { EquationBlockEditor } from "./BlockEditors/EquationBlockEditor";
import { TextBlockEditor } from "./BlockEditors/TextBlockEditor";
import { stopEventPropagation } from "./AuthoringHelpers";
import { DividerBlockEditor } from "./BlockEditors/DividerBlockEditor";
import { ClipboardType, clipboardWrite } from "js/core/utilities/clipboard";

const RolloverBlock = styled.div.attrs(({ bounds }) => ({
    style: { ...bounds.deflate(1).toObject() }
}))`
    position: absolute;
    pointer-events: auto;
    border: dotted 1px ${themeColors.ui_blue};
    opacity: 0.5;
    cursor: default;
`;

const FocusedBlock = styled.div.attrs(({ bounds }) => ({
    style: { ...bounds.deflate(1).toObject() }
}))`
    position: absolute;
    pointer-events: auto;
    border: dotted 1px ${themeColors.ui_blue};
    opacity: 0.5;
    cursor: default;
`;

const SelectedBlock = styled.div.attrs(({ bounds }) => ({
    style: { ...bounds.deflate(1).toObject() }
}))`
    position: absolute;
    pointer-events: auto;
    background: ${themeColors.textSelection};
    opacity: 0.5;
    cursor: default;
`;

const AddBlockWidget = styled.div.attrs(({ bounds }) => ({
    style: { ...bounds.toObject() }
}))`
    position: absolute;
    pointer-events: auto;
    border-bottom: dotted 1px ${themeColors.ui_blue};
    cursor: default;
`;

const blockContainerMock = { blocks: [] };

export class AuthoringBlockEditor extends Component {
    constructor(props) {
        super(props);

        this.defaultState = {
            rolloverBlock: null,
            focusedBlock: null,
            selectedBlocks: [],
            dragBounds: null,
            dropBounds: null,
            dragBlocks: [],
            isDragging: false,
            isShowingPopup: false,
            mouseDownPoint: null,
            popupAnchorBelow: null,
            insertionBounds: null,
            insertionIndex: null
        };

        this.state = { ...this.defaultState };
    }

    get blockContainer() {
        // Fallback to blockContainerMock in cases where React has a null ref, likely a race condition with canvas
        return this.props.element.childElement.blockContainerRef?.current || blockContainerMock;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.focusedBlock !== this.state.focusedBlock || !_.isEqual(prevState.selectedBlocks, this.state.selectedBlocks)) {
            this.props.onSelectedBlocksChanged(this.state.focusedBlock, this.state.selectedBlocks);
        }

        if (prevProps.element !== this.props.element) {
            const prevBlockContainer = prevProps.element.childElement.blockContainerRef.current;
            this.removeEmptyBlockFromShape(prevBlockContainer)
                .then(() => {
                    this.setState({ ...this.defaultState });
                });
        }
    }

    componentWillUnmount() {
        this.removeEmptyBlockFromShape();
    }

    /**
     * Deletes single empty block from a shape (but not a textbox) when losing focus
    */
    removeEmptyBlockFromShape = async blockContainer => {
        if (blockContainer && !blockContainer.isTextBox && blockContainer.blocks.length == 1 && blockContainer.blocks[0].model.html == "") {
            blockContainer.model.fitToText = false;
            blockContainer.model.textAlign = HorizontalAlignType.CENTER;
            blockContainer.model.verticalAlign = null;
            blockContainer.model.textInset = 20;
            await this.deleteBlock(blockContainer.blocks[0]);
        }
    }

    handleMouseDown = event => {
        if ($(event.target).hasClass("editor-control, editor-control-bar") || $(event.target).closest(".editor-control, .editor-control-bar, .ui-popover, .MuiPopover-root").length) {
            return;
        }

        let block;
        for (const childBlock of this.blockContainer.blocks) {
            if (childBlock.bounds.contains(event.pageX, event.pageY)) {
                block = childBlock;
                break;
            }
        }

        if (event.button === 2) {
            // Propagate up to AuthoringLayer to open context menu
            return;
        }

        if (block) {
            // Mousedown is on block so select and prevent propagation up to AuthoringLayer
            event.stopPropagation();
            this.setState({
                focusedBlock: block,
                selectedBlocks: [block],
                rolloverBlock: block,
                mouseDownPoint: new geom.Point(event.pageX, event.pageY)
            });
        } else {
            // Mousedown is not on block so null focusedBlock and rolloverBlock and let mousedown event propagate up to AuthoringLayer
            this.clearSelection();
        }
    }

    handleMouseMove = event => {
        // Avoid handling mouse move when there's a popover
        if ($(".MuiPopover-root").length > 0) return;

        const { focusedBlock, rolloverBlock, isShowingPopup, isDragging, mouseDownPoint, insertionBounds, insertionIndex } = this.state;
        if (isDragging) return;
        if (isShowingPopup) return;

        let block;
        for (const childBlock of this.blockContainer.blocks) {
            if (childBlock.bounds.inflate({ left: 10, right: 10 }).contains(event.pageX, event.pageY)) {
                block = childBlock;
                break;
            }
        }
        if (block !== rolloverBlock) {
            this.setState({ rolloverBlock: block });
        }

        if (event.buttons == 1 && mouseDownPoint && (block != focusedBlock || !block)) {
            const selectedBlocks = [];
            const selectionBox = new geom.Rect(mouseDownPoint, new geom.Point(event.pageX, event.pageY));
            for (const childBlock of this.blockContainer.blocks) {
                if (selectionBox.intersects(childBlock.bounds)) {
                    selectedBlocks.push(childBlock);
                }
            }
            this.setState({
                focusedBlock: null,
                selectedBlocks
            });
        }

        let newInsertionBounds = null;
        let newInsertionIndex = null;
        if (!block && this.blockContainer.blocks.length) {
            if (event.pageY < this.blockContainer.blocks[0].bounds.top) {
                newInsertionBounds = new geom.Rect(this.blockContainer.blocks[0].bounds.left, this.blockContainer.blocks[0].bounds.top - 10, this.blockContainer.blocks[0].bounds.width, 5).deflate({
                    left: 20,
                    right: 20
                });
                newInsertionIndex = 0;
            } else {
                const prevBlock = this.blockContainer.blocks.find(block => event.pageY > block.bounds.bottom && event.pageY < block.bounds.bottom + 20);
                if (prevBlock) {
                    const prevBlockIndex = this.blockContainer.blocks.indexOf(prevBlock);
                    const nextBlock = this.blockContainer.blocks[prevBlockIndex + 1];
                    let gap = 10;
                    if (nextBlock) {
                        gap = nextBlock.bounds.top - prevBlock.bounds.bottom;
                    }
                    newInsertionBounds = new geom.Rect(prevBlock.bounds.left, prevBlock.bounds.bottom, prevBlock.bounds.width, gap / 2).deflate({
                        left: 20,
                        right: 20
                    });
                    newInsertionIndex = prevBlockIndex + 1;
                }
            }
        }

        if (insertionIndex !== newInsertionIndex || insertionBounds?.toString() !== newInsertionBounds?.toString()) {
            this.setState({ insertionBounds: newInsertionBounds, insertionIndex: newInsertionIndex });
        }
    }

    handleMouseUp = event => {
        this.setState({ mouseDownPoint: null });
    }

    sendKeyDownEventToBlock = event => {
        const { focusedBlock, selectedBlocks } = this.state;

        if (focusedBlock) {
            switch (event.which) {
                case Key.UP_ARROW:
                case Key.DOWN_ARROW:
                    const newIndex = focusedBlock.index + (event.which === Key.UP_ARROW ? -1 : 1);
                    if (newIndex in this.blockContainer.blocks) {
                        event.preventDefault();
                        this.setState({
                            focusedBlock: this.blockContainer.blocks[newIndex],
                            rolloverBlock: this.blockContainer.blocks[newIndex]
                        });
                    }
                    return true;
                case Key.ENTER:
                    event.preventDefault();
                    this.handleAddBlock({
                        model: {
                            type: AuthoringBlockType.TEXT,
                            textStyle: TextStyleType.BODY
                        },
                        replaceCurrentBlock: false
                    });
                    return true;
                case Key.DELETE:
                case Key.BACKSPACE:
                    if (focusedBlock.type.equalsAnyOf(AuthoringBlockType.DIVIDER, AuthoringBlockType.CODE, AuthoringBlockType.EQUATION)) {
                        event.preventDefault();
                        this.clearSelection();
                        this.deleteBlocks([focusedBlock]);
                        return true;
                    }
                    break;
            }
        } else if (selectedBlocks.length) {
            switch (event.which) {
                case Key.ENTER:
                    event.preventDefault();
                    return true;
                case Key.DELETE:
                case Key.BACKSPACE: {
                    event.preventDefault();
                    let firstBlock = selectedBlocks.shift();
                    firstBlock.model.html = "";
                    this.setState({
                        focusedBlock: firstBlock,
                        rolloverBlock: firstBlock
                    });
                    this.deleteBlocks(selectedBlocks);
                    return true;
                }
                case Key.KEY_C:
                case Key.KEY_X:
                    if (event.metaKey || event.ctrlKey) {
                        clipboardWrite({
                            [ClipboardType.BLOCKS]: selectedBlocks.map(block => block.model),
                        });

                        if (event.which == Key.KEY_X) {
                            this.deleteBlocks(selectedBlocks);
                        }
                        return true;
                    }
                    break;
                default: {
                    let firstBlock = selectedBlocks.shift();
                    firstBlock.model.html = "";
                    this.setState({
                        focusedBlock: firstBlock,
                        rolloverBlock: firstBlock
                    });
                    this.deleteBlocks(selectedBlocks);
                    return true;
                }
            }
        }
    }

    handleAddBlock = async ({ model, insertAtIndex = null, replaceCurrentBlock = false }) => {
        const { rolloverBlock, focusedBlock, selectedBlocks } = this.state;
        this.handleClosePopup();

        let index = 0;

        const targetBlock = focusedBlock || rolloverBlock;

        if (insertAtIndex == null && targetBlock) {
            if (replaceCurrentBlock) {
                index = targetBlock.index;
            } else {
                index = targetBlock.index + 1;

                const nextBlocks = this.blockContainer.blocks.filter((block, index) => index >= targetBlock.index + 1);
                const nextBlock = nextBlocks[0];

                if ([TextStyleType.NUMBERED_LIST, TextStyleType.BULLET_LIST].includes(targetBlock.model.textStyle) &&
                    nextBlock &&
                    [TextStyleType.NUMBERED_LIST, TextStyleType.BULLET_LIST].includes(nextBlock.model.textStyle) &&
                    nextBlock.listIndent > targetBlock.listIndent
                ) {
                    for (const block of nextBlocks) {
                        if (block.listIndent > targetBlock.listIndent) {
                            index++;
                        } else {
                            break;
                        }
                    }
                }
            }
        } else {
            index = insertAtIndex;
        }

        if (Object.values(TextStyleType).includes(model.type)) {
            model.textStyle = model.type;
            model.type = AuthoringBlockType.TEXT;
        }

        if (replaceCurrentBlock) {
            // Temporarily removing selection to avoid errors on next render
            // we'll have to wait until the setState finishes to make sure
            // we fully refresh before deleting the block
            await new Promise(resolve => this.setState({
                focusedBlock: null,
                rolloverBlock: null,
                selectedBlocks: selectedBlocks.filter(block => block !== targetBlock)
            }, resolve));
            await this.deleteBlock(targetBlock);
        }

        const addedBlock = await this.addBlock(model, index);

        this.focusedBlockSelectionPosition = "start";
        this.setState({
            insertionBounds: null,
            focusedBlock: addedBlock,
            rolloverBlock: addedBlock
        }, () => this.focusedBlockSelectionPosition = null);
    }

    handleDeleteBlock = async (block, focusPreviousBlock) => {
        if (focusPreviousBlock) {
            // this is coming from backspace-ing in textblock
            if (this.blockContainer.blocks.length > 1) {
                // move focus to previous block AND fall through delete the current block
                await this.handleFocusBlock(block, "prev", "end");
            } else {
                return; // early-out so don't allow delete last block in a shape or textbox when backspacing
            }
        } else {
            this.clearSelection();
        }

        await this.deleteBlock(block);
    }

    handleMergeBlocks = async (startBlock, endBlock) => {
        startBlock.model.html += endBlock.model.html;
        await this.handleFocusBlock(endBlock, "prev", startBlock.ref.current.textContent.length);
        await this.deleteBlock(endBlock);
    }

    handleSelectAllBlocks = () => {
        this.setState({
            focusedBlock: null,
            selectedBlocks: this.blockContainer.blocks
        });
    }

    handleFocusBlock = (currentBlock, direction, selectionPosition = "start") => {
        const currentBlockIndex = currentBlock.index;
        let newBlockIndex = currentBlockIndex;
        if (direction == "prev") {
            newBlockIndex -= 1;
        } else {
            newBlockIndex += 1;
        }

        if (newBlockIndex < 0 || newBlockIndex >= this.blockContainer.blocks.length) {
            return;
        }

        const newFocusedBlock = this.blockContainer.blocks[newBlockIndex];

        this.focusedBlockSelectionPosition = selectionPosition;

        return new Promise(resolve =>
            this.setState({
                focusedBlock: newFocusedBlock,
                rolloverBlock: newFocusedBlock
            }, () => {
                this.focusedBlockSelectionPosition = null;
                resolve();
            })
        );
    }

    addBlock = async (blockModel, index) => {
        const { refreshCanvasAndSaveChanges } = this.props;

        blockModel.id = _.uniqueId("block");
        this.blockContainer.model.blocks.insert(blockModel, index);

        await refreshCanvasAndSaveChanges();

        const newBlock = this.blockContainer.blocks.find(block => block.model.id === blockModel.id);
        return newBlock;
    }

    deleteBlock = block => this.deleteBlocks([block])

    deleteBlocks = async blocks => {
        const { refreshCanvasAndSaveChanges } = this.props;

        for (const block of blocks) {
            this.blockContainer.model.blocks.remove(block.model);
        }

        await refreshCanvasAndSaveChanges();
    }

    moveBlocks = async (blocks, newIndex) => {
        const { refreshCanvasAndSaveChanges } = this.props;

        const lastBlock = blocks[blocks.length - 1];
        const nextBlocks = this.blockContainer.blocks.filter((block, index) => index > lastBlock.index);

        const nextBlocksToMove = [];
        if ([TextStyleType.NUMBERED_LIST, TextStyleType.BULLET_LIST].includes(lastBlock.model.textStyle) &&
            nextBlocks.length > 0 &&
            [TextStyleType.NUMBERED_LIST, TextStyleType.BULLET_LIST].includes(nextBlocks[0].model.textStyle) &&
            nextBlocks[0].listIndent >= lastBlock.listIndent
        ) {
            for (const nextBlock of nextBlocks) {
                if (nextBlock.index === newIndex) {
                    break;
                }

                if (nextBlock.listIndent >= lastBlock.listIndent) {
                    nextBlocksToMove.push(nextBlock);
                } else {
                    break;
                }
            }
        }

        const firstBlock = blocks[0];
        if (newIndex > firstBlock.index) {
            newIndex -= nextBlocksToMove.length + blocks.length - 1;
        }

        this.blockContainer.model.blocks.splice(firstBlock.index, nextBlocksToMove.length + blocks.length);
        this.blockContainer.model.blocks.splice(newIndex, 0, ...blocks.map(block => block.model), ...nextBlocksToMove.map(block => block.model));

        await refreshCanvasAndSaveChanges();

        return this.blockContainer.blocks.filter(block => block.index >= newIndex && block.index < (newIndex + blocks.length));
    }

    clearSelection = () => {
        this.setState({
            focusedBlock: null,
            rolloverBlock: null,
            selectedBlocks: [],
            mouseDownPoint: null
        });
    }

    handleShowPopup = (event, position) => {
        event.stopPropagation();

        if (position == "above") {
            this.setState({
                popupAnchorAbove: event.currentTarget,
                isShowingPopup: true,
            });
        } else {
            this.setState({
                popupAnchorBelow: event.currentTarget,
                isShowingPopup: true,
            });
        }
    }

    handleClosePopup = () => {
        this.setState({
            popupAnchorAbove: null,
            popupAnchorBelow: null,
            isShowingPopup: false
        });
    }

    handleDragBlocks = dragBlocks => {
        document.addEventListener("mousemove", this.handleDrag, true);
        document.addEventListener("mouseup", this.handleDragEnd, true);

        const dragBounds = this.getBlocksSelectionBounds(dragBlocks);

        this.setState({ dragBounds, dragBlocks });
    }

    handleDrag = async event => {
        const { dragBounds, dragBlocks } = this.state;

        let isOverTopBlock = false;
        const dropBlock = this.blockContainer.blocks.find(block => {
            if (block.index === 0) {
                isOverTopBlock = block.bounds.top >= event.pageY;
                return block.bounds.bottom >= event.pageY;
            } else if (block.index === this.blockContainer.blocks.length - 1) {
                return block.bounds.top <= event.pageY;
            }

            const prevBlock = this.blockContainer.blocks[block.index - 1];
            const nextBlock = this.blockContainer.blocks[block.index + 1];
            return nextBlock.bounds.top >= event.pageY && prevBlock.bounds.bottom <= event.pageY;
        });

        let dropBounds;
        let dropBlockIndex;
        if (dropBlock) {
            dropBounds = this.convertToSelectionBounds(dropBlock.bounds);

            if (isOverTopBlock) {
                dropBlockIndex = 0;
                dropBounds.top -= dropBounds.height;
            } else if (dropBlock.index < dragBlocks[0].index) {
                dropBlockIndex = dropBlock.index + 1;
            } else {
                dropBlockIndex = dropBlock.index;
            }
        }

        let currentY = this.convertToSelectionBounds(new geom.Point(0, event.pageY)).y;
        if (dragBlocks[0].type == AuthoringBlockType.TEXT) {
            currentY -= 10;
        }

        dragBounds.top = currentY;

        this.setState({
            isDragging: true,
            dragBounds,
            dropBounds,
            dropBlockIndex
        });
    }

    handleDragEnd = async () => {
        const { dropBlockIndex, dragBlocks } = this.state;

        document.removeEventListener("mousemove", this.handleDrag, true);
        document.removeEventListener("mouseup", this.handleDragEnd, true);

        this.setState({
            isDragging: false,
            dragBounds: null,
            dropBounds: null,
            dropBlockIndex: null
        });

        if (dropBlockIndex != null && !dragBlocks.some(block => block.index === dropBlockIndex)) {
            this.setState({
                focusedBlock: null,
                rolloverBlock: null,
                selectedBlocks: [],
                dragBlocks: []
            });

            const movedBlocks = await this.moveBlocks(dragBlocks, dropBlockIndex);
            if (movedBlocks.length === 1) {
                this.setState({
                    focusedBlock: movedBlocks[0],
                    rolloverBlock: movedBlocks[0],
                    dragBlocks: []
                });
            } else {
                this.setState({
                    selectedBlocks: movedBlocks,
                    dragBlocks: []
                });
            }
        } else {
            this.setState({
                dragBlocks: []
            });
        }
    }

    convertToSelectionBounds = bounds => {
        const selectionOffset = $("#selection_layer").offset();
        return bounds.offset(-selectionOffset.left, -selectionOffset.top).offset(-this.props.bounds.left, -this.props.bounds.top);
    }

    getBlocksSelectionBounds = blocks => {
        let bounds;
        blocks.forEach(block => {
            if (!bounds) {
                bounds = block.bounds;
            } else {
                bounds = bounds.union(block.bounds);
            }
        });
        return this.convertToSelectionBounds(bounds);
    }

    render() {
        const { element, bounds, refreshCanvasAndSaveChanges, refreshElement, saveChanges } = this.props;
        const { popupAnchorBelow, rolloverBlock, focusedBlock, selectedBlocks, isDragging, dragBounds, dropBounds, insertionBounds, insertionIndex } = this.state;

        if (!this.blockContainer) {
            return null;
        }

        const editors = [];
        for (const block of this.blockContainer.blocks) {
            // Block may not have been rendered
            if (!block) {
                continue;
            }

            const blockBounds = this.convertToSelectionBounds(block.bounds);
            // Always rendering all text editors
            if (block.type === AuthoringBlockType.TEXT) {
                editors.push(
                    <TextBlockEditor
                        key={block.index}
                        isFocused={block === focusedBlock}
                        selectionPosition={this.focusedBlockSelectionPosition}
                        element={element}
                        container={this.blockContainer}
                        block={block}
                        bounds={blockBounds}
                        containerBounds={bounds}
                        refreshCanvasAndSaveChanges={refreshCanvasAndSaveChanges}
                        refreshElement={refreshElement}
                        saveChanges={saveChanges}
                        onAddBlockOnEnterKey={this.handleAddBlock}
                        onDeleteBlock={this.handleDeleteBlock}
                        onFocusOtherBlock={this.handleFocusBlock}
                        onMergeBlocks={this.handleMergeBlocks}
                        onShowMenu={this.handleShowPopup}
                        onSelectAllBlocks={this.handleSelectAllBlocks}
                    />
                );
            } else if (block === focusedBlock) {
                const editorProps = {
                    key: block.index,
                    element,
                    container: this.blockContainer,
                    block: block,
                    bounds: blockBounds,
                    containerBounds: bounds,
                    refreshCanvasAndSaveChanges: refreshCanvasAndSaveChanges,
                    refreshElement: refreshElement
                };

                // Will render other editors only for selected block
                switch (block.type) {
                    case AuthoringBlockType.EQUATION:
                        editors.push(
                            <EquationBlockEditor {...editorProps} />
                        );
                        break;
                    case AuthoringBlockType.CODE:
                        editors.push(
                            <CodeBlockEditor {...editorProps} />
                        );
                        break;
                    case AuthoringBlockType.DIVIDER:
                        editors.push(
                            <DividerBlockEditor {...editorProps} />
                        );
                        break;
                }
            }
        }

        let rolloverBlockBounds;
        if (rolloverBlock && !isDragging) {
            rolloverBlockBounds = this.convertToSelectionBounds(rolloverBlock.bounds);
        }

        return (
            <div
                style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                }}
                onMouseMove={this.handleMouseMove}
                onMouseDown={this.handleMouseDown}
                onMouseUp={this.handleMouseUp}
            >
                {!focusedBlock && selectedBlocks.filter(block => block.bounds).map((block, index) => {
                    if (index === 0 && (this.blockContainer.blocks.length - selectedBlocks.length) > 0) {
                        return (<SelectedBlock key={index} bounds={this.convertToSelectionBounds(block.bounds)} >
                            <DragWidget
                                onDrag={() => this.handleDragBlocks(selectedBlocks)}
                            />
                        </SelectedBlock>);
                    }
                    return <SelectedBlock key={index} bounds={this.convertToSelectionBounds(block.bounds)} />;
                })}
                {rolloverBlockBounds && (rolloverBlock !== focusedBlock) &&
                    <RolloverBlock bounds={rolloverBlockBounds} />}
                {focusedBlock && focusedBlock.bounds &&
                    <FocusedBlock bounds={this.convertToSelectionBounds(focusedBlock.bounds)}>
                        {this.blockContainer.blocks.length > 1 &&
                            // only show drag widget if there are multiple blocks
                            <DragWidget
                                onDrag={() => this.handleDragBlocks([focusedBlock])}
                            />
                        }
                        {(this.blockContainer.blocks.length > 1 || !this.blockContainer.isTextBox) &&
                            // don't show delete block widget for the last block in a textbox
                            <DeleteButton
                                className="editor-control"
                                {...stopEventPropagation}
                                onClick={() => this.handleDeleteBlock(focusedBlock)}
                            />
                        }
                    </FocusedBlock>}
                {insertionBounds &&
                    // This menu is popped up from add button
                    <AddBlockWidget bounds={this.convertToSelectionBounds(insertionBounds)}>
                        <AddBlockPopupMenu
                            position="below"
                            onSelect={type => this.handleAddBlock({
                                model: { type },
                                insertAtIndex: insertionIndex
                            })}
                            onMouseDown={event => this.handleShowPopup(event, "below")}
                            popupAnchor={popupAnchorBelow}
                            onClosePopup={this.handleClosePopup}
                        />
                    </AddBlockWidget>
                }
                {!insertionBounds &&
                    // This menu is popped up from the / key commamd
                    <AddBlockPopupMenu
                        showAddBlockButton={false}
                        position="below"
                        onSelect={type => this.handleAddBlock({
                            model: { type },
                            replaceCurrentBlock: true
                        })}
                        popupAnchor={popupAnchorBelow}
                        onClosePopup={this.handleClosePopup}
                    />
                }
                {editors}
                {isDragging &&
                    <Fragment>
                        <DragBlock className="drag-block" bounds={dragBounds}>
                            <DragWidget block={rolloverBlock} />
                        </DragBlock>
                        {dropBounds && <DropTarget bounds={dropBounds} />}
                    </Fragment>
                }
            </div >
        );
    }
}

const DeleteButton = styled.div`
    position: absolute;
    top: 0px;
    right: -24px;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: ${themeColors.ui_blue};
    cursor: pointer;
    &:after {
        content: "\\E5CD";
        font-family: "Material Icons";
        font-size: 14px;
    }
`;

const AddBlockButton = styled.div.attrs(({ top, bottom }) => ({
    style: { top, bottom }
}))`
    position: absolute;   
    z-index: 100;
    pointer-events: auto;
    height: 16px;
    width: 16px;
    background: ${themeColors.ui_blue};
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    left: calc(50% - 8px);
    border-radius: 50%;
    cursor: pointer;
    opacity: ${props => props.hidden ? 0 : 1};
    &:after {
       content: "\\e145";
       font-family: "Material Icons";
       font-size: 12px;
    }
`;

class AddBlockPopupMenu extends Component {
    render() {
        const { position, popupAnchor, onMouseDown, onClosePopup, onSelect, showAddBlockButton = true } = this.props;

        let top, bottom;
        let popupPosition = {};
        if (position === "above") {
            top = -8;
            popupPosition = {
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center"
                },
                transformOrigin: {
                    vertical: "bottom",
                    horizontal: "center"
                }
            };
        } else {
            bottom = -8;
            popupPosition = {
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center"
                },
                transformOrigin: {
                    vertical: "top",
                    horizontal: "center"
                }
            };
        }

        const allowedBlockTypes = [
            TextStyleType.HEADING,
            TextStyleType.TITLE,
            TextStyleType.BODY,
            TextStyleType.CAPTION,
            TextStyleType.BULLET_LIST,
            TextStyleType.NUMBERED_LIST,
            AuthoringBlockType.DIVIDER,
            AuthoringBlockType.CODE,
            AuthoringBlockType.EQUATION
        ];

        return (
            <Fragment>
                {showAddBlockButton && <AddBlockButton className="editor-control" top={top} bottom={bottom} onMouseDown={onMouseDown} />}
                <Menu
                    open={!!popupAnchor}
                    anchorEl={popupAnchor}
                    disableEnforceFocus
                    {...popupPosition}
                    onClose={onClosePopup}
                    {...stopEventPropagation}
                >
                    {allowedBlockTypes.map(style => (
                        <MenuItem
                            key={style}
                            value={style}
                            onClick={() => onSelect(style)}
                            style={{ height: 60 }}
                        >
                            <FlexBox middle>
                                <img src={getStaticUrl(`/images/ui/contentblocktypes/${style}.svg`)} style={{ width: 100 }} />
                                <Gap20 />
                                {style.toTitleCase()}
                            </FlexBox>
                        </MenuItem>
                    ))}
                </Menu>
            </Fragment>
        );
    }
}

const DragBlock = styled.div.attrs(({ bounds }) => ({
    style: { ...bounds.toObject() }
}))`
  position: absolute;
  background: ${themeColors.ui_blue};
  opacity: 0.1;
`;

const DragWidgetBox = styled.div`
  color: ${themeColors.ui_blue};
  font-size: 11px;
  text-transform: uppercase;
  padding: 6px 10px 6px 5px;
  position: absolute;
  left: -30px;
  top: 0px;
  width: 30px;
  height: 20px;
  pointer-events: auto;
  cursor: grab;
  display: flex;
  align-items: center;
  .material-icons {
     font-size: 16px;
     margin-right: 10px;
  }
`;

class DragWidget extends Component {
    render() {
        const { onDrag } = this.props;

        return (
            <DragWidgetBox
                className="editor-control"
                {...stopEventPropagation}
                onMouseDown={event => {
                    event.stopPropagation();
                    onDrag(event);
                }}
            >
                <Icon>drag_indicator</Icon>
            </DragWidgetBox>
        );
    }
}

const DropTarget = styled.div.attrs(({ bounds }) => ({
    style: { ...bounds.toObject() }
}))`
  position: absolute;
  border-bottom: solid 3px ${themeColors.ui_blue};
`;
