import { controls } from "js/editor/ui";
import { _, $ } from "js/vendor";
import { AssetType } from "common/constants";
import { ShowDialog } from "js/react/components/Dialogs/BaseDialog";
import { AddAssetsContainer } from "js/react/views/AddAssets";

import { ElementOptionsMenu, ElementSelection } from "../BaseElementEditor";
import { CollectionElementSelection, CollectionItemElementSelection } from "../CollectionElementEditor";
import { CreateMediaMenu } from "./pictureEditor";

const UserTestimonialSelection = CollectionElementSelection.extend({
    getAddItemLabel() {
        return "Add Quote";
    },

    renderControls: function() {
        this.createAddItemButton({
            transition: false,
            markStylesAsDirty: true
        });

        this.addControl({
            id: "user-testimonial-styles",
            type: controls.POPUP_BUTTON,
            label: "Style",
            items: () => [{
                type: "control",
                view: () => controls.createIconGrid(this, {
                    model: this.element.model,
                    transitionModel: false,
                    property: "frameStyle",
                    markStylesAsDirty: true,
                    items: [{
                        value: "none", label: "None", icon: "/images/ui/quote-frame-none.svg"
                    }, {
                        value: "divider",
                        label: "Divider",
                        icon: "/images/ui/quote-frame-divider.svg",
                        enabled: this.element.itemCount != 1
                    }, {
                        value: "box", label: "Box", icon: "/images/ui/quote-frame-box.svg"
                    }, {
                        value: "quote-box", label: "Quote Box", icon: "/images/ui/quote-frame-quote-box.svg"
                    }]
                })
            }]
        });
    }
});

const UserTestimonialOptionsMenu = ElementOptionsMenu.extend({
    renderControls() {
        this.addControl({
            type: controls.TOGGLE,
            label: "Show Fancy Quotes",
            property: "showDesignQuotes"
        });

        this.addControl({
            type: controls.TOGGLE,
            label: "Match Quote Sizes",
            property: "matchQuoteSizes",
            enabled: this.element.itemCount > 1
        });

        this.addControl({
            type: controls.TOGGLE,
            label: "Flip Orientation",
            property: "flipOrientation",
            enabled: (this.element.itemCount == 2 || this.element.itemCount == 3 || this.element.itemCount == 5)
        });
    }
});

const UserTestimonialItemSelection = CollectionItemElementSelection.extend({
    getWidgetPosition() {
        return "inner";
    },

    getOffset() {
        return 0;
    },

    getDropTargets: function(containerElement) {
        return containerElement.itemElements;
    },

    renderControls() {
        const popup = this.addControl({
            type: controls.POPUP_BUTTON,
            label: "Attribution",
            items: [{
                type: "control",
                view: controls.createToggle(this, {
                    label: "Show Attribution",
                    value: this.element.model.showAttribution,
                    callback: value => {
                        $("#showImage").toggleClass("disabled", !value);
                        $("#showLogo").toggleClass("disabled", !value);

                        this.element.model.showAttribution = value;
                        if (!value) {
                            this.element.model.content = null;
                            this.element.model.logo = null;
                            this.element.model.title = null;
                            this.element.model.body = null;
                        }

                        this.element.canvas.updateCanvasModel(false);
                    }
                })
            }, {
                type: "divider"
            }, {
                type: "control",
                view: controls.createToggle(this, {
                    label: "Show Photo",
                    model: this.element.model,
                    property: "showImage",
                    enabled: this.element.model.showAttribution,
                    callback: value => {
                        if (value && (this.element.model.content == null || this.element.model.content.content_value == null)) {
                            ShowDialog(AddAssetsContainer, {
                                assetType: AssetType.IMAGE,
                                callback: assetModel => {
                                    this.element.model.content = assetModel;
                                    this.element.model.showImage = true;
                                    this.element.markStylesAsDirty();
                                    this.element.canvas.updateCanvasModel(false);
                                }
                            });
                        } else {
                            this.element.model.showImage = value;
                            if (!value) {
                                this.element.model.content = null;
                            }
                            this.element.markStylesAsDirty();
                            this.element.canvas.updateCanvasModel(false);
                        }
                    }
                })
            }, {
                type: "control",
                view: controls.createToggle(this, {
                    label: "Show Logo",
                    model: this.element.model,
                    property: "showLogo",
                    enabled: this.element.model.showAttribution,
                    callback: value => {
                        if (value && (this.element.model.logo == null || this.element.model.logo.content_value == null)) {
                            popup.closePopupMenu();

                            ShowDialog(AddAssetsContainer, {
                                assetType: AssetType.LOGO,
                                callback: assetModel => {
                                    assetModel.showBackdrop = true;
                                    this.element.model.logo = assetModel;
                                    this.element.model.showLogo = true;
                                    this.element.markStylesAsDirty();
                                    this.element.canvas.updateCanvasModel(false);
                                }
                            });
                        } else {
                            this.element.model.showLogo = value;
                            if (!value) {
                                this.element.model.logo = null;
                            }
                            this.element.markStylesAsDirty();
                            this.element.canvas.updateCanvasModel(false);
                        }
                    }
                })
            }]
        });
    }
});

const UserTestimonialPhotoElementSelection = ElementSelection.extend({
    renderControls() {
        this.createDeleteComponentWidget({
            action: () => {
                this.element.parentElement.model.showImage = false;
                this.element.canvas.updateCanvasModel(true);
            }
        });

        CreateMediaMenu(this, this.element);
    },
});

const UserTestimonialLogoElementSelection = ElementSelection.extend({
    renderControls() {
        this.createDeleteComponentWidget({
            action: () => {
                this.element.parentElement.model.showLogo = false;
                this.element.canvas.updateCanvasModel(true);
            }
        });

        if (!this.element.showDefaultOverlay) {
            CreateMediaMenu(this, this.element);

            this.addControl({
                type: controls.SLIDER,
                property: "logoScale",
                min: 0.5,
                max: 2,
                step: 0.05,
                transitionModel: false
            });
        }
    },
});

export const editors = {
    UserTestimonialSelection,
    UserTestimonialOptionsMenu,
    UserTestimonialItemSelection,
    // UserTestimonialAttributionSelection,
    UserTestimonialPhotoElementSelection,
    UserTestimonialLogoElementSelection,
};
