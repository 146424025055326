import * as geom from "js/core/utilities/geom";
import { FormatType, VerticalAlignType } from "common/constants";

import { TextElement } from "../../base/TextElement";
import { SVGRectElement } from "../../base/SVGElement";
import { layoutHelper } from "../../layouts/LayoutHelper";
import CompareValuesItem from "./CompareValuesItem";
import CompareValuesItemLabel from "./CompareValuesItemLabel";

export default class CompareHorizontalBarItem extends CompareValuesItem {
    get selectionPadding() {
        return { left: 20, right: 0, top: 0, bottom: 0 };
    }

    get labelText() {
        return this.getRootElement().formatValue(this.currentValue);
    }

    _build() {
        this.shape = this.addElement("shape", () => SVGRectElement);

        this.text = this.addElement("title", () => TextElement, {
            autoWidth: false,
            autoHeight: true
        });

        if (this.parentElement.format != FormatType.NONE) {
            this.label = this.addElement("label", () => CompareValuesItemLabel, {
                model: {
                    label: this.labelText
                },
                autoWidth: true,
                canEdit: false
            });
        }
    }

    _calcProps(props, options) {
        let { size } = props;

        size = new geom.Size(size.width, options.barHeight);

        let textProps = this.text.calcProps(new geom.Size(options.titleWidth, options.barHeight));
        textProps.bounds = new geom.Rect(0, size.height / 2 - textProps.size.height / 2, options.titleWidth, textProps.size.height);

        let compare = this.getRootElement();

        let availableWidth = size.width - this.text.bounds.width;
        let barWidth = availableWidth * this.percentageValue;
        if (this.isAnimating) {
            barWidth *= this.drawPercentage;
        }

        let shapeProps = this.shape.createProps({
            layer: -1
        });

        if (compare.minValue >= 0) {
            shapeProps.bounds = new geom.Rect(textProps.bounds.width, 0, barWidth, size.height);
        } else {
            shapeProps.bounds = new geom.Rect(0, 0, barWidth, size.height);
            let baseLineX = textProps.bounds.width + availableWidth - availableWidth * Math.abs(compare.maxValue) / (compare.maxValue - compare.minValue);
            if (this.model.value >= 0) {
                shapeProps.bounds.left = baseLineX;
            } else {
                shapeProps.bounds.left = baseLineX - barWidth;
            }
        }

        if (this.label) {
            this.label.styles.fontSize = size.height * 0.5;

            if (this.isAnimating) {
                this.label.updateText(this.labelText);
            }

            const labelProps = this.label.calcProps(size);

            const isInside = this.isAnimating ? this.finalLabelPositionIsInside : (shapeProps.bounds.width > labelProps.size.width);
            // if (!this.isAnimating) {
            props.isLabelInside = isInside;
            // }
            if (isInside) {
                labelProps.bounds = new geom.Rect(this.shape.bounds.right - labelProps.size.width, shapeProps.bounds.top + layoutHelper.getVerticalAlignOffset(labelProps.size.height, shapeProps.bounds.height, VerticalAlignType.MIDDLE), labelProps.size);
            } else {
                labelProps.bounds = new geom.Rect(shapeProps.bounds.right, shapeProps.bounds.top + layoutHelper.getVerticalAlignOffset(labelProps.size.height, shapeProps.bounds.height, VerticalAlignType.MIDDLE), labelProps.size);
            }

            if (this.hasStoredPropChanged("isLabelInside", props.isLabelInside)) {
                this.markStylesAsDirty();
            }
        }

        return { size };
    }

    getBackgroundColor(forElement) {
        if (this.label && forElement === this.label && this.calculatedProps.isLabelInside) {
            return this.getShapeFillColor(this.shape);
        } else {
            return super.getBackgroundColor(forElement);
        }
    }

    get animationElementName() {
        return `Bar #${this.itemIndex + 1}`;
    }

    _getAnimations() {
        return [{
            name: "Grow in",
            prepare: () => {
                this.text.animationState.fadeInProgress = 0;
                this.animationState.fadeInProgress = 0;
                this.animationState.value = 0;
                if (this.label) {
                    this.finalLabelPositionIsInside = this.calculatedProps.isLabelInside;
                    this.label.animationState.fadeInProgress = 0;
                }
            },
            onBeforeAnimationFrame: progress => {
                this.text.animationState.fadeInProgress = Math.clamp((progress - 0.7) / 0.3, 0, 1);
                this.animationState.fadeInProgress = Math.min(1, progress * 3);
                this.animationState.value = progress;
                if (this.label) {
                    this.label.animationState.fadeInProgress = progress;
                }
                return this;
            }
        }];
    }
}
