import React from "react";
import styled from "styled-components";
import { ELEMENT_TRANSITION_DURATION } from "js/core/utilities/svgHelpers";
import { AssetType } from "common/constants";
import * as geom from "js/core/utilities/geom";

import { TextGroup } from "../../base/TextGroup";
import { CollectionElement, CollectionItemElement } from "../../base/CollectionElement";
import { layoutHelper } from "../../layouts/LayoutHelper";
import { FramedMediaElement } from "../../base/MediaElements/FramedMediaElement";

const DisableInteractions = styled.div`
    * {
        pointer-events: none !important;
    }
`;

class ImageCarousel extends CollectionElement {
    get previousIndex() {
        return this._previousIndex;
    }

    get currentIndex() {
        return this._currentIndex;
    }

    set currentIndex(index) {
        this._previousIndex = this._currentIndex;
        if (index < 0) {
            this._currentIndex = this.itemCollection.length - index % -this.itemCollection.length;
        } else {
            this._currentIndex = index % this.itemCollection.length;
        }
    }

    setupElement() {
        this._currentIndex = 0;
        this._previousIndex = 0;
    }

    getChildItemType() {
        return ImageCarouselItem;
    }

    get defaultItemData() {
        return {
            title: { text: "" },
            body: { text: "" }
        };
    }

    _calcProps(props, options) {
        const { size, children } = props;

        this.itemElements.forEach(element => element.calcProps(size));

        this.buildPlaybackStages();

        return { size };
    }

    addItem(props, index) {
        super.addItem(props, index);
        this.buildPlaybackStages();
    }

    deleteItem(itemId) {
        super.deleteItem(itemId);
        this.currentIndex = Math.max(this.currentIndex - 1, 0);
        this.buildPlaybackStages();
    }

    buildPlaybackStages() {
        this.canvas.clearPlaybackStages();
        for (let index = 0; index < this.itemElements.length; index++) {
            this.canvas.addPlaybackStage({
                type: "carousel",
                index,
                callback: (stage, animate) => this.goToItem(stage.index, animate)
            });
        }
    }

    goNextItem() {
        return this.goToItem(this.currentIndex + 1, true);
    }

    goPrevItem() {
        return this.goToItem(this.currentIndex - 1, true);
    }

    async goToItem(index, animate) {
        this.currentIndex = index;
        // No need to recalc here
        this.canvas.refreshRender(animate, false);
        // Resolve only after the animation finishes
        if (animate) {
            await new Promise(resolve => setTimeout(resolve, ELEMENT_TRANSITION_DURATION));
        }
    }

    get transition() {
        return this.model.transition ?? "slide-left";
    }

    renderChildren(transition) {
        return this.itemElements.map((element, index) => {
            let animationName;

            if (index == this.currentIndex) {
                // Current element changed -> animate slide in/out
                switch (this.transition) {
                    case "slide-left":
                        animationName = this.previousIndex < this.currentIndex ? "element-slide-in-right" : "element-slide-in-left";
                        break;
                    case "slide-up":
                        animationName = this.previousIndex < this.currentIndex ? "element-slide-in-down" : "element-slide-in-up";
                        break;
                    case "fade":
                    default:
                        animationName = "element-fade-in";
                }
                element.calculatedProps.opacity = 1;
                element.content.content.disabled = false;  // enable add content button
            } else if (index == this.previousIndex) {
                switch (this.transition) {
                    case "slide-left":
                        animationName = this.previousIndex < this.currentIndex ? "element-slide-out-left" : "element-slide-out-right";
                        break;
                    case "slide-up":
                        animationName = this.previousIndex < this.currentIndex ? "element-slide-out-up" : "element-slide-out-down";
                        break;
                    case "fade":
                    default:
                        animationName = "element-fade-out";
                }
                element.calculatedProps.opacity = 0;
                element.content.content.disabled = true;  // disable add content button
            } else {
                // Non current elements will still be rendered (but with 0 opacity)
                // in order to get all assets preloaded and cached
                element.calculatedProps.opacity = 0;
                element.content.content.disabled = true;    // disable add content button
            }

            // render the content
            let content = element.renderElement(transition, { animationName });

            // if hidden or disabled, prevent interacting
            // with a slide that's not visible
            if (element.content.content.disabled) {
                content = <DisableInteractions>{content}</DisableInteractions>;
            }

            return content;
        });
    }

    get disableAllAnimationsByDefault() {
        return true;
    }

    get animationElementName() {
        return "Carousel";
    }

    get animateChildren() {
        return false;
    }

    _getAnimations() {
        return [{
            name: "Fade in",
            prepare: () => this.animationState.fadeInProgress = 0,
            onBeforeAnimationFrame: progress => {
                this.animationState.fadeInProgress = progress;
            }
        }];
    }
}

class ImageCarouselItem extends CollectionItemElement {
    get selectionPadding() {
        return 0;
    }

    get previousIndex() {
        return this.parentElement.previousIndex;
    }

    get currentIndex() {
        return this.parentElement.currentIndex;
    }

    get canSelectChildElements() {
        return this.itemIndex === this.parentElement.currentIndex;
    }

    get canSelect() {
        return this.itemIndex === this.parentElement.currentIndex;
    }

    get canRollover() {
        return this.itemIndex === this.parentElement.currentIndex;
    }

    get flip() {
        switch (this.parentElement.model.flip) {
            case "left":
                return false;
            case "right":
                return true;
            case "alternate":
                return this.itemIndex % 2;
        }
    }

    get layout() {
        return this.model.layout || "left";
    }

    get contentSize() {
        return this.model.contentSize;
    }

    _build() {
        this.content = this.addElement("content", () => FramedMediaElement, {
            model: this.model,
            defaultAssetType: AssetType.IMAGE,
            allowUnframedImages: false
        });
        this.text = this.addElement("text", () => TextGroup, {
            autoHeight: true,
            allowAlignment: true,
            scaleTextToFit: true,
            body: {
                allowParagraphStyles: true,
            },
            title: {
                singleLine: true
            }
        });
    }

    _calcProps(props, options) {
        const { size } = props;

        let dividerSize = this.contentSize || Math.min(size.width / 2, size.height);

        let textSize;
        let contentSize;

        switch (this.layout) {
            case "background":
                contentSize = size;
                textSize = size;
                break;
            case "left":
                contentSize = new geom.Size(dividerSize ?? size.width / 2, size.height);
                textSize = new geom.Size(size.width - contentSize.width, size.height);
                this.text.styles.marginLeft = this.styles.gap ?? 50;
                break;
            case "right":
                contentSize = new geom.Size(dividerSize ?? size.width / 2, size.height);
                textSize = new geom.Size(size.width - contentSize.width, size.height);
                this.text.styles.marginRight = this.styles.gap ?? 50;
                break;
            case "top":
                contentSize = new geom.Size(size.width, dividerSize ?? size.height / 2);
                textSize = new geom.Size(size.width, size.height - contentSize.height);
                this.text.styles.marginTop = this.styles.gap ?? 50;
                break;
            case "bottom":
                contentSize = new geom.Size(size.width, dividerSize ?? size.height / 2);
                textSize = new geom.Size(size.width, size.height - contentSize.height);
                this.text.styles.marginBottom = this.styles.gap ?? 50;
                break;
        }

        const contentProps = this.content.calcProps(contentSize);
        const textProps = this.text.calcProps(textSize);

        switch (this.layout) {
            case "background":
                contentProps.bounds = new geom.Rect(0, 0, contentSize);
                textProps.bounds = new geom.Rect(0, 0, textSize);
                break;
            case "left":
                contentProps.bounds = new geom.Rect(0, size.height / 2 - contentProps.size.height / 2, contentSize.width, contentProps.size.height);
                textProps.bounds = new geom.Rect(contentSize.width, size.height / 2 - textProps.size.height / 2, textProps.size);
                break;
            case "right":
                contentProps.bounds = new geom.Rect(size.width - contentSize.width, size.height / 2 - contentProps.size.height / 2, contentSize.width, contentProps.size.height);
                textProps.bounds = new geom.Rect(0, size.height / 2 - textProps.size.height / 2, textProps.size);
                break;
            case "top":
                contentProps.bounds = new geom.Rect(layoutHelper.getHorizontalAlignOffset(contentProps.size.width, size.width), 0, contentProps.size);
                textProps.bounds = new geom.Rect(0, contentProps.size.height, textSize);
                break;
            case "bottom":
                textProps.bounds = new geom.Rect(0, 0, textSize);
                contentProps.bounds = new geom.Rect(layoutHelper.getHorizontalAlignOffset(contentProps.size.width, size.width), textSize.height, contentProps.size);
                break;
        }

        return { size };
    }
}

export const elements = {
    ImageCarousel,
};
