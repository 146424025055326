import { _ } from "js/vendor";
import { HiliteType } from "common/constants";
import { getValueOrDefault } from "js/core/utilities/extensions";
import { app } from "js/namespaces";

import { BaseElement } from "./BaseElement";

class CollectionElement extends BaseElement {
    get collectionPropertyName() {
        return "items";
    }

    get itemCollection() {
        if (!this.model[this.collectionPropertyName]) {
            this.model[this.collectionPropertyName] = [];
        }

        return this.model[this.collectionPropertyName];
    }

    get itemElements() {
        return _.map(this.model[this.collectionPropertyName], itemModel => {
            return this.elements[itemModel.id];
        });
    }

    getItemElementById(id) {
        return _.find(this.itemElements, { id: id });
    }

    get shadeColors() {
        if (_.some(this.itemCollection, item => item.hilited)) {
            // don't shade if any of the items are hilighted
            return false;
        } else {
            return getValueOrDefault(this.model.shadeColors, true);
        }
    }

    _build() {
        this.buildItems();
    }

    buildItems() {
        if (this.itemCollection.length == 0) {
            // let itemCount = this.minItemCount == 0 ? 1 : this.minItemCount;  mjg: working on connector group and i'm not sure why this is here since it prevents minItemCount from being zero
            let itemCount = this.minItemCount;
            for (let i = 0; i < itemCount; i++) {
                this.itemCollection.push(this.defaultItemData);
            }
        }

        // check for any null items
        for (let i = 0; i < this.itemCollection.length; i++) {
            if (this.itemCollection[i] == null) {
                this.itemCollection[i] = {};
            }
        }

        for (let itemModel of this.itemCollection) {
            if (!itemModel.id) {
                // This might create inconsistent states in collaboration. So far this is harmless,
                // but if we expect the id to never change once it has been set, it can break in collaboration.
                itemModel.id = _.uniqueId() + new Date().getTime();
            }
            this.addElement(itemModel.id, () => this.getChildItemType(itemModel), Object.assign({ model: itemModel }, this.getChildOptions(itemModel)));
        }
    }

    getChildOptions(model) {
        return {};
    }

    getChildItemType(itemModel) {
        // override to return the class type of an item for this container element
    }

    get minItemCount() {
        return 1;
    }

    get maxItemCount() {
        return 10;
    }

    get itemCount() {
        return this.model[this.collectionPropertyName].length;
    }

    get supportsAddHotKey() {
        return true;
    }

    addItem(props, index) {
        props = _.extend({ id: _.uniqueId() + new Date().getTime() }, this.defaultItemData, props || {});
        if (index != undefined) {
            this.itemCollection.splice(index, 0, props);
        } else {
            this.itemCollection.push(props);
        }
        this.markStylesAsDirty();

        return props;
    }

    deleteItem(itemId) {
        let item = _.find(this.itemCollection, { id: itemId });
        let itemElement = _.find(this.itemElements, { id: itemId });
        if (itemElement) {
            itemElement.toBeDeleted = true;
        }
        if (item) {
            this.itemCollection.remove(item);
        }
        this.markStylesAsDirty();
    }

    getItemIndex(itemId) {
        return _.findIndex(this.itemCollection, { id: itemId });
    }

    get defaultItemData() {
        return {};
    }

    get allowDragDropElements() {
        return true;
    }

    getAnimations() {
        const animations = this._getAnimations();
        animations.forEach(animation => animation.element = this);

        if (this.animateChildren) {
            Object.values(this.elements)
                // Will preserve the correct order of the children
                .sort((elementA, elementB) => elementA.itemIndex - elementB.itemIndex)
                .forEach(element => {
                    animations.push(...element.getAnimations());
                });
        }

        if (this.disableAnimationsByDefault) {
            animations.forEach(animation => animation.disabledByDefault = true);
        }

        return animations;
    }
}

class CollectionItemElement extends BaseElement {
    get name() {
        return "Item";
    }

    get isCollectionItem() {
        return true;
    }

    get collectionItemIndex() {
        return this.itemIndex >= 0 ? this.itemIndex : null;
    }

    get itemIndex() {
        return this.parentElement.itemCollection.indexOf(this.model);
    }

    get itemCount() {
        return this.parentElement.itemCount;
    }

    get _canSelect() {
        return true;
    }

    get canRollover() {
        return true;
    }

    get selectionPadding() {
        return 20;
    }

    get canDrag() {
        return true;
    }

    get canDelete() {
        return true;
    }

    get shadeColors() {
        return this.parentElement.shadeColors;
    }

    calcShadedColors(shape) {
        if (this.shadeColors) {
            let slideColor = this.canvas.getSlideColor();
            if (slideColor != "colorful") {
                let backgroundColor = this.getParentBackgroundColor(this);

                if (backgroundColor.isColor) {
                    shape.styles.fillOpacity = .3 + (.7 * this.itemIndex / this.itemCount);
                } else {
                    shape.styles.fillColor = this.canvas.getTheme().palette.getShadedColor(this.canvas.getTheme().palette.getForeColor(slideColor, null, backgroundColor), this.itemIndex, this.itemCount, false).toRgbString();
                }
            }
        }
    }

    // get rolloverUIType() {
    //     if (this.options.rollover) {
    //         return this.options.rollover;
    //     } else {
    //         return  "CollectionItemElementRollover";
    //     }
    // }
    // get rolloverPadding() {
    //     return {top: 30};
    // }

    _loadStyles(styles) {
        super._loadStyles(styles);
        this.loadEmphasizedStyles(styles, this.getHiliteType());
    }

    // recursively navigate through a style object's properties and merge the appropriate emphasized and deemphasized styles into it
    loadEmphasizedStyles(styles, hiliteType) {
        // if element is emphasized or de-emphasized, merge the root styles with the appropriate state styles
        switch (hiliteType) {
            case HiliteType.DEEMPHASIZED:
                if (styles.deemphasized) {
                    _.merge(styles, styles.deemphasized);
                }
                break;
            case HiliteType.EMPHASIZED:
                if (styles.emphasized) {
                    _.merge(styles, styles.emphasized);
                }
                break;
            case HiliteType.NONE:
                break;
        }

        for (let key in styles) {
            if (typeof (styles[key]) == "object" && styles[key] != null) {
                this.loadEmphasizedStyles(styles[key], hiliteType);
            }
        }
    }

    getHiliteType() {
        // check if any sibling elements are spotlighted
        const hasHilitedSibling = this.parentElement.itemCollection.filter(m => m.hilited).length > 0;

        if (this.model.hilited) {
            return HiliteType.EMPHASIZED;
        } else if (hasHilitedSibling) {
            return HiliteType.DEEMPHASIZED;
        } else {
            return HiliteType.NONE;
        }
    }

    get animationElementName() {
        return `${this.type.charAt(0).toUpperCase()}${this.type.slice(1)} #${this.itemIndex + 1}`;
    }

    get animateChildren() {
        return false;
    }

    _getAnimations() {
        return [{
            name: "Fade in",
            prepare: () => this.animationState.fadeInProgress = 0,
            onBeforeAnimationFrame: progress => {
                this.animationState.fadeInProgress = progress;
            }
        }];
    }
}

export { CollectionElement, CollectionItemElement };
