import { _ } from "js/vendor";
import * as geom from "js/core/utilities/geom";

import { CollectionElement, CollectionItemElement } from "../base/CollectionElement";
import { TextElement } from "../base/TextElement";

let swotChars = ["S", "W", "O", "T"];

class SwotDiagram extends CollectionElement {
    getChildItemType() {
        return SwotDiagramItem;
    }

    get maxItemCount() {
        return 4;
    }

    get minItemCount() {
        return 4;
    }

    _calcProps(props, options) {
        let { size } = props;

        let layouter = this.getLayouter(props, this.itemElements, size).calcTextBoxGridLayout();

        return { size: layouter.size };
    }

    deleteItem() {
        // noop
    }
}

class SwotDiagramItem extends CollectionItemElement {
    get selectionPadding() {
        return 0;
    }

    get canSelect() {
        return false;
    }

    _build() {
        if (!this.model.char) {
            this.model.char = {
                text: swotChars[this.itemIndex]
            };
        }

        if (this.parentElement.model.showCellChar) {
            this.char = this.addElement("char", () => TextElement, {
                canEdit: true,
                isTabbable: true
            });
        }
        if (this.parentElement.model.showCellTitle) {
            this.title = this.addElement("title", () => TextElement, {
                autoHeight: true
            });
        }
        this.body = this.addElement("body", () => TextElement);
    }

    _calcProps(props, options) {
        let { size } = props;

        const layoutBounds = new geom.Rect(0, 0, size);
        let remainingTextBounds = layoutBounds;
        let bodyBounds;
        let textLeft = 0;

        if (this.parentElement.model.showCellChar) {
            let charProps = this.char.calcProps(new geom.Size(0.4 * size.height, 0.4 * size.height));
            let charLeft = 0,
                charTop = 0;

            if (this.itemIndex % 2 === 0) {
                charLeft = size.width - charProps.size.width;
            } else {
                textLeft = charProps.size.width;
            }
            if (this.itemIndex < 2) {
                charTop = size.height - charProps.size.height;
            }
            charProps.bounds = new geom.Rect(charLeft, charTop, charProps.size);
            remainingTextBounds = layoutBounds.largestRemainingRect(charProps.bounds);
        }

        if (this.parentElement.model.showCellTitle) {
            let titleProps = this.title.calcProps(new geom.Size(remainingTextBounds.width, remainingTextBounds.height));
            titleProps.bounds = new geom.Rect(textLeft, 0, titleProps.size);
            bodyBounds = remainingTextBounds.largestRemainingRect(titleProps.bounds);
        } else {
            bodyBounds = remainingTextBounds;
        }

        let bodyProps = this.body.calcProps(new geom.Size(bodyBounds.width, bodyBounds.height));
        bodyProps.bounds = bodyBounds;

        return { size };
    }

    get animationElementName() {
        return swotChars[this.itemIndex];
    }
}

export { SwotDiagramItem };

export const elements = {
    SwotDiagram,
};
