import React, { Component, Fragment } from "react";
import styled from "styled-components";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import {
    MenuItem,
    Slider,
    Switch,
    IconButton,
    Icon,
    Tooltip,
    Divider
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

import { AuthoringBlockType, TextStyleType, HorizontalAlignType, AuthoringShapeDirection } from "common/constants";
import { $, _ } from "js/vendor";
import { app } from "js/namespaces";
import { FlexBox } from "js/react/components/LayoutGrid";
import { Gap10, Gap20, Gap5 } from "js/react/components/Gap";
import { dialogTheme } from "js/react/materialThemeOverrides";
import { getStaticUrl } from "js/config";
import { PopupMenu, PopupMenuPaddedContainer } from "js/react/components/PopupMenu";
import { LabeledContainer } from "js/react/components/LabeledContainer";
import { ColorPicker } from "js/react/components/ColorPicker";

import { ControlBar } from "../../EditorComponents/ControlBar";
import { SelectInput } from "../../EditorComponents/SelectInput";
import { ConvertScreenToSelectionLayer } from "./AuthoringHelpers";
import { BoundsBox } from "../SelectionBox";
import { AuthoringBlockEditor } from "./AuthoringBlockEditor";
import { TextFormatWidgetBar } from "./BlockEditors/TextFormatBar";
import { AuthoringBaseEditor } from "./AuthoringBaseEditor";
import { NumericInput } from "../../EditorComponents/Controls";
import { ShadowEditor } from "./Components/ShadowEditor";

const AdjustmentContainer = styled.div.attrs(({ bounds }) => ({
    style: {
        ...bounds.toObject()
    }
}))`
  position: absolute;
  pointer-events: none;
  z-index: 100;
`;

const AdjustmentHandle = styled.div.attrs(({ point }) => ({
    style: {
        left: point.x - 4,
        top: point.y - 4
    }
}))`
  position: absolute;
  background: gold;
  border: solid 1px #333;
  width: 8px;
  height: 8px;
  cursor: pointer;
  pointer-events: auto;
  &:hover {
    background: goldenrod;
  } 
`;

export class AuthoringShapeEditor extends AuthoringBaseEditor {
    state = {
        fill: null,
        stroke: null,
        strokeWidth: null,
        strokeStyle: null,
        opacity: null,
        cornerRadius: null,
        adjustmentHandles: [],
        selectedBlocks: [],
        focusedBlock: null
    }

    constructor(props) {
        super(props);

        this.controlBarRef = React.createRef();
        this.blockEditorRef = React.createRef();
    }

    componentDidMount() {
        super.componentDidMount();

        if (this.props.selection.length == 1) {
            this.setState({
                adjustmentHandles: this.props.selection[0].childElement.getAdjustmentHandles() || []
            });
        }

        window.refresh = () => this.forceUpdate();
    }

    setSelectionState = () => {
        super.setSelectionState();

        this.setState({
            fill: this.getElementValue("fill"),
            stroke: this.getElementValue("stroke"),
            strokeWidth: this.getElementValue("strokeWidth"),
            strokeStyle: this.getElementValue("strokeStyle"),
            cornerRadius: this.getElementValue("cornerRadius"),
            textAlign: this.getElementValue("textAlign"),
            verticalAlign: this.getElementValue("verticalAlign"),
            textInset: this.getElementValue("textInset"),
            fitToText: this.getElementValue("fitToText"),
            direction: this.getElementValue("direction"),
            blockGap: this.getElementValue("blockGap"),
        });
    }

    setStroke = (prop, value) => {
        const modelValues = { [prop]: value };
        for (const element of this.props.selection) {
            if ((prop == "stroke" || prop == "strokeStyle") && element.model.strokeWidth == 0) {
                modelValues.strokeWidth = 1;
            }
            if (prop == "strokeWidth" && value > 0 && element.model.stroke == "none") {
                modelValues.stroke = "background_dark";
            }
        }
        this.setModelValues(modelValues);
    }

    handleSetTextAlign = value => {
        const { refreshCanvasAndSaveChanges } = this.props;

        for (const element of this.props.selection) {
            element.model.textAlign = value;
            for (const block of element.model.blocks) {
                block.textAlign = undefined;
            }
        }

        refreshCanvasAndSaveChanges().then(() => this.setState({ textAlign: value }));
    }

    handleAdjustmentHandleMouseDown = (event, handle) => {
        const element = this.props.selection[0];

        $(document).on("mousemove.drag", event => {
            event.stopPropagation();

            const offset = ConvertScreenToSelectionLayer(event.pageX, event.pageY).multiply(1 / app.currentCanvas.getScale());
            handle.setPosition(offset.x - element.model.x, offset.y - element.model.y);

            element.refreshElement(false);
            this.forceUpdate();
        });

        $(document).on("mouseup.drag", () => {
            $(document).off(".drag");
            element.canvas.saveCanvasModel();
        });
    }

    createInitialTextBlock() {
        this.blockEditorRef.current.handleAddBlock({
            model: {
                type: AuthoringBlockType.TEXT,
                textStyle: TextStyleType.TITLE,
                textAlign: HorizontalAlignType.CENTER,
                html: ""
            },
            insertAtIndex: 0
        });
    }

    sendKeyDownEventToBlock(event) {
        if (this.blockEditorRef.current) {
            return this.blockEditorRef.current.sendKeyDownEventToBlock(event);
        }
    }

    handleSelectedBlocksChanged = (focusedBlock, selectedBlocks) => {
        this.setState({
            focusedBlock,
            selectedBlocks
        });
    }

    render() {
        const { bounds, selection, refreshCanvasAndSaveChanges, refreshElement } = this.props;
        const { focusedBlock, selectedBlocks, fill, stroke, strokeWidth, strokeStyle, opacity, rotation, blockGap, textInset, fitToText, direction, x, y, width, height, shadow } = this.state;

        if (app.currentCanvas.layouter.isGenerating) {
            return null;
        }

        const canChangeDirection = selection.length === 1 && selection[0].canChangeDirection;

        let adjustmentHandles = [];
        if (selection.length === 1) {
            adjustmentHandles = selection[0].childElement.getAdjustmentHandles() || [];
        }

        const blocks = selection.map(element => element.childElement.blockContainerRef.current.blocks).flat();
        const textBlocks = (selectedBlocks.length > 0 ? selectedBlocks : blocks).filter(block => block.type === AuthoringBlockType.TEXT);

        const canChangeFill = !selection.some(element => !element.childElement.canChangeFill);
        const canChangeStroke = !selection.some(element => !element.childElement.canChangeStroke);

        return (
            <BoundsBox
                bounds={bounds.zeroOffset()}
            >
                {selection.length == 1 &&
                    <AuthoringBlockEditor
                        ref={this.blockEditorRef}
                        onSelectedBlocksChanged={this.handleSelectedBlocksChanged}
                        element={selection[0]}
                        {...this.props}
                    />
                }

                {!focusedBlock && textBlocks.length > 0 &&
                    <TextFormatWidgetBar
                        bounds={bounds}
                        containers={selection}
                        selectedBlocks={textBlocks}
                        isMultiSelectMode={true}
                        refreshCanvasAndSaveChanges={refreshCanvasAndSaveChanges}
                        refreshElement={refreshElement}
                    />
                }

                <AdjustmentContainer bounds={bounds.zeroOffset()}>
                    {adjustmentHandles.map((adj, index) => (
                        <AdjustmentHandle key={index}
                            className="adj-handle"
                            point={adj.getPosition().multiply(app.currentCanvas.getScale())}
                            onMouseDown={event => this.handleAdjustmentHandleMouseDown(event, adj)}
                        />
                    ))}
                </AdjustmentContainer>

                {!focusedBlock &&
                    <ControlBar ref={this.controlBarRef}>
                        {canChangeFill &&
                            <ColorPicker
                                label="Fill"
                                color={fill}
                                showNone
                                onChange={color => this.setModelValues({ fill: color })}
                                showColorPicker
                            />
                        }
                        {canChangeStroke &&
                            <ColorPicker
                                label="Border"
                                color={stroke}
                                showNone
                                onChange={color => this.setStroke("stroke", color)}
                                showColorPicker
                            >
                                <FlexBox left middle style={{ marginBottom: 20 }}>
                                    <label>Width</label>
                                    <Gap10 />
                                    <SelectInput
                                        value={strokeWidth}
                                        onChange={value => this.setStroke("strokeWidth", value)}
                                    >
                                        <MenuItem value={0}>0</MenuItem>
                                        <MenuItem value={1}>1</MenuItem>
                                        <MenuItem value={2}>2</MenuItem>
                                        <MenuItem value={3}>3</MenuItem>
                                        <MenuItem value={5}>5</MenuItem>
                                        <MenuItem value={10}>10</MenuItem>
                                    </SelectInput>

                                    <Gap20 />
                                    <label>Style</label>
                                    <Gap10 />

                                    <ToggleButtonGroup value={strokeStyle} exclusive onChange={(event, value) => this.setStroke("strokeStyle", value)}>
                                        <ToggleButton value="solid">
                                            <img src={getStaticUrl("/images/ui/connectors/line-style-solid.svg")} />
                                        </ToggleButton>
                                        <ToggleButton value="dotted">
                                            <img src={getStaticUrl("/images/ui/connectors/line-style-dotted.svg")} />
                                        </ToggleButton>
                                        <ToggleButton value="dashed">
                                            <img src={getStaticUrl("/images/ui/connectors/line-style-dashed.svg")} />
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </FlexBox>
                            </ColorPicker>
                        }

                        {canChangeDirection &&
                            <Tooltip title="Rotate">
                                <IconButton onClick={() => {
                                    const rotatedBounds = {
                                        width: height,
                                        height: width,
                                        x: x - (height - width) / 2,
                                        y: y - (width - height) / 2
                                    };

                                    switch (direction) {
                                        case AuthoringShapeDirection.DOWN:
                                            this.setModelValues({
                                                direction: AuthoringShapeDirection.LEFT,
                                                ...rotatedBounds
                                            });
                                            break;
                                        case AuthoringShapeDirection.LEFT:
                                            this.setModelValues({
                                                direction: AuthoringShapeDirection.UP,
                                                ...rotatedBounds
                                            });
                                            break;
                                        case AuthoringShapeDirection.UP:
                                            this.setModelValues({
                                                direction: AuthoringShapeDirection.RIGHT,
                                                ...rotatedBounds
                                            });
                                            break;
                                        default:
                                            this.setModelValues({
                                                direction: AuthoringShapeDirection.DOWN,
                                                ...rotatedBounds
                                            });
                                            break;
                                    }
                                }}>
                                    <Icon>rotate_right</Icon>
                                </IconButton>
                            </Tooltip>
                        }

                        <PopupMenu icon="settings">
                            <PopupMenuPaddedContainer>
                                {blocks.length > 0 &&
                                    <Fragment>
                                        <MuiThemeProvider theme={dialogTheme}>
                                            <LabeledContainer icon="border_outer" label="Fit to Text">
                                                <Switch
                                                    name="fitToText"
                                                    color="primary"
                                                    checked={fitToText}
                                                    onChange={event => this.setModelValues({ fitToText: event.target.checked })}
                                                />
                                            </LabeledContainer>
                                        </MuiThemeProvider>
                                        <LabeledContainer icon="padding" label="Text Inset">
                                            <NumericInput
                                                value={textInset}
                                                onChange={value => this.setModelValues({
                                                    textInset: value,
                                                    textPadding: null
                                                })}
                                                min={0}
                                                max={200}
                                            />
                                        </LabeledContainer>
                                        <LabeledContainer icon="format_line_spacing" label="Block Spacing">
                                            <NumericInput
                                                value={blockGap}
                                                min={-20}
                                                max={100}
                                                onChange={value => this.setModelValues({ blockGap: value })}
                                            />
                                        </LabeledContainer>
                                        <Divider />
                                    </Fragment>
                                }

                                <LabeledContainer icon="lens_blur" label="Shadow">
                                    <ShadowEditor shadow={shadow} onChange={value => this.setModelValues({ shadow: value })} />
                                </LabeledContainer>
                                <LabeledContainer icon="opacity" label="Opacity">
                                    <Slider
                                        value={opacity}
                                        onChange={(event, value) => this.setModelValues({ opacity: value }, false)}
                                        onChangeCommitted={(event, value) => this.setModelValues({ opacity: value })}
                                        valueLabelDisplay="auto"
                                        min={0}
                                        max={100}
                                    />
                                </LabeledContainer>
                                {/*<LabeledContainer icon="rotate_right" label="Rotation">*/}
                                {/*    <Slider*/}
                                {/*        value={rotation}*/}
                                {/*        onChange={(event, value) => this.setModelValues({ rotation: value }, false)}*/}
                                {/*        onChangeCommitted={(event, value) => this.setModelValues({ rotation: value })}*/}
                                {/*        valueLabelDisplay="auto"*/}
                                {/*        min={-180}*/}
                                {/*        max={180}*/}
                                {/*    />*/}
                                {/*</LabeledContainer>*/}
                            </PopupMenuPaddedContainer>
                        </PopupMenu>

                    </ControlBar>
                }
            </BoundsBox>
        );
    }
}
