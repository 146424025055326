import * as geom from "js/core/utilities/geom";
import { BackgroundStyleType, TextBreakType } from "common/constants";
import { _ } from "js/vendor";

import { SVGRectElement } from "../base/SVGElement";
import { BaseElement } from "../base/BaseElement";
import { TextElement } from "../base/TextElement";

class DisplayText extends BaseElement {
    get selectionPadding() {
        return 0;
    }

    get showRollover() {
        return false;
    }

    get _canSelect() {
        return true;
    }

    get canPosition() {
        return this.getBackgroundColor() == BackgroundStyleType.IMAGE || this.hasBackdrop;
    }

    get textPosition() {
        if (this.canPosition) {
            return this.model.textPosition || "center";
        } else {
            return "center";
        }
    }

    get canStyleText() {
        return super.getBackgroundColor() == BackgroundStyleType.IMAGE;
    }

    get textStyle() {
        if (this.canStyleText) {
            return this.model.textStyle || "none";
        } else {
            return "none";
        }
    }

    get hasBackdrop() {
        return this.textStyle.contains("box");
    }

    get allowTextAlign() {
        return this.options.canAlign || false;
    }

    get showLabel() {
        if (this.showLabelPlaceholder) return true;
        if (this.model.label && !_.isEmpty(this.model.label.text)) return true;
    }

    get showLabelPlaceholder() {
        return this._showLabelPlaceholder == undefined ? false : this._showLabelPlaceholder;
    }

    set showLabelPlaceholder(value) {
        this._showLabelPlaceholder = value;
    }

    get showDescription() {
        if (this.showDescriptionPlaceholder) return true;
        if (this.model.description && !_.isEmpty(this.model.description.text)) return true;
    }

    get showDescriptionPlaceholder() {
        return this._showDescriptionPlaceholder == undefined ? false : this._showDescriptionPlaceholder;
    }

    set showDescriptionPlaceholder(value) {
        this._showDescriptionPlaceholder = value;
    }

    get textAlign() {
        return this.model.textAlign || this.styles.textAlign || "center";
    }

    get textColor() {
        return this.model.textColor || "default";
    }

    get singleLineHeadline() {
        return this.options.singleLineHeadline;
    }

    get requireParentSelection() {
        return false;
    }

    _build() {
        if (this.hasBackdrop) {
            this.backdrop = this.addElement("backdrop", () => SVGRectElement);
        }

        this.headline = this.addElement("headline", () => DisplayTextHeadline, {
            bindTo: "title",
            breakType: TextBreakType.EVEN,
            allowedBreakTypes: [TextBreakType.EVEN, TextBreakType.DEFAULT],
            autoWidth: false,
            autoHeight: true,
            exportSVG: true,
            scaleTextToFit: true,
            singleLine: this.singleLineHeadline,
            allowAlignment: this.allowTextAlign,
            placeholder: "Type header"
        });

        if (this.showLabel) {
            this.label = this.addElement("label", () => DisplayTextLabel, {
                placeholder: "Type label",
                autoWidth: false,
                autoHeight: true,
                allowAlignment: this.allowTextAlign,
                scaleTextToFit: true,
            });
        } else {
            this.label = null;
        }

        if (this.showDescription) {
            this.description = this.addElement("description", () => DisplayTextDescription, {
                placeholder: "Type description",
                autoWidth: false,
                autoHeight: true,
                allowAlignment: this.allowTextAlign,
                scaleTextToFit: true,
            });
        } else {
            this.description = null;
        }
    }

    _calcProps(props, options) {
        let { size } = props;

        this.headline.styles.textAlign = this.textAlign;
        this.label && (this.label.styles.textAlign = this.textAlign);
        this.description && (this.description.styles.textAlign = this.textAlign);

        switch (this.textStyle) {
            case "dark_text":
            case "white_box":
            case "transparent_light_box":
                this.headline.styles.fontColor = "primary_dark";
                this.label && (this.label.styles.fontColor = "primary_dark");
                this.description && (this.description.styles.fontColor = "primary_dark");
                break;
            case "white_text":
            case "transparent_dark_box":
                this.headline.styles.fontColor = "primary_light";
                this.label && (this.label.styles.fontColor = "primary_light");
                this.description && (this.description.styles.fontColor = "primary_light");
                break;
            case "white_text_with_shadow":
                this.headline.styles.fontColor = "primary_light";
                this.label && (this.label.styles.fontColor = "primary_light");
                this.description && (this.description.styles.fontColor = "primary_light");
                this.headline.styles.filter = "textShadow";
                this.label && (this.label.styles.filter = "textShadow");
                this.description && (this.description.styles.filter = "textShadow");
                break;
        }

        let availableTextBounds = new geom.Rect(0, 0, size);

        if (this.textPosition.contains("left")) {
            availableTextBounds = availableTextBounds.deflate({ right: availableTextBounds.width / 2 });
        }
        if (this.textPosition.contains("right")) {
            availableTextBounds = availableTextBounds.deflate({ left: availableTextBounds.width / 2 });
        }
        if (this.textPosition.contains("top")) {
            availableTextBounds = availableTextBounds.deflate({ bottom: availableTextBounds.height / 2 });
        }
        if (this.textPosition.contains("bottom")) {
            availableTextBounds = availableTextBounds.deflate({ top: availableTextBounds.height / 2 });
        }

        let minHeadlineHeight = 50;

        let textScale = 1;
        let labelProps, descProps;
        let availableHeight = size.height;
        if (this.showLabel) {
            labelProps = this.label.calcProps(new geom.Size(size.width, availableHeight - minHeadlineHeight));
            availableHeight -= labelProps.size.height;
            textScale = Math.min(labelProps.textScale, textScale);
        }
        if (this.showDescription) {
            descProps = this.description.calcProps(new geom.Size(size.width, availableHeight - minHeadlineHeight));
            availableHeight -= descProps.size.height;
            textScale = Math.min(descProps.textScale, textScale);
        }

        let headlineProps = this.headline.calcProps(new geom.Size(size.width, availableHeight), { forceTextScale: options.forceTextScale });
        textScale = Math.min(headlineProps.textScale, textScale);

        availableHeight = size.height;

        if (options.scaleTextToFit && textScale < 1) {
            if (this.showLabel) {
                labelProps = this.label.calcProps(new geom.Size(size.width, availableHeight - minHeadlineHeight), { forceTextScale: textScale });
                availableHeight -= labelProps.size.height;
            }
            if (this.showDescription) {
                descProps = this.description.calcProps(new geom.Size(size.width, availableHeight - minHeadlineHeight), { forceTextScale: textScale });
                availableHeight -= descProps.size.height;
            }

            headlineProps = this.headline.calcProps(new geom.Size(size.width, availableHeight), { forceTextScale: textScale });
        }

        let layouter = this.getLayouter(props, [this.label, this.headline, this.description], availableTextBounds.size);
        layouter.distributeVertically({
            horizontalAlign: this.textAlign,
            useCalculatedSizes: true,
        });

        if (this.hasBackdrop) {
            let backdropProps = this.backdrop.createProps({ layer: -1 });
            switch (this.textStyle) {
                case "none":
                    break;
                case "white_box":
                    this.backdrop.updateStyles({ fillColor: "white", fillOpacity: 1 });
                    break;
                case "transparent_light_box":
                    this.backdrop.updateStyles({ fillColor: "white", fillOpacity: 0.5 });
                    break;
                case "transparent_dark_box":
                    this.backdrop.updateStyles({ fillColor: "black", fillOpacity: 0.3 });
                    break;
            }

            let textBounds = headlineProps.textBounds.offset(this.headline.bounds.position);
            if (this.showLabel) {
                textBounds = textBounds.union(labelProps.textBounds.offset(labelProps.bounds.position));
            }
            if (this.showDescription) {
                textBounds = textBounds.union(descProps.textBounds.offset(descProps.bounds.position).offset(this.description.elementBounds.position));
            }

            backdropProps.bounds = textBounds.offset(this.styles.paddingLeft + this.styles.marginLeft, this.styles.paddingTop + this.styles.marginTop).inflate({
                left: this.styles.backdrop.paddingLeft,
                top: this.styles.backdrop.paddingTop,
                right: this.styles.backdrop.paddingRight,
                bottom: this.styles.backdrop.paddingBottom
            });
        }

        return { size: layouter.size };
    }

    getBackgroundColor(forElement) {
        return super.getBackgroundColor(forElement);
        // if (this.decoration) {
        //     return this.getDecorationFillColor(forElement);
        //     // return tinycolor(this.backdrop.styles.fillColor);
        // } else {
        //     return super.getBackgroundColor(forElement);
        // }
    }
}

class DisplayTextHeadline extends TextElement {
    get allowStyling() {
        return true;
    }

    get passThroughSelection() {
        return true;
    }

    get requireParentSelection() {
        return false;
    }

    // get textLayoutType() {
    //     return "even";
    // }

    // getRolloverBounds() {
    //     let bounds = super.getRolloverBounds();
    //     if (!this.model.showLabel) {
    //         bounds = bounds.inflate({top: 50});
    //     }
    //     if (!this.model.showDescription) {
    //         bounds = bounds.inflate({bottom: 50});
    //     }
    //     return bounds;
    // }
}

class DisplayTextLabel extends TextElement {
    get allowStyling() {
        return true;
    }

    get passThroughSelection() {
        return true;
    }

    get requireParentSelection() {
        return false;
    }
}

class DisplayTextDescription extends TextElement {
    get allowStyling() {
        return true;
    }

    get passThroughSelection() {
        return true;
    }

    get requireParentSelection() {
        return false;
    }
}

export { DisplayText };

export const elements = {
    DisplayText
};
