import * as geom from "js/core/utilities/geom";
import { DirectionType, NodeType, ContentBlockType } from "common/constants";
import { _ } from "js/vendor";

import { CollectionElement } from "../base/CollectionElement";
import ConnectorGroup from "./connectors/ConnectorGroup";
import { getNodeElementFromType } from "../base/NodeElement";
import { GridComponent } from "../components/Grid";
import { getContentItemFromType } from "../base/ContentItem";

class NodeDiagram extends CollectionElement {
    get useLegacyNodes() {
        return this.model.useLegacyNodes;
    }

    getChildItemType(model) {
        if (this.useLegacyNodes) {
            return getNodeElementFromType(model.nodeType || NodeType.BULLET_TEXT);
        } else {
            return getContentItemFromType(model.nodeType || NodeType.BULLET_TEXT);
        }
    }

    getAllowedNodeTypes() {
        return [NodeType.BOX, NodeType.CIRCLE, NodeType.DIAMOND, NodeType.CAPSULE, NodeType.TEXT, NodeType.CONTENT_AND_TEXT, NodeType.BULLET_TEXT, NodeType.NUMBERED_TEXT, NodeType.CONTENT];
    }

    get defaultItemData() {
        return {
            x: Math.random(),
            y: Math.random(),
            nodeType: NodeType.BOX,
            textStyle: ContentBlockType.TITLE
        };
    }

    getItemSelectionUIType(node) {
        return "NodeDiagramItemSelection";
    }

    getItemRolloverUIType(node) {
        return "NodeDiagramItemRollover";
    }

    getChildOptions() {
        return { allowConnection: true };
    }

    get showGrid() {
        return this.model.showGrid;
    }

    get gridSize() {
        return 10;
    }

    get snapToGrid() {
        return this.model.snapToGrid == undefined ? true : this.model.snapToGrid;
    }

    get sortedNodes() {
        let nodes = [];
        if (this.itemElements.some(node => node.isCyclicalNode)) {
            nodes = _.sortBy(this.itemElements, node => node.model.y);
        } else {
            let maxLevel = _.maxBy(this.itemElements, node => node.treeLevel).treeLevel;
            for (let i = 0; i <= maxLevel; i++) {
                let nodesAtLevel = this.itemElements.filter(node => node.treeLevel == i);
                nodes = nodes.concat(_.sortBy(nodesAtLevel, node => node.model.y));
            }
        }
        return nodes;
    }

    get allowDragDropElements() {
        return false;
    }

    refreshElement(transition) {
        this.canvas.refreshElement(this, transition);
    }

    get canRefreshElement() {
        return true;
    }

    get disableAllAnimationsByDefault() {
        return true;
    }

    _build() {
        super._build();

        if (this.showGrid) {
            this.grid = this.addElement("grid", () => GridComponent);
            this.grid.layer = -2;
        }

        if (!this.model.connectors) {
            this.model.connectors = {
                items: []
            };
        }

        this.connectors = this.addElement("connectors", () => ConnectorGroup, {
            model: this.model.connectors,
            containerElement: this,
            connectable: "both"
        });
        this.connectors.layer = -1;
    }

    getTextDirection(angle, topTextAbove = false, bottomTextBelow = false) {
        while (angle < 0) angle += 2 * Math.PI;
        while (angle > 2 * Math.PI) angle -= 2 * Math.PI;
        if (angle < 0.01) return DirectionType.RIGHT;
        if (angle < Math.PI / 2 - 0.01) return DirectionType.RIGHT;
        if (angle < Math.PI / 2 + 0.01 && !bottomTextBelow) return DirectionType.RIGHT;
        if (angle < Math.PI / 2 + 0.01 && bottomTextBelow) return DirectionType.BOTTOM;
        if (angle < Math.PI - 0.01) return DirectionType.LEFT;
        if (angle < Math.PI + 0.01) return DirectionType.LEFT;
        if (angle < 3 * Math.PI / 2 - 0.01) return DirectionType.LEFT;
        if (angle < 3 * Math.PI / 2 + 0.01 && !topTextAbove) return DirectionType.RIGHT;
        if (angle < 3 * Math.PI / 2 + 0.01 && topTextAbove) return DirectionType.TOP;
        if (angle < 2 * Math.PI - 0.01) return DirectionType.RIGHT;
        return DirectionType.RIGHT;
    }

    snapPointToGrid(pt) {
        return new geom.Point(Math.round(pt.x / this.gridSize) * this.gridSize, Math.round(pt.y / this.gridSize) * this.gridSize);
    }

    _calcProps(props, options) {
        let { size } = props;
        size.height = Math.round(size.height / this.gridSize) * this.gridSize;
        size.width = Math.round(size.width / this.gridSize) * this.gridSize;

        if (this.showGrid) {
            this.grid.calcProps(size, {
                gridSize: this.gridSize,
                layer: -2
            });
        }

        for (let node of this.itemElements) {
            let pt = new geom.Point(node.model.x * size.width, node.model.y * size.height);

            if (this.snapToGrid) {
                pt = this.snapPointToGrid(pt);
            }

            let nodeProps = node.calcProps(new geom.Size(300, 200));
            nodeProps.bounds = new geom.Rect(pt, nodeProps.size);
        }

        this.connectors.calcProps(size);

        return { size };
    }

    deleteItem(itemId) {
        // delete any connectors that referenced the deleted item
        _.remove(this.model.connectors.items, connector => connector.target == itemId || connector.source == itemId);
        super.deleteItem(itemId);
    }

    buildNodeTree(nodes) {
        if (!nodes) {
            let nodeTree = [];
            let rootNodes = this.itemElements.filter(node => node.connectorsToNode.length == 0);
            if (rootNodes.length) {
                nodeTree.push(rootNodes);
            } else {
                nodeTree.push([_.sortBy(this.itemElements, node => node.model.y)[0]]);
            }
            this.buildNodeTree(nodeTree);
            return nodeTree;
        } else {
            let childNodes = [];
            for (let node of _.last(nodes)) {
                node.treeLevel = nodes.length;
                for (let childNode of node.childNodes) {
                    if (!nodes.flat().includes(childNode)) {
                        childNodes.push(childNode);
                    }
                }
            }
            nodes.push(childNodes);

            if (childNodes.length) {
                this.buildNodeTree(nodes);
            }
        }
    }

    centerLayout() {
        let nodeBounds = this.itemElements.reduce((bounds, element) => bounds.union(element.bounds), this.itemElements[0].bounds);

        let centered = nodeBounds.centerInRect(this.elementBounds);

        let offsetX = Math.round((centered.left - nodeBounds.left) / this.bounds.width * 100) / 100;
        let offsetY = Math.round((centered.top - nodeBounds.top) / this.bounds.height * 100) / 100;

        for (let element of this.itemElements) {
            element.model.x += offsetX;
            element.model.y += offsetY;
        }
    }

    async pasteFromClipboard({ dataType, model }) {
        if (dataType === "element") {
            const modelToPaste = _.cloneDeep(model);
            while (this.itemElements.filter(item => item.model.x === modelToPaste.x && item.model.y === modelToPaste.y).length > 0) {
                modelToPaste.x += 0.05;
                modelToPaste.y += 0.05;
            }
            delete modelToPaste.id;

            const { id } = this.addItem(modelToPaste);
            await this.canvas.updateCanvasModel(false);
            return this.getChild(id);
        }
    }

    getAnimations() {
        const animations = [];

        let nodeIdx = 1;
        const connectorsWithAnimations = [];
        const nodesWithAnimations = [];
        this.nodeTree = this.buildNodeTree();
        this.nodeTree.forEach(nodeTreeLevel => {
            nodeTreeLevel.forEach(node => {
                const nodeText = node.getInnerText(5);
                const nodeName = `Node ${nodeText ? `"${nodeText}"` : `#${nodeIdx}`}`;
                nodeIdx++;

                const nodeAnimations = node.getAnimations();
                nodeAnimations.forEach(animation => animation.elementName = nodeName);
                animations.push(...nodeAnimations);

                node.connectorsToNode
                    .filter(connector => !connectorsWithAnimations.includes(connector))
                    .filter(connector => nodesWithAnimations.includes(connector.startTarget))
                    .forEach(connector => {
                        const connectorName = `→ ${nodeName}`;
                        const connectorAnimations = connector.getAnimations();
                        connectorAnimations.forEach(animation => animation.elementName = connectorName);
                        animations.push(...connectorAnimations);
                        connectorsWithAnimations.push(connector);
                    });

                node.connectorsFromNode
                    .filter(connector => !connectorsWithAnimations.includes(connector))
                    .filter(connector => nodesWithAnimations.includes(connector.endTarget))
                    .forEach(connector => {
                        const connectorName = `← ${nodeName}`;
                        const connectorAnimations = connector.getAnimations();
                        connectorAnimations.forEach(animation => animation.elementName = connectorName);
                        animations.push(...connectorAnimations);
                        connectorsWithAnimations.push(connector);
                    });

                nodesWithAnimations.push(node);
            });
        });

        return animations;
    }

    _migrate_9() {
        this.model.useLegacyNodes = true;
    }
}

class LinearNodeDiagram extends NodeDiagram {
    _calcProps(props, options) {
        let { size } = props;
        let nodes = this.sortedNodes;

        let deltaX = 0.8 / (nodes.length - 1);
        let x = 0.1;

        for (let node of this.itemElements) {
            node.model.x = x;
            node.model.y = 0.5;

            let nodeX = node.model.x * size.width;
            let nodeY = node.model.y * size.height;

            node.bounds = new geom.Rect(nodeX, nodeY, node.calcSize(new geom.Size(300, 200)));
            x += deltaX;
        }

        this.connectors.calcSize(size);
        this.connectors.bounds = new geom.Rect(0, 0, size);

        return { size };
    }
}

export const elements = {
    NodeDiagram,
    LinearNodeDiagram
};

