import { $, _, Backbone, numeral } from "js/vendor";
import { app } from "js/namespaces";
import { controls } from "js/editor/ui";
import { PositionType, FormatType, CHART_DATE_FORMATS } from "common/constants";
import { formatter } from "js/core/utilities/formatter";
import { ShowDialog, ShowWarningDialog } from "js/react/components/Dialogs/BaseDialog";
import DataLockedDialog from "js/react/components/Dialogs/DataLockedDialog";
import { getDataSourceControl } from "js/editor/dataSourceMenu";

import { FormatOptionsMenu } from "../FormatOptionsMenu";

import "css/chart-dialog.scss";
import { SideBar, SideBarCanvasModifier } from "js/editor/legacyEditor/SideBar";

export const ChartDialog = SideBar.extend({
    fullScreen: false,
    className: "chart_dialog",
    type: "ChartPanel",
    lockSlide: true,
    showHeader: true,
    isResizable: true,

    preventCanvasEditingWhenOpen: true,

    verticalGapOffset: 60,

    getTitle: function() {
        return "Edit Chart";
    },

    getCanvasModifier: function() {
        return SideBarCanvasModifier.SCALE;
    },

    getPanelHeight: function() {
        return 230;
    },

    refreshRender: function() {
        this.$el.empty();
        this.$header = null;
        this.render();
        this.renderTabContents("data");
    },

    getSelectedCoords: function() {
        const $el = this.$table.find(":focus");
        if ($el.length) {
            return [
                $el.closest("tr").index() - 1,
                $el.index()
            ];
        } else {
            return null;
        }
    },

    focusSelectedCoords: function(coords) {
        if (coords && this.$table) {
            const row = $(this.$table.find("tr")[coords[0]]);
            const cell = $(row.find("td")[coords[1]]);
            cell.focus();
        }
    },

    renderHeader: function() {
        if (!this.$header) {
            this.$header = this.$el.addEl($.div("header"));
        }
        this.$header.empty();

        var title = this.getTitle();
        if (title) {
            this.$header.addEl($.div("title").text(this.getTitle()));
        }

        this.renderTabBar();

        var $closeButton = this.$header.addEl($.div("close_button"));
        $closeButton.on("click", $.proxy(this.close, this));
    },

    renderTabBar: function() {

    },

});

export const EditChartDialog = ChartDialog.extend({
    setup: function() {
        this.listenTo(app.undoManager, "undo", () => {
            this.currentTab.refreshRender();
        });
        this.listenTo(app.undoManager, "redo", () => {
            this.currentTab.refreshRender();
        });
    },

    renderTabBar: function() {
        let $tabBar = this.$header.addEl($.div("chart-tabbar"));

        this.renderTabs($tabBar);

        $tabBar.on("click", ".tab", event => {
            $tabBar.find(".tab.selected").removeClass("selected");
            $(event.target).addClass("selected");
            this.renderTabContents($(event.target).data("tab"));
        });
    },

    renderTabs: function($tabBar) {
        $tabBar.append($.div("tab selected", "Data").data("tab", "data"));
        $tabBar.append($.div("tab", "Value Axis (Y)").data("tab", "y-axis"));

        let hasYAxis2 = this.element.chart.series.find(serie => serie.yAxis === this.element.chart.yAxis[1]);
        if (hasYAxis2) {
            $tabBar.append($.div("tab", "Right Value Axis (Y2)").data("tab", "y-axis2"));
        }
        $tabBar.append($.div("tab", "Category Axis (X)").data("tab", "x-axis"));

        if (this.element.getChartType() != "waterfall") {
            $tabBar.append($.div("tab", "Chart Options").data("tab", "legend"));
        }
    },

    onShown: function() {
        this.renderTabContents("data");
    },

    renderTabContents: function(tab) {
        this.currentTab = this.getTabContents(tab);
        this.$contents.html(this.currentTab.render().$el);
        this.currentTab.dialog = this;
        this.currentTab.onShown();
    },

    getTabContents(tab) {
        switch (tab) {
            case "data":
                if (this.element.getChartType() == "waterfall") {
                    return new Chart_WaterfallDataTab({ element: this.element });
                } else {
                    return new Chart_DataTab({ element: this.element, dialog: this });
                }
            case "x-axis":
                return new Chart_XAxisTab({ element: this.element });
            case "y-axis":
                return new Chart_YAxisTab({ element: this.element, axis: "yAxis" });
            case "y-axis2":
                return new Chart_YAxisTab({ element: this.element, axis: "yAxis2" });
            case "legend":
                return new Chart_LegendTab({ element: this.element });
        }
    }
});

export const ChartTab = Backbone.View.extend({
    className: "tab-contents",

    initialize: function(options) {
        this.element = options.element;
        this.setup(options);
    },

    setup: function(options) {
    },

    onShown: function() {
    },

    refreshRender: function() {
        this.$el.empty();
        this.render();
    }

});

export const Chart_XAxisTab = ChartTab.extend({
    render: function() {
        let xAxis = this.element.chartModel.xAxis;
        xAxis.labels = xAxis.labels || { enabled: false };

        let $group1 = this.$el.addEl($.div("control-group"));
        // $group1.append(controls.createToggle(this, {
        //     label: "Show Axis",
        //     model: xAxis,
        //     property: "visible"
        // }));

        $group1.append(controls.createToggle(this, {
            label: "Axis Title",
            model: xAxis,
            property: "axisTitle"
        }));

        let $group2 = this.$el.addEl($.div("control-group"));
        $group2.append(controls.createToggle(this, {
            label: "Axis Line",
            model: xAxis,
            property: "showAxisLine"
        }));

        $group2.append(controls.createToggle(this, {
            label: "Grid Lines",
            model: xAxis,
            property: "showGridLines"
        }));
        $group2.append(controls.createToggle(this, {
            label: "Ticks",
            model: xAxis,
            property: "showMajorTicks"
        }));

        let $group3 = this.$el.addEl($.div("control-group"));
        $group3.append(controls.createToggle(this, {
            label: "Show Labels",
            model: xAxis.labels,
            property: "enabled",
            callback: () => this.refreshRender()
        }));

        let dateFormats = [];
        for (let format of CHART_DATE_FORMATS) {
            if (format == "none") {
                dateFormats.push({ label: "None", value: "none" });
            } else {
                dateFormats.push({ label: formatter.formatDate(new Date(), format), value: format });
            }
        }
        $group3.append(controls.createDropdownMenu(this, {
            label: "Date Format",
            items: dateFormats,
            value: xAxis.dateFormatting || dateFormats[0].value,
            enabled: xAxis.labels.enabled,
            callback: value => {
                xAxis.dateFormatting = value;
                app.currentCanvas.updateCanvasModel(true);
            }
        }));
        $group3.append(controls.createDropdownMenu(this, {
            label: "Label Rotate",
            items: [{
                value: "auto", label: "Auto"
            }, {
                value: 0, label: "None"
            }, {
                value: -45, label: "45 degrees"
            }],
            value: xAxis.labels.rotation ?? "auto",
            enabled: xAxis.labels.enabled,
            callback: value => {
                xAxis.labels.rotation = (value == "auto") ? null : value;
                app.currentCanvas.updateCanvasModel(true);
            }
        }));

        let $group4 = this.$el.addEl($.div("control-group"));

        $group4.append(controls.createToggle(this, {
            label: "Axis Padding",
            value: !xAxis.zeroAxisPadding,
            callback: value => {
                xAxis.zeroAxisPadding = !value;
                app.currentCanvas.updateCanvasModel(true);
            },
            enabled: !this.element.chartModel.series.some(s => s.type == "column" || s.type == "waterfall")
        }));

        return this;
    }
});

export const Chart_YAxisTab = ChartTab.extend({
    setup: function(options) {
        this.axis = options.axis;
    },

    render: function() {
        let axisModel = this.element.chartModel[this.axis];

        let $group1 = this.$el.addEl($.div("control-group"));
        // $group1.append(controls.createToggle(this, {
        //     label: "Show Axis",
        //     model: axisModel,
        //     property: "visible",
        // }));
        $group1.append(controls.createDropdownMenu(this, {
            label: "Axis Title",
            model: axisModel,
            property: "axisTitle",
            items: [{ value: "none", label: "None" }, { value: "edge", label: "On Axis" }, {
                value: "top",
                label: "Top of Axis"
            }]
        }));

        $group1.append(controls.createToggle(this, {
            label: "Flip Axis",
            model: axisModel,
            property: "opposite",
            enabled: this.element.chartModel.yAxis2.visible == false
        }));

        $group1.append(controls.createDropdownMenu(this, {
            label: "Axis Type",
            model: axisModel,
            property: "axisType",
            items: [{
                value: "linear", label: "Linear"
            }, {
                value: "logarithmic", label: "Logarithmic"
            }]
        }));

        let $group2 = this.$el.addEl($.div("control-group"));

        $group2.append(controls.createToggle(this, {
            label: "Axis Line",
            model: axisModel,
            property: "showAxisLine"
        }));

        $group2.append(controls.createToggle(this, {
            label: "Grid Lines",
            model: axisModel,
            property: "showGridLines"
        }));

        $group2.append(controls.createToggle(this, {
            label: "Ticks",
            model: axisModel,
            property: "showMajorTicks"
        }));

        $group2.append(controls.createToggle(this, {
            label: "Show Labels",
            model: axisModel.labels || {},
            property: "enabled"
        }));

        let $axisFormat = this.$el.addEl($.div("control-group"));

        let axisFormat = axisModel.format || FormatType.NUMBER;
        let axisFormatOptions = axisModel.formatOptions || formatter.getDefaultFormatOptions();

        $axisFormat.append(controls.createDropdownMenu(this, {
            label: "Axis Format",
            value: axisFormat.toTitleCase(),
            menuContents: closeMenu => {
                let $menu = new FormatOptionsMenu({
                    format: axisFormat,
                    formatOptions: axisFormatOptions,
                    allowedFormats: [FormatType.NUMBER, FormatType.CURRENCY, FormatType.PERCENT],
                    callback: format => {
                        axisModel.format = format.format;
                        axisModel.formatOptions = format.formatOptions;
                        this.element.canvas.updateCanvasModel(false);
                    },
                }).render().$el;
                return $menu;
            },
            onClose: () => {
                this.refreshRender();
            }
        }));

        $axisFormat.append(controls.createDropdownMenu(this, {
            label: "Scale Values",
            value: axisModel.labelFormat,
            items: [
                { value: "ones", label: "None" },
                { value: "thousands", label: "Thousands" },
                { value: "millions", label: "Millions" },
                { value: "billions", label: "Billions" },
                { value: "trillions", label: "Trillions" },
                { value: "percent", label: "Percent" }
            ],
            callback: format => {
                axisModel.labelFormat = format;
                app.currentCanvas.updateCanvasModel(true);
            }
        }));

        $axisFormat.append(controls.createInput(this, {
            label: "Prefix",
            model: axisModel,
            property: "prefix"
        }));
        $axisFormat.append(controls.createInput(this, {
            label: "Suffix",
            model: axisModel,
            property: "suffix"
        }));

        let $axisRange = this.$el.addEl($.div("control-group"));

        $axisRange.addEl(controls.createInput(this, {
            label: "Axis Min",
            model: axisModel,
            property: "min",
            placeholder: "auto",
            callback: value => {
                // Ensure the value is a number and that it only has one decimal point
                value = parseFloat(value);
                value = isNaN(value) ? undefined : Math.round(value * 10) / 10;
                // Ensure min is less than max
                if (value > axisModel.max) {
                    axisModel.min = axisModel.max;
                    axisModel.max = value;
                } else {
                    axisModel.min = value;
                }
                this.element.canvas.updateCanvasModel(false);
                this.refreshRender();
            },
        }));
        $axisRange.append(controls.createInput(this, {
            label: "Axis Max",
            model: axisModel,
            property: "max",
            placeholder: "auto",
            callback: value => {
                // Ensure the value is a number and that it only has one decimal point
                value = parseFloat(value);
                value = isNaN(value) ? undefined : Math.round(value * 10) / 10;
                // Ensure max is greater than min
                if (value < axisModel.min) {
                    axisModel.max = axisModel.min;
                    axisModel.min = value;
                } else {
                    axisModel.max = value;
                }
                this.element.canvas.updateCanvasModel(false);
                this.refreshRender();
            },
        }));

        $axisRange.append(controls.createNumericStepper(this, {
            label: "Axis Steps",
            model: axisModel,
            property: "tickAmount",
            min: 1,
            max: 30,
            step: 1,
            placeholder: "auto",
        }));

        $axisRange.append(controls.createToggle(this, {
            label: "Show Minimum",
            model: axisModel,
            property: "showFirstLabel"
        }));

        return this;
    }
});

export const Chart_LegendTab = ChartTab.extend({
    render: function() {
        let $legend = this.$el.addEl($.div("control-group"));

        $legend.append(controls.createDropdownMenu(this, {
            label: "Chart Legend",
            value: this.element.model.legendPosition,
            items: [{
                value: "off",
                label: "None"
            }, {
                value: PositionType.BOTTOM,
                label: "Below"
            }, {
                value: PositionType.TOP,
                label: "Above"
            }, {
                value: PositionType.RIGHT,
                label: "Right"
            }, {
                value: PositionType.LEFT,
                label: "Left"
            }, {
                value: "proximate",
                label: "With Series"
            }],
            callback: value => {
                this.element.model.legendPosition = value;
                this.element.lastBounds = null;

                app.currentCanvas.layouter.clear(); // this is a special case fix to deal with a highcharts bug when changing legend to/from proximate
                this.element.canvas.updateCanvasModel(false);
            }
        }));

        $legend.append(controls.createToggle(this, {
            label: "Reverse",
            property: "legendReverse",
            model: this.element.model
        }));

        let $chartOptions = this.$el.addEl($.div("control-group"));

        $chartOptions.append(controls.createDropdownMenu(this, {
            label: "Chart Stacking",
            value: this.element.chartModel.series[0].stacking || "none",
            items: [{
                value: "none",
                label: "None"
            }, {
                value: "normal",
                label: "Stacked"
            }, {
                value: "percent",
                label: "100% Stacked"
            }],
            callback: newStacking => {
                // Update yAxis format if we're switching to/from "percent" stacking
                const oldStacking = this.element.chartModel.series[0].stacking;
                if (oldStacking !== "percent" && newStacking == "percent") {
                    this.element.chartModel.yAxis.format = "percent";
                    this.element.chartModel.yAxis.labelFormat = "percent";
                } else if (oldStacking == "percent" && newStacking !== "percent") {
                    this.element.chartModel.yAxis.format = "number";
                    this.element.chartModel.yAxis.labelFormat = "ones";
                }

                this.element.chartModel.series.forEach(series => {
                    series.stacking = newStacking !== "none" ? newStacking : null;
                });
                this.element.canvas.updateCanvasModel(true);
            }
        }));

        return this;
    }
});

export const Chart_DataTab = ChartTab.extend({
    className: "standard-chart",

    setup: function(options) {
        this.dialog = options.dialog;
    },

    getChartData: function() {
        return this.element.chartModel;
    },

    getMaxDataLength: function() {
        return 250;
    },

    getColCount: function() {
        return _.maxBy(this.getChartData().series, s => s.data.length || 0).data.length;
    },

    refreshRender: function() {
        const scroll = this.$table.scrollLeft();
        const coords = this.getSelectedCoords();
        this.render();
        this.$table.scrollLeft(scroll);
        this.focusSelectedCoords(coords);
    },

    getSelectedCoords: function() {
        const $el = this.$table.find(":focus");
        if ($el.length) {
            return [
                $el.closest("tr").index() - 1,
                $el.index()
            ];
        } else {
            return null;
        }
    },

    focusSelectedCoords: function(coords) {
        if (coords && this.$table) {
            const row = $(this.$table.find("tr")[coords[0]]);
            const cell = $(row.find("td")[coords[1]]);
            cell.focus();
        }
    },

    render: function() {
        this.$el.empty();

        this.chartData = this.getChartData();

        let $tableFrame = this.$el.addEl($.div("table-frame"));
        let $tableBox = $tableFrame.addEl($.div("table_box"));

        this.$seriesTable = $tableBox.addEl($.table("series"));
        this.$table = $tableBox.addEl($.table("datatable"));

        if (!this.element.parentElement.hasDataSourceLink()) {
            this.$seriesTable.sortable({
                axis: "y",
                handle: ".series_drag_handle",
                helper: "clone",
                containment: "parent",
                start: (event, ui) => {
                    this.$table.velocity("transition.fadeOut", { display: "inline-block" });
                },
                stop: (event, ui) => {
                    let series = _.find(this.chartData.series, { id: ui.item.data("series-id") });
                    this.chartData.series.remove(series);
                    this.chartData.series.insert(series, this.$seriesTable.children().index(ui.item) - 1);

                    this.refreshRender();
                    this.element.canvas.updateCanvasModel(false);
                }
            });
        }

        // create column widgets
        let $colWidgets = this.$table.addEl($.div("colWidgets"));
        for (let i = 0; i < this.getColCount(); i++) {
            let $colWidget = $colWidgets.addEl($.div("colWidget"));
            $colWidget.append(controls.createPopupButton(this, {
                label: i + 1,
                showArrow: true,
                enabled: !this.element.parentElement.hasDataSourceLink(),
                items: [{
                    value: "add_before", label: "Add Column Before"
                }, {
                    value: "add_after", label: "Add Column After"
                }, {
                    type: "divider"
                }, {
                    value: "delete", label: "Delete Column"
                }],
                callback: item => {
                    switch (item) {
                        case "add_before":
                            this.insertColumn(i);
                            break;
                        case "add_after":
                            this.insertColumn(i + 1);
                            break;
                        case "delete":
                            this.deleteColumn(i);
                            break;
                    }
                }

            }));
        }

        // create the categories from the xAxis data
        this.createCategoriesRow();

        // blank row for series so the series table is aligned with data
        let blankSeriesRow = $.tr("blank");

        // adding shared datasource control menu
        let $importDataControl = getDataSourceControl(this.element.parentElement, this);
        blankSeriesRow.addEl($importDataControl);

        this.$seriesTable.addEl(blankSeriesRow);

        // create a table row for each data series
        for (var series of this.chartData.series) {
            this.createSeriesRow(series);
        }

        if (!this.element.parentElement.hasDataSourceLink()) {
            let $addColBtn = $tableBox.addEl($.div("add_column_button"));
            $addColBtn.on("click", () => this.addColumn());

            if (this.chartData.series.length < 10) {
                $tableFrame.append(controls.createButton(this, {
                    className: "add-series-button",
                    label: "Add Series",
                    icon: "add",
                    callback: () => this.addSeries()
                }));
            }
        } else {
            this.$table.addClass("datasource_linked");
            $tableBox.on("dblclick", () => {
                ShowDialog(DataLockedDialog, { element: this.element.parentElement, elementEditor: this });
            });
        }

        this.$table.editableTableWidget();

        // _.defer(() => {
        $colWidgets.width(this.$table[0].scrollWidth);
        // });

        // listen for changes to the add_column column to add a new column
        // this.$table.on("change", "td.add_column", event => this.addColumn(event));

        this.$table.on("focus", "tr.series td.data_value", event => {
            this.isEditing = false;
            var $cell = $(event.currentTarget);

            var $seriesRow = $cell.parent();

            var seriesId = $seriesRow.attr("data-series-id");
            var index = $seriesRow.find("td.data_value").index($cell);

            if (this.element.chart[seriesId] && this.element.chart[seriesId].points.length > index) {
                this.element.chart[seriesId].points[index].setState("hover");
            }
        });

        this.$table.on("blurEditor", "tr.series td.data_value", event => {
            this.isEditing = true;
            var $seriesRow = $(event.currentTarget).parent();

            var seriesId = $seriesRow.attr("data-series-id");
            var index = $seriesRow.find("td.data_value").index($(event.currentTarget));

            if (this.element.chart[seriesId] && this.element.chart[seriesId].points.length > index) {
                this.element.chart[seriesId].points[index].setState("");
            }
        });

        return this;
    },

    onShown: function() {
        $(".colWidgets").width(this.$table[0].scrollWidth);
    },

    createCategoriesRow: function() {
        let $categories = this.$table.addEl($.tr("categories"));
        let categories = this.chartData.xAxis.categories;

        this.renderData($categories, categories, "category");
        $categories.on("change", "td:not(.add_column)", event => this.updateCategories(event));
    },

    createSeriesRow: function(seriesModel) {
        // create a new row in the series table to hold series info
        var $seriesRow = this.$seriesTable.addEl($.tr("series"));
        $seriesRow.attr("data-series-id", seriesModel.id);

        var $labelTD = $seriesRow.addEl($.td("series_label"));
        var $labelContainer = $labelTD.addEl($.div());

        if (!this.element.parentElement.hasDataSourceLink()) {
            $labelContainer.addEl($.div("series_drag_handle"));
        } else {
            $labelTD.addClass("datasource_linked");
        }

        // series label
        var $labelInput = $labelContainer.addEl($.input("text").val(seriesModel.name || "Untitled"));
        $labelInput.on("change", event => {
            seriesModel.name = $(event.currentTarget).val();
            this.element.canvas.updateCanvasModel(false);
        });
        $labelInput.on("keydown", event => {
            if (event.which == 9) {
                this.$table.find(`tr[data-series-id='${$(event.currentTarget).closest("tr").data("series-id")}'] td:first-child`).focus();
                event.preventDefault();
            }
        });

        // series type
        $labelContainer.append(controls.createIconSelectMenu(this, {
            showArrow: false,
            selectedIcon: () => {
                switch (seriesModel.type) {
                    case "line":
                        return "/images/ui/charts/chart_line.svg";
                    case "column":
                        return "/images/ui/charts/chart_column.svg";
                    case "area":
                        return "/images/ui/charts/chart_area.svg";
                    case "spline":
                        return "/images/ui/charts/chart_spline.svg";
                    case "areaspline":
                        return "/images/ui/charts/chart_areaspline.svg";
                }
            },
            items: closeMenu => [{
                type: "control",
                view: () => controls.createIconGrid(this, {
                    value: seriesModel.type,
                    items: [{
                        value: "line",
                        label: "Line",
                        icon: "/images/ui/charts/chart_line.svg"
                    }, {
                        value: "column",
                        label: "Column",
                        icon: "/images/ui/charts/chart_column.svg"
                    }, {
                        value: "area",
                        label: "Area",
                        icon: "/images/ui/charts/chart_area.svg"
                    }, {
                        value: "spline",
                        label: "Spline",
                        icon: "/images/ui/charts/chart_spline.svg"
                    }, {
                        value: "areaspline",
                        label: "Area Spline",
                        icon: "/images/ui/charts/chart_areaspline.svg"
                    }],
                    callback: value => {
                        seriesModel.type = value;
                        this.element.canvas.layouter.clear();
                        this.refreshRender();
                        this.element.canvas.updateCanvasModel(false);
                        closeMenu();
                    }
                })
            }]
        }));

        //series color
        let seriesColorProps = this.element.getSeriesProps(seriesModel);
        $labelContainer.append(controls.createColorPicker(this, {
            model: seriesModel,
            includeAuto: true,
            includePicker: true,
            property: "colorName",
            selectedColor: seriesColorProps.color || seriesColorProps.lineColor,
            paletteColors: this.element.canvas.getTheme().palette.getChartColors()
        }));

        // series options
        var $optionsMenu = controls.createMenu(this, {
            icon: "settings",
            showArrow: false,
            menuContents: closeMenu => {
                var $menu = $.grid();

                if (seriesModel.type != "column") {
                    $menu.append(controls.createNumericStepper(this, {
                        label: "Line Width",
                        value: seriesModel.lineWidth || this.element.styles.series[seriesModel.type].lineWidth,
                        callback: value => {
                            seriesModel.lineWidth = value;
                            this.element.markStylesAsDirty();
                            this.element.canvas.updateCanvasModel(false);
                        },
                        min: 1,
                        max: 25
                    }));
                }

                $menu.append(controls.createToggle(this, {
                    label: "Data Labels",
                    model: seriesModel,
                    property: "showDataLabels"
                }));

                if (seriesModel.type != "column") {
                    $menu.append(controls.createDropdownMenu(this, {
                        label: "Data Markers",
                        nullValue: "none",
                        menuClass: "marker_type_menu",
                        items: [{
                            value: "none",
                            label: "None",
                            image: "/images/ui/icons/chart_markers/marker_none.png"
                        }, {
                            value: "circle",
                            label: "Circle",
                            image: "/images/ui/icons/chart_markers/marker_circle.png"
                        }, {
                            value: "square",
                            label: "Square",
                            image: "/images/ui/icons/chart_markers/marker_square.png"
                        }, {
                            value: "triangle",
                            label: "Triangle",
                            image: "/images/ui/icons/chart_markers/marker_triangle.png"
                        }, {
                            value: "triangle-down",
                            label: "Down Triangle",
                            image: "/images/ui/icons/chart_markers/marker_down.png"
                        }, {
                            value: "diamond",
                            label: "Diamond",
                            image: "/images/ui/icons/chart_markers/marker_diamond.png"
                        }],
                        value: seriesModel.marker,
                        callback: value => {
                            seriesModel.marker = value;
                            app.currentCanvas.layouter.clear(); // this is a special case fix to deal with a highcharts bug when changing legend to/from proximate
                            app.currentCanvas.updateCanvasModel(false);
                        }
                    }));
                }

                $menu.append($.hr());

                $menu.append(controls.createToggle(this, {
                    model: seriesModel,
                    label: "Plot on Right Axis",
                    toggled: seriesModel.yAxis == 1,
                    callback: value => {
                        seriesModel.yAxis = value ? 1 : 0;
                        this.chartData.yAxis.opposite = false;
                        this.element.canvas.updateCanvasModel(true).then(() => {
                            this.dialog.refreshRender();
                        });
                    }
                }));

                $menu.append($.hr());

                if (!this.element.parentElement.hasDataSourceLink()) {
                    $menu.append($.hr());

                    $menu.append(controls.createButton(this, {
                        label: "Delete Series",
                        callback: () => {
                            this.deleteSeries(seriesModel);
                            closeMenu();
                        }
                    }));
                }

                return $menu;
            }
        });
        $labelContainer.addEl($optionsMenu);

        // create a new row to hold the data in the series
        var $seriesDataRow = this.$table.addEl($.tr("series").attr("data-series-id", seriesModel.id));

        var seriesData = seriesModel.data;

        this.renderData($seriesDataRow, seriesData, "data_value");

        // listen for changes to update the data in the series
        $seriesDataRow.on("change", "td.data_value", event => {
            this.isEditing = true;

            let enteredValue = $(event.target).text();
            if (_.isEmpty(enteredValue)) {
                enteredValue = null;
            } else {
                enteredValue = numeral(enteredValue).value();
                // If entered a malformed number then revert the value
                if (!_.isFinite(enteredValue)) {
                    enteredValue = numeral($(event.target).data("model").y).value();
                }
            }

            // If datasource linked to the element then don't allow content changes
            if (this.element.parentElement.hasDataSourceLink()) {
                enteredValue = numeral($(event.target).data("model").y).value();
            }

            // If entered an empty value then delete annotations for the point
            if (enteredValue === null) {
                this.deleteAnnotationsForPoint(seriesModel.id, $(event.target).data("index"));
            }

            // Updating the point value
            $(event.target).text(enteredValue);
            $(event.target).data("model").y = enteredValue;

            app.currentCanvas.updateCanvasModel(true);
        });

        return $seriesRow;
    },

    renderData($el, data, tdClass) {
        $el.empty();
        for (let i = 0; i < this.getColCount(); i++) {
            if (i < data.length) {
                const point = data[i];

                if (_.isObject(point)) {
                    const $td = $el.addEl($.td(tdClass).text(_.defaultTo(point.y, "")));
                    $td.data("model", point);
                    $td.data("index", i);
                } else {
                    $el.addEl($.td(tdClass).text(point));
                }
            }
        }
        this.$table.editableTableWidget();
    },

    addColumn: function() {
        const categories = _.map(this.$table.find("tr.categories").find("td:not(.add_column)"), td => $(td).text());
        if (categories.length >= this.getMaxDataLength()) {
            return ShowWarningDialog({
                title: "Can't add data",
                message: `Charts are limited to ${this.getMaxDataLength()} data points.`,
            });
        }

        categories.push("");
        this.setXAxis(categories);

        for (const series of this.chartData.series) {
            series.data.push({ y: null, pt: true });
        }

        this.refreshRender();

        this.$table.scrollLeft(this.$table[0].scrollWidth - this.$table.width());
        for (const row of this.$table.find("tr:not(.new_data)")) {
            const $cell = $(row).find("td:not(.add_column)").last();
            // Focusing on the added value cell
            if (!$cell.hasClass("category")) {
                $cell.focus();
                this.$table[0].showEditor();
                break;
            }
        }

        this.element.canvas.updateCanvasModel(true);
    },

    setXAxis(categories) {
        this.chartData.xAxis.categories = categories;
        this.chartData.xAxis.autoType = "linear";
    },

    insertColumn: function(index) {
        if (this.chartData.xAxis.categories.length >= this.getMaxDataLength()) {
            return ShowWarningDialog({
                title: "Can't add data",
                message: `Charts are limited to ${this.getMaxDataLength()} data points.`,
            });
        }

        const annotations = this.element.model.chartAnnotations;
        if (annotations) {
            if (annotations.connections) {
                annotations.connections.items.forEach(connector => {
                    const { sourceSnapOptions, targetSnapOptions, labels } = connector;

                    if (sourceSnapOptions && sourceSnapOptions.pointIndex >= index) {
                        sourceSnapOptions.pointIndex++;
                    }
                    if (targetSnapOptions && targetSnapOptions.pointIndex >= index) {
                        targetSnapOptions.pointIndex++;
                    }

                    if (labels && labels.length > 0) {
                        labels.forEach(label => {
                            const { dataSource } = label;

                            if (dataSource) {
                                if (dataSource.pointAIndex >= index) {
                                    dataSource.pointAIndex++;
                                }
                                if (dataSource.pointBIndex >= index) {
                                    dataSource.pointBIndex++;
                                }
                            }
                        });
                    }
                });
            }

            if (annotations.items) {
                annotations.items.forEach(annotation => {
                    const { dataSource, snapOptions } = annotation;

                    if (dataSource && dataSource.pointIndex >= index) {
                        dataSource.pointIndex++;
                    }
                    if (snapOptions && snapOptions.pointIndex >= index) {
                        snapOptions.pointIndex++;
                    }
                });
            }
        }

        const xAxisModel = this.chartData.xAxis;
        const categories = _.clone(xAxisModel.categories);
        categories.insert("", index);
        this.setXAxis(categories);

        for (const seriesModel of this.chartData.series) {
            seriesModel.data.insert({ y: null, pt: true }, index);
        }

        this.refreshRender();
        this.element.canvas.updateCanvasModel(true);
    },

    deleteColumn: function(index) {
        if (this.$table.find("tr.categories td:not(.add_column)").length == 1) {
            return ShowWarningDialog({
                title: "Can't delete column",
                message: "You can't delete the only column in this chart.",
            });
        }

        const annotations = this.element.model.chartAnnotations;
        if (annotations) {
            if (annotations.items) {
                annotations.items.forEach(annotation => {
                    const { id, dataSource, snapOptions } = annotation;

                    if ((dataSource && dataSource.pointIndex === index) ||
                        (snapOptions && snapOptions.pointIndex === index)) {
                        this.element.annotations.deleteItem(id);
                        return;
                    }

                    if (dataSource && dataSource.pointIndex > index) {
                        dataSource.pointIndex--;
                    }
                    if (snapOptions && snapOptions.pointIndex > index) {
                        snapOptions.pointIndex--;
                    }
                });
            }

            if (annotations.connections) {
                annotations.connections.items.forEach(connector => {
                    const { id, source, sourceSnapOptions, targetSnapOptions, labels } = connector;

                    if ((sourceSnapOptions && sourceSnapOptions.pointIndex === index) ||
                        (targetSnapOptions && targetSnapOptions.pointIndex === index)) {
                        const sourceAnnotation = annotations.items ? annotations.items.find(annotation => annotation.id === source) : null;
                        if (sourceAnnotation) {
                            this.element.annotations.deleteItem(sourceAnnotation.id);
                        }

                        this.element.annotations.connectors.deleteItem(id);
                        return;
                    }

                    if (sourceSnapOptions && sourceSnapOptions.pointIndex > index) {
                        sourceSnapOptions.pointIndex--;
                    }
                    if (targetSnapOptions && targetSnapOptions.pointIndex > index) {
                        targetSnapOptions.pointIndex--;
                    }

                    if (labels && labels.length > 0) {
                        labels.forEach(label => {
                            const { dataSource } = label;

                            if (dataSource) {
                                if (dataSource.pointAIndex > index) {
                                    dataSource.pointAIndex--;
                                }
                                if (dataSource.pointBIndex > index) {
                                    dataSource.pointBIndex--;
                                }
                            }
                        });
                    }
                });
            }
        }

        const xAxisModel = this.chartData.xAxis;
        const categories = _.clone(xAxisModel.categories);
        categories.splice(index, 1);
        this.setXAxis(categories);

        for (const seriesModel of this.chartData.series) {
            seriesModel.data.splice(index, 1);
        }

        this.refreshRender();
        this.element.canvas.updateCanvasModel(true);
    },

    addSeries: function() {
        let lastSeries = this.chartData.series.length > 0 ? _.last(this.chartData.series) : null;

        let seriesColor;
        if (lastSeries.colorName.startsWith("chart")) {
            seriesColor = "chart" + (parseInt(lastSeries.colorName.substr(5)) % Object.keys(this.element.canvas.getTheme().palette.getChartColors()).length + 1);
        } else {
            seriesColor = "chart1";
        }

        let chartColors = this.element.canvas.getTheme().palette.getChartColors();
        let lastSeriesColorIndex = lastSeries.colorName;

        let newSeries = {
            id: "series" + new Date().getTime(),
            name: "Untitled",
            type: lastSeries ? lastSeries.type : "line",
            data: this.generateRandomSeriesData(lastSeries.data.length, _.dropRightWhile(this.chartData.xAxis.categories, c => c == "").length, _.maxBy(lastSeries.data, pt => pt.y).y),
            colorName: seriesColor,
            marker: "none",
            lineWidth: lastSeries.lineWidth,
            stacking: this.element.chartModel.series[0]?.stacking,
            zones: [
                {
                    style: "default",
                }
            ],
        };

        var newSeriesModel = this.chartData.series.push(newSeries);

        this.refreshRender();
        this.element.canvas.updateCanvasModel(true);
    },

    generateRandomSeriesData: function(totalLength, categoryLength, max) {
        let data = [];
        for (let i = 0; i < totalLength; i++) {
            if (i < categoryLength) {
                data.push({ y: parseInt(Math.random() * max), pt: true });
            } else {
                data.push({ pt: true });
            }
        }
        return data;
    },

    generateExponentialSeriesData: function(length, max) {
        let data = [],
            exponent = 2,
            n = Math.pow(exponent, length) - 1,
            d = exponent - 1,
            t = max / (n / d);

        for (let i = 0; i < length; i++) {
            let val = Math.round(t * Math.pow(exponent, i + 1));
            data.push({ y: val, pt: true });
        }

        return data;
    },

    deleteSeries: function(seriesModel) {
        if (this.$seriesTable.find("tr[data-series-id]").length == 1) {
            return ShowWarningDialog({
                title: "Can't delete series",
                message: "You can't delete the only series from this chart.",
            });
        }
        this.chartData.series.remove(seriesModel);

        const annotations = this.element.annotations;
        if (annotations) {
            annotations.itemCollection.forEach(item => {
                if (item.dataSource?.seriesId === seriesModel.id) {
                    annotations.deleteItem(item.id);
                }
            });
        }

        this.refreshRender();
        this.element.canvas.updateCanvasModel(true);
    },

    updateCategories: function(event) {
        this.setXAxis(_.map(this.$table.find("tr.categories").find("td:not(.add_column)"), td => $(td).text()));

        this.element.canvas.updateCanvasModel(true);
    },

    deleteAnnotationsForPoint: function(seriesId, pointIndex) {
        const annotations = this.element.model.chartAnnotations;
        if (annotations) {
            if (annotations.items) {
                annotations.items.forEach(annotation => {
                    const { id, dataSource, snapOptions } = annotation;

                    if ((dataSource && dataSource.seriesId === seriesId && dataSource.pointIndex === pointIndex) ||
                        (snapOptions && snapOptions.seriesId === seriesId && snapOptions.pointIndex === pointIndex)) {
                        this.element.annotations.deleteItem(id);
                    }
                });
            }

            if (annotations.connections) {
                annotations.connections.items.forEach(connector => {
                    const { id, source, sourceSnapOptions, targetSnapOptions } = connector;

                    if ((sourceSnapOptions && sourceSnapOptions.seriesId === seriesId && sourceSnapOptions.pointIndex === pointIndex) ||
                        (targetSnapOptions && targetSnapOptions.seriesId === seriesId && targetSnapOptions.pointIndex === pointIndex)
                    ) {
                        const sourceAnnotation = annotations.items ? annotations.items.find(annotation => annotation.id === source) : null;
                        if (sourceAnnotation) {
                            this.element.annotations.deleteItem(sourceAnnotation.id);
                        }

                        this.element.annotations.connectors.deleteItem(id);
                    }
                });
            }
        }
    }
});

export const Chart_WaterfallDataTab = Chart_DataTab.extend({
    className: "waterfall-chart",

    render: function() {
        this.$el.empty();

        this.chartData = this.element.model.chartData;

        let $row = this.$el.addEl($.div("row"));

        let $importDataControl = getDataSourceControl(this.element.parentElement, this);
        $row.append($importDataControl);

        $row.append(controls.createColorPicker(this, {
            label: "Positive Bar Color",
            model: this.chartData,
            property: "positiveBarColor",
            paletteColors: this.element.canvas.getTheme().palette.getChartColors()
        }));

        $row.append(controls.createColorPicker(this, {
            label: "Negative Bar Color",
            model: this.chartData,
            property: "negativeBarColor",
            paletteColors: this.element.canvas.getTheme().palette.getChartColors()
        }));

        $row.append(controls.createColorPicker(this, {
            label: "Sum Bar Color",
            model: this.chartData,
            property: "sumBarColor",
            paletteColors: this.element.canvas.getTheme().palette.getChartColors()
        }));

        let $tableFrame = this.$el.addEl($.div("table-frame"));
        let $tableBox = $tableFrame.addEl($.div("table_box"));

        this.$seriesTable = $tableBox.addEl($.table("series"));
        this.$table = $tableBox.addEl($.table("datatable"));

        // create column widgets
        let $colWidgets = this.$table.addEl($.div("colWidgets"));
        for (let i = 0; i < this.getColCount(); i++) {
            let $colWidget = $colWidgets.addEl($.div("colWidget"));
            $colWidget.append(controls.createPopupButton(this, {
                label: i + 1,
                showArrow: true,
                enabled: !this.element.parentElement.hasDataSourceLink(),
                items: [{
                    value: "add_before", label: "Add Column Before"
                }, {
                    value: "add_after", label: "Add Column After"
                }, {
                    type: "divider"
                }, {
                    value: "delete", label: "Delete Column"
                }],
                callback: item => {
                    switch (item) {
                        case "add_before":
                            this.insertColumn(i);
                            break;
                        case "add_after":
                            this.insertColumn(i + 1);
                            break;
                        case "delete":
                            this.deleteColumn(i);
                            break;
                    }
                }

            }));
        }

        // create the categories from the xAxis data
        this.createCategoriesRow();

        // blank row for series so the series table is aligned with data
        this.$seriesTable.addEl($.tr("blank"));

        // create a table row for each data series
        for (var series of this.chartData.series) {
            this.createSeriesRow(series);
        }

        if (!this.element.parentElement.hasDataSourceLink()) {
            let $addColBtn = $tableBox.addEl($.div("add_column_button"));
            $addColBtn.on("click", () => this.addColumn());
        } else {
            this.$table.addClass("datasource_linked");
            $tableBox.on("dblclick", () => {
                ShowDialog(DataLockedDialog, { element: this.element.parentElement, elementEditor: this });
            });
        }

        this.$table.editableTableWidget();

        $colWidgets.width(this.$table[0].scrollWidth);

        this.$table.on("focus", "tr.series td.data_value", event => {
            this.isEditing = false;
            var $cell = $(event.currentTarget);

            var $seriesRow = $cell.parent();

            var seriesId = $seriesRow.attr("data-series-id");
            var index = $seriesRow.find("td.data_value").index($cell);

            if (this.element.chart[seriesId] && this.element.chart[seriesId].points.length > index) {
                this.element.chart[seriesId].points[index].setState("hover");
            }
        });

        this.$table.on("blurEditor", "tr.series td.data_value", event => {
            this.isEditing = true;
            var $seriesRow = $(event.currentTarget).parent();

            var seriesId = $seriesRow.attr("data-series-id");
            var index = $seriesRow.find("td.data_value").index($(event.currentTarget));

            if (this.element.chart[seriesId] && this.element.chart[seriesId].points.length > index) {
                this.element.chart[seriesId].points[index].setState("");
            }
        });

        return this;
    },

    createSeriesRow: function(seriesModel) {
        // create a new row in the series table to hold series info
        var $seriesRow = this.$seriesTable.addEl($.tr("series"));
        $seriesRow.attr("data-series-id", seriesModel.id);

        // create a new row to hold the data in the series
        var $seriesDataRow = this.$table.addEl($.tr("series").attr("data-series-id", seriesModel.id));

        var seriesData = seriesModel.data;

        this.renderData($seriesDataRow, seriesData, "data_value");

        // listen for changes to update the data in the series
        $seriesDataRow.on("change", "td", event => {
            this.isEditing = true;
            let val = $(event.target).text();

            let enteredValue;
            if (_.isEmpty(val)) {
                enteredValue = null;
            } else if (numeral.validateEx(val)) {
                enteredValue = numeral(val).value();
            } else {
                enteredValue = "SUM";
                $(event.target).text("SUM");
            }

            // If entered an empty value then delete annotations for the point
            if (enteredValue === null) {
                this.deleteAnnotationsForPoint(seriesModel.id, $(event.target).data("index"));
            }

            // Updating the model
            const model = $(event.target).data("model");
            model.pt = true;
            model.y = enteredValue;

            this.refreshRender();
            this.element.canvas.updateCanvasModel(true);
        });

        // waterfall allows text entry and sets point to intermediateSum = true
        $seriesDataRow.on("validate", "td.data_value", () => {
            return true;
        });

        return $seriesRow;
    },
});
