import { _ } from "js/vendor";
import { controls } from "js/editor/ui";

import { ElementSelection } from "../BaseElementEditor";
import { CreateMediaMenu } from "./pictureEditor";

const IconSelection = ElementSelection.extend({
    getTitle: function() {
        return false;
    },

    getOffset: function() {
        return 10;
    },

    renderControls: function() {
        CreateMediaMenu(this, this.element.parentElement, { allowAdjust: false });

        this.addControl({
            type: controls.SLIDER,
            min: 0.1,
            max: 1.5,
            step: 0.01,
            property: "iconScale"
        });

        // this.addControl({
        //     type: controls.COLOR_PALETTE_PICKER,
        //     property: "iconColor",
        //     includeAuto: true,
        //     showBackgroundColors: true,
        //     autoLabel: "AUTO",
        //     getAutoColor: () => {
        //         return this.element.canvas.getTheme().palette.getForeColor("primary", null, this.element.getBackgroundColor()).toRgbString();
        //     }
        // });
    }
});

export const editors = {
    IconSelection,
};
