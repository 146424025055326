import * as geom from "js/core/utilities/geom";
import { _ } from "js/vendor";
import { getSizingValue } from "js/core/utilities/utilities";

export function calcRowLayout(options = {}) {
    let styles = _.defaults({}, this.styles, {
        maxItemCount: 20,
        maxItemWidth: "100%",
        minItemWidth: "100px",
        maxItemHeight: "100%",
        matchRowHeights: true,
        hGridAlign: "center",
        vGridAlign: "middle",
        divider: null,
        alignInfoBlockContent: false,
        maxRows: 2,
        maxHGap: 100,
        minHGap: 10
    });

    // Object.assign(options, {itemOptions: {orientation: "vertical"}});

    let isFit = false;

    let maxItemWidth = getSizingValue(styles.maxItemWidth, this.containerSize.width);
    let minItemWidth = getSizingValue(styles.minItemWidth, this.containerSize.width);

    // see if we can fit all the this.items into a single row
    // find the best item width to fit all the this.items within the maxItemWidth/minItemWidth range
    // if we can't fit all the this.items at the minItemWidth, we will go to multiple rows
    let itemCount = Math.max(this.items.length, 1);
    let bestItemWidth = (this.containerSize.width - (itemCount - 1) * styles.minHGap) / itemCount; // desired item width to fit all this.items horizontally
    bestItemWidth = Math.clamp(bestItemWidth, minItemWidth, maxItemWidth); // clamp the item width within the allowed range

    let totalWidthOfItemsAtBestWidth = itemCount * Math.floor(bestItemWidth + styles.minHGap) - styles.minHGap; // the total width of all the this.items at the desired itemWidth (this may be wider than the bounds)

    //figure out the ideal number of this.items per row to evenly distribute
    let totalRows = this.totalRows = Math.min(styles.maxRows, Math.ceil(totalWidthOfItemsAtBestWidth / this.containerSize.width));
    let totalCols = Math.ceil(itemCount / totalRows);

    // calculate the actual item width we will use (use the minHGap but the actual gap may be wider if we can fit it)
    let itemWidth = (this.containerSize.width - totalCols * styles.minHGap + styles.minHGap) / totalCols;
    if (itemWidth < minItemWidth) {
        isFit = false;
    }
    itemWidth = Math.clamp(itemWidth, minItemWidth, maxItemWidth);

    let vGap = styles.vGap || 10;

    //calculate the available item height possible by dividing bounds.height by total rows
    let availableItemHeight = (this.containerSize.height - totalRows * vGap + vGap) / totalRows;
    vGap = this.containerSize.height - availableItemHeight * totalRows;

    //calculate the optimum horizontal gap
    let hGap = 0;
    if (totalCols > 1) {
        hGap = Math.min(styles.maxHGap, (this.containerSize.width - itemWidth * totalCols) / (totalCols - 1));
    }

    let colBounds = new geom.Rect(0, 0, itemWidth, 10000);

    // calculate the tallest item's height to use as the rowHeight (so all this.items are aligned)
    let maxRowHeight = getSizingValue(styles.maxRowHeight, this.containerSize.height);

    for (let item of this.items) {
        item.calcProps(new geom.Size(itemWidth, availableItemHeight), Object.assign({}, options.itemOptions, {
            totalRows,
            rowIndex: Math.floor(item.itemIndex / totalCols)
        }));
    }
    let itemHeight = _.reduce(this.items, (tallestItemHeight, item) => {
        let calculatedSize = item.calculatedProps.size;
        if (calculatedSize.height > tallestItemHeight) {
            return calculatedSize.height;
        } else {
            return tallestItemHeight;
        }
    }, 0);

    var totalWidth = (itemWidth + hGap) * totalCols - hGap;
    var totalHeight = (itemHeight + vGap) * totalRows - vGap;

    if (Math.floor(totalWidth) <= Math.ceil(this.containerSize.width) && Math.floor(totalHeight) <= Math.ceil(this.containerSize.height)) {
        isFit = true;
    }

    // align the entire set of grid this.items within the bounds (this doesn't happen during calcLayout because we only get the actual bounds with offset during layout)

    let y = 0;
    for (let row = 0; row < totalRows; row++) {
        //get the this.items that will be in this row
        let rowItems = this.items.slice(row * totalCols, row * totalCols + totalCols);

        //horizontally align the row (independently of the other rows)
        if (styles.fullRow) {
            itemWidth = (this.containerSize.width - hGap * (rowItems.length - 1)) / rowItems.length;
        }
        let x = 0;
        switch (styles.rowAlign || "center") {
            case "center":
                x = this.containerSize.width / 2 - (rowItems.length * (itemWidth + hGap) - hGap) / 2;
                break;
            case "left":
                x = 0;
                break;
            case "right":
                x = this.containerSize.width - (rowItems.length * (itemWidth + hGap) - hGap);
                break;
        }

        // let layoutOptions = {};
        // if (this.styles.alignInfoBlockContent) {
        //     layoutOptions.forceContentHeight = layout.shortestContentHeight;
        // }

        //layout each item in it's column
        for (let col = 0; col < rowItems.length; col++) {
            let item = rowItems[col];

            let verticalOffset;
            switch (item.verticalRowAlign || this.styles.verticalRowAlign || "top") {
                case "top":
                    verticalOffset = 0;
                    break;
                case "bottom":
                    verticalOffset = itemHeight - item.calculatedProps.size.height;
                    break;
                case "middle":
                    verticalOffset = 0;
                    break;
            }

            item.calculatedProps.bounds = new geom.Rect(x, y + verticalOffset, itemWidth, itemHeight);
            x += itemWidth + hGap;
        }
        y += itemHeight + vGap;
    }

    this.size = new geom.Size(totalWidth, totalHeight);
    this.isFit = isFit;
    this.isTextFit = this.items.every(item => item.calculatedProps.isTextFit !== false);

    return this;
}
