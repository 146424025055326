import { controls } from "js/editor/ui";
import { FormatType, NodeType } from "common/constants";
import { app } from "js/namespaces";
import * as geom from "js/core/utilities/geom";
import { ds } from "js/core/models/dataService";
import { numeral } from "js/vendor";
import { ShowDialog } from "js/react/components/Dialogs/BaseDialog";
import EditTextDialog from "js/react/components/Dialogs/EditTextDialog";

import { FormatOptionsMenu } from "../FormatOptionsMenu";
import { ElementOptionsMenu } from "../BaseElementEditor";
import { ElementSelection } from "../BaseElementEditor";

const ThermometerSelection = ElementSelection.extend({

    renderControls: function() {
        this.addControl({
            type: controls.SLIDER,
            min: 0,
            max: 100,
            label: "Value",
            property: "value"
        });

        this.addControl({
            type: controls.COLOR_PALETTE_PICKER,
            property: "color",
            includeAuto: true
        });

        this.addControl({
            type: controls.POPUP_BUTTON,
            label: "Format",
            menuContents: closeMenu => {
                let $menu = new FormatOptionsMenu({
                    element: this.element,
                    allowedFormats: [FormatType.PERCENT, FormatType.NUMBER, FormatType.CURRENCY],
                    showTableFormatOptions: false
                }).render().$el;
                return $menu;
            }
        });

        this.addControl({
            type: controls.BUTTON,
            label: "Add Callout",
            icon: "add_circle",
            enabled: this.element.annotations.itemCount < this.element.annotations.maxItemCount,
            callback: () => {
                const annotations = this.element.annotations;

                const annotation = annotations.addItem({
                    nodeType: NodeType.BULLET_TEXT,
                    hideNodeConnectorWidget: true,
                    x: this.element.position === "right" ? 0 : 0.75,
                    y: Math.random()
                });

                annotations.connectors.addItem({
                    source: annotation.id,
                    target: this.element.thermometerGauge.uniquePath,
                    endPointIsLocked: true,
                    canChangeConnectorType: false
                });

                app.currentCanvas.updateCanvasModel().then(() => {
                    ds.selection.element = annotations.getItemElementById(annotation.id);
                });
            }
        });
    }
});

const ThermometerOptionsMenu = ElementOptionsMenu.extend({
    renderControls: function() {
        this.addControl({
            type: controls.DROPDOWN_MENU,
            label: "Position",
            property: "position",
            items: [{
                value: "left", label: "Left"
            }, {
                value: "center", label: "Center"
            }, {
                value: "right", label: "Right"
            }]
        });

        this.addControl({
            type: controls.TOGGLE,
            property: "showBulb",
            label: "Show Bulb",
            // Disabled temporarily until figured out why gauge is not transitioned correctly
            transitionModel: false
        });

        this.addControl({
            type: controls.TOGGLE,
            property: "showValue",
            label: "Show Value"
        });

        this.addControl({
            type: controls.NUMERIC,
            label: "Max Value",
            property: "targetMax",
            triggerEvent: "blur",
            min: 1
        });
        this.addControl({
            type: controls.NUMERIC,
            label: "Tick Count",
            property: "tickCount",
            triggerEvent: "change",
            min: 2,
            max: 15
        });

        this.addControl({
            type: controls.TOGGLE,
            label: "Show Tick Values",
            property: "showTickValues"
        });
    }
});

const ThermometerLabelSelection = ElementSelection.extend({
    showSelectionBox: false,

    setup: function() {
        const thermometer = this.element.getParentOfType("Thermometer");

        const selectionBounds = this.element.selectionBounds;
        const screenBounds = this.element.getScreenBounds().offset(0, -selectionBounds.height / 2);
        const targetPt = new geom.Point(screenBounds.centerH, screenBounds.centerV);

        const currentValue = thermometer.model.value / 100 * thermometer.model.targetMax;

        ShowDialog(EditTextDialog, {
            targetPt,
            target: this.element,
            minWidth: 150,
            value: currentValue,
            callback: value => {
                if (numeral.validateEx(value)) {
                    //logic to handle percents so that "40%" doesn't update to .4
                    if (value.includes("%")) {
                        const indexOfPercent = value.indexOf("%");
                        value = value.substring(0, indexOfPercent);
                    }

                    const updatedValue = numeral(value).value() * 100 / thermometer.model.targetMax;
                    if (updatedValue >= 100) {
                        thermometer.model.value = 100;
                    } else {
                        thermometer.model.value = updatedValue;
                    }
                    thermometer.canvas.updateCanvasModel(false).then(() => {
                        ds.selection.element = null;
                    });
                }
            },
        });
    },

    onMouseDown: function() {
        ds.selection.selection = null;
    },

});

export const editors = {
    ThermometerLabelSelection,
    ThermometerSelection,
    ThermometerOptionsMenu,
};
