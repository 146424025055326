import { isDevelopment } from "js/config";
import { getTemplates, variations, classicTemplates, chartUtils } from "common/slideTemplates/v9"; // eslint-disable-line

const slideTemplates = getTemplates(isDevelopment);

export {
    slideTemplates,
    variations,
    classicTemplates,
    chartUtils
};
