import { controls } from "js/editor/ui";

import { ElementOptionsMenu } from "../BaseElementEditor";
import {
    CollectionElementSelection,
    CollectionItemElementSelection
} from "../CollectionElementEditor";

const ArrowBarsSelection = CollectionElementSelection.extend({
    renderControls: function() {
        this.createAddItemButton();
    },

    getAddItemLabel: function() {
        return "Add Arrow";
    }
});

const ArrowBarsOptionsMenu = ElementOptionsMenu.extend({
    renderControls: function() {
        this.addControl({
            type: controls.TOGGLE,
            property: "showIndex",
            label: "Show Number"
        });

        this.addControl({
            type: controls.TOGGLE,
            property: "showFoldEffect",
            label: "Fold Effect"
        });
    }
});

const ArrowBarItemSelection = CollectionItemElementSelection.extend({
    getDragAxis: function() {
        return "y";
    },

    renderControls: function() {
        this.createEmphasizeWidget();

        this.addControl({
            type: controls.SLIDER,
            property: "value",
            label: "Length",
            min: 15,
            max: 100
        });

        this.addControl({
            type: controls.COLOR_PALETTE_PICKER,
            property: "color",
            includeAuto: true
        });
    }
});

const ArrowBarItemOptionsMenu = ElementOptionsMenu.extend({
    renderControls: function() {
        this.addControl({
            type: controls.TOGGLE,
            property: "showArrowHead",
            label: "Arrow Head"
        });
    }
});

export const editors = {
    ArrowBarsSelection,
    ArrowBarsOptionsMenu,
    ArrowBarItemSelection,
    ArrowBarItemOptionsMenu
};
