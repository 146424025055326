import { _ } from "js/vendor";
import { ElementTextBlockPositionType } from "common/constants";
import { ContentBlockType } from "common/constants";

import { TextElement } from "../base/TextElement";

export class ElementTextBlock extends TextElement {
    static get schema() {
        return {
            backgroundColor: "background_accent"
        };
    }

    get selectionPadding() {
        if (this.canvas.model.layout.elementTextBlockPosition == ElementTextBlockPositionType.TRAY) {
            return 0;
        } else {
            return 10;
        }
    }

    get allowStyling() {
        return true;
    }

    get allowAlignment() {
        return true;
    }

    get autoHeight() {
        return true;
    }

    get allowedBlockTypes() {
        return [ContentBlockType.HEADING, ContentBlockType.TITLE, ContentBlockType.BODY, ContentBlockType.CAPTION];
    }

    get allowBlockType() {
        return true;
    }

    get breakType() {
        return "even";
    }

    _loadStyles(styles) {
        super._loadStyles(styles);

        if (!this.model.color) {
            this.model.color = this.model.backgroundColor;
            delete this.model.backgroundColor;
        }

        switch (this.canvas.model.layout.elementTextBlockPosition) {
            case ElementTextBlockPositionType.TRAY:
                styles.applyStyles(styles.tray);

                break;
            case ElementTextBlockPositionType.INLINE:
                styles.applyStyles(styles.below);
                break;
        }

        if (this.canvas.model.layout.showElementAttribution) {
            styles.paddingBottom += 20;
        }
    }

    resetUserColors() {
        return false;
    }

    _migrate_8() {
        // migrate no set backgroundColor to use theme instead of backgroundAccent which is default now
        if (!this.model.backgroundColor) {
            this.model.backgroundColor = this.canvas.getSlideColor();
        }
    }

    get disableAnimationsByDefault() {
        return true;
    }
}

export class ElementAttribution extends TextElement {
    get minHeight() {
        return this.styles.marginTop + this.styles.marginBottom + this.styles.paddingTop + this.styles.paddingBottom + this.styles.fontSize;
    }

    get selectionPadding() {
        return 10;
    }

    get autoHeight() {
        return true;
    }

    getBackgroundColor(forElement) {
        if (this.canvas.model.layout.elementTextBlockPosition == "tray" && this.canvas.layouter.elements.elementTextBlock) {
            return this.canvas.layouter.elements.elementTextBlock.getBackgroundColor();
        } else {
            return super.getBackgroundColor(forElement);
        }
    }

    get disableAnimationsByDefault() {
        return true;
    }
}

export const elements = {
    ElementTextBlock,
    ElementAttribution,
};
