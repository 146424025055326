import { _ } from "js/vendor";
import { app } from "js/namespaces.js";
import * as geom from "js/core/utilities/geom";
import { AssetType, DirectionType, PositionType, NodeType, TextBlockPreset, ContentBlockType } from "common/constants";
import { HorizontalAlignType } from "common/constants";
import { getCenteredRect } from "js/core/utilities/geom";
import { AnchorType } from "js/core/utilities/geom";
import { Shape } from "js/core/utilities/shapes";

import { AdjustableContentElement } from "./ContentElement";
import { CollectionItemElement } from "./CollectionElement";
import {
    SVGCircleElement,
    SVGPathElement
} from "./SVGElement";
import { TextElement } from "./TextElement";
import { ContentItem } from "./ContentItem";
import ConnectorItem from "../elements/connectors/ConnectorItem";
import { ContentBlockCollection } from "../elements/ContentBlock";

export function getNodeElementFromType(type) {
    switch (type) {
        case NodeType.FLEX_CIRCLE:
            return FlexCircleNodeElement;
        case NodeType.BULLET_TEXT:
            return BulletTextNodeElement;
        case NodeType.TEXT:
            return TextNodeElement;
        case NodeType.NUMBERED_TEXT:
            return NumberedTextNodeElement;
        case NodeType.CONTENT_AND_TEXT:
            return ContentAndTextNodeElement;
        case NodeType.CONTENT:
            return ContentNodeElement;
        case NodeType.CIRCLE:
        case NodeType.BOX:
        case NodeType.CAPSULE:
        case NodeType.DIAMOND:
            return TextInShapeNodeElement;
        default:
            return FlexCircleNodeElement;
    }
}

export class NodeElement extends CollectionItemElement {
    get canSelect() {
        return true;
    }

    get canCopyToClipboard() {
        return true;
    }

    getClipboardData() {
        return {
            dataType: "element",
            elementType: this.type,
            parentElementType: this.parentElement.type,
            slideId: this.canvas.dataModel.id,
            model: _.cloneDeep(this.model)
        };
    }

    get selectionPadding() {
        return 10;
    }

    get canResizeTextWidth() {
        return true;
    }

    get canDragResize() {
        return true;
    }

    setUserWidth(width) {
        this.model.userSize = width;
    }

    get canRefreshElement() {
        return true;
    }

    refreshElement(transition) {
        this.canvas.refreshElement(this, transition, true);
    }

    get TextElementType() {
        if (this.options.isSingleText) {
            return TextElement;
        }

        return ContentBlockCollection;
    }

    get defaultBlockType() {
        return ContentBlockType.TITLE;
    }

    get autoWidth() {
        return this.userSize == null;
    }

    get canChangeTextDirection() {
        return _.defaultTo(this.options.canChangeTextDirection, true);
    }

    get canChangeColors() {
        if (this.options.canChangeColors != undefined) {
            return this.options.canChangeColors;
        }

        return true;
    }

    get showSizeSlider() {
        return this.options.showSizeSlider ?? false;
    }

    getInnerText(trimLength = null) {
        if (!this.model.blocks || !this.text) {
            return null;
        }
        const text = this.model.blocks[0].content?.text;
        if (!text) {
            return null;
        }

        if (!trimLength) {
            return text;
        }

        return `${text.slice(0, 5)}${text.length > trimLength ? "..." : ""}`;
    }

    allowRollover(currentSelectedElement) {
        if (currentSelectedElement instanceof ConnectorItem) {
            return false;
        } else {
            return true;
        }
    }

    get selectionUIType() {
        if (this.parentElement.getItemSelectionUIType) {
            return this.parentElement.getItemSelectionUIType(this);
        }
        return "NodeElementSelection";
    }

    get rolloverUIType() {
        if (this.parentElement.getItemRolloverUIType) {
            return this.parentElement.getItemRolloverUIType(this);
        }
        return "NodeElementRollover";
    }

    get textPosition() {
        return this.model.textPosition || PositionType.CENTER;
    }

    get horizontalScaleOrigin() {
        switch (this.calculatedTextDirection || this.textDirection) {
            case DirectionType.LEFT:
                return HorizontalAlignType.RIGHT;
            case DirectionType.RIGHT:
                return HorizontalAlignType.LEFT;
            default:
                return HorizontalAlignType.CENTER;
        }
    }

    get textDirection() {
        return this.model.textDirection || this.options.textDirection || this.defaultDirectionType;
    }

    get defaultDirectionType() {
        return DirectionType.RIGHT;
    }

    getAutoTextDirection(size) {
        if (app.isDraggingItem) {
            return this.calculatedTextDirection || DirectionType.RIGHT;
        }

        if (this.connectorsFromNode.length > 0 || this.connectorsToNode.length > 0) {
            const nodePoint = new geom.Point(size.width * this.model.x, size.height * this.model.y);

            let anchorPoint;
            if (this.connectorsFromNode.length > 0) {
                const connector = this.connectorsFromNode[0];
                if (connector.endTarget) {
                    if (connector.endTarget instanceof NodeElement || connector.endTarget instanceof ContentItem) {
                        // if the connection is to another node element, it may not have been calc'd yet so use the model to determine the anchorPoint
                        anchorPoint = new geom.Point(size.width * connector.endTarget.model.x, size.height * connector.endTarget.model.y);
                    } else if (connector.endTarget.getAnchorPoint) {
                        anchorPoint = connector.endTarget.getAnchorPoint(connector, connector.startAnchor, nodePoint, connector.type, false);
                    } else if (connector.endTarget.anchorBounds) {
                        anchorPoint = connector.endTarget.anchorBounds.center;
                    } else {
                        anchorPoint = new geom.Rect(0, 0, connector.endTarget.size).center;
                    }
                } else {
                    anchorPoint = new geom.Point(connector.model.targetPoint);
                }
            } else {
                const connector = this.connectorsToNode[0];
                if (connector.startTarget) {
                    if (connector.startTarget instanceof NodeElement || connector.endTarget instanceof ContentItem) {
                        // if the connection is to another node element, it may not have been calc'd yet so use the model to determine the anchorPoint
                        anchorPoint = new geom.Point(size.width * connector.startTarget.model.x, size.height * connector.startTarget.model.y);
                    } else if (connector.startTarget.getAnchorPoint) {
                        anchorPoint = connector.startTarget.getAnchorPoint(connector, connector.endAnchor, nodePoint, connector.type, true);
                    } else if (connector.startTarget.anchorBounds) {
                        anchorPoint = connector.startTarget.anchorBounds.center;
                    } else {
                        anchorPoint = new geom.Rect(0, 0, connector.startTarget.size).center;
                    }
                } else {
                    anchorPoint = new geom.Point(connector.model.startPoint);
                }
            }

            let calculatedTextDirection;
            const nodeAngle = anchorPoint.angleToPoint(nodePoint);
            if (nodeAngle > 290 || nodeAngle < 70) {
                calculatedTextDirection = DirectionType.RIGHT;
            } else if (nodeAngle > 250 && nodeAngle <= 290) {
                calculatedTextDirection = DirectionType.TOP;
            } else if (nodeAngle > 110 && nodeAngle <= 250) {
                calculatedTextDirection = DirectionType.LEFT;
            } else {
                calculatedTextDirection = DirectionType.BOTTOM;
            }

            // If not dragging, then just return the calculated direction
            if (!this.isDragging) {
                return calculatedTextDirection;
            }

            // Direction hasn't changed
            if (this.calculatedTextDirection === calculatedTextDirection) {
                return calculatedTextDirection;
            }

            const dragWidgetAngle = anchorPoint.angleToPoint(this.dragWidgetPosition);
            // Won't be changing direction if the angle to the drag widget changed less than
            // by 30 degrees
            if (this.textDirectionChangedAtAngle && Math.abs(this.textDirectionChangedAtAngle - dragWidgetAngle) < 30) {
                return this.calculatedTextDirection;
            }

            // Changing direction
            this.textDirectionChangedAtAngle = dragWidgetAngle;
            return calculatedTextDirection;
        } else {
            return DirectionType.RIGHT;
        }
    }

    get userSize() {
        return this.model.userSize;
    }

    get textScale() {
        return this.model.textScale || 1;
    }

    get minWidth() {
        return _.defaultTo(this.options.minWidth, 40);
    }

    get maxWidth() {
        return _.defaultTo(this.options.maxWidth, 1180);
    }

    get minHeight() {
        return _.defaultTo(this.options.minHeight, 20);
    }

    get maxHeight() {
        return _.defaultTo(this.options.maxHeight, 600);
    }

    get nodeType() {
        return this.model.nodeType || NodeType.BOX;
    }

    get bounds() {
        // IMPORTANT: returning a modiifed copy of bounds means that we can't directly modify bounds property for this
        // element!
        return super.bounds.offset(-this.registrationPoint.x, -this.registrationPoint.y);
    }

    set bounds(value) {
        this._bounds = value;
    }

    get connectionShape() {
        return {
            bounds: this.shape.bounds.inflate(this.anchorPadding),
            type: this.nodeType
        };
    }

    get anchorPadding() {
        return this.options.anchorPadding || this.styles.anchorPadding || 0;
    }

    getAnchorPoint(connector, anchor) {
        let anchorBounds = this.anchorBounds.inflate(this.anchorPadding);
        switch (anchor) {
            case AnchorType.FREE:
            case AnchorType.CENTER:
                return anchorBounds.center;
            case AnchorType.LEFT:
                return anchorBounds.getPoint("middle-left");
            case AnchorType.RIGHT:
                return anchorBounds.getPoint("middle-right");
            case AnchorType.TOP:
                return anchorBounds.getPoint("top-center");
            case AnchorType.BOTTOM:
                return anchorBounds.getPoint("bottom-center");
        }
    }

    get availableAnchorPoints() {
        return [AnchorType.FREE, AnchorType.LEFT, AnchorType.RIGHT, AnchorType.TOP, AnchorType.BOTTOM];
    }

    // tree hierarchy helpers

    get connectorsFromNode() {
        if (this.parentElement.connectors) {
            return this.parentElement.connectors.getConnectorsForItem(this.id, "source");
        } else {
            return [];
        }
    }

    get connectorsToNode() {
        if (this.parentElement.connectors) {
            return this.parentElement.connectors.getConnectorsForItem(this.id, "target");
        } else {
            return [];
        }
    }

    get childNodes() {
        return _.map(this.connectorsFromNode, c => this.parentElement.getChild(c.model.target));
    }

    get parentNodes() {
        return _.map(this.connectorsToNode, c => this.parentElement.getChild(c.model.source));
    }

    get isCyclicalNode() {
        let inConnectorNodes = _.map(this.connectorsToNode, c => c.model.source);

        let checkChildNodes = (currentNode, targetNode, checkedNodes) => {
            for (let childNode of currentNode.childNodes) {
                if (checkedNodes.contains(childNode)) continue;
                checkedNodes.push(childNode);

                if (targetNode.id == childNode.id) {
                    return true;
                }
                if (checkChildNodes(childNode, targetNode, checkedNodes)) {
                    return true;
                }
            }
        };

        return checkChildNodes(this, this, []) === true;
    }

    get firstTextLineHeight() {
        return this.text.firstTextLineHeight;
    }

    _migrate_6() {
        if (!this.model.blocks) {
            this.model.blocks = [];
            if (this.model.title) {
                this.model.blocks.push({
                    type: "title",
                    content: {
                        text: this.model.title.text == undefined ? this.model.title : this.model.title.text,
                        styles: this.model.title.styles || {}
                    }
                });
            }
            if (this.model.body) {
                this.model.blocks.push({
                    type: "body",
                    content: {
                        text: this.model.body.text == undefined ? this.model.body : this.model.body.text,
                        styles: this.model.body.styles || {}
                    }
                });
            }
        }
    }

    _migrate_8() {
        // this is for pre-version 8 chart annotations that didn't use node elements
        if (this.model.text && !this.model.blocks) {
            this.model.blocks = [{
                type: "title",
                content: {
                    text: this.model.text.text || "",
                    styles: this.model.text.styles || {}
                }
            }];
        }
    }

    _migrate_9() {
        // legacy annotation nodes were sometimes set to primary color which causes issues with getUserDefinedColor() so i'm migrating (see BA-10863)
        if (this.model.color == "primary" || this.model.color == "secondary") {
            this.model.color = "background_dark";
        }
    }

    get animationElementName() {
        const text = this.getInnerText(5);
        return `Node ${text ? `"${text}"` : `#${this.itemIndex + 1}`}`;
    }

    get animateChildren() {
        return false;
    }

    _getAnimations() {
        return [{
            name: "Fade in",
            prepare: () => this.animationState.fadeInProgress = 0,
            onBeforeAnimationFrame: progress => {
                this.animationState.fadeInProgress = progress;
            }
        }];
    }
}

export class FlexCircleNodeElement extends NodeElement {
    get selectionPadding() {
        return 0;
    }

    get anchorBounds() {
        return this.shapeBounds.offset(this.bounds.position);
    }

    get registrationPoint() {
        return this.shapeBounds.center;
    }

    get textDirection() {
        return this.model.textDirection || this.options.textDirection || DirectionType.AUTO;
    }

    get nodeType() {
        return NodeType.FLEX_CIRCLE;
    }

    get shapeSize() {
        return this.model.size || 100;
    }

    get decorationStyle() {
        if (this.model.decorationStyle) {
            return this.model.decorationStyle;
        } else {
            if (this.model.hilited || this.canvas.getTheme().get("styleElementStyle") == "filled") {
                return "muted";
            } else {
                return this.canvas.getTheme().get("styleElementStyle");
            }
        }
    }

    get horizontalScaleOrigin() {
        if (this.textDirection === DirectionType.AUTO && !this.textInCircle) {
            return HorizontalAlignType.LEFT;
        }

        return super.horizontalScaleOrigin;
    }

    _build() {
        this.shape = this.addElement("shape", () => SVGPathElement);

        this.text = this.addElement("text", () => this.TextElementType, {
            allowedBlockTypes: TextBlockPreset.NODE_ELEMENT,
            allowAlignment: false,
            autoWidth: this.autoWidth,
            autoHeight: true,
        });
    }

    _calcProps(props, options) {
        const { size } = props;

        size.width = this.userSize || size.width;

        let textProps;
        let calculatedSize;

        props.textInCircle = false;

        if (this.textDirection === DirectionType.AUTO) {
            this.text.styles.textAlign = HorizontalAlignType.CENTER;
            const textInCircleSize = geom.fitRectInCircle(this.shapeSize, 1, 1);
            textProps = this.text.calcProps(textInCircleSize, { forceTextScale: 1, textAlign: HorizontalAlignType.CENTER });
            if (this.text.isTextFit) {
                props.textInCircle = true;
                this.shapeBounds = new geom.Rect(0, 0, this.shapeSize, this.shapeSize);
                textProps.bounds = getCenteredRect(textProps.size, this.shapeBounds);
                calculatedSize = this.shapeBounds.size;
            }
        }

        if (!props.textInCircle) {
            let textDirection = this.textDirection;
            if (textDirection === DirectionType.AUTO) {
                textDirection = DirectionType.RIGHT;
            }

            this.text.styles.applyStyles(this.styles.text.textDirection[textDirection]);

            switch (textDirection) {
                case DirectionType.LEFT:
                    textProps = this.text.calcProps(new geom.Size(size.width - this.shapeSize, size.height), { scaleToFit: true, textAlign: HorizontalAlignType.RIGHT });
                    textProps.bounds = new geom.Rect(0, Math.max(0, this.shapeSize / 2 - textProps.size.height / 2), textProps.size);
                    this.shapeBounds = new geom.Rect(textProps.size.width, Math.max(0, textProps.size.height / 2 - this.shapeSize / 2), this.shapeSize, this.shapeSize);
                    break;
                case DirectionType.RIGHT:
                    textProps = this.text.calcProps(new geom.Size(size.width - this.shapeSize, size.height), { scaleToFit: true, textAlign: HorizontalAlignType.LEFT });
                    textProps.bounds = new geom.Rect(this.shapeSize, Math.max(0, this.shapeSize / 2 - textProps.size.height / 2), textProps.size);
                    this.shapeBounds = new geom.Rect(0, Math.max(0, textProps.size.height / 2 - this.shapeSize / 2), this.shapeSize, this.shapeSize);
                    break;
                case DirectionType.TOP:
                    textProps = this.text.calcProps(new geom.Size(size.width, size.height), { scaleToFit: true, textAlign: HorizontalAlignType.CENTER });
                    textProps.bounds = new geom.Rect(Math.max(0, this.shapeSize / 2 - textProps.size.width / 2), 0, textProps.size);
                    this.shapeBounds = new geom.Rect(Math.max(0, textProps.size.width / 2 - this.shapeSize / 2), textProps.size.height, this.shapeSize, this.shapeSize);
                    break;
                case DirectionType.BOTTOM:
                    textProps = this.text.calcProps(new geom.Size(size.width, size.height), { scaleToFit: true, textAlign: HorizontalAlignType.CENTER });
                    textProps.bounds = new geom.Rect(Math.max(0, this.shapeSize / 2 - textProps.size.width / 2), this.shapeSize, textProps.size);
                    this.shapeBounds = new geom.Rect(Math.max(0, textProps.size.width / 2 - this.shapeSize / 2), 0, this.shapeSize, this.shapeSize);
                    break;
            }

            calculatedSize = this.shapeBounds.union(textProps.bounds).size;
        }

        const shapeProps = this.shape.createProps({
            path: Shape.drawCircle(this.shapeBounds.width / 2, this.shapeBounds.center).toPathData(),
            layer: -1
        });

        if (this.decorationStyle == "muted" && this.canvas.layouter.isGenerating) {
            this.shape.styles.fillColor = "slide 0.7"; // this matches the pre-elements styling on xygraph nodes
        }

        if (this.hasStoredPropChanged("textInCircle", props.textInCircle)) {
            this.markStylesAsDirty();
        }

        if (this.isDragResizing) {
            return { size: new geom.Size(size.width, calculatedSize.height) };
        } else {
            return { size: calculatedSize };
        }
    }

    getBackgroundColor(forElement) {
        if (forElement && forElement instanceof TextElement && this.calculatedProps.textInCircle) {
            return this.getShapeFillColor(this.shape);
        } else {
            return super.getBackgroundColor(forElement);
        }
    }
}

export class TextInShapeNodeElement extends NodeElement {
    get selectionPadding() {
        return 0;
    }

    get anchorBounds() {
        return this.shape.bounds.offset(this.bounds.position);
    }

    get registrationPoint() {
        return this.shape.bounds.center;
    }

    get textDirection() {
        return null;
    }

    get maxWidth() {
        if (this.nodeType == NodeType.CIRCLE || this.nodeType == NodeType.DIAMOND) {
            return Math.min(500, super.maxWidth);
        } else {
            return Math.min(1180, super.maxWidth);
        }
    }

    get canChangeTextDirection() {
        return false;
    }

    _build() {
        this.shape = this.addElement("shape", () => SVGPathElement);

        this.text = this.addElement("text", () => this.TextElementType, {
            allowedBlockTypes: TextBlockPreset.NODE_ELEMENT,
            autoWidth: this.autoWidth,
            autoHeight: true,
            allowAlignment: true,
            scaleTextToFit: true,
            selectionPadding: 0
        });
    }

    _loadStyles(styles) {
        if (this.model.title) {
            // scale padding based on userFontScale to look better optically
            styles.text.paddingLeft = (this.model.title.userFontScale || 1) * styles.text.paddingLeft;
            styles.text.paddingRight = (this.model.title.userFontScale || 1) * styles.text.paddingRight;
            styles.text.paddingTop = (this.model.title.userFontScale || 1) * styles.text.paddingTop;
            styles.text.paddingBottom = (this.model.title.userFontScale || 1) * styles.text.paddingBottom;
        }
    }

    _calcProps(props, options) {
        let { size } = props;

        size.width = Math.clamp(this.userSize || size.width, this.minWidth, this.maxWidth);
        size.height = Math.min(size.width, size.height);

        if (this.canvas.layouter.isGenerating) {
            if (this.styles.shape.fillColor == "none") {
                this.shape.styles.fillColor = "backgroundColor";
            }
            if (this.model.showShadow) {
                this.shape.styles.filter = "annotationShadow";
            }
        }

        let shapeProps = this.shape.calcProps(size, {
            layer: -1
        });
        let textProps = this.text.calcProps(size, {
            forceTextScale: this.textScale,
            autoWidth: this.autoWidth
        });

        if (this.autoWidth) {
            size.width = Math.clamp(textProps.size.width, this.minWidth, this.maxWidth);
        }

        let shapeBounds = new geom.Rect(0, 0, size.width, textProps.size.height).inflate(this.styles.textInset || 0).zeroOffset();

        if (options.snapToGrid) {
            shapeBounds.width = Math.round(shapeBounds.width / options.snapToGrid) * options.snapToGrid;
            shapeBounds.height = Math.round(shapeBounds.height / options.snapToGrid) * options.snapToGrid;
        }

        switch (this.nodeType) {
            case NodeType.BOX:
                shapeProps.bounds = shapeBounds;
                shapeProps.path = Shape.drawRect(shapeBounds, this.styles.shape.cornerRadius / 2);
                textProps.bounds = getCenteredRect(textProps.size, shapeProps.bounds);
                break;
            case NodeType.CIRCLE:
                shapeProps.bounds = shapeBounds.square(true);
                shapeProps.path = Shape.drawCircle(shapeProps.bounds.width / 2, shapeProps.bounds.center).toPathData();
                textProps.bounds = getCenteredRect(textProps.size, shapeProps.bounds);
                break;
            case NodeType.DIAMOND:
                shapeProps.bounds = new geom.Rect(0, 0, shapeBounds.width + 20, (shapeBounds.width + 20) * .666);
                shapeProps.path = Shape.drawDiamond(shapeProps.bounds).toPathData();
                textProps.bounds = getCenteredRect(textProps.size, shapeProps.bounds);
                break;
            case NodeType.CAPSULE:
                shapeProps.bounds = shapeBounds;
                shapeProps.path = Shape.drawCapsule(shapeProps.bounds, 0).toPathData();
                textProps.bounds = getCenteredRect(textProps.size, shapeProps.bounds);
                break;
            case NodeType.TEXT:
                shapeProps.bounds = new geom.Rect(0, 0, textProps.size).inflate(15).zeroOffset();
                textProps.bounds = getCenteredRect(textProps.size, shapeBounds);
                break;
        }

        return { size: shapeProps.bounds.size };
    }

    getBackgroundColor(forElement) {
        if (forElement && forElement instanceof TextElement) {
            return this.getShapeFillColor(this.shape);
        } else {
            return super.getBackgroundColor(forElement);
        }
    }
}

export class TextNodeElement extends NodeElement {
    get anchorBounds() {
        return this.bounds.inflate(0);
    }

    get selectionPadding() {
        return 20;
    }

    get rolloverPadding() {
        return 20; // extra padding to bounds used when detecting if mouse is rolled over this element
    }

    get registrationPoint() {
        return new geom.Point(this.calculatedProps.bounds.width / 2, this.calculatedProps.bounds.height / 2);
    }

    get textDirection() {
        return null;
    }

    get allowDecorationStyles() {
        return false;
    }

    get connectionShape() {
        return {
            bounds: this.text.bounds.inflate(this.anchorPadding),
            type: "rect"
        };
    }

    get canChangeTextDirection() {
        return false;
    }

    _loadStyles(styles) {
        if (this.getRootElement().type == "NodeDiagram") {
            styles.applyStyles(this.getRootElement().styles);
        }
    }

    _build() {
        this.text = this.addElement("text", () => this.TextElementType, {
            allowedBlockTypes: TextBlockPreset.NODE_ELEMENT,
            autoWidth: this.autoWidth,
            scaleTextToFit: true,
            autoHeight: true,
            allowAlignment: true
        });
    }

    _calcProps(props, options) {
        const { size } = props;

        size.width = Math.clamp(this.userSize || size.width, this.minWidth, this.maxWidth);

        const textProps = this.text.calcProps(size, { autoWidth: this.autoWidth });

        size.width = Math.clamp(textProps.size.width, this.minWidth, this.maxWidth);

        textProps.bounds = new geom.Rect(0, 0, textProps.size);

        if (this.isDragResizing) {
            return { size: new geom.Size(size.width, textProps.size.height) };
        } else {
            return { size: new geom.Size(textProps.size.width, textProps.size.height) };
        }
    }

    getBackgroundColor(forElement) {
        return super.getBackgroundColor(forElement);
    }
}

export class ContentNodeElement extends NodeElement {
    get anchorBounds() {
        return this.content.bounds.offset(this.bounds.position);
    }

    get registrationPoint() {
        return this.content.bounds.center;
    }

    get connectionShape() {
        let frameType = this.content.frameType;
        // We want the connector to end closer to icons when they don't have a decoration
        if (frameType === "none" && this.content.assetType === AssetType.ICON) {
            frameType = "circle";
        }

        return {
            bounds: this.content.bounds.inflate(this.anchorPadding),
            type: frameType
        };
    }

    get minWidth() {
        return 20;
    }

    get userSize() {
        return this.model.userSize || 100;
    }

    get canChangeTextDirection() {
        return false;
    }

    get canResizeTextWidth() {
        return false;
    }

    _build() {
        this.content = this.addElement("content", () => AdjustableContentElement, {
            defaultAssetType: AssetType.ICON,
            canSelect: false,
            canEdit: false
        });
    }

    _calcProps(props, options) {
        let { size } = props;

        size.width = Math.clamp(this.userSize || size.width, this.minWidth, this.maxWidth);
        let contentProps = this.content.calcProps(size);
        contentProps.bounds = new geom.Rect(0, 0, contentProps.size);

        return { size: contentProps.size };
    }
}

export class ContentAndTextNodeElement extends NodeElement {
    get anchorBounds() {
        return this.content.bounds.offset(this.bounds.position);
    }

    get registrationPoint() {
        return this.content.bounds.center;
    }

    get connectionShape() {
        let frameType = this.content.frameType;
        // We want the connector to end closer to icons when they don't have a decoration
        if (frameType === "none" && this.content.assetType === AssetType.ICON) {
            frameType = "circle";
        }

        return {
            bounds: this.content.bounds.inflate(this.anchorPadding),
            type: frameType
        };
    }

    get defaultDirectionType() {
        return DirectionType.AUTO;
    }

    _build() {
        this.text = this.addElement("text", () => this.TextElementType, {
            allowAlignment: false,
            allowedBlockTypes: TextBlockPreset.NODE_ELEMENT,
            autoWidth: this.autoWidth,
            autoHeight: true,
        });
        this.content = this.addElement("content", () => AdjustableContentElement, {
            defaultAssetType: AssetType.ICON
        });
    }

    _calcProps(props, options) {
        let { size } = props;
        let textDirection = options.textDirection || this.textDirection;
        if (textDirection == DirectionType.AUTO) {
            textDirection = this.getAutoTextDirection(size);
        }
        this.calculatedTextDirection = textDirection;

        size.width = Math.clamp(this.userSize || size.width, this.minWidth, this.maxWidth);

        let contentProps = this.content.calcProps(size);

        this.text.updateStyles(this.styles.text.textDirection[textDirection]);
        // styles.applyStyles(styles.text.textDirection[textDirection], this.styles.text);

        let textProps;
        let contentAndTextMiddleLine;
        switch (textDirection) {
            case DirectionType.LEFT:
                textProps = this.text.calcProps(new geom.Size(size.width - contentProps.size.width, size.height), {
                    textAlign: HorizontalAlignType.RIGHT,
                    autoWidth: this.autoWidth
                });
                contentAndTextMiddleLine = Math.max(contentProps.size.height, textProps.size.height) / 2;
                textProps.bounds = new geom.Rect(0, contentAndTextMiddleLine - textProps.size.height / 2, textProps.size);
                contentProps.bounds = new geom.Rect(textProps.size.width, contentAndTextMiddleLine - contentProps.size.height / 2, contentProps.size);
                break;
            case DirectionType.RIGHT:
                textProps = this.text.calcProps(new geom.Size(size.width - contentProps.size.width, size.height), {
                    textAlign: HorizontalAlignType.LEFT,
                    autoWidth: this.autoWidth
                });
                contentAndTextMiddleLine = Math.max(contentProps.size.height, textProps.size.height) / 2;
                textProps.bounds = new geom.Rect(contentProps.size.width, contentAndTextMiddleLine - textProps.size.height / 2, textProps.size);
                contentProps.bounds = new geom.Rect(0, contentAndTextMiddleLine - contentProps.size.height / 2, contentProps.size);
                break;
            case DirectionType.TOP:
                textProps = this.text.calcProps(new geom.Size(size.width, size.height - contentProps.size.height), {
                    textAlign: HorizontalAlignType.CENTER,
                    autoWidth: this.autoWidth
                });
                contentAndTextMiddleLine = Math.max(contentProps.size.width, textProps.size.width) / 2;
                textProps.bounds = new geom.Rect(contentAndTextMiddleLine - textProps.size.width / 2, 0, textProps.size);
                contentProps.bounds = new geom.Rect(contentAndTextMiddleLine - contentProps.size.width / 2, textProps.size.height, contentProps.size);
                break;
            case DirectionType.BOTTOM:
                textProps = this.text.calcProps(new geom.Size(size.width, size.height - contentProps.size.height), {
                    textAlign: HorizontalAlignType.CENTER,
                    autoWidth: this.autoWidth
                });
                contentAndTextMiddleLine = Math.max(contentProps.size.width, textProps.size.width) / 2;
                textProps.bounds = new geom.Rect(contentAndTextMiddleLine - textProps.size.width / 2, contentProps.size.height, textProps.size);
                contentProps.bounds = new geom.Rect(contentAndTextMiddleLine - contentProps.size.width / 2, 0, contentProps.size);
                break;
        }

        let calculatedSize = contentProps.bounds.union(textProps.bounds).size;

        if (this.isDragResizing) {
            return { size: new geom.Size(size.width, calculatedSize.height) };
        } else {
            return { size: calculatedSize };
        }
    }
}

export class BulletTextNodeElement extends NodeElement {
    get anchorBounds() {
        return this.bullet.bounds.offset(this.bounds.position).inflate(5);
    }

    get selectionPadding() {
        return 15;
    }

    get rolloverPadding() {
        return 15; // extra padding to bounds used when detecting if mouse is rolled over this element
    }

    get registrationPoint() {
        return this.bullet.bounds.center;
    }

    get availableAnchorPoints() {
        return [AnchorType.FREE];
    }

    get connectionShape() {
        return {
            bounds: this.bullet.bounds.inflate(this.anchorPadding),
            type: "circle"
        };
    }

    get allowDecorationStyles() {
        return false;
    }

    get defaultDirectionType() {
        return DirectionType.AUTO;
    }

    _build() {
        this.bullet = this.addElement("bullet", () => SVGCircleElement);

        this.text = this.addElement("text", () => this.TextElementType, {
            allowAlignment: false,
            allowedBlockTypes: TextBlockPreset.NODE_ELEMENT,
            autoWidth: this.autoWidth,
            autoHeight: true,
        });
    }

    _calcProps(props, options) {
        let { size } = props;

        let textDirection = options.textDirection || this.textDirection;
        if (textDirection == DirectionType.AUTO) {
            textDirection = this.getAutoTextDirection(size);
        }
        this.calculatedTextDirection = textDirection;

        size.width = Math.clamp(this.userSize || size.width, this.minWidth, this.maxWidth);
        size.height = Math.min(size.width, size.height);

        let bulletSize = this.styles.bulletSize || 10;

        let bulletProps = this.bullet.calcProps(new geom.Size(bulletSize, bulletSize), {
            styles: {
                fillColor: this.model.color || this.styles.bullet.fillColor || "slide"
            }
        });

        this.text.updateStyles(this.styles.text.textDirection[textDirection]);

        let textProps;
        let bulletAndTextMiddleLine;
        switch (textDirection) {
            case DirectionType.LEFT:
                textProps = this.text.calcProps(new geom.Size(size.width - bulletSize, size.height), {
                    textAlign: HorizontalAlignType.RIGHT
                });
                bulletAndTextMiddleLine = Math.max(bulletSize, this.firstTextLineHeight) / 2;
                bulletProps.bounds = new geom.Rect(textProps.size.width, bulletAndTextMiddleLine - bulletSize / 2, bulletSize, bulletSize);
                textProps.bounds = new geom.Rect(0, bulletAndTextMiddleLine - this.firstTextLineHeight / 2, textProps.size);
                break;
            case DirectionType.RIGHT:
                textProps = this.text.calcProps(new geom.Size(size.width - bulletSize, size.height), {
                    textAlign: HorizontalAlignType.LEFT
                });
                bulletAndTextMiddleLine = Math.max(bulletSize, this.firstTextLineHeight) / 2;
                bulletProps.bounds = new geom.Rect(0, bulletAndTextMiddleLine - bulletSize / 2, bulletSize, bulletSize);
                textProps.bounds = new geom.Rect(bulletSize, bulletAndTextMiddleLine - this.firstTextLineHeight / 2, textProps.size);
                break;
            case DirectionType.TOP:
                textProps = this.text.calcProps(new geom.Size(size.width, size.height - bulletSize), {
                    textAlign: HorizontalAlignType.CENTER
                });
                bulletAndTextMiddleLine = Math.max(bulletSize, textProps.size.width) / 2;
                textProps.bounds = new geom.Rect(bulletAndTextMiddleLine - textProps.size.width / 2, 0, textProps.size);
                bulletProps.bounds = new geom.Rect(bulletAndTextMiddleLine - bulletSize / 2, textProps.size.height, bulletSize, bulletSize);
                break;
            case DirectionType.BOTTOM:
            default:
                textProps = this.text.calcProps(new geom.Size(size.width, size.height - bulletSize), {
                    textAlign: HorizontalAlignType.CENTER
                });
                bulletAndTextMiddleLine = Math.max(bulletSize, textProps.size.width) / 2;
                textProps.bounds = new geom.Rect(bulletAndTextMiddleLine - textProps.size.width / 2, bulletSize, textProps.size);
                bulletProps.bounds = new geom.Rect(bulletAndTextMiddleLine - bulletSize / 2, 0, bulletSize, bulletSize);
                break;
        }

        if (this.autoWidth) {
            size.width = Math.clamp(bulletProps.bounds.union(textProps.bounds).width, this.minWidth, this.maxWidth);
        }

        let calculatedSize = bulletProps.bounds.union(textProps.bounds).size;

        if (this.isDragResizing) {
            return { size: new geom.Size(size.width, calculatedSize.height) };
        } else {
            return { size: calculatedSize };
        }
    }
}

export class NumberedTextNodeElement extends NodeElement {
    get anchorBounds() {
        return this.labelShapeBounds.offset(this.bounds.position).inflate(10);
    }

    get selectionPadding() {
        return 15;
    }

    get rolloverPadding() {
        return 15; // extra padding to bounds used when detecting if mouse is rolled over this element
    }

    get registrationPoint() {
        return this.labelShapeBounds.center;
    }

    get availableAnchorPoints() {
        return [AnchorType.FREE];
    }

    get connectionShape() {
        return {
            bounds: this.labelShapeBounds.inflate(this.anchorPadding),
            type: "circle"
        };
    }

    get defaultDirectionType() {
        return DirectionType.AUTO;
    }

    _build() {
        if (this.model.label == null) {
            let maxItem = _.maxBy(this.parentElement.itemCollection, node => parseInt(node.label ? node.label.text : 0));
            let maxNum = maxItem.label ? parseInt(maxItem.label.text) : 0;
            this.model.label = { text: maxNum + 1 };
        }

        this.labelShape = this.addElement("labelShape", () => SVGPathElement);
        this.label = this.addElement("label", () => TextElement, {
            autoWidth: true,
            autoHeight: true,
            canSelect: true,
            canRollover: true,
            doubleClickToSelect: false,
            placeholder: "?",
            singleLine: true
        });

        this.text = this.addElement("text", () => this.TextElementType, {
            allowAlignment: false,
            allowedBlockTypes: TextBlockPreset.NODE_ELEMENT,
            autoWidth: this.autoWidth,
            autoHeight: true,
        });
    }

    _calcProps(props, options) {
        let { size } = props;

        let textDirection = options.textDirection || this.textDirection;
        if (textDirection == DirectionType.AUTO) {
            textDirection = this.getAutoTextDirection(size);
        }
        this.calculatedTextDirection = textDirection;

        size.width = Math.clamp(this.userSize || size.width, this.minWidth, this.maxWidth);
        size.height = Math.min(size.width, size.height);

        let labelShapeHeight = this.styles.labelShape.size;
        let labelProps = this.label.calcProps(new geom.Size(200, 200));
        let labelWidth = Math.max(labelShapeHeight, labelProps.size.width);

        this.text.updateStyles(this.styles.text.textDirection[textDirection]);

        let labelShapeProps = this.labelShape.createProps({
            layer: -1
        });

        let textProps;
        let labelShapeAndTextMiddleLine;
        switch (textDirection) {
            case DirectionType.LEFT:
                textProps = this.text.calcProps(new geom.Size(size.width - labelWidth, size.height), {
                    textAlign: HorizontalAlignType.RIGHT
                });
                labelShapeAndTextMiddleLine = Math.max(labelShapeHeight, this.firstTextLineHeight) / 2;
                labelShapeProps.bounds = new geom.Rect(textProps.size.width, labelShapeAndTextMiddleLine - labelShapeHeight / 2, labelShapeHeight, labelShapeHeight);
                textProps.bounds = new geom.Rect(0, labelShapeAndTextMiddleLine - this.firstTextLineHeight / 2, textProps.size);
                break;
            case DirectionType.RIGHT:
                textProps = this.text.calcProps(new geom.Size(size.width - labelWidth, size.height), {
                    textAlign: HorizontalAlignType.LEFT
                });
                labelShapeAndTextMiddleLine = Math.max(labelShapeHeight, this.firstTextLineHeight) / 2;
                labelShapeProps.bounds = new geom.Rect(0, labelShapeAndTextMiddleLine - labelShapeHeight / 2, labelShapeHeight, labelShapeHeight);
                textProps.bounds = new geom.Rect(labelShapeHeight, labelShapeAndTextMiddleLine - this.firstTextLineHeight / 2, textProps.size);
                break;
            case DirectionType.TOP:
                textProps = this.text.calcProps(new geom.Size(size.width, size.height - labelProps.size.height), {
                    textAlign: HorizontalAlignType.CENTER
                });
                labelShapeAndTextMiddleLine = Math.max(textProps.size.width, labelWidth) / 2;
                textProps.bounds = new geom.Rect(labelShapeAndTextMiddleLine - textProps.size.width / 2, 0, textProps.size);
                labelShapeProps.bounds = new geom.Rect(labelShapeAndTextMiddleLine - labelWidth / 2, textProps.size.height, labelWidth, labelShapeHeight);
                break;
            case DirectionType.BOTTOM:
            default:
                textProps = this.text.calcProps(new geom.Size(size.width, size.height - labelProps.size.height), {
                    textAlign: HorizontalAlignType.CENTER
                });
                labelShapeAndTextMiddleLine = Math.max(textProps.size.width, labelWidth) / 2;
                textProps.bounds = new geom.Rect(labelShapeAndTextMiddleLine - textProps.size.width / 2, labelShapeHeight, textProps.size);
                labelShapeProps.bounds = new geom.Rect(labelShapeAndTextMiddleLine - labelWidth / 2, 0, labelWidth, labelShapeHeight);
                break;
        }

        this.labelShapeBounds = labelShapeProps.bounds;
        labelShapeProps.path = Shape.drawCapsule(labelShapeProps.bounds).toPathData();
        labelProps.bounds = getCenteredRect(labelProps.size, labelShapeProps.bounds);

        // if (this.autoWidth) {
        //     size.width = Math.clamp(labelShapeBounds.union(this.text.bounds).width, this.minWidth, this.maxWidth);
        // }

        let calculatedSize = labelShapeProps.bounds.union(textProps.bounds).size;

        if (this.isDragResizing) {
            return { size: new geom.Size(size.width, calculatedSize.height) };
        } else {
            return { size: calculatedSize };
        }
    }

    getBackgroundColor(forElement) {
        if (forElement && forElement.id == "label") {
            return this.getShapeFillColor(this.labelShape);
        } else {
            return super.getBackgroundColor(forElement);
        }
    }
}
