import { _ } from "js/vendor";
import { app } from "js/namespaces.js";
import * as geom from "js/core/utilities/geom";
import { breakLines } from "js/core/utilities/linebreak";
import { TextStyleEnum } from "common/constants";
import { fontManager } from "js/core/services/fonts";

class TextLayout {
    constructor(textBox) {
        this.textBox = textBox;
        this.fontSizeCache = {};
        this.cachedRegularFont = this.textBox.getFontForTextStyle(TextStyleEnum.REGULAR);
    }

    get styles() {
        return this.textBox.styles;
    }

    stylesForTextStyle(textStyle) {
        var styles = this.textBox.styles;
        if (textStyle == TextStyleEnum.REGULAR) {
            return styles;
        } else {
            return _.merge({}, this.textBox.styles[textStyle] || {}, styles);
        }
    }

    fontSizeMultiplier(font = this.cachedRegularFont, size = this.fontSize(TextStyleEnum.REGULAR)) {
        return size / font.unitsPerEm;
        //return this.fontSize(TextStyleEnum.REGULAR) / this.cachedRegularFont.unitsPerEm;
    }

    get lineHeight() {
        return this.styles.lineHeight || 1;
    }

    verticalOffset(style) {
        return this.stylesForTextStyle(style).verticalOffset || 0;
    }

    get letterSpacing() {
        return this.styles.letterSpacing || 0;
    }

    get paragraphSpacing() {
        return this.fontSize(TextStyleEnum.REGULAR) / 2;
    }

    get spaceWidth() {
        return this.fontSizeMultiplier() * this.cachedRegularFont.charToGlyph(" ").advanceWidth;
    }

    get textAlign() {
        if (this.textBox.id === "body" && (this.textBox.paragraphStyle === "bullet_list" || this.textBox.paragraphStyle === "numbered_list")) {
            return "left";
        } else {
            return this.styles.textAlign || "left";
        }
    }

    get verticalAlign() {
        return this.styles.verticalAlign || "top";
    }

    get scaleFontSize() {
        //return this.styles.scaleToFit;
        return false;
    }

    get scaleTextToFit() {
        //return this.styles.scaleToFit;
        return false;
    }

    get minFontSize() {
        return this.styles.minFontSize || 9;
    }

    get maxFontSize() {
        return this.styles.maxFontSize || 600;
    }

    get usePixelHeight() {
        return this.styles.usePixelHeight;
    }

    fontSize(style) {
        if (!this.fontSizeCache[style]) {
            let fontSize;
            switch (style) {
                case TextStyleEnum.BOLD:
                case TextStyleEnum.BOLDITALIC:
                    if (this.styles.bold) {
                        fontSize = this.styles.bold.fontSize || this.styles.fontSize;
                    } else {
                        fontSize = this.styles.fontSize;
                    }
                // falls through
                case TextStyleEnum.REGULAR:
                default:
                    fontSize = this.styles.fontSize;
            }

            this.fontSizeCache[style] = fontSize;
        }

        let fontSize = this.fontSizeCache[style];
        fontSize *= (this.textBox.textScale || 1);
        fontSize *= (this.styles.fontScaling || 100) / 100;
        fontSize *= app.currentTheme.get("fontScale") || 1;

        fontSize *= this.textBox.userFontScale;

        fontSize = Math.min(fontSize, this.maxFontSize);
        fontSize = Math.max(fontSize, this.minFontSize);

        fontSize = Math.round(fontSize);

        return fontSize;
    }

    get textTransform() {
        return this.styles.textTransform;
    }

    fontHeight(style = TextStyleEnum.REGULAR, size = this.fontSize(TextStyleEnum.REGULAR)) {
        var font = this.textBox.getFontForTextStyle(style);

        var fontHeight;

        // if (this.textBox.usePixelHeight) {
        let metrics = this.cachedRegularFont.charToGlyph("H").getMetrics();
        return this.convertFontUnitsToPixels("H", metrics.yMax - metrics.yMin, size, TextStyleEnum.REGULAR);
        // } else {
        //     fontHeight = font.tables.head.yMax + Math.abs(font.tables.head.yMin);
        //     return fontHeight * this.fontSizeMultiplier(font, size);
        // }
    }

    fontVerticalOffset(style) {
        return 0;
    }

    createLine(charIndex) {
        return {
            words: [],
            width: 0,
            charIndex: charIndex,
            charCount: 0
        };
    }

    shouldWrap(bounds, line, paragraph, word) {
        // if (word.text == " ") return false;
        //
        // if (TextModel.punctuationRegex.test(paragraph.words[word.wordIndex - 1]).text){
        //     return false; // don't break if previous char is punctuation
        // }
        // if (TextModel.breakWordRegex.test(word.text) && paragraph.words[word.wordIndex -1].text != " ") {
        //     return false; // don't break if this char is punctuation or a space
        // }
        return Math.ceil(line.width + word.width) > Math.ceil(bounds.width) || (this.textBox.options.forceWrapWidth && Math.ceil(line.width + word.width) > this.textBox.options.forceWrapWidth);
    }

    calculateWordWidth(word) {
        let text = word.formattedText || word.text;
        switch (this.textTransform) {
            case "uppercase":
                text = text.toUpperCase();
                break;
            case "lowercase":
                text = text.toLowerCase();
                break;
        }

        word.formattedText = text;

        const font = this.textBox.getFontForTextStyle(word.style);

        if (app.wordWidthCache[text] && app.wordWidthCache[text][font.name] && app.wordWidthCache[text][font.name][word.style]) {
            word.scaledGlyphWidths = app.wordWidthCache[text][font.name][word.style];
            const fontSizeScale = word.fontSize / 100;
            word.glyphWidths = word.scaledGlyphWidths.map(w => w * fontSizeScale);
            word.width = _.sum(word.glyphWidths);
        } else {
            if (text.codePointAt() === 13) {
                word.glyphWidths = [0];
                word.scaledGlyphWidths = [0];
                word.width = 0;
                // Some fonts don't have a glyph for the line break char (bebasneue)
                const glyph = fontManager.fontHasGlyphForChar(this.cachedRegularFont, text[0]) ? this.cachedRegularFont.charToGlyph(text[0]) : fontManager.fallbackFont.charToGlyph(text[0]);
                word.glyphs = [glyph];
            } else {
                const scaledSpaceWidth = 100 * this.styles.letterSpacing || 0;
                const fontSizeScale = word.fontSize / 100;

                word.glyphs = [];
                word.scaledGlyphWidths = [];
                word.glyphWidths = [];

                text.normalize().split("").map((chr, index) => {
                    const font = this.textBox.getFallbackFont(word.style, chr) || this.textBox.getFontForTextStyle(word.style);
                    const glyph = font.charToGlyph(chr);
                    const unitsPerEm = font.unitsPerEm;
                    const fontScale = 1 / (unitsPerEm / 100);
                    let advanceWidth = glyph.advanceWidth;
                    if (Number.isNaN(advanceWidth)) {
                        const metrics = glyph.getMetrics();
                        const newWidth = Math.round(metrics.xMax + metrics.xMin);
                        advanceWidth = newWidth;
                    }
                    const scaledWidth = (advanceWidth * fontScale) + scaledSpaceWidth;
                    word.glyphs[index] = glyph;
                    word.scaledGlyphWidths[index] = scaledWidth;
                    word.glyphWidths[index] = scaledWidth * fontSizeScale;
                });

                word.width = _.sum(word.glyphWidths);
            }
            !app.wordWidthCache[text] && (app.wordWidthCache[text] = {});
            !app.wordWidthCache[text][font.name] && (app.wordWidthCache[text][font.name] = {});

            app.wordWidthCache[text][font.name][word.style] = word.scaledGlyphWidths;
        }

        return word.width;
    }

    convertFontUnitsToPixels(ch, value, size, style) {
        return value * size / this.textBox.getFontForTextStyle(style).unitsPerEm;
    }

    createWord(wordModel, wordIndex = 0, paragraphIndex = 0) {
        const word = {
            text: wordModel.text,
            fontSize: this.fontSize(wordModel.style),
            style: wordModel.style,
            link: wordModel.link,
            color: wordModel.color,
            wordIndex: wordIndex,
            paragraphIndex: paragraphIndex,
            isSpace: wordModel.text === " "
        };

        //cache and compute word width
        this.calculateWordWidth(word);
        return word;
    }

    buildLines(bounds, model) {
        var lines = [];

        this.hasEmphasizedWords = false;
        let paragraphIndex = 0;

        // calculate wrapping runs
        let paragraphRuns = [];
        model.paragraphs.forEach(paragraph => {
            let wordRun = [];

            let wordIndex = 0;
            let run = [];
            for (let wordModel of paragraph.words) {
                const word = this.createWord(wordModel, wordIndex, paragraphIndex);
                if (word.style.equalsAnyOf(TextStyleEnum.BOLD, TextStyleEnum.BOLDITALIC)) {
                    this.hasEmphasizedWords = true;
                }

                run.push(word);
                if (word.isSpace) {
                    wordRun.push(run);
                    run = [];
                }
            }
            if (run.length) {
                wordRun.push(run);
            }
            paragraphRuns.push(wordRun);
            paragraphIndex++;
        });

        paragraphIndex = 0;
        let fontSize = null;
        // wrap lines
        paragraphRuns.forEach(paragraphRun => {
            let line = this.createLine(0);
            let lineIndex = 0;
            line.paragraphIndex = paragraphIndex;
            line.isParagraphStart = true;

            for (let wordRun of paragraphRun) {
                let runWidth = _.sumBy(wordRun, w => w.width);

                // check if we need to wrap and add a new line if we do

                if (line.words.length && Math.ceil(line.width + runWidth) > Math.ceil(bounds.width)) {
                    //use the last computed fontsize which could be the font size of the previous line.
                    line.fontSize = fontSize;

                    // add the current line to the lines array
                    lines.push(line);

                    // create a new line
                    line = this.createLine(line.charIndex + line.charCount);
                    line.paragraphIndex = paragraphIndex;
                    lineIndex++;
                }
                fontSize = wordRun[0].fontSize;// Math.max(word.fontSize, line.fontSize || 0);
                line.words = line.words.concat(wordRun);

                _.each(wordRun, w => w.line = line);

                // if (word.formattedText) {
                //     line.charCount += word.formattedText.length;
                // } else {
                line.charCount += _.sumBy(wordRun, word => word.text.length);
                // }

                if (runWidth > 0) {
                    line.width += runWidth;
                }

                // wordIndex++;
            }

            //use the last computed fontsize which could be the font size of the previous line.
            line.fontSize = fontSize;

            lines.push(line); // add the last line to the lines array

            paragraphIndex++;
        });

        return lines;
    }

    positionLines(lines, bounds) {
        // calculate any scale
        var curParagraphIndex = 0;

        // now that we have the words broken into lines, we can position the words within each line
        var lineY = 0;
        let fontHeight = lines[0] && this.fontHeight(TextStyleEnum.REGULAR, lines[0].fontSize);
        let verticalOffset = 0;
        lines.forEach(line => {
            if (line.paragraphIndex != curParagraphIndex) {
                curParagraphIndex = line.paragraphIndex;
                lineY += this.styles.paragraphSpacing || 0;
            }

            line.y = lineY;

            var x = 0;

            // switch (this.textBox.canFormat && this.textBox.model.get("text_format")) {
            //     case "numbered_list":
            //         x += this.styles.numbered.listIndent || this.styles.listIndent || 0;
            //         break;
            //     case "bullet_list":
            //         x += this.styles.bullet.listIndent || this.styles.listIndent || 0;
            //         break;
            // }

            line.words.forEach(word => {
                word.fontSize = this.fontSize(word.style);

                // store position of word (we will position svg paths in DOM later)
                word.x = x;
                // word y is the line's y + the baseline height (calculated from the font ascender) - any vertical offset defined by the layout engine
                verticalOffset = this.verticalOffset(word.style) + this.textBox.getFontForTextStyle(word.style).fontHeight * (word.fontSize / 100);
                word.y = lineY + verticalOffset;

                // x += this.calculateWordWidth(word) + (this.letterSpacing * word.fontSize);
                x += word.width;
            });

            // give a word offset. vertical offset will be the last valid word's vertical offset (eg: the previous line).
            line.wordY = lineY + verticalOffset;

            line.height = fontHeight;
            line.height = line.height * this.lineHeight;
            // line.height = line.height * this.lineHeight * (1 - (line.height - 32) * .0025);

            line.fontHeight = fontHeight;

            lineY += line.height;
        });

        // do horizontal align
        if (lines.length > 0) {
            switch (this.textAlign) {
                case "start":
                case "left":
                    break;
                case "center": {
                    let centerX = _.maxBy(lines, line => line.width).width / 2;
                    for (let line of lines) {
                        let offsetX = centerX - line.width / 2;
                        for (let word of line.words) {
                            word.x += offsetX;
                        }
                    }
                    break;
                }
                case "right": {
                    let maxLineWidth = _.maxBy(lines, line => line.width).width;

                    for (let line of lines) {
                        let offsetX = maxLineWidth - line.width;
                        if (line.words.length > 0) {
                            if (_.last(line.words).text == " ") {
                                offsetX += this.spaceWidth;
                            }
                            for (let word of line.words) {
                                word.x += offsetX;
                            }
                        }
                    }
                    break;
                }
            }
        }
    }

    getActualTextHeight(lines) {
        let height = 0;
        for (let line of lines) {
            let yMin = 0;
            let yMax = 0;

            for (let word of line.words) {
                if (word.glyphs) {
                    for (let glyph of word.glyphs) {
                        let metrics = glyph.getMetrics();
                        yMin = Math.min(metrics.yMin, yMin);
                        yMax = Math.max(metrics.yMax, yMax);
                    }
                }
            }

            let lineHeight = this.convertFontUnitsToPixels("a", yMax - yMin, this.fontSize(TextStyleEnum.REGULAR), TextStyleEnum.REGULAR);
            height = line.y + lineHeight;
        }

        return height;
    }

    getTextSize(lines, usePixelTextHeight) {
        let totalWidth = _.maxBy(lines, line => line.width).width;

        let lastLine = Math.min(lines.length, this.styles.maxLines || 1000) - 1;

        // let totalHeight = lines[lastLine].y + this.fontHeight(TextStyleEnum.REGULAR, this.fontSize(TextStyleEnum.REGULAR));
        let totalHeight = lines[lastLine].y + lines[lastLine].fontHeight;

        // let totalHeight = lines[lastLine].y + lines[lastLine].height;
        // if (usePixelTextHeight && this.textBox.getCharCount() > 0) {
        //     totalHeight = this.getActualTextHeight(lines);
        // }

        return new geom.Size(totalWidth, totalHeight);
    }

    calcSize(size, usePixelTextHeight) {
        let lines = this.buildLines(size);
        this.positionLines(lines, size);
        var textBounds = this.getTextSize(lines, usePixelTextHeight);
        return textBounds;
    }

    layout(size, model) {
        let lines = this.buildLines(size, model);
        this.positionLines(lines, size);

        return {
            lines: lines,
            size: this.getTextSize(lines, this.textBox.usePixelHeight),
            containerSize: size,
            hasEmphasizedWords: this.hasEmphasizedWords
        };
    }
}

class DefaultTextLayout extends TextLayout {
}

class EvenBreakTextLayout extends TextLayout {
    fontVerticalOffset(style) {
        switch (style) {
            case TextStyleEnum.BOLD:
                return 0;
            default:
                return 0;
        }
    }

    get lineHeight() {
        return this.styles.lineHeight || 1;
    }

    calcSize(size) {
        return this.layout(size).textBounds;
    }

    get minLines() {
        return 1;
    }

    get maxLines() {
        return this.textBox.options.goalLines || 100;
    }

    goalWidth(bounds) {
        return bounds.width;
        // return Math.min(800, bounds.width);
    }

    buildLines(size, model) {
        // if there are multiple paragraphs or no paragraphs, don't do evenBreaking
        if (model.paragraphs.length != 1) {
            return super.buildLines(size, model);
        }

        const paragraph = model.paragraphs[0];
        const lines = [];
        const words = [];
        let wordIndex = 0;

        for (let wordModel of paragraph.words) {
            const word = this.createWord(wordModel, wordIndex, 0);
            if (word.style.equalsAnyOf(TextStyleEnum.BOLD, TextStyleEnum.BOLDITALIC)) {
                this.hasEmphasizedWords = true;
            }
            words.push({
                width: word.width,
                value: word,
                canBreak: word.isSpace
            });
            wordIndex++;
        }

        let brokenLines = breakLines(words, size.width, { goalWidth: this.goalWidth(size) });

        //default to the standard buildLines if we are unable to find a result.
        if (!brokenLines) {
            return super.buildLines(size, model);
        }

        if (brokenLines) {
            for (let breakLineWords of brokenLines) {
                let line = this.createLine(0);
                line.paragraphIndex = 0;

                for (let word of breakLineWords) {
                    word.fontSize = this.fontSize(word.style);

                    let wordWidth = this.calculateWordWidth(word);

                    line.fontSize = Math.max(word.fontSize, line.fontSize || 0);
                    line.words.push(word);
                    word.line = line;

                    if (word.formattedText) {
                        line.charCount += word.formattedText.length;
                    } else {
                        line.charCount += word.text.length;
                    }
                    if (word !== breakLineWords[breakLineWords.length - 1] || !word.isSpace) {
                        line.width += wordWidth;
                    }
                }

                lines.push(line);
            }
        }

        return lines;
    }
}

class CaptionTextLayout extends EvenBreakTextLayout {

    //     fontSize(style){
    //         return 30;
    //     }

}

class FitTextLayout extends DefaultTextLayout {
    get verticalAlign() {
        return "middle";
    }

    get lineHeight() {
        return 1;
    }

    get scaleFontSize() {
        return true;
    }
}

class BlockTextLayout extends EvenBreakTextLayout {
    get textAlign() {
        return "center";
    }

    get verticalAlign() {
        return "middle";
    }

    get scaleFontSize() {
        return true;
    }

    get scaleTextToFit() {
        return true;
    }

    get textTransform() {
        return "uppercase";
    }

    fontSize(style) {
        return 50 + (this.styles.textFlow || 0 * 20);
    }

    get maxFontSize() {
        return this.styles.maxFontSize || 600;
    }

    goalWidth(bounds) {
        return bounds.width * .666;
    }

    // get optimalCharsPerLine() {
    //     return 13;
    // }

    //     get lineHeight() {
    //         return 20;
    //     }

    shouldWrap(bounds, line, paragraph, word, wordWidth) {
        if (this.textBox.disableReturn || this.textBox.autoWidth()) {
            return false;
        } else {
            if (!this.optimalCharsPerLine) {
                this.optimalCharsPerLine = Math.round(bounds.width / 50);
            }

            if (line.charCount > this.optimalCharsPerLine) {
                return true;
            } else {
                var wordIndex = paragraph.words.indexOf(word);
                //                 if (wordIndex > 0) {
                //                     // wrap if the last word was emphasized
                //                     if (paragraph.words[wordIndex - 1].style == TextStyleEnum.BOLD) {
                //                         return true;
                //                     }
                //                 } else {
                //                     return false; // don't ever wrap the first word in a paragraph
                //                 }
                //                 if (word.style == TextStyleEnum.BOLD) {
                //                     // wrap if the next word is emphasized
                //                     return true;
                //                 }
                if (word.glyphs && word.glyphs.length) {
                    wordWidth -= this.convertFontUnitsToPixels(_.last(word.text.split("")), _.last(word.glyphs).getMetrics().xMin, this.fontSize(word.style), word.style);
                }

                return line.width + wordWidth > bounds.width;
            }
        }
    }

    positionLines(lines, bounds) {
        // calculate the font unit height of the H character
        var hGlyph = this.cachedRegularFont.charToGlyph("H");
        // calculate the offset from the ascender to the actual top of the text so we can position the words exactly - this only works for blocky all-caps type fonts
        var offset = this.convertFontUnitsToPixels("H", this.cachedRegularFont.ascender - hGlyph.getMetrics().yMax, this.fontSize(TextStyleEnum.REGULAR), TextStyleEnum.REGULAR);

        var baseFontHeight = this.convertFontUnitsToPixels("H", hGlyph.getMetrics().yMax, this.fontSize(TextStyleEnum.REGULAR), TextStyleEnum.REGULAR);

        // now that we have the words broken into lines, we can position the words within each line
        var lineY = 0;
        let fontHeight = lines[0] && this.fontHeight(TextStyleEnum.REGULAR, lines[0].fontSize);
        for (let line of lines) {
            var scale = Math.min(bounds.width / line.width, 50);

            line.y = lineY;
            var x = 0;

            line.fontSize *= scale;

            for (let word of line.words) {
                // adjust fontSize for scale
                word.fontSize = line.fontSize;

                // store position of word (we will position svg paths in DOM later)
                word.x = x;
                word.y = line.y - offset * scale;
                x += this.calculateWordWidth(word) + this.spaceWidth * scale;
            }

            line.width = x - this.spaceWidth * scale;
            line.fontHeight = fontHeight;
            line.height = line.fontHeight + (this.lineSpacing || 10);

            lineY += line.height;
        }
    }

    getTextSize(lines) {
        let totalWidth = _.maxBy(lines, line => line.width).width;
        let totalHeight = 0;

        let fontHeight = lines[0] && this.fontHeight(TextStyleEnum.REGULAR, lines[0].fontSize);
        for (let line of lines) {
            totalHeight += fontHeight + (this.lineSpacing || 10);
        }
        totalHeight -= this.lineSpacing || 10;

        if (this.textBox.getCharCount() == 0) {
            totalHeight = 10;
        }
        return new geom.Size(totalWidth, totalHeight);
    }
}

class BigStatLayout extends DefaultTextLayout {
}

export {
    TextLayout,
    DefaultTextLayout,
    EvenBreakTextLayout,
    CaptionTextLayout,
    FitTextLayout,
    BlockTextLayout,
    BigStatLayout
};
